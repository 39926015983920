import { Method } from "axios";
import instance from "api/instance";

const request = async (
  methods: Method,
  urls: string,
  headers = {},
  jsonData: any
) => {
  if (methods === "get") {
    try {
      const data = await instance({
        method: methods,
        url: urls,
        headers: {
          ...headers,
        },
        params: jsonData,
      });

      return data;
    } catch (err) {
      //console.log("request function error", err);
      await Promise.reject(err);
    }
  } else {
    try {
      const data = await instance({
        method: methods,
        url: urls,
        headers: {
          ...headers,
        },
        data: jsonData,
      });

      return data;
    } catch (err) {
      //console.log("request function error", err);
      await Promise.reject(err);
    }
  }
};

export default request;
