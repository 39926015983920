import { useNavigate } from "react-router-dom";

import Button from "components/Button/Button";

const VacationAppForm = () => {
  const navigate = useNavigate();

  return (
    <>
      <ul className="approval-detail-form">
        <li>
          <div>신청자</div>
          <div>김누리</div>
        </li>
        <li>
          <div>직급·직책</div>
          <div></div>
        </li>
        <li>
          <div>부서</div>
          <div></div>
        </li>
        <li>
          <div>사원번호</div>
          <div></div>
        </li>
        <li>
          <div>보유 연차 개수</div>
          <div></div>
        </li>
        <li>
          <div>신청일</div>
          <div></div>
        </li>
        <li>
          <div>사용 기간</div>
          <div></div>
        </li>
        <li>
          <div>사유</div>
          <div></div>
        </li>
        <li>
          <div>인수인계자</div>
          <div></div>
        </li>
        <li>
          <div>인수인계 현황</div>
          <div></div>
        </li>
        <li>
          <div>비고</div>
          <div></div>
        </li>
      </ul>
      <div className="submit-btn">
        <Button
          type="button"
          variant="tertiary"
          preset="1"
          onClick={() => navigate(-1)}
        >
          목록
        </Button>
        <span className="void-sec"></span>
        <Button type="button" variant="secondary" preset="1">
          반려
        </Button>
        <span className="void-sec"></span>
        <Button type="button" variant="primary" preset="2">
          결재
        </Button>
      </div>
    </>
  );
};

export default VacationAppForm;
