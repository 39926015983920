/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

interface LabelProps {
  children: React.ReactNode;
  isHightlight?: boolean;
}

const Label = (props: LabelProps) => {
  const { children, isHightlight } = props;
  const root = rootCss();

  return (
    <label css={root}>
      {children}
      {isHightlight && <span>*</span>}
    </label>
  );
};

const rootCss = () => {
  return css`
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #222;

    & span {
      color: #c09260;
      margin-left: 4px;
    }
  `;
};

export default Label;
