import React, { useState, useEffect, useRef } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import request from "api/request";

import Layout from "layout/layout";

import usePageSlice from "hooks/usePageSlice";

import Filter from "components/Filter/Filter";
import Select from "components/Select/Select";
import Search from "components/Search/Search";
import ProfileImage from "components/Profile/Profile";
import Pagination from "components/Pagination/Pagination";

import {
  PAGE_LAYOUT,
  EmployeeSelectOptions,
  PartTimerSupportFilterOptions,
  PARTTIMER_SUPPORT_PART_OPTIONS,
  EDUCATION_OPTIONS,
  GRADUATE_OPTIONS,
  MILITARY_OPTIONS,
  DISABLED_OPTIONS,
} from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";
import { useTheme } from "utils/context/themeProvider";

import NoDataSVG from "assets/images/no_data_ico.svg";
import IcDropBtnSVG from "assets/images/icon_drop.svg";
import DownloadSVG from "assets/images/download_ic2.svg";
import CredentialsSVG from "assets/images/credentials.svg";

const defaultValues = {
  filter: [
    PartTimerSupportFilterOptions.map((e: any) => {
      if (e.checked) return e.value;
    }),
  ],
  select: {
    value: "ALL",
    label: "전체",
  },
  search: "",
};

const PartTimerRegist = () => {
  const navigate = useNavigate();
  const [ThemeMode] = useTheme();
  const { permissions } = useSelector((s: any) => s.auth);
  const scrollContentRef = useRef<HTMLDivElement | null>(null);
  const [sWidth, setSWidth] = useState<number>(0);
  const [tableData, setTableData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const fixed = FixedWrap();
  const scroll = ScrollWrap(sWidth);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (scrollContentRef.current)
      setSWidth(scrollContentRef?.current?.scrollWidth);
  }, [values.filter, scrollContentRef, tableData]);

  //useEffect(() => {
  //  request("get", "/resume", {}, {})
  //    .then((res: any) => {
  //      if (res.status === 200) {
  //        setTableData(res?.data.result.data);
  //        setTotalCount(res.data.result.total_elements);
  //        setTotalPages(Math.ceil(res.data.result.total_elements / 10));
  //      }
  //    })
  //    .catch((err) => {
  //      console.log(err);
  //    });
  //}, []);

  const pageArr = usePageSlice(tableData);

  return (
    <Layout page={PAGE_LAYOUT.part_timer_regist_list}>
      <PartTimerSupportStyled>
        <div className="status-wrap">
          <div className="status-header">
            <div className="search-sec">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(
                    () => {},
                    () => {}
                  )();
                }}
              >
                <div className="filter">
                  <Controller
                    control={control}
                    name="filter"
                    render={({ field }) => (
                      <Filter
                        {...field}
                        option={PartTimerSupportFilterOptions}
                        handleFormSubmit={() => {}}
                      />
                    )}
                  />
                </div>
                <div className="search">
                  <div className="search-select">
                    <Controller
                      control={control}
                      name="select"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="전체"
                          w="130"
                          h="52"
                          fontS="15"
                          variant="tertiary"
                          color={ThemeMode === "light" ? "#000" : "#fff"}
                          bgColor={ThemeMode === "light" ? "#fff" : "#211f1e"}
                          options={EmployeeSelectOptions}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name="search"
                      render={({ field }) => (
                        <Search
                          {...field}
                          color={ThemeMode === "light" ? "#000" : "#c9c9c9"}
                          bgcolor={ThemeMode === "light" ? "#fff" : "#211f1e"}
                          placeholder="검색어를 입력하세요."
                          handleFormSubmit={() => {}}
                        />
                      )}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="status-btn">
              <div
                className="xlsx-download"
                onClick={() => navigate("/work/part_timer/regist")}
              >
                파트타이머 추가
              </div>
            </div>
          </div>
          <div>
            {tableData?.length > 0 ? (
              <div className="status-table">
                <div css={fixed}>
                  <div className="fixed-table">
                    <div>
                      상태 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                    <div>
                      지원자 번호{" "}
                      <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                    <div>
                      성명 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                    <div>
                      생년월일 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                    <div>
                      성별 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                  </div>
                  <div className="fixed-data">
                    {pageArr[page - 1]?.map((item: any, i: number) => {
                      const idToString = item?.id?.toString();
                      return (
                        <div key={i}>
                          <div>
                            {item.state === "APPROVE_WAITING" && (
                              <span className="resume-write">대기중</span>
                            )}
                            {item.state === "TEMPORARY_SAVE" && (
                              <span className="resume-write">
                                지원서 작성중
                              </span>
                            )}
                            {item.state === "APPROVED" && (
                              <span className="approve-status">수락</span>
                            )}
                            {item.state === "APPROVE_REJECTED" && (
                              <span>거절</span>
                            )}
                          </div>
                          <div>
                            {idToString.length === 1
                              ? "A00000" + item.id
                              : "A0000" + item.id}
                          </div>
                          <div className="fixed-data-profile">
                            <ProfileImage
                              width="28px"
                              height="28px"
                              fileId={
                                item?.image_info ? item?.image_info?.id : null
                              }
                              name={item.resume_name}
                              isPopup={true}
                            />
                            <span>{item.resume_name}</span>
                          </div>
                          <div>{item.birth_day ? item.birth_day : "-"}</div>
                          <div>{item.gender ? item.gender : "-"}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="fixed-line"></div>
                <div css={scroll}>
                  <div className="scroll-table">
                    <div>
                      {values.filter[0]?.map((item: any, i: number) => {
                        const clientWidth =
                          scrollContentRef?.current?.children[0]?.children[
                            i - 1
                          ]?.clientWidth;

                        const result = PartTimerSupportFilterOptions.filter(
                          (e: any) => e.value === item
                        );

                        return (
                          item !== "All" && (
                            <div
                              key={i}
                              css={{
                                width: `${clientWidth}px`,
                                minWidth: "120px",
                              }}
                            >
                              {result[0]?.label}{" "}
                              <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                  <div className="scroll-data" ref={scrollContentRef}>
                    {pageArr[page - 1]?.map((item: any, i: number) => {
                      return (
                        <div
                          key={i}
                          onClick={() =>
                            navigate(
                              `/curr_status/part_timer_support/detail/${i}`
                            )
                          }
                        >
                          {values.filter[0].includes("email") && (
                            <div className="email-sec">{item.email}</div>
                          )}
                          {values.filter[0].includes("address") && (
                            <div className="address-sec">
                              ({item.zip_code}){item.address}{" "}
                              {item.detail_address}
                            </div>
                          )}
                          {values.filter[0].includes("primary") && (
                            <div>
                              {item.job1_depth2
                                ? PARTTIMER_SUPPORT_PART_OPTIONS.filter(
                                    (e: any) => e.value === item.job1_depth2
                                  )[0]?.label
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("secondary") && (
                            <div>
                              {item.job2_depth2
                                ? PARTTIMER_SUPPORT_PART_OPTIONS.filter(
                                    (e: any) => e.value === item.job2_depth2
                                  )[0]?.label
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("highestLevel") && (
                            <div>
                              {item.education
                                ? EDUCATION_OPTIONS.filter(
                                    (e: any) => e.value === item.education
                                  )[0]?.label
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("schoolName") && (
                            <div>
                              {item.school_name ? item.school_name : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("major") && (
                            <div>{item.major ? item.major : "-"}</div>
                          )}
                          {values.filter[0].includes("graduationStatus") && (
                            <div>
                              {item.graduation_category
                                ? GRADUATE_OPTIONS.filter(
                                    (e: any) =>
                                      e.value === item.graduation_category
                                  )[0]?.label
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("classOf") && (
                            <div>{item.graduation ? item.graduation : "-"}</div>
                          )}
                          {values.filter[0].includes("career") && <div>-</div>}
                          {values.filter[0].includes("military") && (
                            <div>
                              {item.military_service
                                ? MILITARY_OPTIONS.filter(
                                    (e: any) =>
                                      e.value === item.military_service
                                  )[0]?.label
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("disability") && (
                            <div>
                              {item.disabled
                                ? DISABLED_OPTIONS.filter(
                                    (e: any) => e.value === item.disabled
                                  )[0]?.label
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("bank") && (
                            <div>{item.bank ? item.bank : "-"}</div>
                          )}
                          {values.filter[0].includes("account") && (
                            <div className="account">
                              {item.account_number ? item.account_number : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("credentials") && (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  `/curr_status/part_timer_support/credential/${item.id}`,
                                  {
                                    state: {
                                      resumeId: item.id,
                                      resumeName: item.resume_name,
                                      license: JSON.parse(item.license_info),
                                    },
                                  }
                                );
                              }}
                            >
                              {item.license_info ? (
                                <img src={CredentialsSVG} alt="자격증 이미지" />
                              ) : (
                                "-"
                              )}
                            </div>
                          )}
                          {values.filter[0].includes("phoneNumber") && (
                            <div>
                              {item.phone
                                ? item.phone.substring(0, 3) +
                                  "-" +
                                  item.phone.substring(3, 7) +
                                  "-" +
                                  item.phone.substring(7, 11)
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("resume") && <div>-</div>}
                          {/*
                          <div>
                            {item.pay ? (
                              <a
                                href={DownloadSVG}
                                download
                                onClick={(e) => e.stopPropagation()}
                              >
                                <img
                                  src={DownloadSVG}
                                  alt="급여명세서 다운로드 이미지"
                                />
                              </a>
                            ) : (
                              <span onClick={(e) => e.stopPropagation()}>
                                -
                              </span>
                            )}
                          </div>
                          */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="list-no-data-wrap">
                <div>
                  <div>
                    <div className="no-data-img">
                      <img src={NoDataSVG} alt="검색 결과 없음 이미지" />
                    </div>
                    <div>검색 결과가 없습니다.</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="pagenation">
            <Pagination
              page={page}
              count={totalPages}
              list={tableData.length}
              onChangePage={setPage}
            />
          </div>
        </div>
      </PartTimerSupportStyled>
    </Layout>
  );
};

export default PartTimerRegist;

const FixedWrap = () => {
  return css`
    width: 45%;

    @media screen and (max-width: 1600px) {
      width: 70%;
    }

    .fixed-table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      font-size: 18px;
      font-weight: bold;

      & > div {
        cursor: pointer;
        text-align: center;
        width: 20%;
      }
    }

    .fixed-data {
      & > div {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          text-align: center;
          width: 20%;
        }
      }

      .fixed-data-profile {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin: 0 0 0 8px;
        }
      }
    }
  `;
};

const ScrollWrap = (sWidth: number) => {
  return css`
    width: 1000px;
    position: relative;
    left: -15px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c09260;
      border-radius: 10px;
      background-clip: padding-box;
    }

    @media screen and (max-width: 1600px) {
      width: 700px;
    }

    @media screen and (min-width: 1600px) {
      width: 1100px;
    }

    .scroll-table {
      width: ${`${sWidth}px`};

      & > div {
        width: ${`${sWidth}px`};
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 20px 20px;
        font-size: 18px;
        font-weight: bold;

        & div {
          flex: 0 0 auto;
          cursor: pointer;
          margin: 0 30px;
          text-align: center;
        }
      }
    }

    .scroll-data {
      width: ${`${sWidth}px`};

      & > div {
        width: ${`${sWidth}px`};
        padding: 20px 0 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          min-width: 120px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 auto;
          cursor: pointer;
          margin: 0 30px;
          text-align: center;
        }

        .email-sec {
          min-width: 200px;
        }

        .account {
          min-width: 160px;
        }

        .address-sec {
          width: 220px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: start;
        }
      }
    }
  `;
};

const PartTimerSupportStyled = styled.div`
  width: 100%;
  margin: 40px;

  .resume-write {
    font-size: 15px;
    font-weight: bold;
    color: #222;
  }

  .approve-status {
    font-size: 15px;
    font-weight: bold;
    color: #c09260;
  }

  .approve-btn {
    font-size: 14px;
    font-weight: bold;
    color: #c09260;
    width: 48px;
    height: 30px;
    border-radius: 3px;
    border: solid 1px #c09260;
  }

  .reject-btn {
    font-size: 14px;
    font-weight: bold;
    color: #a89a8b;
    width: 48px;
    height: 30px;
    border-radius: 3px;
    border: solid 1px #a89a8b;
  }

  .void-sec {
    margin: 0 2px;
  }
`;
