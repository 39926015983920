import { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "components/Input/Input";
import Textbox from "components/TextBox/TextBox";
import Search from "components/Search/Search";
import Pagination from "components/Pagination/Pagination";

import BtnCheckOffSVG from "assets/images/btn_check_off.png";
import BtnCheckOnSVG from "assets/images/btn_check_on.png";

import { PAGE_LAYOUT, CODE_OPTIONS } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

const defaultValues = {
  search: "",
  subcodeName: "",
  subcode: {
    value: "init",
    label: "선택",
  },
};

const ItemManageForm = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [listData, setListData] = useState([{ test: "1111" }]);
  const [isAddItem, setIsAddItem] = useState(false);
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const handleDelList = () => {};

  const handleEditList = () => {};

  const handleSaveCode = () => {};

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <ItemManageFormStyled>
      <div className="itemmanage-form-wrap">
        <div className="itemmanage-form-inner">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
            }}
          >
            <Controller
              control={control}
              name="search"
              render={({ field }) => (
                <Search
                  {...field}
                  placeholder="검색어를 입력하세요."
                  handleFormSubmit={handleFormSubmit}
                />
              )}
            />
          </form>
          <div className="status-btn">
            <div className="itemmanage-btn" onClick={() => handleDelList()}>
              선택 삭제
            </div>
            <div className="itemmanage-btn" onClick={() => handleEditList()}>
              선택 수정
            </div>
            <div
              className="itemmanage-add-btn"
              onClick={() => setIsAddItem(true)}
            >
              품목 추가
            </div>
          </div>
        </div>
        <div className="itemmanage-form-body">
          <table>
            <thead>
              <tr>
                <th className="code-head-child1">
                  <input type="checkbox" name="" id="" />
                </th>
                <th className="code-head-child2">품목명</th>
                <th className="code-head-child3">규격</th>
                <th className="code-head-child3">단가</th>
                <th className="code-head-child3">세탁비용</th>
                <th className="code-detail-btn"></th>
              </tr>
            </thead>
            <tbody>
              {isAddItem && (
                <tr>
                  <td className="code-head-child1">
                    <input type="checkbox" name="" id="" />
                  </td>
                  <td className="code-head-child1-1 code-head-child2">
                    <Controller
                      control={control}
                      name="subcodeName"
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="text"
                          multiline={false}
                          fullWidth
                          errors={errors}
                        />
                      )}
                    />
                  </td>
                  <td className="code-head-child1-1 code-head-child3">
                    <div>
                      <Controller
                        control={control}
                        name="subcodeName"
                        render={({ field }) => (
                          <Textbox
                            {...field}
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            fullWidth
                            leftpostion="true"
                            errors={errors}
                          >
                            ₩
                          </Textbox>
                        )}
                      />
                    </div>
                  </td>
                  <td className="code-head-child1-1 code-head-child3">
                    <div>
                      <Controller
                        control={control}
                        name="subcodeName"
                        render={({ field }) => (
                          <Textbox
                            {...field}
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            fullWidth
                            leftpostion="true"
                            errors={errors}
                          >
                            ₩
                          </Textbox>
                        )}
                      />
                    </div>
                  </td>
                  <td className="code-head-child1-1 code-head-child3">
                    <div>
                      <Controller
                        control={control}
                        name="subcodeName"
                        render={({ field }) => (
                          <Textbox
                            {...field}
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            fullWidth
                            leftpostion="true"
                            errors={errors}
                          >
                            ₩
                          </Textbox>
                        )}
                      />
                    </div>
                  </td>
                  <td className="code-detail-btn">
                    <button onClick={() => handleSaveCode()}>저장</button>
                  </td>
                </tr>
              )}
              {listData.length > 0 &&
                listData.map((e: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td className="code-head-child1">
                        <input type="checkbox" name="" id="" />
                      </td>
                      <td className="code-head-child1-1 code-head-child2">
                        품목2
                      </td>
                      <td className="code-head-child1-1 code-head-child3">
                        1040*1940
                      </td>
                      <td className="code-head-child1-1 code-head-child3">
                        \ 10,000
                      </td>
                      <td className="code-head-child1-1 code-head-child3">
                        \ 10,000
                      </td>
                      <td className="code-detail-btn">
                        <button className="void-btn"></button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="pagenation">
            <Pagination
              page={page}
              count={totalPages}
              list={tableData.length}
              onChangePage={setPage}
            />
          </div>
        </div>
      </div>
    </ItemManageFormStyled>
  );
};

export default ItemManageForm;

const ItemManageFormStyled = styled.div`
  width: 100%;

  .itemmanage-form-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .itemmanage-btn {
    width: 100px;
    height: 44px;
    border-radius: 6px;
    background-color: #444;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
  }

  .itemmanage-add-btn {
    width: 100px;
    height: 44px;
    border-radius: 6px;
    background-color: #c09260;
    color: #fff;
    cursor: pointer;
  }

  .itemmanage-form-body {
    margin-top: 30px;
    table {
      width: 100%;
    }

    thead {
      height: 68px;
      background-color: #f9f9f9;

      th {
        font-size: 18px;
        font-weight: bold;
        vertical-align: middle;
        color: #101010;
      }
    }
    tbody {
      tr {
        height: 66px;
        border-bottom: 1px solid #ebebeb;
      }

      td {
        font-size: 15px;
        font-weight: normal;
        vertical-align: middle;
        color: #444;
      }
    }

    .code-head-child1 {
      width: 5%;
      text-align: center;

      input {
        appearance: none;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        background: url(${BtnCheckOffSVG}) no-repeat center/100%;

        &:checked {
          background: url(${BtnCheckOnSVG}) no-repeat center/100%;
        }
      }
    }

    .code-head-child1-1 {
      width: 23%;
      text-align: center;

      input {
        width: 50%;
        height: 40px;
        border: 1px solid #d9d9d9;
      }
    }
    .code-head-child2 {
      input {
        width: 50%;
      }
    }

    .code-head-child3 {
      & > div {
        margin-left: 25%;
      }

      input {
        width: 70%;
      }
    }

    .code-detail-btn {
      width: 48px;

      .void-btn {
        margin-right: 20px;
        opacity: 0;
      }
      button {
        margin-right: 20px;
        width: 48px;
        height: 30px;
        border-radius: 3px;
        border: solid 1px #c09260;
        color: #c09260;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
`;
