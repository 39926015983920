import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import Layout from "layout/layout";

import Button from "components/Button/Button";

import { PAGE_LAYOUT } from "utils/constants";

import HelpPNG from "assets/images/help_icon.png";

const Help = () => {
  const navigate = useNavigate();

  return (
    <Layout page={PAGE_LAYOUT.help}>
      <HelpStyled>
        <div>
          <div className="help-wrap">
            <div>
              <img src={HelpPNG} alt="" />
            </div>
            <div className="help-text">현재 페이지 준비중입니다!</div>
          </div>
          <div className="submit-btn">
            <Button
              type="button"
              variant="tertiary"
              preset="1"
              onClick={() => navigate(-1)}
            >
              이전 페이지로 이동
            </Button>
          </div>
        </div>
      </HelpStyled>
    </Layout>
  );
};

export default Help;

const HelpStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 40px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 215px 35px 35px 35px;

    img {
      width: 143px;
    }
  }

  .help-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: normal;
    color: #444;
  }

  .help-text {
    margin-top: 40px;
  }

  .submit-btn {
    width: 408px;
    margin: 0 auto;
    margin-top: 200px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
  }
`;
