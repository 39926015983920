import { useRef, useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import request from "api/request";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";

import Layout from "layout/layout";

import { PAGE_LAYOUT } from "utils/constants";

import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import SearchItemForm from "components/SearchPopup/SearchItemForm";

import { EMPLOYEE_TYPE_OPTIONS } from "utils/constants";

import IconCalSVG from "assets/images/icon_cal.svg";
import IconPlusSVG from "assets/images/icon_plus.svg";

import validation from "utils/validations/employeeValidationSchema";

import LinenTapMenu from "../LinenTapMenu";

import "react-dayjs-picker/dist/index.css";

interface InventoryProcessProps {
  inventoryProcessDate: string;
  inventoryProcessPlace: {
    value: string;
    label: string;
  };
  inventoryProcessFloor: {
    value: string;
    label: string;
  };
  inventoryProcessInput: {
    inventoryProcessItem: [];
    inventoryProcessCount: string;
    inventoryProcessItemPrice: string;
    inventoryProcessBuyPrice: string;
    inventoryProcessStandard: string;
  }[];
  inventoryProcessTotal: string;
}

const defaultValues: InventoryProcessProps = {
  inventoryProcessDate: "",
  inventoryProcessPlace: {
    value: "init",
    label: "선택",
  },
  inventoryProcessFloor: {
    value: "init",
    label: "선택",
  },
  inventoryProcessInput: [
    {
      inventoryProcessItem: [],
      inventoryProcessCount: "",
      inventoryProcessItemPrice: "",
      inventoryProcessBuyPrice: "",
      inventoryProcessStandard: "",
    },
  ],
  inventoryProcessTotal: "",
};

const InventoryProcess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openDate, setOpenDate] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validation),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const {
    fields: inventoryProcessFields,
    append: inventoryProcessAppend,
    remove: inventoryProcessRemove,
  } = useFieldArray({
    control,
    name: "inventoryProcessInput",
  });

  const handleAddInventoryProcess = useCallback(() => {
    inventoryProcessAppend({
      inventoryProcessItem: [],
      inventoryProcessCount: "",
      inventoryProcessItemPrice: "",
      inventoryProcessBuyPrice: "",
      inventoryProcessStandard: "",
    });
  }, [inventoryProcessAppend]);

  return (
    <Layout page={PAGE_LAYOUT.linen2}>
      <InventoryProcessStyled>
        <LinenTapMenu textChange={location?.state?.textChange} />
        <div className="inventory-process-wrap">
          <div className="inventory-process-title">
            {location?.state?.title}
          </div>
          <div className="inventory-process-inner">
            <form
              onSubmit={(e) => {
                e.preventDefault();

                handleSubmit(
                  () => {},
                  (err) => {
                    console.log(err);
                  }
                )();
              }}
            >
              <h3>&#183; 정보 &#183;</h3>
              <div className="inventory-process-border">
                <Row label="날짜">
                  <div
                    className="date-image"
                    onClick={() => {
                      setOpenDate(true);
                    }}
                  >
                    <img src={IconCalSVG} alt="캘린더" />
                  </div>
                  <div className="input-sec date-input">
                    <Controller
                      control={control}
                      name="inventoryProcessDate"
                      render={({ field }) => (
                        <DatePicker
                          //register={register("employeeBOD")}
                          isOpen={openDate}
                          setIsOpen={setOpenDate}
                          popoverPositions={["bottom"]}
                          onSelect={(date) => {
                            field.onChange(dayjs(date).format("YYYY-MM-DD"));
                          }}
                          value={field.value}
                          closeOnSelect={true}
                          onChange={() => {}}
                          placeholder="생년월일을 선택하세요."
                          //errors={errors}
                        />
                      )}
                    />
                  </div>
                </Row>
                <Row label="지점">
                  <div className="input-sec">
                    <Controller
                      control={control}
                      name="inventoryProcessPlace"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="선택"
                          variant="primary"
                          fullWidth
                          options={EMPLOYEE_TYPE_OPTIONS}
                          errors={errors}
                        />
                      )}
                    />
                  </div>
                </Row>
                <Row label="층">
                  <div className="input-sec">
                    <Controller
                      control={control}
                      name="inventoryProcessFloor"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="선택"
                          variant="primary"
                          fullWidth
                          options={EMPLOYEE_TYPE_OPTIONS}
                          errors={errors}
                        />
                      )}
                    />
                  </div>
                </Row>
              </div>
              <div>
                <h3>&#183; 구매 품목 &#183;</h3>
                {inventoryProcessFields.map((item: any, index: number) => {
                  return (
                    <div key={item.id} className="newpurchase-border">
                      <SearchItemForm
                        label="구매 품목"
                        placeName={`inventoryProcessInput.${index}.inventoryProcessItem`}
                        Controller={Controller}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                      />
                      <Row label="수량">
                        <div className="input-sec">
                          <Controller
                            control={control}
                            name={`inventoryProcessInput.${index}.inventoryProcessCount`}
                            render={({ field }: any) => (
                              <Input
                                {...field}
                                type="text"
                                multiline={false}
                                fullWidth={false}
                                errors={errors}
                              />
                            )}
                          />
                        </div>
                      </Row>
                      <Row label="품목 단가">
                        <div className="input-sec">
                          <Controller
                            control={control}
                            name={`inventoryProcessInput.${index}.inventoryProcessItemPrice`}
                            render={({ field }: any) => (
                              <Input
                                {...field}
                                type="text"
                                placeholder="₩"
                                multiline={false}
                                fullWidth={false}
                                errors={errors}
                                disabled
                              />
                            )}
                          />
                        </div>
                      </Row>
                      <Row label="구매 단가">
                        <div className="input-sec">
                          <Controller
                            control={control}
                            name={`inventoryProcessInput.${index}.inventoryProcessBuyPrice`}
                            render={({ field }: any) => (
                              <Input
                                {...field}
                                type="text"
                                placeholder="₩"
                                multiline={false}
                                fullWidth={false}
                                errors={errors}
                                disabled
                              />
                            )}
                          />
                        </div>
                      </Row>
                      <Row label="규격">
                        <div className="input-sec">
                          <Controller
                            control={control}
                            name={`inventoryProcessInput.${index}.inventoryProcessStandard`}
                            render={({ field }: any) => (
                              <Input
                                {...field}
                                type="text"
                                multiline={false}
                                fullWidth={false}
                                errors={errors}
                                disabled
                              />
                            )}
                          />
                        </div>
                      </Row>
                      <div className="section-add">
                        {inventoryProcessFields.length > 1 && (
                          <button
                            type="button"
                            className="delete-sec"
                            onClick={() => inventoryProcessRemove(1)}
                          >
                            삭제
                          </button>
                        )}
                        <button
                          className="add-sec"
                          type="button"
                          onClick={() => handleAddInventoryProcess()}
                        >
                          품목 추가{" "}
                          <img src={IconPlusSVG} alt="품목 추가 버튼" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="inventory-process-All">
                <Row label="총 구매 가격">
                  <div className="input-sec">
                    <Controller
                      control={control}
                      name="inventoryProcessTotal"
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="text"
                          placeholder="₩"
                          multiline={false}
                          fullWidth={false}
                          errors={errors}
                          disabled
                        />
                      )}
                    />
                  </div>
                </Row>
              </div>

              <div className="submit-btn">
                <Button
                  type="button"
                  variant="tertiary"
                  preset="1"
                  onClick={() => navigate(-1)}
                >
                  취소
                </Button>
                <span className="void-sec"></span>
                <Button type="submit" variant="primary" preset="2">
                  등록
                </Button>
              </div>
            </form>
          </div>
        </div>
      </InventoryProcessStyled>
    </Layout>
  );
};

export default InventoryProcess;

const InventoryProcessStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 75px 40px 40px 40px;

  form {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
  }

  .inventory-process-border {
    padding-top: 40px;
    border-top: 1px solid #222;
  }

  .input-sec {
    margin: 16px 0 30px;
  }
  .date-image {
    position: absolute;
    margin-top: 8px;
    margin-left: 620px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 49px;
    height: 49px;
    background-color: #444;
    cursor: pointer;
  }

  .date-input {
    input::placeholder {
      color: #b2b2b2;
    }
    input[name="rdp-date-picker"] {
      width: 100%;
      height: 65px;
      border: solid 1px #ddd;
      background-color: #fff;
      line-height: 1.29;
      font-size: 16px;
      color: #000;
      padding-left: 20px;
    }
    .react-tiny-popover-container {
      height: 100px;
      background-color: red;
    }
  }

  .section-add {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;

    & .delete-sec {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 8px 12px;
      border: solid 1px #444;
      margin-right: 6px;
      color: #444;
    }

    & .add-sec {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      background-color: #444;
      color: #fff;

      & img {
        margin-left: 4px;
      }
    }
  }

  .inventory-process-All {
    padding-top: 24px;
    border-top: 1px solid #222;
  }

  .submit-btn {
    width: 408px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
