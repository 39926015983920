import React, { useState, useEffect, useRef } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import request from "api/request";
import Layout from "layout/layout";
import usePageSlice from "hooks/usePageSlice";
import Filter from "components/Filter/Filter";
import Select from "components/Select/Select";
import Search from "components/Search/Search";
import ProfileImage from "components/Profile/Profile";
import Pagination from "components/Pagination/Pagination";

import {
  PAGE_LAYOUT,
  ParttimerSelectOptions,
  PartTimerFilterOptions,
  PartTimerLankSelectOptions,
  PartTimerWarningSelectOptions,
  DEPARTMENT_OPTIONS,
} from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";
import { useTheme } from "utils/context/themeProvider";

import NoDataSVG from "assets/images/no_data_ico.svg";
import AlertSVG from "assets/images/alert_ic.svg";
import IcDropBtnSVG from "assets/images/icon_drop.svg";
import BtnCheckOnSVG from "assets/images/btn_check_on.png";
import BtnCheckOffSVG from "assets/images/btn_check_off.png";

import LankPopup from "./LankPopup";

const defaultValues = {
  filter: [
    PartTimerFilterOptions.map((e: any) => {
      if (e.checked) return e.value;
    }),
  ],
  select: {
    value: "ALL",
    label: "전체",
  },
  search: "",
};

const PartTimer = () => {
  const navigate = useNavigate();
  const [ThemeMode] = useTheme();
  const scrollContentRef = useRef<HTMLDivElement | null>(null);
  const [sWidth, setSWidth] = useState<number>(0);
  const [tableData, setTableData] = useState<any>([]);
  const [testData, setTestData] = useState<any>([]);
  const [classData, setClassData] = useState<any>();
  const [totalPages, setTotalPages] = useState(0);
  const [checkRequest, setCheckRequest] = useState(0);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [lankOpen, setLankOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const fixed = FixedWrap();
  const scroll = ScrollWrap(sWidth);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (scrollContentRef.current)
      setSWidth(scrollContentRef?.current?.scrollWidth);
  }, [values.filter, scrollContentRef, tableData]);

  // 파트타이머 현황 api 호출
  //useEffect(() => {
  //  let keywords: string | undefined = "";

  //  let payload: any = {
  //    type: "ALL",
  //    search_filter: values?.select.value,
  //  };

  //  if (values?.select.value === "ID" && values?.search?.split("")[0] === "I") {
  //    const regex = /(IP|0)/i;
  //    keywords = values?.search?.split(regex).pop();
  //  }

  //  if (keywords !== undefined && keywords !== "") {
  //    payload = {
  //      ...payload,
  //      keyword: keywords,
  //    };
  //  } else {
  //    payload = {
  //      ...payload,
  //      keyword: values?.search ? values?.search : null,
  //    };
  //  }

  //  request("get", "/users/parttimer_list", {}, payload).then((res: any) => {
  //    if (res.status === 200) {
  //      let dataList: any = [];
  //      res?.data.result.raws_parttimer.data.map((data: any) =>
  //        dataList.push(data)
  //      );
  //      res?.data.result.raws_partner_parttimer.data.map((data: any) =>
  //        dataList.push(data)
  //      );
  //      setTableData(dataList);
  //      setTotalCount(dataList.length);
  //      setTotalPages(Math.ceil(dataList.length / 10));
  //      setCheckRequest(
  //        dataList.filter((e: any) => e.check_working === "CHECK_REQUEST")
  //          .length
  //      );
  //    }
  //  });
  //}, [values?.search, values?.select.value, isEdit]);

  //// 등급 api 호출
  //useEffect(() => {
  //  request("get", "/users/parttimer_list/class", {}, {}).then((res: any) => {
  //    if (res.status === 200) {
  //      setClassData(res.data.result[0]);
  //    }
  //  });
  //}, [lankOpen, isEdit]);

  //// 파트타이머 등급 및 경고 누적 수 수정
  //const handleEditParttimer = async (
  //  type: string,
  //  data: any,
  //  userId: string
  //) => {
  //  let payload = {};
  //  let increase =
  //    (data === "A" && classData.a_class) ||
  //    (data === "B" && classData.b_class) ||
  //    (data === "C" && classData.c_class);

  //  if (type === "class") {
  //    payload = {
  //      ...payload,
  //      class: data,
  //      increase_rate: increase,
  //    };
  //  }
  //  if (type === "warning") {
  //    payload = {
  //      ...payload,
  //      cumulative_number_of_warnings: Number(data),
  //    };
  //  }
  //  if (type === "limit") {
  //    payload = {
  //      ...payload,
  //      accumulated_time_limit: !data,
  //    };
  //  }
  //  console.log(payload);
  //  await request("put", `/users/parttimer_list/${userId}`, {}, payload).then(
  //    (res: any) => {
  //      if (res.status === 200) setIsEdit((prev) => !prev);
  //    }
  //  );
  //};

  const pageArr = usePageSlice(tableData);

  return (
    <Layout page={PAGE_LAYOUT.part_timer}>
      <PartTimerStyled>
        <div className="status-wrap">
          <div className="parttimer-header">
            <div className="search-sec">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(
                    () => {},
                    () => {}
                  )();
                }}
              >
                <div className="filter">
                  <Controller
                    control={control}
                    name="filter"
                    render={({ field }) => (
                      <Filter
                        {...field}
                        option={PartTimerFilterOptions}
                        handleFormSubmit={() => {}}
                      />
                    )}
                  />
                </div>
                <div className="search">
                  <div className="search-select">
                    <Controller
                      control={control}
                      name="select"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="전체"
                          w="130"
                          h="52"
                          fontS="15"
                          variant="tertiary"
                          color={ThemeMode === "light" ? "#000" : "#fff"}
                          bgColor={ThemeMode === "light" ? "#fff" : "#211f1e"}
                          options={ParttimerSelectOptions}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name="search"
                      render={({ field }) => (
                        <Search
                          {...field}
                          color={ThemeMode === "light" ? "#000" : "#c9c9c9"}
                          bgcolor={ThemeMode === "light" ? "#fff" : "#211f1e"}
                          placeholder="검색어를 입력하세요."
                          handleFormSubmit={() => {}}
                        />
                      )}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="parttimer-header-btn">
              <div className="alert-work-status">
                <img src={AlertSVG} alt="알림 이미지" />
                <div>대기중인 근무 확인 요청</div>
                <span>{checkRequest}</span>
              </div>
              <div className="xlsx-download">엑셀로 내보내기</div>
              <div
                className="status-regist-btn"
                onClick={() => setLankOpen(true)}
              >
                파트타이머 등급 설정
              </div>
            </div>
          </div>
          <div>
            {tableData.length > 0 ? (
              <div className="status-table">
                <div css={fixed}>
                  <div className="fixed-table">
                    <div>
                      ID <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                    <div>
                      소속 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                    <div>
                      협력사 코드{" "}
                      <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                    <div>
                      성명 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                    <div>
                      근무 확인{" "}
                      <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                  </div>
                  <div className="fixed-data">
                    {pageArr[page - 1]?.map((item: any, i: number) => {
                      const idToString = item?.id?.toString();

                      return (
                        <div
                          key={i}
                          onClick={() =>
                            navigate(
                              `/curr_status/part_timer/detail/${item.id}`,
                              {
                                state: {
                                  parttimer: item,
                                },
                              }
                            )
                          }
                        >
                          <div>
                            {item.company
                              ? idToString.length === 1
                                ? "AP00000" + item.id
                                : "AP0000" + item.id
                              : idToString.length === 1
                              ? "IP00000" + item.id
                              : "IP0000" + item.id}
                          </div>
                          <div>
                            {item.company ? item.company?.company_name : "개인"}
                          </div>
                          <div>
                            {item.company
                              ? item.company?.id.toString().length === 1
                                ? "BPZ00" + item.company.id
                                : "BPZ0" + item.company.id
                              : "개인"}
                          </div>
                          <div className="fixed-data-profile">
                            <ProfileImage
                              width="28px"
                              height="28px"
                              fileId={
                                item?.image_info ? item?.image_info?.id : null
                              }
                              name={item.user_name}
                              isPopup={true}
                            />
                            <span>{item.user_name}</span>
                          </div>
                          <div>
                            {!item.check_working && <div>-</div>}
                            {item.check_working === "CHECKED" && (
                              <WorkConfirm>확인 완료</WorkConfirm>
                            )}
                            {item.check_working === "NOT_CHECKED" && (
                              <WorkMiss>불참</WorkMiss>
                            )}
                            {item.check_working === "CHECK_REQUEST" && (
                              <WorkCheck>근무 확인 요청</WorkCheck>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="fixed-line"></div>
                <div css={scroll}>
                  <div className="scroll-table">
                    <div>
                      {values.filter[0]?.map((item: any, i: number) => {
                        const clientWidth =
                          scrollContentRef?.current?.children[0]?.children[
                            i - 1
                          ]?.clientWidth;

                        const result = PartTimerFilterOptions.filter(
                          (e: any) => e.value === item
                        );

                        return (
                          item !== "All" && (
                            <div
                              key={i}
                              css={{
                                textAlign: "center",
                                width: `${clientWidth}px`,
                                minWidth: "120px",
                              }}
                            >
                              {result[0]?.label}{" "}
                              <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                  <div className="scroll-data" ref={scrollContentRef}>
                    {pageArr[page - 1]?.map((item: any, i: number) => {
                      return (
                        <div
                          key={i}
                          onClick={() =>
                            navigate(
                              `/curr_status/part_timer/detail/${item.id}`,
                              {
                                state: {
                                  parttimer: item,
                                },
                              }
                            )
                          }
                        >
                          {values.filter[0].includes("terms") && (
                            <div>
                              <img
                                src={BtnCheckOnSVG}
                                alt="약관동의여부 체크 이미지"
                                style={{ width: "20px" }}
                              />
                            </div>
                          )}
                          {values.filter[0].includes("email") && (
                            <div className="email-sec">{item.signname}</div>
                          )}
                          {values.filter[0].includes("birth") && (
                            <div>
                              {item.birth.substring(0, 4)}.
                              {item.birth.substring(4, 6)}.
                              {item.birth.substring(6, 8)}
                            </div>
                          )}
                          {values.filter[0].includes("gender") && (
                            <div>{item.gender}</div>
                          )}
                          {values.filter[0].includes("address") && (
                            <div className="address-sec">{item.address}</div>
                          )}
                          {values.filter[0].includes("phone") && (
                            <div>
                              {item.user_phone.substring(0, 3)}-
                              {item.user_phone.substring(3, 7)}-
                              {item.user_phone.substring(7, 11)}
                            </div>
                          )}
                          {values.filter[0].includes("bank") && (
                            <div>{item.bank}</div>
                          )}
                          {values.filter[0].includes("account") && (
                            <div className="account">{item.account_number}</div>
                          )}
                          {values.filter[0].includes("isCareer") && (
                            <div>
                              <img
                                src={
                                  item.company ? BtnCheckOnSVG : BtnCheckOffSVG
                                }
                                alt="경력여부 체크 이미지"
                                style={{ width: "20px" }}
                              />
                            </div>
                          )}
                          {values.filter[0].includes("primaryPart") && (
                            <div>
                              {item.job1_depth1
                                ? DEPARTMENT_OPTIONS.filter(
                                    (e: any) => e.value === item.job1_depth1
                                  )[0]?.label
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("secondaryPart") && (
                            <div>
                              {item.job2_depth1
                                ? DEPARTMENT_OPTIONS.filter(
                                    (e: any) => e.value === item.job2_depth1
                                  )[0]?.label
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("field") && (
                            <div>{item.main_field ? item.main_field : "-"}</div>
                          )}
                          {values.filter[0].includes("hourlyWage") && (
                            <div>
                              {item.latest_hourly_wage
                                ? item.latest_hourly_wage + "원"
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("cumulativeTime") && (
                            <div>
                              {item.cumulative_hours_per_month
                                ? item.cumulative_hours_per_month
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("rating") && (
                            <div onClick={(e) => e.stopPropagation()}>
                              <Select
                                position
                                placeholder="선택"
                                variant="quaternary"
                                defaultValue={
                                  item.class
                                    ? PartTimerLankSelectOptions.filter(
                                        (e: any) => e.value === item.class
                                      )[0].value
                                    : PartTimerLankSelectOptions[2].value
                                }
                                options={PartTimerLankSelectOptions}
                              />
                            </div>
                          )}
                          {values.filter[0].includes("increaseRate") && (
                            <div>
                              {item.increase_rate
                                ? item.increase_rate + "%"
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("veteran") && (
                            <div>보훈</div>
                          )}
                          {values.filter[0].includes("emergency") && (
                            <div>
                              {item.emergency_contact_number
                                ? item.emergency_contact_number
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("relationship") && (
                            <div>
                              {item.relationship ? item.relationship : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("cumulativeNumber") && (
                            <div onClick={(e) => e.stopPropagation()}>
                              <Select
                                position
                                placeholder="선택"
                                variant="quaternary"
                                defaultValue={
                                  item.cumulative_number_of_warnings
                                    ? PartTimerWarningSelectOptions.filter(
                                        (e: any) =>
                                          e.value ===
                                          String(
                                            item.cumulative_number_of_warnings
                                          )
                                      )[0].value
                                    : PartTimerWarningSelectOptions[0].value
                                }
                                options={PartTimerWarningSelectOptions}
                              />
                            </div>
                          )}
                          {values.filter[0].includes("note") && (
                            <div>{item.note ? item.note : "-"}</div>
                          )}
                          {values.filter[0].includes("restriction") && (
                            <div style={{ width: "200px" }}>
                              {item.accumulated_time_limit
                                ? item.accumulated_time_limit
                                : "-"}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="list-no-data-wrap">
                <div>
                  <div>
                    <div className="no-data-img">
                      <img src={NoDataSVG} alt="검색 결과 없음 이미지" />
                    </div>
                    <div>검색 결과가 없습니다.</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="pagenation">
            <Pagination
              page={page}
              count={totalPages}
              list={tableData.length}
              onChangePage={setPage}
            />
          </div>
          {lankOpen && <LankPopup setLankOpen={setLankOpen} />}
        </div>
      </PartTimerStyled>
    </Layout>
  );
};

export default PartTimer;

const FixedWrap = () => {
  return css`
    width: 50%;

    @media screen and (max-width: 1600px) {
      width: 70%;
    }

    .fixed-table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      font-size: 18px;
      font-weight: bold;

      & > div {
        cursor: pointer;
        text-align: center;
        width: 20%;
      }
    }

    .fixed-data {
      & > div {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          text-align: center;
          width: 20%;
        }
      }

      .fixed-data-profile {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin: 0 0 0 8px;
        }
      }
    }
  `;
};

const ScrollWrap = (sWidth: number) => {
  return css`
    width: 1000px;
    position: relative;
    left: -15px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c09260;
      border-radius: 10px;
      background-clip: padding-box;
    }

    @media screen and (max-width: 1600px) {
      width: 700px;
    }

    @media screen and (min-width: 1600px) {
      width: 1100px;
    }

    .scroll-table {
      width: ${`${sWidth}px`};

      & > div {
        width: ${`${sWidth}px`};
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 20px 20px;
        font-size: 18px;
        font-weight: bold;

        & > div {
          flex: 0 0 auto;
          cursor: pointer;
          margin: 0 30px;
          text-align: center;
        }
      }
    }

    .scroll-data {
      width: ${`${sWidth}px`};

      & > div {
        width: ${`${sWidth}px`};
        padding: 20px 0 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          min-width: 120px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 auto;
          cursor: pointer;
          margin: 0 30px;
          text-align: center;
        }

        .email-sec {
          min-width: 200px;
        }

        .account {
          min-width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .address-sec {
          width: 220px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: start;
        }
      }
    }
  `;
};

const PartTimerStyled = styled.div`
  width: 100%;
  margin: 40px;

  .status-btn {
    align-items: center;
    padding-right: 15px;
  }

  .alert-work-status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 201px;
    height: 34px;
    border: solid 1px #c9c9c9;
    border-radius: 20px;
    margin-right: 13px;
    font-size: 14px;

    div {
      margin-top: 2px;
    }

    img {
      margin: -1px 5px 0 0;
    }

    span {
      color: #c09260;
      font-weight: bold;
      margin: 2px 0 0 5px;
    }
  }

  .parttimer-header-btn {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: end;

    & div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .status-regist-btn {
    width: 176px;
  }
`;

const WorkConfirm = styled.div`
  color: #000;
  font-weight: bold;
`;
const WorkCheck = styled.div`
  color: #c09260;
  font-weight: bold;
`;
const WorkMiss = styled.div`
  color: #b2b2b2;
  font-weight: bold;
`;
