import { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/layout";

import Filter from "components/Filter/Filter";
import Select from "components/Select/Select";
import Search from "components/Search/Search";
import Pagination from "components/Pagination/Pagination";

import {
  PAGE_LAYOUT,
  EmployeeSelectOptions,
  approvalFilterOptions,
} from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import IcDropBtnSVG from "assets/images/icon_drop.svg";

import { TestApprovalData, TestSeniorData } from "utils/testdata";

const defaultValues = {
  approvalFilter: [],
  approvalStatus: "All",
  approvalGubun: "All",
  approvalPlace: "All",
  approvalSearch: "",
};

const Approval = () => {
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [page, setPage] = useState(1);
  const [sWidth, setSWidth] = useState<number>(0);

  useEffect(() => {
    if (contentRef.current) setSWidth(contentRef?.current?.scrollWidth);
  }, [contentRef]);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <Layout page={PAGE_LAYOUT.approval}>
      <ApprovalStyled>
        <div className="status-wrap">
          <div className="approval-status-top">
            <div className="search-sec">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleFormSubmit();
                }}
              >
                <div className="filter">
                  <Controller
                    control={control}
                    name="approvalFilter"
                    render={({ field }) => (
                      <Filter
                        {...field}
                        option={approvalFilterOptions}
                        handleFormSubmit={handleFormSubmit}
                      />
                    )}
                  />
                </div>
                <div className="search">
                  <div className="search-select approval-search-select">
                    <div className="approval-header-text">상태</div>
                    <Controller
                      control={control}
                      name="approvalStatus"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="전체"
                          w="120"
                          h="52"
                          fontS="15"
                          variant="tertiary"
                          options={EmployeeSelectOptions}
                        />
                      )}
                    />
                  </div>
                  <div className="search-select approval-search-select">
                    <div className="approval-header-text">구분</div>
                    <Controller
                      control={control}
                      name="approvalGubun"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="전체"
                          w="120"
                          h="52"
                          fontS="15"
                          variant="tertiary"
                          options={EmployeeSelectOptions}
                        />
                      )}
                    />
                  </div>
                  <div className="search-select approval-search-select">
                    <div className="approval-header-text">근무지</div>
                    <Controller
                      control={control}
                      name="approvalPlace"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="전체"
                          w="120"
                          h="52"
                          fontS="15"
                          variant="tertiary"
                          options={EmployeeSelectOptions}
                        />
                      )}
                    />
                  </div>
                  <div className="approval-search-select">
                    <div className="approval-header-text">검색</div>
                    <Controller
                      control={control}
                      name="approvalSearch"
                      render={({ field }) => (
                        <Search
                          {...field}
                          w="250px"
                          placeholder="검색어를 입력하세요."
                          handleFormSubmit={handleFormSubmit}
                        />
                      )}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="status-btn approval-btn">
              <div
                className="status-regist-btn"
                onClick={() => navigate("/approval/regist")}
              >
                결재 신청
              </div>
            </div>
          </div>
          <div className="approval-scroll">
            <div className="approval-scroll-wrap">
              <div className="approval-header">
                <div className="approval-header-inner1">결재 정보</div>
                <div className="approval-header-inner2">상급자1</div>
                <div className="approval-header-inner2">상급자2</div>
                <div className="approval-header-inner2">상급자3</div>
              </div>
              <div className="approval-option-top" ref={contentRef}>
                <div className="approval-option-top-inner1">
                  <div>
                    NO. <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                  <div>
                    상태 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                  <div>
                    구분 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                  <div className="approval-option-top-inner1-title">
                    제목 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                  <div>
                    신청자 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                  <div>
                    근무지 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                  <div>
                    신청일 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                </div>
                <div className="approval-option-top-inner2">
                  <div>
                    결재자 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                  <div>
                    <div>
                      결재일<span>(결재시간)</span>
                    </div>
                    <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                </div>
                <div className="approval-option-top-inner2">
                  <div>
                    결재자 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                  <div>
                    <div>
                      결재일<span>(결재시간)</span>
                    </div>
                    <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                </div>
                <div className="approval-option-top-inner2">
                  <div>
                    결재자 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                  <div>
                    <div>
                      결재일<span>(결재시간)</span>
                    </div>
                    <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                  </div>
                </div>
              </div>
              <div className="approval-content">
                <div className="approval-content-inner1">
                  {TestApprovalData.map((item: any, i: number) => {
                    return (
                      <div
                        key={i}
                        onClick={() =>
                          navigate(`/approval/detail/${i}`, {
                            state: {
                              docType: item.gubun,
                            },
                          })
                        }
                      >
                        <div>{item.no}</div>
                        <div>{item.status}</div>
                        <div>
                          {item.gubun === "VACATION_APP" && "연차 · 반차 신청"}
                          {item.gubun === "LETTER_APP" && "품의서 구분"}
                          {item.gubun === "PLAN_APP" && "기획안 구분"}
                        </div>
                        <div className="approval-option-top-inner1-title">
                          {item.title}
                        </div>
                        <div>{item.name}</div>
                        <div>{item.place}</div>
                        <div>{item.rdgdtm}</div>
                      </div>
                    );
                  })}
                </div>
                <div className="approval-content-inner2">
                  {TestSeniorData.map((item: any, i: number) => {
                    return (
                      <div key={i}>
                        <div>{item.approver}</div>
                        <div>{item.date}</div>
                      </div>
                    );
                  })}
                </div>
                <div className="approval-content-inner2">
                  {TestSeniorData.map((item: any, i: number) => {
                    return (
                      <div key={i}>
                        <div>{item.approver}</div>
                        <div>{item.date}</div>
                      </div>
                    );
                  })}
                </div>
                <div className="approval-content-inner2">
                  {TestSeniorData.map((item: any, i: number) => {
                    return (
                      <div key={i}>
                        <div>{item.approver}</div>
                        <div>{item.date}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="pagenation">
            <Pagination
              page={page}
              count={60}
              list={10}
              onChangePage={setPage}
            />
          </div>
        </div>
      </ApprovalStyled>
    </Layout>
  );
};

export default Approval;

const ApprovalStyled = styled.div`
  width: 100%;
  margin: 40px;

  .status-regist-btn {
    width: 120px;
  }

  .approval-header-text {
    font-size: 16px;
    font-weight: bold;
    color: #222;
    margin-right: 12px;
    margin-left: 15px;
  }

  .approval-search-select {
    display: flex;
    align-items: center;
  }

  .approval-scroll {
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c09260;
      border-radius: 10px;
      background-clip: padding-box;
    }
  }

  .approval-scroll-wrap {
    width: 120%;

    @media screen and (max-width: 1500px) {
      width: 160%;
    }
  }

  .approval-header {
    height: 61px;
    background-color: rgba(168, 154, 139, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    margin-top: 30px;
    & div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 61px;
    }
  }

  .approval-header-inner1 {
    width: 80%;
  }
  .approval-header-inner2 {
    width: 20%;
    border-left: solid 1px #ddd;
  }

  .approval-btn {
    margin-top: 30px;
    justify-content: end;
  }

  .approval-option-top {
    display: flex;
    align-items: center;
    height: 68px;
    background-color: #f9f9f9;

    & > div {
      display: flex;
    }
    & > div > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      color: #101010;
      text-align: center;
      height: 68px;

      span {
        display: block;
        font-size: 15px;
        font-weight: normal;
        color: #444;
      }

      img {
        margin-left: 4px;
      }
    }
  }
  .approval-option-top-inner1 {
    justify-content: space-around;
    width: 80%;

    & > div {
      width: 100%;
      text-align: center;
    }
  }
  .approval-option-top-inner2 {
    width: 20%;
    border-left: solid 1px #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .approval-option-top-inner1-title {
    min-width: 250px;
  }

  .approval-content {
    display: flex;
  }

  .approval-content-inner1 {
    width: 80%;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ebebeb;

      & > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 68px;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }

  .approval-content-inner2 {
    width: 20%;
    border-left: solid 1px #ddd;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid #ebebeb;

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 68px;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }
`;
