import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/layout";
import userApi from "api/user";
import Search from "components/Search/Search";
import BtnCheckOffSVG from "assets/images/btn_check_off.png";
import BtnCheckOnSVG from "assets/images/btn_check_on.png";
import { PAGE_LAYOUT } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

const defaultValues = {
  search: "",
};

const AuthSetting = () => {
  const [allMember, setAllMember] = useState([]);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    userApi
      .allMember()
      .then((res: any) => {
        if (res.status === 200) {
          setAllMember(res.data.result.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <Layout page={PAGE_LAYOUT.auth_setting}>
      <AuthSettingStyled>
        <div className="status-wrap">
          <div className="auth-setting-wrap">
            <div className="auth-setting-header">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleFormSubmit();
                }}
              >
                <Controller
                  control={control}
                  name="search"
                  render={({ field }) => (
                    <Search
                      {...field}
                      placeholder="검색어를 입력하세요."
                      handleFormSubmit={handleFormSubmit}
                    />
                  )}
                />
              </form>
              <div className="status-btn">
                <div className="auth-setting-cencle-btn">취소</div>
                <div className="auth-setting-edit-btn">수정</div>
              </div>
            </div>
            <div className="auth-setting-table">
              <table>
                <thead>
                  <tr>
                    <th className="auth-setting-table-head-info">성명</th>
                    <th className="auth-setting-table-head-info">부서</th>
                    <th className="auth-setting-table-head-info">직급</th>
                    <th className="auth-setting-table-head-info">근무지</th>
                    <th className="auth-setting-table-head-role">관리자</th>
                    <th className="auth-setting-table-head-role">팀장</th>
                    <th className="auth-setting-table-head-role">일반직원</th>
                    <th className="auth-setting-table-head-role">파트타이머</th>
                    <th className="auth-setting-table-head-role">협력사</th>
                  </tr>
                </thead>
                <tbody>
                  {allMember.map((item: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td className="auth-table-body">{item.user_name}</td>
                        {item.user_role === "PARTTIMER" ||
                        item.user_role === "PARTNER_PARTTIMER" ? (
                          <td className="auth-table-body">파트타이머</td>
                        ) : (
                          <td className="auth-table-body">{item.department}</td>
                        )}
                        {item.user_role === "PARTTIMER" ||
                        item.user_role === "PARTNER_PARTTIMER" ? (
                          <td className="auth-table-body">파트타이머</td>
                        ) : (
                          <td className="auth-table-body">{item.position}</td>
                        )}
                        {item.user_role === "EMPLOYEE" && (
                          <td className="auth-table-body">
                            {item.user_employee_info?.working_place}
                          </td>
                        )}
                        {item.user_role === "PARTNER" && (
                          <td className="auth-table-body">
                            {item.company?.company_name}
                          </td>
                        )}
                        {item.user_role === "PARTTIMER" && (
                          <td className="auth-table-body">{item.department}</td>
                        )}
                        {item.user_role === "PARTNER_PARTTIMER" && (
                          <td className="auth-table-body">{item.department}</td>
                        )}
                        <td>
                          <input
                            type="radio"
                            name={`auth${i}`}
                            id={`auth${i}`}
                          />
                        </td>
                        <td>
                          <input
                            type="radio"
                            name={`auth${i}`}
                            id={`auth${i}`}
                          />
                        </td>
                        <td>
                          <input
                            type="radio"
                            name={`auth${i}`}
                            id={`auth${i}`}
                          />
                        </td>
                        <td>
                          <input
                            type="radio"
                            name={`auth${i}`}
                            id={`auth${i}`}
                          />
                        </td>
                        <td>
                          <input
                            type="radio"
                            name={`auth${i}`}
                            id={`auth${i}`}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </AuthSettingStyled>
    </Layout>
  );
};

export default AuthSetting;

const AuthSettingStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 40px;

  & > div {
    display: flex;
    padding: 35px;
  }

  .auth-setting-wrap {
    width: 100%;
  }

  .auth-setting-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .auth-setting-cencle-btn {
    width: 100px;
    height: 44px;
    border-radius: 6px;
    background-color: #444;
    color: #fff;
    cursor: pointer;
  }

  .auth-setting-edit-btn {
    width: 100px;
    height: 44px;
    margin-left: 10px;
    border-radius: 6px;
    background-color: #c09260;
    color: #fff;
    cursor: pointer;
  }

  .auth-setting-table {
    margin-top: 30px;
    table {
      width: 100%;
    }
    thead {
      height: 68px;
      background-color: #e4e0db;

      th {
        font-size: 18px;
        font-weight: bold;
        vertical-align: middle;
        color: #101010;
      }
    }

    tbody {
      tr {
        height: 68px;
        border-bottom: 1px solid #ebebeb;
      }

      td {
        text-align: center;
        font-size: 15px;
        color: #444;
        vertical-align: middle;

        input {
          appearance: none;
          margin-right: 8px;
          width: 20px;
          height: 20px;
          background: url(${BtnCheckOffSVG}) no-repeat center/100%;

          &:checked {
            background: url(${BtnCheckOnSVG}) no-repeat center/100%;
          }
        }
      }
    }

    .auth-setting-table-head-info {
      width: 7%;
    }

    .auth-setting-table-head-role {
      width: 14.4%;
    }

    .auth-table-body {
      background-color: #f9f9f9;
    }
  }
`;
