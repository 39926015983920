/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef, useEffect, useState } from "react";

import RadioBtnOnPNG from "assets/images/radio_btn_on.png";
import RadioBtnOffPNG from "assets/images/radio_btn_off.png";

interface RadioProps {
  w?: string;
  h?: string;
  p?: string;
  id?: string;
  name?: string;
  value?: string;
  children: string;
}

const SimpleRadio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { id, name, value, children, ...rest } = props;

  const root = rootCss(props);

  return (
    <>
      <label css={root}>
        <input ref={ref} value={value} name={name} type="radio" {...rest} />
        <span>{children}</span>
      </label>
    </>
  );
});

export default SimpleRadio;

const rootCss = (props: RadioProps) => {
  return css`
    margin-right: 50px;
    cursor: pointer;

    span {
      position: relative;
      top: -4px;
      padding-left: 10px;
    }

    input {
      appearance: none;
      width: 20px;
      height: 20px;
      background: url(${RadioBtnOffPNG}) no-repeat center/100%;
      cursor: pointer;
    }

    input:checked {
      background: url(${RadioBtnOnPNG}) no-repeat center/100%;
    }
  `;
};
