import React, { useRef, useState, useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import request from "api/request";
import Layout from "layout/layout";
import Button from "components/Button/Button";
import { PAGE_LAYOUT } from "utils/constants";
import useSubmitAlert from "hooks/useSubmitAlert";

const EmployeeDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { noti } = useSubmitAlert();
  const { REACT_APP_API } = process.env;
  const fileTextRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [specPdf, setSpecPdf] = useState([]);
  const [detailData, setDetailData] = useState<any>([]);
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    setDetailData(location?.state?.employee?.user_employee_info);
  }, [isReload]);

  // 급여명세서 데이터가 있을 시 set
  useEffect(() => {
    if (detailData?.payslip_info) {
      if (fileTextRef?.current)
        fileTextRef.current.value =
          detailData?.payslip_info.file_name +
          "." +
          detailData?.payslip_info.extension;
    }
  }, [detailData?.payslip_info]);

  // 급여명세서 업로드 시 value에 파일 이름 set
  const handleChange = useCallback((e: any) => {
    if (fileTextRef.current) {
      fileTextRef.current.value = e.target.files[0].name;
      setSpecPdf(e.target.files);
    }
  }, []);

  // 급여명세서 pdf 파일 선택 시 폼데이터에 담아 즉시 업로드
  useEffect(() => {
    if (specPdf.length > 0) {
      const pdfForm = new FormData();
      pdfForm.append("file", specPdf[0]);

      //handlePdfUpload(pdfForm);
    }
  }, [specPdf]);

  // 급여명세서 업로드
  //const handlePdfUpload = async (pdfForm: any) => {
  //  const attachment: any = [];
  //  try {
  //    const res: any = await request("post", "/files", {}, pdfForm);

  //    if (res?.status === 200) {
  //      attachment.push(res.data.result.id);
  //    }
  //  } catch (err) {
  //    console.log(err);
  //  }

  //  let payload: any = {
  //    payslip: attachment,
  //  };

  //  handleEdit(payload);
  //};

  // 등록현황 수락 클릭 시
  const handleStatus = async () => {
    let payload: any = {
      registration_status: "ACCEPT",
    };

    //handleEdit(payload);
  };

  // 급여명세서 or 등록현황 수정
  //const handleEdit = useCallback(
  //  async (payload: any) => {
  //    await request(
  //      "put",
  //      `/users/edit/employee/${location?.state?.employee.id}`,
  //      {},
  //      payload
  //    )
  //      .then((res) => {
  //        if (res?.status === 200) {
  //          setIsReload((prev) => !prev);
  //        }
  //        if (payload?.registration_status) {
  //          navigate("/curr_status/employee", { replace: true });
  //        }
  //      })
  //      .catch((err) => {
  //        throw err;
  //      });
  //  },
  //  [isReload]
  //);

  // 임직원 삭제
  //const handleDelete = async () => {
  //  await request("delete", `/users/${location?.state?.employee.id}`, {}, {})
  //    .then(() => {
  //      navigate("/curr_status/employee", { replace: true });
  //    })
  //    .catch((err) => {
  //      console.log(err);
  //    });
  //};

  return (
    <Layout page={PAGE_LAYOUT.employee_submit}>
      <EmployeeDetailStyle>
        <div>
          <div className="detail-container">
            <div>
              <h3>&#183; 개인 정보 &#183;</h3>
              <ul>
                <li>
                  <div>이름</div>
                  <div>{detailData.user_name}</div>
                </li>
                <li>
                  <div>생년월일</div>
                  <div>{detailData.birth}</div>
                </li>
                <li>
                  <div>성별</div>
                  <div>
                    {detailData.gender === "Male" && "남성"}
                    {detailData.gender === "Female" && "여성"}
                  </div>
                </li>
                <li>
                  <div>사원번호</div>
                  <div>E00000{detailData.id}</div>
                </li>
                <li>
                  <div>증명사진</div>
                  <div className="idphoto-sec">
                    {detailData.profile_image_info ? (
                      <img
                        src={`${REACT_APP_API}/files/${detailData.profile_image_info?.id}`}
                        alt="증명사진 이미지"
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                </li>
                <li>
                  <div>이메일 주소</div>
                  <div>{detailData.signname}</div>
                </li>
                <li>
                  <div>근무지</div>
                  <div>{detailData.working_place}</div>
                </li>
                <li>
                  <div>부서</div>
                  <div>{detailData.department}</div>
                </li>
                <li>
                  <div>직급</div>
                  <div>{detailData.rank}</div>
                </li>
                <li>
                  <div>직책</div>
                  <div>{detailData.position}</div>
                </li>
                <li>
                  <div>휴대전화번호</div>
                  <div>{detailData.user_phone}</div>
                </li>
                <li>
                  <div>계좌번호</div>
                  <div>
                    {detailData.bank} {detailData.bank_account}
                  </div>
                </li>
                <li>
                  <div>주소</div>
                  <div>
                    ({detailData.zip_code}) {detailData.address}{" "}
                    {detailData.detail_address}
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <h3>&#183; 계약 정보 &#183;</h3>
              <ul>
                <li>
                  <div>상태</div>
                  <div>{detailData.status === "EXPECTED" && "입사 예정"}</div>
                </li>
                <li>
                  <div>입사일</div>
                  <div>{dayjs(detailData.join_date).format("YYYY.MM.DD")}</div>
                </li>
                <li>
                  <div>퇴사일</div>
                  <div>
                    {detailData.leave_date
                      ? dayjs(detailData.leave_date).format("YYYY.MM.DD")
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>근로 형태</div>
                  <div>
                    {detailData.user_role === "FULLTIME_WORKER" && "정규직"}
                    {detailData.user_role === "CONTRACT_WORKER" && "계약직"}
                  </div>
                </li>
                <li>
                  <div>계약기간</div>
                  <div>{detailData.term ? detailData.term + "년" : "-"}</div>
                </li>
                <li>
                  <div>총 근무 기간</div>
                  <div>-</div>
                </li>
                <li>
                  <div>계약 연봉</div>
                  <div>
                    {detailData.contract_salary
                      ? detailData.contract_salary + "만원"
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>현재 연봉</div>
                  <div>
                    {detailData.current_salary
                      ? detailData.current_salary + "만원"
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>작년 연봉</div>
                  <div>
                    {detailData.last_years_salary
                      ? detailData.last_years_salary + "만원"
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>급여 명세서</div>
                  <div className="pdf-file-wrap">
                    <div>
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept=".pdf"
                        id="pdf-file"
                        onChange={(e: any) => handleChange(e)}
                      />
                      <label htmlFor="pdf-file-text">
                        <input
                          ref={fileTextRef}
                          type="text"
                          id="pdf-file-text"
                          placeholder="급여명세서를 업로드 해주세요.(pdf)"
                          disabled
                        />
                      </label>
                    </div>
                    <button className="add-pdf">
                      <label htmlFor="pdf-file">업로드</label>
                    </button>
                  </div>
                </li>
                <li>
                  <div>등록 현황</div>
                  {detailData.registration_status === "ING" && (
                    <div className="employee-submit-status-btn">
                      <div
                        className="reject-btn"
                        onClick={() => {
                          const isReject = noti(
                            "거부하시겠습니까?",
                            "예",
                            "아니오"
                          );
                          const getData = () => {
                            isReject.then((res: boolean) => {
                              return;
                            });
                          };
                          getData();
                        }}
                      >
                        거부
                      </div>
                      <div
                        className="accept-btn"
                        onClick={() => {
                          const isAccept = noti(
                            "수락하시겠습니까?",
                            "예",
                            "아니오"
                          );
                          const getData = () => {
                            isAccept.then((res: boolean) => {
                              if (res) handleStatus();
                            });
                          };
                          getData();
                        }}
                      >
                        수락
                      </div>
                    </div>
                  )}
                  {detailData.registration_status === "ACCEPT" && (
                    <div className="registration-accept">등록 완료</div>
                  )}
                </li>
              </ul>
            </div>
            <div>
              <h3>&#183; 연차 정보 &#183;</h3>
              <ul>
                <li>
                  <div>지급 연차</div>
                  <div>
                    {detailData.offer_holiday
                      ? detailData.offer_holiday + "일"
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>이용 연차</div>
                  <div>
                    {detailData.used_holiday
                      ? detailData.used_holiday + "일"
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>남은 연차</div>
                  <div>
                    {detailData.left_holiday
                      ? detailData.left_holiday + "일"
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>이용 유급휴가</div>
                  <div>
                    {detailData.used_paid_holiday
                      ? detailData.used_paid_holiday + "일"
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>이용 무급휴가</div>
                  <div>
                    {detailData.used_unpaid_holiday
                      ? detailData.used_unpaid_holiday + "일"
                      : "-"}
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <h3>&#183; 비고 &#183;</h3>
              <div className="note-sec">{detailData.note}</div>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                목록
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="secondary"
                preset="1"
                onClick={() => {
                  const isDelete = noti(
                    "해당 임직원을 삭제하시겠습니까?",
                    "예",
                    "아니오"
                  );
                  const getData = () => {
                    isDelete.then((res: boolean) => {
                      return;
                    });
                  };
                  getData();
                }}
              >
                삭제
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="primary"
                preset="2"
                onClick={() =>
                  navigate(
                    `/curr_status/employee/edit/${location?.state?.employee?.id}`,
                    {
                      state: { employee: location?.state?.employee },
                    }
                  )
                }
              >
                수정
              </Button>
            </div>
          </div>
        </div>
      </EmployeeDetailStyle>
    </Layout>
  );
};

export default EmployeeDetail;

const EmployeeDetailStyle = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 35px;
  }

  .detail-container {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid #222;
  }

  li {
    display: flex;
    align-items: center;
    padding: 30px 0;
    border-bottom: 1px solid #ddd;

    & > div:nth-of-type(1) {
      width: 150px;
      font-size: 18px;
      font-weight: bold;
      color: #b2b2b2;
    }

    & > div {
      font-size: 18px;
      font-weight: normal;
      color: #000;
    }

    .registration-accept {
      font-weight: bold;
    }
  }

  .idphoto-sec {
    width: 100px;
    height: 134px;
    background-color: #f1f1f1;
    img {
      width: 100%;
    }
  }

  .pdf-file-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;

    #pdf-file {
      display: none;
    }

    #pdf-file-text {
      display: flex;
      align-items: center;
      width: 450px;
      height: 35px;
      background: transparent;
      font-size: 16px;
      padding-left: 25px;
      color: #000;
    }

    .add-pdf {
      width: 66px;
      height: 35px;
      font-size: 16px;
      font-weight: normal;
      color: #fff;
      background-color: #444;
      label {
        cursor: pointer;
      }
    }
  }

  .employee-submit-status-btn {
    display: flex;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 66px;
      height: 35px;
      border-radius: 3px;
      font-size: 16px;
      font-weight: normal;
      cursor: pointer;
    }
    .reject-btn {
      color: #a89a8b;
      border: solid 1px #a89a8b;
      margin-right: 8px;
    }
    .accept-btn {
      color: #c09260;
      border: solid 1px #c09260;
    }
  }

  .note-sec {
    margin-top: 30px;
    padding: 20px;
    border: solid 1px #ddd;
    background-color: #f9f9f9;
    min-height: 170px;
  }

  .submit-btn {
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
