/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef } from "react";

interface RadioProps {
  w?: string;
  h?: string;
  p?: string;
  name?: string;
  value?: string;
  isToggle?: boolean;
  children: string;
}

const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { isToggle, name, value, children, ...rest } = props;

  const root = rootCss(props);
  const input = inputCss(props);

  return (
    <>
      {isToggle ? (
        <label css={root} className="component-radio">
          <input ref={ref} css={input} type="checkbox" {...rest} />
          <span>{children}</span>
        </label>
      ) : (
        <label css={root} className="component-radio">
          <input
            ref={ref}
            value={value}
            name={name}
            css={input}
            type="radio"
            {...rest}
          />
          <span>{children}</span>
        </label>
      )}
    </>
  );
});

export default Radio;

const rootCss = (props: RadioProps) => {
  const paddingTop = props.p ? `${props.p}px` : "88px";
  return css`
    position: relative;

    & span {
      position: absolute;
      padding-top: 0;
      top: -80%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      font-weight: normal;
      color: #000;
    }
  `;
};

const inputCss = (props: RadioProps) => {
  const width = props.w ? `${props.w}px` : "88px";
  const height = props.h ? `${props.h}px` : "65px";

  return css`
    width: ${width};
    height: 65px;
    border: solid 1px #ddd;
    border-radius: 0;
    -webkit-border-radius: 0;
    appearance: none;
    cursor: pointer;

    &:checked {
      border: solid 1px #c09260;
      & + span {
        color: #c09260;
      }
    }
  `;
};
