/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef } from "react";

import BtnCheckOffSVG from "assets/images/btn_check_off.png";
import BtnCheckOnSVG from "assets/images/btn_check_on.png";

import { ERROR_COLOR } from "utils/constants";

interface CheckboxProps {
  checked: boolean;
  onChange: (e: any) => void;
  children: React.ReactNode;
  name?: any;
  errors?: any;
  fontWegiht?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { checked, onChange, children, name, errors, fontWegiht, ...rest } =
    props;

  const root = rootCss(props);
  const input = inputCss(props);

  return (
    <>
      <label css={root} className="component-checkbox">
        <input
          ref={ref}
          css={input}
          type="checkbox"
          onChange={(e) => onChange(e)}
          checked={checked}
          {...rest}
        />
        {children}
      </label>
    </>
  );
});

const rootCss = (props: CheckboxProps) => {
  const errors = props.errors?.[props.name]?.type === "required";
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    color: ${errors ? ERROR_COLOR : "#000"};
    cursor: pointer;
  `;
};

const inputCss = (props: CheckboxProps) => {
  return css`
    appearance: none;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    background: url(${BtnCheckOffSVG}) no-repeat center/100%;
    cursor: pointer;

    &:checked {
      background: url(${BtnCheckOnSVG}) no-repeat center/100%;
    }
  `;
};

export default Checkbox;
