/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { LaundryTestData } from "utils/testdata";

const DataMode = () => {
  return (
    <DataModeStyled>
      <div className="laundry-data-mode-wrap">
        <div className="laundry-data-mode-info">
          <div className="laundry-data-mode-info-title">품목정보</div>
          <div className="laundry-data-mode-info-sub">
            <div>품목명</div>
            <div>규격</div>
            <div>품목단가</div>
            <div>세탁단가</div>
          </div>
          <div className="laundry-data-mode-flex laundry-data-mode-flex1">
            <div>품목 1</div>
            <div>1040*1940</div>
            <div>₩10,000</div>
            <div>₩10,000</div>
          </div>
          <div className="laundry-data-mode-sub">단가</div>
        </div>
        <div className="laundry-data-mode-scroll">
          <div>
            {LaundryTestData.map((e: any, i: number) => {
              return (
                <div
                  className="laundry-data-mode-layer"
                  key={i}
                  css={{
                    minWidth: "450px",
                  }}
                >
                  <div className="laundry-data-mode-info-title">{e.room}</div>
                  <div className="laundry-data-mode-info-sub laundry-data-mode-total-sub">
                    <div>수량</div>
                    <div>품목단가</div>
                    <div>세탁단가</div>
                  </div>
                  <div className="laundry-data-mode-flex laundry-data-mode-flex2">
                    <div>{e.count}</div>
                    <div>{e.itempay}</div>
                    <div>{e.laundrypay}</div>
                  </div>
                  <div className="laundry-data-mode-sub laundry-data-mode-flex2">
                    <div>80</div>
                    <div>₩1,600,000</div>
                    <div>₩800,000</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="laundry-data-mode-total">
          <div className="laundry-data-mode-info-title">품목정보</div>
          <div className="laundry-data-mode-info-sub laundry-data-mode-total-sub">
            <div>총 세탁 수량</div>
            <div>총 품목 단가</div>
            <div>총 세탁 단가</div>
          </div>
          <div className="laundry-data-mode-flex laundry-data-mode-flex2">
            <div>20</div>
            <div>₩10,000</div>
            <div>₩10,000</div>
          </div>
          <div className="laundry-data-mode-sub laundry-data-mode-flex2">
            <div>80</div>
            <div>₩1,600,000</div>
            <div>₩800,000</div>
          </div>
        </div>
      </div>
      <div className="grand-total">
        <div>GRAND TOTAL</div>
        <div>₩ 44,100,000</div>
      </div>
    </DataModeStyled>
  );
};

export default DataMode;

const DataModeStyled = styled.div`
  width: 100%;
  .laundry-data-mode-wrap {
    width: 100%;
    display: flex;
  }

  .laundry-data-mode-info {
    width: 30%;
    border-right: 1px solid #ebebeb;
  }

  .laundry-data-mode-scroll {
    width: 600px;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    /*@media screen and (max-width: 1600px) {
      width: 600px;
    }*/
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c09260;
      border-radius: 10px;
      background-clip: padding-box;
    }

    & > div {
      display: flex;
    }

    .laundry-data-mode-layer {
      border-right: 1px solid #ebebeb;
    }
  }

  .laundry-data-mode-total {
    width: 30%;
    border-left: 1px solid #ebebeb;
  }

  .laundry-data-mode-info-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 62px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: rgba(168, 154, 139, 0.3);
  }

  .laundry-data-mode-info-sub {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: #f9f9f9;

    & > div {
      width: 25%;
      text-align: center;
    }
  }

  .laundry-data-mode-total-sub {
    & > div {
      width: 33.333%;
      text-align: center;
    }
  }

  .laundry-data-mode-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
    border-bottom: 1px solid #ebebeb;
  }

  .laundry-data-mode-flex1 > div {
    width: 25%;
    text-align: center;
  }

  .laundry-data-mode-flex2 > div {
    width: 33.333%;
    text-align: center;
  }

  .laundry-data-mode-sub {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    height: 68px;
    border-bottom: 3px solid #ebebeb;
  }

  .grand-total {
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    border-bottom: 3px solid #444;

    & div {
      margin: 0 30px;
    }
  }
`;
