import { useState } from "react";
import styled from "@emotion/styled";

import SearchMember from "components/SearchPopup/SearchMember";

interface SeniorFormProps {
  Controller: any;
  setValue: any;
  control: any;
  errors: any;
}

const SeniorForm = ({
  Controller,
  setValue,
  control,
  errors,
}: SeniorFormProps) => {
  const [result, setResult] = useState<any>([]);
  const [openSearchMember, setOpenSearchMember] = useState(false);

  return (
    <SeniorFormStyled>
      <div className="senior">
        <div>
          <div className="senior-title">상급자 1</div>
          <Controller
            control={control}
            name="approvalSenior01"
            render={({ field }: any) => (
              <div>
                [라운드리] 장누리
                <span onClick={() => setOpenSearchMember(true)}>변경</span>
              </div>
            )}
          />
        </div>
        <div>
          <div className="senior-title">상급자 2</div>
          <Controller
            control={control}
            name="approvalSenior02"
            render={({ field }: any) => (
              <div>
                [라운드리] 장누리
                <span onClick={() => setOpenSearchMember(true)}>변경</span>
              </div>
            )}
          />
        </div>
        <div>
          <div className="senior-title">상급자 3</div>
          <Controller
            control={control}
            name="approvalSenior03"
            render={({ field }: any) => (
              <div>
                [라운드리] 장누리
                <span onClick={() => setOpenSearchMember(true)}>변경</span>
              </div>
            )}
          />
        </div>
      </div>
      {openSearchMember && (
        <SearchMember
          setOpenSearchMember={setOpenSearchMember}
          setResult={setResult}
        />
      )}
    </SeniorFormStyled>
  );
};

export default SeniorForm;

const SeniorFormStyled = styled.div`
  .senior {
    width: 560px;
    border: solid 1px #b2b2b2;
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #444;
    margin: 0 auto 60px auto;

    & > div {
      width: 33.3333%;
    }

    & > div:nth-of-type(2) {
      border-left: solid 1px #b2b2b2;
      border-right: solid 1px #b2b2b2;
    }

    span {
      display: block;
      font-size: 18px;
      font-weight: normal;
      color: #78aef3;
      text-decoration: underline;
      margin: 12px 0 27px 0;
      cursor: pointer;
    }

    .senior-title {
      font-weight: normal;
      padding: 16px 0;
      margin-bottom: 27px;
      background-color: rgba(168, 154, 139, 0.3);
    }
  }
`;
