import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import BtnDelSVG from "assets/images/btn_del.svg";
import DefaultProfile from "assets/images/profile_ic-02.svg";

interface ProfileImageProps {
  width?: string;
  height?: string;
  fileId?: any;
  name?: string;
  isPopup?: boolean;
  REACT_APP_API?: string | undefined;
}

const ProfileImage = ({
  width,
  height,
  fileId,
  name,
  isPopup,
}: ProfileImageProps) => {
  const [openPopup, setOpenPopup] = useState(false);
  const { REACT_APP_API } = process.env;

  const handleOpenPopup = (e: any) => {
    e.stopPropagation();
    if (isPopup) {
      setOpenPopup(true);
    }
  };

  return (
    <>
      <ProfileImageStyle
        width={width}
        height={height}
        fileId={fileId}
        REACT_APP_API={REACT_APP_API}
        onClick={(e) => handleOpenPopup(e)}
      />
      {openPopup && (
        <ProfileDetailStyle
          fileId={fileId === null ? DefaultProfile : fileId}
          REACT_APP_API={REACT_APP_API}
        >
          <div className="profile-popup">
            <div className="profile-top">
              <div className="profile-name">{name}</div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenPopup(false);
                }}
              >
                <img src={BtnDelSVG} alt="" />
              </div>
            </div>
            <div className="profile-image"></div>
          </div>
        </ProfileDetailStyle>
      )}
    </>
  );
};

const ProfileImageStyle = styled.div`
  width: ${(props: ProfileImageProps) => props.width};
  height: ${(props: ProfileImageProps) => props.height};
  margin-bottom: 5px;
  border-radius: 50%;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props: ProfileImageProps) =>
    props.fileId === null
      ? `url(${DefaultProfile})`
      : `url(${props.REACT_APP_API}/files/${props.fileId})`};
`;

const ProfileDetailStyle = styled.div`
  position: fixed;
  z-index: 99999999;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  .profile-popup {
    width: 360px;
    background-color: #fff;
    border: solid 1px #c09260;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    @media screen and (max-width: 1600px) {
      width: 300px;
    }
  }

  .profile-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px;
  }

  .profile-name {
    font-size: 26px;
    font-weight: bold;
    color: #c09260;
  }

  .profile-image {
    margin: 0 30px 30px 30px;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${(props: ProfileImageProps) =>
      props.fileId === null
        ? `url(${DefaultProfile})`
        : `url(${props.REACT_APP_API}/files/${props.fileId})`};

    @media screen and (max-width: 1600px) {
      height: 340px;
    }
  }
`;

export default ProfileImage;
