import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import request from "api/request";
import Button from "components/Button/Button";
import Select from "components/Select/Select";
import Textbox from "components/TextBox/TextBox";

import { PartTimerLankSelectOptions } from "utils/constants";
import lankValidationSchema from "utils/validations/lankValidationSchema";

import BtnDelSVG from "assets/images/btn_del.svg";

interface LankPopupProps {
  setLankOpen: (e: boolean) => void;
}

const defaultValues = {
  lankSelect: {
    value: "init",
    label: "선택",
  },
  classALankSelect: "",
  classBLankSelect: "",
  classCLankSelect: "",
};

const LankPopup = ({ setLankOpen }: LankPopupProps) => {
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(lankValidationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  // 파트타이머 기본 등급 호출
  //useEffect(() => {
  //  request("get", "/users/parttimer_list/class", {}, {}).then((res: any) => {
  //    if (res.status === 200) {
  //      const basic_class = PartTimerLankSelectOptions.filter(
  //        (e: any) => e.value === res.data.result[0].basic_class_setting
  //      );
  //      setValue("lankSelect", basic_class[0]);
  //      setValue("classALankSelect", res.data.result[0].a_class);
  //      setValue("classBLankSelect", res.data.result[0].b_class);
  //      setValue("classCLankSelect", res.data.result[0].c_class);
  //    }
  //  });
  //}, []);

  // 파트타이머 기본 등급 설정
  //const handleSetting = async () => {
  //  const payload = {
  //    basic_class_setting: values.lankSelect.value,
  //    a_class: Number(values.classALankSelect),
  //    b_class: Number(values.classBLankSelect),
  //    c_class: Number(values.classCLankSelect),
  //  };

  //  await request("put", "/users/parttimer_list/edit/class", {}, payload).then(
  //    (res: any) => {
  //      if (res.status === 200) {
  //        setLankOpen(false);
  //      }
  //    }
  //  );
  //};

  return (
    <LankSettingDiv>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(
            () => {},
            () => {}
          );
        }}
      >
        <div className="close-img">
          <img
            src={BtnDelSVG}
            alt="닫기 이미지"
            onClick={() => setLankOpen(false)}
          />
        </div>
        <div className="lank-setting">
          <h4>기본 등급 설정</h4>
          <Controller
            control={control}
            name="lankSelect"
            render={({ field }) => (
              <Select
                {...field}
                placeholder="전체"
                variant="primary"
                fullWidth
                options={PartTimerLankSelectOptions}
              />
            )}
          />
        </div>
        <div>
          <h4>등급 설정 기준</h4>
          <div className="lank-default">
            <div>
              <div className="lank-text">
                <span className="classA">A</span> A등급
              </div>
              <div className="lank-default-select">
                <Controller
                  control={control}
                  name="classALankSelect"
                  render={({ field }) => (
                    <Textbox
                      {...field}
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      placeholder="인상률"
                      fullWidth
                    >
                      %
                    </Textbox>
                  )}
                />
              </div>
            </div>
            <div>
              <div className="lank-text">
                <span className="classB">B</span> B등급
              </div>
              <div className="lank-default-select">
                <Controller
                  control={control}
                  name="classBLankSelect"
                  render={({ field }) => (
                    <Textbox
                      {...field}
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      placeholder="인상률"
                      fullWidth
                    >
                      %
                    </Textbox>
                  )}
                />
              </div>
            </div>
            <div>
              <div className="lank-text">
                <span className="classC">C</span> C등급
              </div>
              <div className="lank-default-select">
                <Controller
                  control={control}
                  name="classCLankSelect"
                  render={({ field }) => (
                    <Textbox
                      {...field}
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      placeholder="인상률"
                      fullWidth
                    >
                      %
                    </Textbox>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="submit-btn">
          <Button
            type="button"
            variant="tertiary"
            preset="1"
            onClick={() => setLankOpen(false)}
          >
            취소
          </Button>
          <span className="void-sec"></span>
          <Button
            type="button"
            variant="primary"
            preset="2"
            //onClick={() => handleSetting()}
          >
            설정
          </Button>
        </div>
      </form>
    </LankSettingDiv>
  );
};

export default LankPopup;

const LankSettingDiv = styled.div`
  position: fixed;
  z-index: 999999999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  & form {
    position: absolute;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 541px;
    border-radius: 20px;
    padding: 28px;
  }

  .close-img {
    text-align: right;
    img {
      cursor: pointer;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    color: #222;
    margin: 13px 0 16px 0;
  }

  .lank-setting {
    margin-bottom: 40px;
  }

  .lank-default {
    & > div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .lank-text {
      width: 25%;
      font-size: 14px;

      span {
        display: inline-block;
        vertical-align: middle;
        padding: 3.5px 5px 5px 5.8px;
        width: 20px;
        height: 20px;
        font-size: 13px;
        border-radius: 50%;
        color: #fff;
        margin: -3px 5px 0 0;
      }
    }
    .classA {
      background-color: #e84946;
    }
    .classB {
      background-color: #fd9603;
    }
    .classC {
      background-color: #6381e0;
    }

    .lank-default-select {
      width: 75%;
    }
  }

  .submit-btn {
    width: 408px;
    margin: 40px auto 20px auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
