/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { PaymentTestData } from "utils/testdata";

const DataMode = () => {
  return (
    <DataModeStyled>
      <div className="payment-data-mode-wrap">
        <div className="payment-item-info">
          <div className="payment-item-title">품목정보</div>
          <div className="payment-item-sub payment-item-info-sub payment-item-flex">
            <div>품목명</div>
            <div>규격</div>
            <div>품목단가</div>
          </div>
          <div className="payment-item-flex payment-item-info-sub">
            <div>품목1</div>
            <div>1040*1940</div>
            <div>₩10,000</div>
          </div>
          <div className="payment-item-flex-sub">자산현황</div>
        </div>
        <div className="payment-data-mode-scroll">
          <div>
            {PaymentTestData.map((e: any, i: number) => {
              return (
                <div
                  key={i}
                  className="payment-item-layer"
                  css={{
                    minWidth: "250px",
                  }}
                >
                  <div className="payment-item-title">{e.floor}</div>
                  <div className="payment-item-sub payment-item-total-sub payment-item-flex">
                    <div>수량</div>
                    <div>단가</div>
                  </div>
                  <div className="payment-item-flex payment-item-total-sub">
                    <div>{e.count}</div>
                    <div>{e.pay}</div>
                  </div>
                  <div className="payment-item-flex-sub">₩80,000</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="payment-item-total">
          <div className="payment-item-title">Total</div>
          <div className="payment-item-sub payment-item-total-sub payment-item-flex">
            <div>품목별 총 수량</div>
            <div>품목별 총 단가</div>
          </div>
          <div className="payment-item-flex payment-item-total-sub">
            <div>40</div>
            <div>₩400,000</div>
          </div>
          <div className="payment-item-flex-sub payment-item-flex-sub2">
            <div>160</div>
            <div>₩1,600,000</div>
          </div>
        </div>
      </div>
      <div className="grand-total">
        <div>GRAND TOTAL</div>
        <div>₩ 44,100,000</div>
      </div>
    </DataModeStyled>
  );
};

export default DataMode;

const DataModeStyled = styled.div`
  width: 100%;
  .payment-data-mode-wrap {
    width: 100%;
    display: flex;
  }

  .payment-item-info {
    width: 25%;
    border-right: 1px solid #ebebeb;
  }

  .payment-data-mode-scroll {
    width: 1000px;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    @media screen and (max-width: 1600px) {
      width: 600px;
    }
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c09260;
      border-radius: 10px;
      background-clip: padding-box;
    }

    & > div {
      display: flex;
    }

    .payment-item-layer {
      border-right: 1px solid #ebebeb;
    }
  }

  .payment-item-total {
    width: 25%;
    border-left: 1px solid #ebebeb;
  }

  .payment-item-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 62px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: rgba(168, 154, 139, 0.3);
  }

  .payment-item-sub {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: #f9f9f9;
  }

  .payment-item-info-sub > div {
    width: 33.333%;
    text-align: center;
  }

  .payment-item-total-sub > div {
    width: 50%;
    text-align: center;
  }

  .payment-item-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
    border-bottom: 1px solid #ebebeb;
  }

  .payment-item-flex-sub {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    height: 68px;
    border-bottom: 3px solid #ebebeb;
  }

  .payment-item-flex-sub2 > div {
    width: 50%;
    text-align: center;
  }

  .grand-total {
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    border-bottom: 3px solid #444;

    & div {
      margin: 0 30px;
    }
  }
`;
