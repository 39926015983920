/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

interface ButtonProps {
  w?: string;
  h?: string;
  fSize?: string;
  colors?: string;
  bgColor?: string;
  variant: string;
  preset: string;
  type: "button" | "submit" | "reset" | undefined;
  onClick?: (e: any) => void;
  disabled?: boolean;
  children: React.ReactNode;
  fullWidth?: boolean;
}

const Button = (props: ButtonProps) => {
  const { variant, preset, type, onClick, disabled, children } = props;

  const root = rootCss(props);

  // 버튼
  const rootPrimary = css`
    border: solid 1px #a0adff;
    background-color: ${(() => {
      switch (parseInt(preset, 10)) {
        case 1:
          return "#ffffff";
        case 2:
          return "#a0adff";
        default:
          return null;
      }
    })()};
  `;
  const rootSecondary = css`
    border: ${(() => {
      switch (parseInt(preset, 10)) {
        case 1:
          return "solid 1px #a89a8b";
        case 2:
          return "solid 1px #a89a8b";
        default:
          return null;
      }
    })()};
    background-color: ${(() => {
      switch (parseInt(preset, 10)) {
        case 1:
          return "#a89a8b";
        case 2:
          return "#ffffff";
        default:
          return null;
      }
    })()};
  `;
  const rootTertiary = css`
    border: ${(() => {
      switch (parseInt(preset, 10)) {
        case 1:
          return "solid 1px #444";
        case 2:
          return "solid 1px #444";
        default:
          return null;
      }
    })()};
    background-color: ${(() => {
      switch (parseInt(preset, 10)) {
        case 1:
          return "#444";
        case 2:
          return "#ffffff";
        default:
          return null;
      }
    })()};
  `;
  const rootPrimaryHover = css`
    border: 1px solid #c09260;
    background-color: #ffffff;

    & span {
      color: #c09260;
    }

    &:hover {
      border: 1px solid #c09260;
      background-color: #c09260;

      & span {
        color: #ffffff;
      }
    }
  `;
  const rootSecondaryHover = css`
    border: 1px solid #a89a8b;
    background-color: #ffffff;

    & span {
      color: #a89a8b;
    }

    &:hover {
      border: 1px solid #a89a8b;
      background-color: #a89a8b;

      & span {
        color: #ffffff;
      }
    }
  `;

  // 라벨
  const labelPrimary = css`
    color: ${(() => {
      switch (parseInt(preset, 10)) {
        case 1:
          return "#c09260";
        case 2:
          return "#ffffff";
        default:
          return null;
      }
    })()};
  `;
  const labelSecondary = css`
    color: ${(() => {
      switch (parseInt(preset, 10)) {
        case 1:
          return "#ffffff";
        case 2:
          return "#a89a8b";
        default:
          return null;
      }
    })()};
  `;

  const labelTertiary = css`
    color: ${(() => {
      switch (parseInt(preset, 10)) {
        case 1:
          return "#ffffff";
        case 2:
          return "#444";
        default:
          return null;
      }
    })()};
  `;

  return (
    <button
      css={[
        root,
        (() => {
          switch (variant) {
            case "primary":
              return rootPrimary;
            case "secondary":
              return rootSecondary;
            case "tertiary":
              return rootTertiary;
            case "primaryHover":
              return rootPrimaryHover;
            case "secondaryHover":
              return rootSecondaryHover;
            default:
              return null;
          }
        })(),
        disabled && rootDisabled,
      ]}
      className="component-button"
      onClick={(e: any) => {
        e.stopPropagation(); // 이벤트 버블링 차단
        !disabled && onClick && onClick(e);
      }}
      type={type}
    >
      <span
        css={[
          (() => {
            switch (variant) {
              case "primary":
                return labelPrimary;
              case "secondary":
                return labelSecondary;
              case "tertiary":
                return labelTertiary;
              default:
                return null;
            }
          })(),
        ]}
      >
        {children}
      </span>
    </button>
  );
};

const rootCss = (props: ButtonProps) => {
  const width = props.w ? `${props.w}px` : "200px";
  const height = props.h ? `${props.h}px` : "65px";

  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props.fullWidth ? "100%" : width};
    height: ${height};
    font-size: 18px;
    color: ${props.colors ?? "#c09260"};
    background-color: ${props.bgColor ?? "#ffffff"};
    border: solid 1px #c09260;
    border-radius: 8px;
  `;
};

const rootDisabled = css`
  opacity: 0.5;
  cursor: default;
`;

export default Button;
