import { useState } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import request from "api/request";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";

import Layout from "layout/layout";

import useAlert from "hooks/useAlert";

import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Select from "components/Select/Select";

import { PAGE_LAYOUT, NOTICE_OPTIONS } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import { CompanyNoticeForm } from "types/registType";
import { CompanyNoticePayloadTypes } from "types/payloadTypes";

import IconCalSVG from "assets/images/icon_cal.svg";

import "react-dayjs-picker/dist/index.css";

const defaultValues: CompanyNoticeForm = {
  CompanyNoticeGubun: {
    value: "init",
    label: "선택",
  },
  CompanyNoticeTitle: "",
  CompanyNoticeDate: "",
  CompanyNoticeContent: "",
};

const CompanyNoticeRegist = () => {
  const { noti } = useAlert();
  const navigate = useNavigate();
  const [isDate, setIsDate] = useState(false);

  const {
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  //const handleCompanyNoticeSubmit = async () => {
  //  let payload: CompanyNoticePayloadTypes = {
  //    type: values.CompanyNoticeGubun.value,
  //    title: values.CompanyNoticeTitle,
  //    start_date: values.CompanyNoticeDate,
  //    content: values.CompanyNoticeContent,
  //  };

  //  await request("post", "/posts", {}, payload)
  //    .then((res: any) => {
  //      if (res.status === 200) {
  //        noti("등록되었습니다.", "확인");
  //        navigate("/notice/company", { replace: true });
  //      }
  //    })
  //    .catch((err) => {
  //      throw err;
  //    });
  //};

  return (
    <Layout page={PAGE_LAYOUT.notice}>
      <CompanyNoticeRegistStyled>
        <div className="status-wrap">
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();

              handleSubmit(
                () => {
                  //handleCompanyNoticeSubmit();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div>
              <h3>&#183; 공지사항 등록 &#183;</h3>
              <Row label="구분">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="CompanyNoticeGubun"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="primary"
                        fullWidth
                        options={NOTICE_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="제목">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="CompanyNoticeTitle"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        placeholder="제목을 입력하세요."
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="날짜">
                <div
                  className="date-image"
                  onClick={() => {
                    setIsDate(true);
                  }}
                >
                  <img src={IconCalSVG} alt="캘린더" />
                </div>
                <div className="input-sec date-input">
                  <Controller
                    control={control}
                    name="CompanyNoticeDate"
                    render={({ field }) => (
                      <DatePicker
                        //register={register("employment")}
                        isOpen={isDate}
                        setIsOpen={setIsDate}
                        popoverPositions={["bottom"]}
                        onSelect={(date) => {
                          field.onChange(dayjs(date).format("YYYY-MM-DD"));
                        }}
                        value={field.value}
                        closeOnSelect={true}
                        onChange={() => {}}
                        //errors={errors}
                        placeholder="날짜를 선택하세요."
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="내용">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="CompanyNoticeContent"
                    render={({ field }) => (
                      <Input
                        {...field}
                        multiline
                        doubleheight="true"
                        useMaxLengthIndicator
                        maxLength="1000"
                        type="text"
                        placeholder="내용을 입력하세요."
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                취소
              </Button>
              <span className="void-sec"></span>
              <Button type="submit" variant="primary" preset="2">
                등록
              </Button>
            </div>
          </form>
        </div>
      </CompanyNoticeRegistStyled>
    </Layout>
  );
};

export default CompanyNoticeRegist;

const CompanyNoticeRegistStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    padding: 35px;
  }
  form {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #222;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .date-image {
    position: absolute;
    margin-top: 8px;
    margin-left: 620px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 49px;
    height: 49px;
    background-color: #444;
    cursor: pointer;
  }

  .date-input {
    input::placeholder {
      color: #b2b2b2;
    }
    input[name="rdp-date-picker"] {
      width: 100%;
      height: 65px;
      border: solid 1px #ddd;
      background-color: #fff;
      line-height: 1.29;
      font-size: 16px;
      color: #000;
      padding-left: 20px;
    }
    .react-tiny-popover-container {
      height: 100px;
      background-color: red;
    }
  }

  .submit-btn {
    width: 408px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
