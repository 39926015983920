import { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";

import request from "api/request";
import Layout from "layout/layout";
import useAlert from "hooks/useAlert";
import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Radio from "components/Radio/Radio";
import Select from "components/Select/Select";
import FileInput from "components/FileInput/FileInput";
import Checkbox from "components/CheckBox/CheckBox";
import Textbox from "components/TextBox/TextBox";
import Postcode from "components/Postcode/Postcode";
import {
  CARD_COMPANY_OPTIONS,
  DEPARTMENT_OPTIONS,
  DISABLED_OPTIONS,
  EDUCATION_OPTIONS,
  ELIGIBILTY_OPTIONS,
  EMPLOY_OPTIONS,
  GRADUATE_OPTIONS,
  MILITARY_OPTIONS,
  PAGE_LAYOUT,
  PART1_OPTIONS,
  PART2_OPTIONS,
  PART3_OPTIONS,
  PART4_OPTIONS,
  PART5_OPTIONS,
} from "utils/constants";
import { PREFIX_NUMBER_OPTIONS } from "utils/constants";
import transferValidationSchema from "utils/validations/transferValidationSchema";
import IconCalSVG from "assets/images/icon_cal.svg";
import IconPlusSVG from "assets/images/icon_plus.svg";
import { PartOptionTypes } from "types/commonTypes";
import { PartTimerRegistPayloadTypes } from "types/payloadTypes";

const defaultValues = {
  parttimerName: "",
  parttimerIdPhoto: [],
  parttimerEmail: "",
  parttimerGender: "",
  parttimerBirth01: "",
  parttimerBirth02: "",
  parttimerBirth03: "",
  parttimerPhone1: {
    value: "init",
    label: "선택",
    code: "",
  },
  parttimerPhone2: "",
  parttimerPhone3: "",
  zipCode: "",
  address: "",
  detailAddress: "",
  parttimerFirst01: {
    value: "init",
    label: "부서 선택",
    code: "",
  },
  parttimerFirst02: {
    value: "init",
    label: "파트 선택",
    code: "",
  },
  parttimerSecond01: {
    value: "init",
    label: "부서 선택",
    code: "",
  },
  parttimerSecond02: {
    value: "init",
    label: "파트 선택",
    code: "",
  },
  parttimerBank01: {
    value: "init",
    label: "선택",
    code: "",
  },
  parttimerBank02: "",
  parttimerEdu: {
    value: "init",
    label: "선택",
    code: "",
  },
  parttimerSchoolName: "",
  parttimerMajor: "",
  parttimerGraduateGubun: {
    value: "init",
    label: "선택",
    code: "",
  },
  parttimerGraduateYear: "",
  parttimerArmy: {
    value: "init",
    label: "선택",
    code: "",
  },
  parttimerVeterans: {
    value: "init",
    label: "선택",
    code: "",
  },
  parttimerDisabled: {
    value: "init",
    label: "선택",
    code: "",
  },
  parttimerWorkInput: [
    {
      career_name: "",
      type: {
        value: "init",
        label: "선택",
        code: "",
      },
      start_date: "",
      end_date: "",
      salary: "",
      job: "",
      job_summary: "",
    },
  ],
  parttimerLicenseInput: [
    {
      license_name: "",
      publisher: "",
      pass_date: "",
    },
  ],
  parttimerSelf: "",
  policyCheck: false,
};

registerLocale("ko", ko);

const PartTimerRegist = () => {
  const { noti } = useAlert();
  const navigate = useNavigate();
  const { userInfo } = useSelector((s: any) => s.auth);
  const [popup, setPopup] = useState(false);
  const [isGraduate, setIsGraduate] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [userChoice, setUserChoice] = useState<any>([]);
  const [firstPartOption, setFirstPartOption] = useState<PartOptionTypes[]>([]);
  const [secondPartOption, setSecondPartOption] = useState<PartOptionTypes[]>(
    []
  );
  const [enrollCompany, setEnrollCompany] = useState({
    address: "",
    zipcode: "",
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(transferValidationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  // 1차 희망 선택 시 파트 옵션
  const choiceFirstPartOption = useCallback(() => {
    switch (values.parttimerFirst01.label) {
      case "객실팀":
        setFirstPartOption(PART1_OPTIONS);
        break;
      case "객실정비팀":
        setFirstPartOption(PART2_OPTIONS);
        break;
      case "식음팀":
        setFirstPartOption(PART3_OPTIONS);
        break;
      case "안전관리팀":
        setFirstPartOption(PART4_OPTIONS);
        break;
      case "시설관리팀":
        setFirstPartOption(PART5_OPTIONS);
        break;
      default:
        setFirstPartOption([]);
        break;
    }
  }, [values.parttimerFirst01]);

  // 2차 희망 선택 시 파트 옵션
  const choiceSecondPartOption = useCallback(() => {
    switch (values.parttimerSecond01.label) {
      case "객실팀":
        setSecondPartOption(PART1_OPTIONS);
        break;
      case "객실정비팀":
        setSecondPartOption(PART2_OPTIONS);
        break;
      case "식음팀":
        setSecondPartOption(PART3_OPTIONS);
        break;
      case "안전관리팀":
        setSecondPartOption(PART4_OPTIONS);
        break;
      case "시설관리팀":
        setSecondPartOption(PART5_OPTIONS);
        break;
      default:
        setSecondPartOption([]);
        break;
    }
  }, [values.parttimerSecond01]);

  useEffect(() => {
    if (values.parttimerGraduateGubun.value === "Graduated") {
      setIsGraduate(true);
    } else {
      setIsGraduate(false);
    }
  }, [values.parttimerGraduateGubun]);

  useEffect(() => {
    setValue("policyCheck", isAgree);
  }, [isAgree]);

  // 최대 업데이트 깊이 초과 에러 대응
  useEffect(() => {
    choiceFirstPartOption();
    choiceSecondPartOption();
  }, [choiceFirstPartOption, choiceSecondPartOption]);

  // 경력사항 추가 필드
  const {
    fields: workFields,
    append: workAppend,
    remove: workRemove,
  } = useFieldArray({
    control,
    name: "parttimerWorkInput",
  });

  // 경력사항 추가 함수
  const handleAddWork = useCallback(() => {
    workAppend({
      career_name: "",
      type: {
        value: "init",
        label: "선택",
        code: "",
      },
      start_date: "",
      end_date: "",
      salary: "",
      job: "",
      job_summary: "",
    });
  }, [workAppend]);

  // 자격증 추가 필드
  const {
    fields: licenseFields,
    append: licenseAppend,
    remove: licenseRemove,
  } = useFieldArray({
    control,
    name: "parttimerLicenseInput",
  });

  // 자격증 추가 함수
  const handleAddLicense = useCallback(() => {
    licenseAppend({
      license_name: "",
      publisher: "",
      pass_date: "",
    });
  }, [licenseAppend]);

  // 우편번호 및 주소 값 set
  useEffect(() => {
    setValue("zipCode", enrollCompany.zipcode);
    setValue("address", enrollCompany.address);
  }, [enrollCompany.zipcode, enrollCompany.address]);

  // 경력사항 리스트에 값이 없을 경우 빈 값 넘기기
  let careerInfo: any;
  values.parttimerWorkInput.map((e) => {
    if (
      e.career_name === "" &&
      e.type.value === "init" &&
      e.start_date === "" &&
      e.end_date === "" &&
      e.job === "" &&
      e.job_summary === "" &&
      e.salary === ""
    ) {
      careerInfo = "";
    } else {
      careerInfo = JSON.stringify(values.parttimerWorkInput);
    }
  });

  // 자격증 리스트에 값이 없을 경우 빈 값 넘기기
  let licenseInfo: any;
  values.parttimerLicenseInput.map((e) => {
    if (e.license_name === "" && e.pass_date === "" && e.publisher === "") {
      licenseInfo = "";
    } else {
      licenseInfo = JSON.stringify(values.parttimerLicenseInput);
    }
  });

  const imageFormData = new FormData();
  imageFormData.append("file", values.parttimerIdPhoto[0]);

  //const submitTransfer = async () => {
  //  const attachment: any = [];

  //  if (values.parttimerIdPhoto) {
  //    try {
  //      const res = await request("post", "/files", {}, imageFormData);

  //      if (res?.status === 200) {
  //        attachment.push(res.data.result.id);
  //      }
  //    } catch (err) {
  //      console.log(err);
  //    }
  //  }

  //  let grad;
  //  if (startDate) {
  //    grad = dayjs(startDate).format("YYYY");
  //  }

  //  let payload: PartTimerRegistPayloadTypes = {
  //    type: "PARTNER_PARTTIMER",
  //    job_title: "누리글로벌서비스 파트타이머 채용",
  //    state: "APPROVE_WAITING",
  //    resume_name: values.parttimerName,
  //    gender: values.parttimerGender,
  //    birth_day:
  //      values.parttimerBirth01 +
  //      values.parttimerBirth02 +
  //      values.parttimerBirth03,
  //    phone:
  //      values.parttimerPhone1.value +
  //      values.parttimerPhone2 +
  //      values.parttimerPhone3,
  //    email: values.parttimerEmail,
  //    zip_code: enrollCompany.zipcode,
  //    address: enrollCompany.address,
  //    detail_address: values.detailAddress,
  //    job1_depth1: values.parttimerFirst01.value,
  //    job1_depth2: values.parttimerFirst02.value,
  //    job2_depth1: values.parttimerSecond01.value,
  //    job2_depth2: values.parttimerSecond02.value,
  //    bank: values.parttimerBank01.value,
  //    account_number: values.parttimerBank02,
  //    education: values.parttimerEdu.value,
  //    school_name: values.parttimerSchoolName,
  //    major: values.parttimerMajor,
  //    graduation: grad !== "undefiend" ? String(grad) : null,
  //    graduation_category: values.parttimerGraduateGubun.value,
  //    military_service: values.parttimerArmy.value,
  //    veterans: values.parttimerVeterans.value,
  //    disabled: values.parttimerDisabled.value,
  //    self_introducation: values.parttimerSelf,
  //    career_info: careerInfo,
  //    license_info: licenseInfo,
  //    company_id: userInfo?.company?.id,
  //  };

  //  if (attachment.length > 0) {
  //    payload = {
  //      ...payload,
  //      image: attachment,
  //    };
  //  }

  //  await request("post", "/resume", {}, payload)
  //    .then((res: any) => {
  //      const transferDone = noti("등록되었습니다.", "확인");

  //      const getData = () => {
  //        transferDone.then((result: boolean) => {
  //          if (result === true) {
  //            navigate("/work/part_timer", { replace: true });
  //          }
  //        });
  //      };

  //      getData();
  //    })
  //    .catch((err) => {
  //      if (err.response.data.message === "duplicated email") {
  //        noti("중복된 이메일입니다.", "확인");
  //      }
  //      if (err.response.data.message === "duplicated phone") {
  //        noti("중복된 핸드폰번호 입니다.", "확인");
  //      }
  //    });
  //};

  return (
    <Layout page={PAGE_LAYOUT.part_timer_regist}>
      <PartTimerSupportStyled>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                (res: any) => {
                  //submitTransfer();
                },
                (err: any) => {}
              )();
            }}
          >
            <div className="sec-gubun">
              <h3>&#183; 개인정보 &#183;</h3>
              <Row label="성명">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerName"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        placeholder="성명을 입력하세요."
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="증명사진">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerIdPhoto"
                    render={({ field }) => (
                      <FileInput
                        {...field}
                        accept=".jpg, .png"
                        errors={errors}
                        fullWidth
                        placeholder={"(JPG(JPEG), PNG / 최대 5MB까지)"}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="이메일">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerEmail"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        placeholder="이메일을 입력하세요."
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="성별">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerGender"
                    render={({ field }) => (
                      <div>
                        <Radio
                          {...field}
                          name="gender"
                          value="남성"
                          w="88"
                          h="65"
                          p="0"
                        >
                          남성
                        </Radio>
                        <span className="void-sec"></span>
                        <Radio
                          {...field}
                          name="gender"
                          value="여성"
                          w="88"
                          h="65"
                          p="0"
                        >
                          여성
                        </Radio>
                      </div>
                    )}
                  />
                </div>
              </Row>
              <Row label="생년월일">
                <div className="input-sec birth-box">
                  <Controller
                    control={control}
                    name="parttimerBirth01"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        placeholder="YYYY"
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                      >
                        년
                      </Textbox>
                    )}
                  />
                  <span className="void-sec"></span>
                  <Controller
                    control={control}
                    name="parttimerBirth02"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        placeholder="MM"
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="2"
                      >
                        월
                      </Textbox>
                    )}
                  />
                  <span className="void-sec"></span>
                  <Controller
                    control={control}
                    name="parttimerBirth03"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        placeholder="DD"
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="2"
                      >
                        일
                      </Textbox>
                    )}
                  />
                </div>
              </Row>
              <Row label="휴대전화번호">
                <div className="input-sec phonenumber">
                  <Controller
                    control={control}
                    name="parttimerPhone1"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="tertiary"
                        options={PREFIX_NUMBER_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                  <span>-</span>
                  <Controller
                    control={control}
                    name="parttimerPhone2"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                        errors={errors}
                      />
                    )}
                  />
                  <span>-</span>
                  <Controller
                    control={control}
                    name="parttimerPhone3"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="주소">
                <div className="input-sec">
                  <div className="zipcode">
                    <Controller
                      control={control}
                      name="zipCode"
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          placeholder="우편번호"
                          value={enrollCompany.zipcode || ""}
                          fullWidth
                          errors={errors}
                        />
                      )}
                    />
                    <button
                      type="button"
                      className="address-search"
                      onClick={() => {
                        setPopup(true);
                      }}
                    >
                      주소찾기
                    </button>
                    {popup && (
                      <Postcode
                        setPopup={setPopup}
                        company={enrollCompany}
                        setcompany={setEnrollCompany}
                      />
                    )}
                  </div>
                  <div className="void-sec"></div>
                  <Controller
                    control={control}
                    name="address"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="주소"
                        fullWidth
                        value={enrollCompany.address || ""}
                        errors={errors}
                      />
                    )}
                  />
                  <div className="void-sec"></div>
                  <Controller
                    control={control}
                    name="detailAddress"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="상세주소를 입력하세요."
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
            </div>
            <div className="sec-gubun">
              <h3>&#183; 지원분야 &#183;</h3>
              <Row label="1차 희망">
                <div className="input-sec hope-select">
                  <Controller
                    control={control}
                    name="parttimerFirst01"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="부서 선택"
                        variant="primary"
                        options={DEPARTMENT_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                  <span className="void-sec"></span>
                  <Controller
                    control={control}
                    name="parttimerFirst02"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="파트 선택"
                        variant="primary"
                        options={firstPartOption}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="2차 희망">
                <div className="input-sec hope-select">
                  <Controller
                    control={control}
                    name="parttimerSecond01"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="부서 선택"
                        variant="primary"
                        options={DEPARTMENT_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                  <span className="void-sec"></span>
                  <Controller
                    control={control}
                    name="parttimerSecond02"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="파트 선택"
                        variant="primary"
                        options={secondPartOption}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="계좌번호">
                <div className="input-sec bank-select">
                  <Controller
                    control={control}
                    name="parttimerBank01"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="은행 선택"
                        variant="secondary"
                        options={CARD_COMPANY_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                  <span className="void-sec"></span>
                  <Controller
                    control={control}
                    name="parttimerBank02"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="'-' 없이 숫자만 입력하세요."
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
                <span className="bank-side-text">
                  * 본인의 시간제 (파트타임) 업무에 대한 급여가 입금될 계좌
                  정보를 입력해주십시오.
                </span>
              </Row>
            </div>
            <div className="sec-gubun">
              <h3>&#183; 학력·병역·장애 &#183;</h3>
              <Row label="최종학력">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerEdu"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="tertiary"
                        options={EDUCATION_OPTIONS}
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="학교명">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerSchoolName"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="학교명을 입력해주세요."
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="전공">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerMajor"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="전공을 입력해주세요."
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="졸업구분">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerGraduateGubun"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="tertiary"
                        options={GRADUATE_OPTIONS}
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="졸업연도">
                <div className="input-sec date-input">
                  <Controller
                    control={control}
                    name="parttimerGraduateYear"
                    render={({ field }) => (
                      <DatePicker
                        locale="ko"
                        selected={startDate}
                        onChange={(date: any) => setStartDate(date)}
                        showYearPicker
                        showPopperArrow={false}
                        dateFormat="yyyy"
                        popperPlacement="bottom"
                        placeholderText="졸업연도를 선택해주세요."
                        startDate={startDate}
                        endDate={null}
                        disabled={!isGraduate}
                      />
                    )}
                  />
                  <div className="date-image">
                    <img src={IconCalSVG} alt="캘린더" />
                  </div>
                </div>
              </Row>
              <Row label="병역">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerArmy"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="tertiary"
                        options={MILITARY_OPTIONS}
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="보훈대상">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerVeterans"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="tertiary"
                        options={ELIGIBILTY_OPTIONS}
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="장애">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerDisabled"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="tertiary"
                        options={DISABLED_OPTIONS}
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
            </div>
            <div className="sec-gubun">
              <h3>&#183; 경력사항 &#183;</h3>
              {workFields.map((license, index) => {
                return (
                  <div className="add-section-inner" key={index}>
                    <Row label="업체">
                      <div className="input-sec">
                        <Controller
                          control={control}
                          name={`parttimerWorkInput.${index}.career_name`}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              placeholder="업체명을 입력하세요."
                              fullWidth
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label="고용형태">
                      <div className="input-sec">
                        <Controller
                          control={control}
                          name={`parttimerWorkInput.${index}.type`}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="선택"
                              variant="tertiary"
                              options={EMPLOY_OPTIONS}
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label="근무기간">
                      <div className="input-sec workday-box">
                        <Controller
                          control={control}
                          name={`parttimerWorkInput.${index}.start_date`}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              placeholder="YYYY.MM.DD"
                              errors={errors}
                            />
                          )}
                        />
                        <span className="void-sec">-</span>
                        <Controller
                          control={control}
                          name={`parttimerWorkInput.${index}.end_date`}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              placeholder="YYYY.MM.DD"
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label="연봉">
                      <div className="input-sec">
                        <Controller
                          control={control}
                          name={`parttimerWorkInput.${index}.salary`}
                          render={({ field }) => (
                            <Textbox
                              {...field}
                              placeholder="연봉을 입력하세요."
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              fullWidth
                            >
                              만원
                            </Textbox>
                          )}
                        />
                      </div>
                    </Row>
                    <Row label="직무">
                      <div className="input-sec">
                        <Controller
                          control={control}
                          name={`parttimerWorkInput.${index}.job`}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              placeholder="직무를 입력하세요."
                              fullWidth
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label="직무 요약">
                      <div className="input-sec">
                        <Controller
                          control={control}
                          name={`parttimerWorkInput.${index}.job_summary`}
                          render={({ field }) => (
                            <Input
                              {...field}
                              multiline
                              useMaxLengthIndicator
                              maxLength="1000"
                              type="text"
                              placeholder="내용을 입력하세요."
                              fullWidth
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <div className="section-add">
                      {workFields.length > 1 && (
                        <button
                          className="delete-sec"
                          onClick={() => workRemove(1)} // 배열에서 1번만 삭제하도록 처리. -> 0번은 삭제 안됨.
                        >
                          삭제
                        </button>
                      )}
                      <button
                        className="add-sec"
                        type="button"
                        onClick={() => handleAddWork()}
                      >
                        경력 추가{" "}
                        <img src={IconPlusSVG} alt="자격증 추가 버튼" />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="sec-gubun">
              <h3>&#183; 자격증 &#183;</h3>
              {licenseFields.map((license, index) => {
                return (
                  <div className="add-section-inner" key={index}>
                    <Row label="취득일">
                      <div className="input-sec">
                        <Controller
                          control={control}
                          name={`parttimerLicenseInput.${index}.license_name`}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              placeholder="자격증명을 입력하세요."
                              fullWidth
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label="발행처">
                      <div className="input-sec">
                        <Controller
                          control={control}
                          name={`parttimerLicenseInput.${index}.publisher`}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              placeholder="발행처를 입력하세요."
                              fullWidth
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label="취득일자">
                      <div className="input-sec">
                        <Controller
                          control={control}
                          name={`parttimerLicenseInput.${index}.pass_date`}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              placeholder="YYYY.MM.DD"
                              fullWidth
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <div className="section-add">
                      {licenseFields.length > 1 && (
                        <button
                          className="delete-sec"
                          onClick={() => licenseRemove(1)}
                        >
                          삭제
                        </button>
                      )}
                      <button
                        className="add-sec"
                        type="button"
                        onClick={() => handleAddLicense()}
                      >
                        자격증 추가{" "}
                        <img src={IconPlusSVG} alt="자격증 추가 버튼" />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="sec-gubun">
              <h3>&#183; 자기소개 &#183;</h3>
              <div className="form-inner">
                <div className="input-box self-box">
                  <Controller
                    control={control}
                    name="parttimerSelf"
                    render={({ field }) => (
                      <Input
                        {...field}
                        multiline
                        useMaxLengthIndicator
                        maxLength="1000"
                        type="text"
                        placeholder="내용을 입력하세요."
                        errors={errors}
                        fullWidth
                        doubleheight="true"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="sec-gubun">
              <h3>&#183; 회원가입 동의 여부 &#183;</h3>
              <div className="policy-content">
                해당 파트타이머는 주식회사 누리글로벌서비스의 회원가입
                약관,서비스 이용 약관, 개인정보 처리 방침을 확인하였습니다.
                <br />
                파트타이머의 동의 없이 회원가입을 진행함으로 인해 발생하는 모든
                손해는 가입을 대행하는 업체와 담당자가 책임집니다.
              </div>
              <div className="agree-checkbox">
                <div className="agree-checkbox-text">이에 동의합니다.</div>
                <Controller
                  control={control}
                  name="policyCheck"
                  render={({ field }) => (
                    <Checkbox
                      checked={isAgree}
                      onChange={(e) => setIsAgree(e.target.checked)}
                    >
                      {" "}
                    </Checkbox>
                  )}
                />
              </div>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                목록
              </Button>
              <span className="void-sec"></span>
              <Button type="submit" variant="primary" preset="2">
                등록
              </Button>
            </div>
          </form>
        </div>
      </PartTimerSupportStyled>
    </Layout>
  );
};

export default PartTimerRegist;

const PartTimerSupportStyled = styled.div`
  width: 100%;
  margin: 40px;
  & > div {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 35px;
  }

  form {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #222;
  }

  .sec-gubun {
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .birth-box {
    display: flex;
  }

  .phonenumber {
    display: flex;
    align-items: center;

    & span {
      margin: 0 10px;
    }
  }

  .zipcode {
    display: flex;
  }

  .address-search {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
  }

  .hope-select {
    display: flex;
    align-items: center;
  }

  .hope-select > div {
    flex-grow: 1;
  }

  .bank-select {
    display: flex;
  }

  .bank-side-text {
    position: relative;
    font-size: 16px;
    font-weight: normal;
    color: #707070;
    top: -20px;
  }

  .date-image {
    position: absolute;
    margin-top: -57px;
    margin-left: 621px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 49px;
    height: 49px;
    background-color: #444;
    cursor: pointer;
  }

  .date-input {
    cursor: pointer;
    input::placeholder {
      color: #b2b2b2;
    }
    input {
      width: 100%;
      height: 65px;
      border: solid 1px #ddd;

      line-height: 1.29;
      font-size: 16px;
      color: #000;
      padding-left: 20px;
    }
  }

  .react-datepicker {
    width: 220px;
  }
  .react-datepicker__year-wrapper {
    max-width: 300px;
  }
  .react-datepicker__navigation--previous {
    top: 8px;
  }
  .react-datepicker__navigation--next {
    top: 8px;
  }
  .workday-box {
    display: flex;
    align-items: center;
  }
  .section-add {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;

    & .delete-sec {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 8px 12px;
      border: solid 1px #444;
      margin-right: 6px;
      color: #444;
    }

    & .add-sec {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      background-color: #444;
      color: #fff;

      & img {
        margin-left: 4px;
      }
    }
  }
  .add-section-inner {
    border-bottom: 2px dashed #ddd;
    margin-top: 40px;

    &:last-of-type {
      border-bottom: 0;
    }
  }
  .policy-content {
    font-size: 14px;
    line-height: 2;
    text-align: center;
  }

  .agree-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .agree-checkbox-text {
    margin-top: 3px;
    margin-right: 8px;
  }

  .submit-btn {
    width: 408px;
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: center;
  }

  & .void-sec {
    height: 10px;
    margin: 0 6px;
  }
`;
