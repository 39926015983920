import { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Select from "components/Select/Select";
import Search from "components/Search/Search";
import SearchItem from "components/SearchPopup/SearchItem";
import Pagination from "components/Pagination/Pagination";

import BtnCheckOffSVG from "assets/images/btn_check_off.png";
import BtnCheckOnSVG from "assets/images/btn_check_on.png";

import { MODE_OPTIONS, EmployeeSelectOptions } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

const defaultValues = {
  selectPlace: {
    value: "init",
    label: "선택",
  },
  selectFloor: {
    value: "init",
    label: "선택",
  },
  selectRoom: {
    value: "init",
    label: "선택",
  },
};

const LinenInfoForm = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [result, setResult] = useState<any>([]);
  const [openSearchItem, setOpenSearchItem] = useState<boolean>(false);
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const handleEditMode = () => {
    setIsEditMode(true);
  };

  const handleEdit = () => {
    setIsEditMode(false);
  };

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <LinenInfoFormStyled>
      <div className="linen-info-form-wrap">
        <div className="linen-info-form-inner">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
            }}
          >
            <div className="search-select">
              <div className="header-text">지점</div>
              <Controller
                control={control}
                name="selectPlace"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="전체"
                    w="180"
                    h="52"
                    fontS="15"
                    variant="tertiary"
                    options={MODE_OPTIONS}
                  />
                )}
              />
            </div>
            <div className="search-select">
              <div className="header-text">층</div>
              <Controller
                control={control}
                name="selectFloor"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="전체"
                    w="130"
                    h="52"
                    fontS="15"
                    variant="tertiary"
                    options={MODE_OPTIONS}
                  />
                )}
              />
            </div>
            <div className="search-select">
              <div className="header-text">객실</div>
              <Controller
                control={control}
                name="selectRoom"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="전체"
                    w="130"
                    h="52"
                    fontS="15"
                    variant="tertiary"
                    options={MODE_OPTIONS}
                  />
                )}
              />
            </div>
          </form>
          {isEditMode ? (
            <div className="linen-info-form-btn">
              <div
                className="linen-info-add-btn"
                onClick={() => setOpenSearchItem(true)}
              >
                품목 추가
              </div>

              <div className="linen-info-del-btn">선택 삭제</div>
              <div className="linen-info-edit-btn" onClick={() => handleEdit()}>
                수정
              </div>
            </div>
          ) : (
            <div className="linen-info-form-btn">
              <div className="linen-info-del-btn">선택 삭제</div>
              <div
                className="linen-info-edit-mode"
                onClick={() => handleEditMode()}
              >
                수정 모드
              </div>
            </div>
          )}
          {openSearchItem && (
            <SearchItem
              setOpenSearchPopup={setOpenSearchItem}
              setResult={setResult}
            />
          )}
        </div>
        <div className="linen-info-form-body">
          <table>
            <thead>
              <tr>
                <th className="linen-head-child1">
                  <input type="checkbox" name="" id="" />
                </th>
                <th className="linen-head-child2">품목명</th>
                <th className="linen-head-child2">규격</th>
                <th className="linen-head-child2">단가</th>
                <th className="linen-head-child2">기본 배치 수량</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="linen-head-child1">
                  <input type="checkbox" name="" id="" />
                </td>
                <td className="linen-head-child2">품목 1</td>
                <td className="linen-head-child2">1040*1940</td>
                <td className="linen-head-child2">\ 10,000</td>
                <td className="linen-head-child2">
                  {isEditMode ? (
                    <input type="number" inputMode="numeric" pattern="[0-9]*" />
                  ) : (
                    "5"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="pagenation">
            <Pagination
              page={page}
              count={totalPages}
              list={tableData.length}
              onChangePage={setPage}
            />
          </div>
        </div>
      </div>
    </LinenInfoFormStyled>
  );
};

export default LinenInfoForm;

const LinenInfoFormStyled = styled.div`
  width: 100%;
  .linen-info-form-wrap {
    .linen-info-form-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    form {
      display: flex;
    }
    .search-select {
      display: flex;
      align-items: center;
    }
    .header-text {
      font-size: 16px;
      font-weight: bold;
      color: #222;
      margin-right: 10px;
      margin-left: 5px;
    }

    .linen-info-form-btn {
      display: flex;
      justify-content: end;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        margin-left: 10px;
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
      }
    }

    .linen-info-add-btn,
    .linen-info-del-btn {
      width: 100px;
      background-color: #444;
    }
    .linen-info-edit-btn {
      width: 75px;
      background-color: #c09260;
    }
    .linen-info-edit-mode {
      width: 100px;
      background-color: #c09260;
    }

    .linen-info-form-body {
      margin-top: 30px;
      table {
        width: 100%;
      }

      thead {
        height: 68px;
        background-color: #f9f9f9;

        th {
          font-size: 18px;
          font-weight: bold;
          vertical-align: middle;
          color: #101010;
        }
      }
      tbody {
        tr {
          height: 66px;
          border-bottom: 1px solid #ebebeb;
        }

        td {
          font-size: 15px;
          font-weight: normal;
          vertical-align: middle;
          color: #444;
        }
      }

      .linen-head-child1 {
        width: 5%;
        text-align: center;

        input {
          appearance: none;
          margin-right: 8px;
          width: 20px;
          height: 20px;
          background: url(${BtnCheckOffSVG}) no-repeat center/100%;

          &:checked {
            background: url(${BtnCheckOnSVG}) no-repeat center/100%;
          }
        }
      }
      .linen-head-child2 {
        width: 23.75%;
        text-align: center;

        input {
          width: 70px;
          height: 30px;
          border: solid 1px #ddd;
          background-color: #fff;
          padding: 10px;
          text-align: right;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &[type="number"] {
            -moz-appearance: textfield;
          }
        }
      }
    }
  }
`;
