import { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";
import Layout from "layout/layout";
import { PAGE_LAYOUT } from "utils/constants";

import DataMode from "./components/DataMode";
import CalenderMode from "./components/CalenderMode";

const WorkStatus = () => {
  const btnRef = useRef([] as any);
  const [textChange, setTextChange] = useState("DATAMODE");

  useEffect(() => {
    if (textChange === "DATAMODE") {
      btnRef.current[0].className = "action";
      btnRef.current[1].className = "void";
    } else if (textChange === "CALENDERMODE") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "action";
    }
  }, [textChange]);

  return (
    <Layout page={PAGE_LAYOUT.work_status}>
      <WorkStatusStyled>
        <div className="mode-btn">
          <div
            ref={(el) => (btnRef.current[0] = el)}
            onClick={() => {
              setTextChange("DATAMODE");
            }}
          >
            {/*데이터 모드*/}
          </div>
          {/*<span>&#183;</span>*/}
          <div
            ref={(el) => (btnRef.current[1] = el)}
            onClick={() => {
              setTextChange("CALENDERMODE");
            }}
          >
            {/*캘린더 모드*/}
          </div>
        </div>
        {textChange === "DATAMODE" ? <DataMode /> : <CalenderMode />}
      </WorkStatusStyled>
    </Layout>
  );
};

export default WorkStatus;

const WorkStatusStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;

  .main-wrap {
    width: 100%;
    margin: 60px 40px 30px 40px;

    & > div {
      display: flex;
      justify-content: center;
    }
  }

  .mode-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    /*margin-bottom: 30px;*/

    span {
      /*font-size: 35px;*/
      /*margin: 4px 12px 0 12px;*/
      color: #b2b2b2;
    }

    & > div {
      /*font-size: 28px;*/
      font-weight: bold;
      color: #707070;
      cursor: pointer;
    }

    .action {
      color: #c09260;
    }
  }

  .search-select {
    display: flex;
    align-items: center;
  }

  .header-text {
    font-size: 16px;
    font-weight: bold;
    color: #222;
    margin-right: 12px;
    margin-left: 15px;
  }

  .work-status-wrap {
    margin: 0 40px;
    background-color: #fff;
  }

  .work-status-inner {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    padding: 35px;
  }

  .work-status-day {
    display: flex;
    align-items: center;

    form {
      display: flex;
      align-items: center;

      .search-void {
        margin: 0 3px;
      }

      .search-date-image2 {
        position: absolute;
        margin-top: 0px;
        margin-left: 296px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        background-color: #444;
        cursor: pointer;
      }

      .search-date-image {
        position: absolute;
        margin-top: 0px;
        margin-left: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        background-color: #444;
        cursor: pointer;
      }

      .date-input {
        input::placeholder {
          color: #b2b2b2;
        }
        input[name="rdp-date-picker"] {
          width: 173px;
          height: 52px;
          border: solid 1px #ddd;
          background-color: #fff;
          line-height: 1.29;
          font-size: 16px;
          color: #000;
          padding-left: 20px;
        }
        .react-tiny-popover-container {
          height: 100px;
          background-color: red;
        }
      }
    }

    .day-text {
      font-size: 24px;
      font-weight: bold;
      color: #222;
    }
  }

  .work-status-option {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 30px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .xlsx-download {
    margin-left: 10px;
  }

  .status-regist-btn {
    width: 120px;
  }

  .search-filter-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: solid 1px #ddd;
    background-color: #fff;
    cursor: pointer;
  }

  .search-filter-btn-on {
    border: solid 1px #c09260;
  }

  .alert-work-status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 158px;
    height: 34px;
    border: solid 1px #c9c9c9;
    border-radius: 20px;
    margin: 0 10px 0 20px;
    font-size: 14px;

    div {
      margin-top: 2px;
    }

    img {
      margin: -1px 5px 0 0;
    }

    span {
      color: #c09260;
      font-weight: bold;
      margin: 2px 0 0 5px;
    }
  }

  .week-or-month {
    display: flex;

    & > div {
      width: 67px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: #b2b2b2;
      background-color: #f5f5f6;
      cursor: pointer;
      padding-top: 2px;

      img {
        height: 19px;
        margin-right: 7px;
      }
    }

    & > div:nth-of-type(1) {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    & > div:nth-of-type(2) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .action {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      background-color: #c09260;
    }
  }

  .calender-wrap {
    margin-top: 20px;

    .week-calender {
      width: 100%;

      thead {
        height: 63px;
        background-color: #f9f9f9;
        border: solid 1px #ddd;

        th:nth-of-type(1) {
          background-color: rgba(168, 154, 139, 0.3);
        }

        th {
          width: 170px;
          font-size: 16px;
          font-weight: bold;
          vertical-align: middle;
        }

        .action {
          background-color: #fffaf5;
        }
      }
    }

    .rbc-toolbar {
      display: none;
    }

    .rbc-month-header {
      height: 39px;
      background-color: #f9f9f9;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: normal;
      }
      & > div:nth-of-type(1) {
        color: #ff1e1e;
      }
      & > div:nth-of-type(7) {
        color: #3c7ee1;
      }
    }

    .rbc-date-cell {
      text-align: left;
      padding: 5px 0 0 5px;
    }

    .rbc-row {
      & > div:nth-of-type(1) {
        color: #ff1e1e;
      }
      & > div:nth-of-type(7) {
        color: #3c7ee1;
      }
    }

    .rbc-event {
      font-size: 12px;
      color: #5d5d5d;
      background-color: transparent;
    }

    .rbc-today {
      background-color: #fffaf5;
    }

    /*.month-claender {
      width: 100%;

      thead {
        height: 39px;
        background-color: #f9f9f9;

        th:nth-of-type(1) {
          color: #ff1e1e;
        }
        th:nth-of-type(7) {
          color: #3c7ee1;
        }

        th {
          width: 216px;
          font-size: 15px;
          font-weight: normal;
          vertical-align: middle;
          border: solid 1px #ddd;
        }
      }
    }*/
  }
`;
