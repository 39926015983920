import React from "react";
import styled from "@emotion/styled";

import useWindowScroll from "hooks/useWindowScroll";

import { PageLayoutTypes } from "types/commonTypes";

import Header from "./header";
import Footer from "./footer";
import Sidebar from "./Sidebar";
import TopButton from "./TopButton";

interface LayoutProps {
  children: React.ReactNode;
  page: PageLayoutTypes;
}

const Layout = ({ children, page }: LayoutProps) => {
  const { overflowY, y } = useWindowScroll();

  return (
    <>
      <Header
        headingText={page.headingText}
        headingContent={page.headingContent}
      />
      <LayoutStyled>
        <div className="content-wrap">
          <Sidebar />
          {children}
          {overflowY && y > 0 && <TopButton />}
        </div>
        <Footer />
      </LayoutStyled>
    </>
  );
};

export default Layout;

const LayoutStyled = styled.div`
  margin-top: 140px;

  .content-wrap {
    display: flex;
    /*padding-bottom: 110px;*/
  }
`;
