import { createSlice } from "@reduxjs/toolkit";

interface CodeTypes {
  HEBR: { value: string; label: string }[];
  HEPR: { value: string; label: string }[];
  SPOT: { value: string; label: string }[];
  DEPT: { value: string; label: string }[];
  RANK: { value: string; label: string }[];
  POSI: { value: string; label: string }[];
  COFI: { value: string; label: string }[];
  RECE: { value: string; label: string }[];
  COLL: { value: string; label: string }[];
  CLAS: { value: string; label: string }[];
  DELI: { value: string; label: string }[];
}

export const codeSlice = createSlice({
  name: "code",
  initialState: {
    HEBR: [],
    HEPR: [],
    SPOT: [],
    DEPT: [],
    RANK: [],
    POSI: [],
    COFI: [],
    RECE: [],
    COLL: [],
    CLAS: [],
    DELI: [],
  },
  reducers: {
    setHEBR: (state: CodeTypes, action) => {
      const codeArr = [];
      for (let i = 0; i < action.payload.length; i++) {
        codeArr.push({
          value: action.payload[i].code_name,
          label: action.payload[i].code_name,
        });
      }
      state.HEBR = codeArr;
    },
    setHEPR: (state: CodeTypes, action) => {
      const codeArr = [];
      for (let i = 0; i < action.payload.length; i++) {
        codeArr.push({
          value: action.payload[i].code_name,
          label: action.payload[i].code_name,
        });
      }
      state.HEPR = codeArr;
    },
    setSPOT: (state: CodeTypes, action) => {
      const codeArr = [];
      for (let i = 0; i < action.payload.length; i++) {
        codeArr.push({
          value: action.payload[i].code_name,
          label: action.payload[i].code_name,
        });
      }
      state.SPOT = codeArr;
    },
    setDEPT: (state: CodeTypes, action) => {
      const codeArr = [];
      for (let i = 0; i < action.payload.length; i++) {
        codeArr.push({
          value: action.payload[i].code_name,
          label: action.payload[i].code_name,
        });
      }
      state.DEPT = codeArr;
    },
    setRANK: (state: CodeTypes, action) => {
      const codeArr = [];
      for (let i = 0; i < action.payload.length; i++) {
        codeArr.push({
          value: action.payload[i].code_name,
          label: action.payload[i].code_name,
        });
      }
      state.RANK = codeArr;
    },
    setPOSI: (state: CodeTypes, action) => {
      const codeArr = [];
      for (let i = 0; i < action.payload.length; i++) {
        codeArr.push({
          value: action.payload[i].code_name,
          label: action.payload[i].code_name,
        });
      }
      state.POSI = codeArr;
    },
    setCOFI: (state: CodeTypes, action) => {
      const codeArr = [];
      for (let i = 0; i < action.payload.length; i++) {
        codeArr.push({
          value: action.payload[i].code_name,
          label: action.payload[i].code_name,
        });
      }
      state.COFI = codeArr;
    },
    setRECE: (state: CodeTypes, action) => {
      const codeArr = [];
      for (let i = 0; i < action.payload.length; i++) {
        codeArr.push({
          value: action.payload[i].code_name,
          label: action.payload[i].code_name,
        });
      }
      state.RECE = codeArr;
    },
    setCOLL: (state: CodeTypes, action) => {
      const codeArr = [];
      for (let i = 0; i < action.payload.length; i++) {
        codeArr.push({
          value: action.payload[i].code_name,
          label: action.payload[i].code_name,
        });
      }
      state.COLL = codeArr;
    },
    setCLAS: (state: CodeTypes, action) => {
      const codeArr = [];
      for (let i = 0; i < action.payload.length; i++) {
        codeArr.push({
          value: action.payload[i].code_name,
          label: action.payload[i].code_name,
        });
      }
      state.CLAS = codeArr;
    },
    setDELI: (state: CodeTypes, action) => {
      const codeArr = [];
      for (let i = 0; i < action.payload.length; i++) {
        codeArr.push({
          value: action.payload[i].code_name,
          label: action.payload[i].code_name,
        });
      }
      state.DELI = codeArr;
    },
  },
});

export const {
  setHEBR,
  setHEPR,
  setSPOT,
  setDEPT,
  setRANK,
  setPOSI,
  setCOFI,
  setRECE,
  setCOLL,
  setCLAS,
  setDELI,
} = codeSlice.actions;

export default codeSlice.reducer;
