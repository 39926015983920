import styled from "@emotion/styled";
import Button from "components/Button/Button";

const ErrorMobile = () => {
  return (
    <ErrorStyled>
      <div className="error-wrap">
        <div className="error-inner">
          <h2>NURIDA</h2>
          <div className="error-text1">
            죄송합니다.
            <br />
            모바일에서는 NURIDA
            <br />
            웹페이지에 접속을 할 수 없습니다.
            <br />
            Google Play에서 NURIDA 앱을 다운로드 하시거나, PC로 접속
            부탁드립니다.
          </div>
          <div className="error-text2">
            회사소개나 인재풀 등록, 회원가입을 원하는 고객님은
            <br />
            공식 웹사이트로 이동해 주시기 바랍니다.
          </div>
          <Button type="button" variant="primary" preset="2" w="306">
            <a href="https://nuri-gs.com/home">공식 웹사이트로 이동</a>
          </Button>
          <span className="void-sec"></span>
          <Button type="button" variant="primary" preset="2" w="306">
            <a href="https://nuri-gs.com/home">모바일 앱 다운받기</a>
          </Button>
        </div>
      </div>
    </ErrorStyled>
  );
};

export default ErrorMobile;

const ErrorStyled = styled.div`
  height: 100vh;
  padding: 0 20px;
  background-color: #1d1d1d;
  color: #fff;

  h2 {
    font-family: "Pretendard";
    font-size: 50px;
    font-weight: bold;
    color: #e4caae;
    margin-bottom: 30px;
  }

  .error-wrap {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    a {
      color: #fff;
      display: block;
    }
  }

  .error-text1 {
    font-size: 20px;
    line-height: 1.52;
    font-weight: normal;
  }

  .error-text2 {
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;
    opacity: 0.4;
    margin: 30px 0;
  }

  .void-sec {
    padding: 5px 0;
  }
`;
