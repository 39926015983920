import { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import Pagination from "components/Pagination/Pagination";
import { TapMenuProps } from "types/commonTypes";

const ListMode = ({ textChange }: TapMenuProps) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  return (
    <ListModeStyled>
      <table>
        <thead>
          <tr>
            <th className="payment-head-child1">번호</th>
            <th className="payment-head-child2">지급 신청서 번호</th>
            <th className="payment-head-child3">지점</th>
            <th className="payment-head-child4">협력업체</th>
            <th className="payment-head-child4">담당자</th>
            <th className="payment-head-child4">상태</th>
            <th className="payment-head-child4">날짜</th>
          </tr>
        </thead>
        <tbody>
          <tr
            onClick={() =>
              navigate(`/work/house/linen/payment/detail/${0}`, {
                state: { textChange: textChange },
              })
            }
          >
            <td className="payment-head-child1">1</td>
            <td className="payment-head-child2">#M220919-001</td>
            <td className="payment-head-child3">서울 M-Tower</td>
            <td className="payment-head-child4">대웅크린월드</td>
            <td className="payment-head-child4">김누리</td>
            <td className="payment-head-child4">임시저장</td>
            <td className="payment-head-child4">2022.09.13</td>
          </tr>
        </tbody>
      </table>
      <div className="pagenation">
        <Pagination
          page={page}
          count={totalPages}
          list={tableData.length}
          onChangePage={setPage}
        />
      </div>
    </ListModeStyled>
  );
};

export default ListMode;

const ListModeStyled = styled.div`
  table {
    width: 100%;

    thead {
      th {
        font-size: 18px;
        font-weight: bold;
        color: #101010;
        vertical-align: middle;
        height: 62px;
        background-color: #f9f9f9;
      }
    }

    tbody {
      tr {
        height: 66px;
        border-bottom: 1px solid #ebebeb;
        cursor: pointer;
      }

      td {
        font-size: 15px;
        font-weight: normal;
        vertical-align: middle;
        color: #444;
      }
    }

    .payment-head-child1 {
      width: 10%;
      text-align: center;
    }
    .payment-head-child2 {
      width: 20%;
      text-align: center;
    }
    .payment-head-child3 {
      width: 20%;
      text-align: center;
    }
    .payment-head-child4 {
      width: 10%;
      text-align: center;
    }
  }
`;
