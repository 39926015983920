import { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

import Layout from "layout/layout";

import { PAGE_LAYOUT } from "utils/constants";

import MypageForm from "./MypageForm";
import MypageParttimerForm from "./MypageParttimerForm";
import MypageHistoryForm from "./MypageHistoryForm";

const Mypage = () => {
  const btnRef = useRef([] as any);
  const { userRole } = useSelector((s: any) => s.auth);
  const [textChange, setTextChange] = useState("INFO");

  useEffect(() => {
    if (textChange === "INFO") {
      btnRef.current[0].className = "action";
      btnRef.current[1].className = "void";
    } else if (textChange === "HISTORY") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "action";
    }
  }, [userRole, textChange]);

  return (
    <Layout page={PAGE_LAYOUT.mypage}>
      <MypageStyled>
        {/*{userRole === "PARTTIMER" && (*/}
        <div className="main-inner-title">
          <div
            ref={(el) => (btnRef.current[0] = el)}
            onClick={() => {
              setTextChange("INFO");
            }}
          >
            정보
          </div>
          <span>&#183;</span>
          <div
            ref={(el) => (btnRef.current[1] = el)}
            onClick={() => {
              setTextChange("HISTORY");
            }}
          >
            파트타이머 지원 이력
          </div>
        </div>
        {/*)}*/}
        <div className="status-wrap">
          {textChange === "INFO" ? (
            <MypageParttimerForm />
          ) : (
            <MypageHistoryForm />
          )}
        </div>
      </MypageStyled>
    </Layout>
  );
};

export default Mypage;

const MypageStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    padding: 35px;
  }

  .main-inner-title {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 35px;
      margin: 4px 12px 0 12px;
      color: #b2b2b2;
    }

    & > div {
      font-size: 28px;
      font-weight: bold;
      color: #707070;
      cursor: pointer;
    }

    .action {
      color: #c09260;
    }
  }
`;
