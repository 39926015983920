import request from "api/request";
import {
  CodeTypes,
  CreateSubCodeTypes,
  UpdateSubCodeTypes,
  DeleteSubCodeTypes,
} from "types/codeTypes";

// 환경설정 코드 표 조회
const getCodeTable = async ({ payload }: CodeTypes) => {
  try {
    const data = await request("get", "/settings/code_list", {}, payload);

    return data;
  } catch (err) {
    throw err;
  }
};

// 서브코드 생성
const createSubCode = async ({ payload }: CreateSubCodeTypes) => {
  try {
    const data = await request("post", "/settings/create/code", {}, payload);

    return data;
  } catch (err) {
    throw err;
  }
};

// 서브코드 수정, 사용여부
const updateSubCode = async ({
  settingCodeId,
  payload,
}: UpdateSubCodeTypes) => {
  try {
    const data = await request(
      "put",
      `/settings/update/code/${settingCodeId}`,
      {},
      payload
    );

    return data;
  } catch (err) {
    throw err;
  }
};

// 서브코드 삭제
const deleteSubCode = async ({
  settingCodeId,
  payload,
}: DeleteSubCodeTypes) => {
  try {
    const data = await request(
      "delete",
      `/settings/delete/code/${settingCodeId}`,
      {},
      payload
    );

    return data;
  } catch (err) {
    throw err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getCodeTable, createSubCode, updateSubCode, deleteSubCode };
