import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/layout";

import Search from "components/Search/Search";

import { PAGE_LAYOUT, MASTER_CODE_OPTIONS } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

const defaultValues = {
  search: "",
};

const CodeSetting = () => {
  const navigate = useNavigate();
  const [listData, setListData] = useState<any>([]);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    const searchText = values.search.split("");
    const res = MASTER_CODE_OPTIONS.filter(
      (e: any) => e.label.split("")[0] === searchText[0]
    );
    setListData(res);

    for (let i = 0; i < MASTER_CODE_OPTIONS.length; i++) {
      if (values.search.toLocaleUpperCase() === MASTER_CODE_OPTIONS[i].value) {
        setListData([MASTER_CODE_OPTIONS[i]]);
      }
    }
  }, [values.search]);

  return (
    <Layout page={PAGE_LAYOUT.setting}>
      <CodeSettingStyled>
        <div className="status-wrap">
          <div className="code-wrap">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(
                  (res) => {
                    console.log(res);
                  },
                  (err) => {
                    console.log(err);
                  }
                )();
              }}
            >
              <Controller
                control={control}
                name="search"
                render={({ field }) => (
                  <Search
                    {...field}
                    placeholder="검색어를 입력하세요."
                    handleFormSubmit={() => {}}
                  />
                )}
              />
            </form>
            <div className="code-body">
              <table>
                <thead>
                  <tr>
                    <th className="code-head-child1">마스터 코드 ID</th>
                    <th className="code-head-child2">코드명</th>
                    <th className="code-head-child1">관리</th>
                  </tr>
                </thead>
                <tbody>
                  {listData?.length > 0
                    ? listData.map((item: any, i: number) => {
                        return (
                          <tr key={i}>
                            <td className="code-head-child1">{item.value}</td>
                            <td className="code-head-child2">{item.label}</td>
                            <td
                              className="code-head-child1 code-detail-btn"
                              onClick={() =>
                                navigate(`/setting/code/edit/${i}`, {
                                  state: { masterCode: item },
                                })
                              }
                            >
                              <button>상세보기</button>
                            </td>
                          </tr>
                        );
                      })
                    : MASTER_CODE_OPTIONS.map((item: any, i: number) => {
                        return (
                          <tr key={i}>
                            <td className="code-head-child1">{item.value}</td>
                            <td className="code-head-child2">{item.label}</td>
                            <td
                              className="code-head-child1 code-detail-btn"
                              onClick={() =>
                                navigate(`/setting/code/edit/${i}`, {
                                  state: { masterCode: item.value },
                                })
                              }
                            >
                              <button>상세보기</button>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </CodeSettingStyled>
    </Layout>
  );
};

export default CodeSetting;

const CodeSettingStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 40px;

  & > div {
    display: flex;
    padding: 35px;
  }

  .code-wrap {
    width: 100%;
  }

  .code-body {
    margin-top: 30px;
    table {
      width: 100%;
    }
    thead {
      height: 68px;
      background-color: #f9f9f9;

      th {
        font-size: 18px;
        font-weight: bold;
        vertical-align: middle;
        color: #101010;
      }
    }
    tbody {
      tr {
        height: 66px;
        border-bottom: 1px solid #ebebeb;
      }

      td {
        font-size: 15px;
        font-weight: normal;
        vertical-align: middle;
        color: #444;

        &:nth-of-type(2) {
          padding-left: 50px;
        }
      }
    }

    .code-head-child1 {
      width: 15%;
      text-align: center;
    }

    .code-head-child2 {
      width: 70%;
    }

    .code-detail-btn {
      button {
        width: 72px;
        height: 30px;
        border-radius: 3px;
        border: solid 1px #c09260;
        color: #c09260;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
`;
