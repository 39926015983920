import React, { useState, useEffect, useRef } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import request from "api/request";

import Layout from "layout/layout";

import usePageSlice from "hooks/usePageSlice";
import useSubmitAlert from "hooks/useSubmitAlert";

import Filter from "components/Filter/Filter";
import Select from "components/Select/Select";
import Search from "components/Search/Search";
import ProfileImage from "components/Profile/Profile";
import Pagination from "components/Pagination/Pagination";

import {
  PAGE_LAYOUT,
  EmployeeSelectOptions,
  EmployeeFilterOptions,
} from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";
import { useTheme } from "utils/context/themeProvider";

import NoDataSVG from "assets/images/no_data_ico.svg";
import IcDropBtnSVG from "assets/images/icon_drop.svg";
import DownloadSVG from "assets/images/download_ic.svg";
import { TestData } from "utils/testdata";
interface EmployeeProps {
  filter: any[];
  select: {
    value: string;
    label: string;
  };
  search: string;
}

const defaultValues: EmployeeProps = {
  filter: [
    EmployeeFilterOptions.map((e: any) => {
      if (e.checked) return e.value;
    }),
  ],
  select: {
    value: "ALL",
    label: "전체",
  },
  search: "",
};

const Employee = () => {
  const { noti } = useSubmitAlert();
  const navigate = useNavigate();
  const [ThemeMode] = useTheme();
  const { REACT_APP_API } = process.env;
  const scrollContentRef = useRef<HTMLDivElement | null>(null);
  const [sWidth, setSWidth] = useState<number>(0);
  const [isReload, setIsReload] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isSorting, setIsSorting] = useState(false);
  const fixed = FixedWrap();
  const scroll = ScrollWrap(sWidth);
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (scrollContentRef.current)
      setSWidth(scrollContentRef?.current?.scrollWidth);
  }, [values.filter, scrollContentRef, TestData]);

  useEffect(() => {
    setTotalCount(TestData.length);
    setTotalPages(Math.ceil(TestData.length / 10));
  }, [TestData]);

  // 임직원 현황 가져오기
  //useEffect(() => {
  //  let keywords: string | undefined = "";

  //  let payload: any = {
  //    type: "EMPLOYEE",
  //    search_filter: values?.select.value,
  //  };

  //  if (values?.select.value === "ID" && values?.search?.split("")[0] === "E") {
  //    const regex = /(E|0)/i;
  //    keywords = values?.search?.split(regex).pop();
  //  }

  //  if (keywords !== undefined && keywords !== "") {
  //    payload = {
  //      ...payload,
  //      keyword: keywords,
  //    };
  //  } else {
  //    payload = {
  //      ...payload,
  //      keyword: values?.search ? values?.search : null,
  //    };
  //  }

  //  request("get", "/users", {}, payload)
  //    .then((res: any) => {
  //      if (res.status === 200) {
  //        setTableData(res?.data.result.data);

  //      }
  //    })
  //    .catch((err) => {
  //      console.log(err);
  //    });
  //}, [isReload, values?.search, values?.select.value]);

  // 등록현황 수정
  //const handleStatuseEdit = async (employeeId: number) => {
  //  let payload: any = {
  //    registration_status: "ACCEPT",
  //  };

  //  await request("put", `/users/edit/employee/${employeeId}`, {}, payload)
  //    .then((res) => {
  //      if (res?.status === 200) {
  //        setIsReload(true);
  //      }
  //    })
  //    .catch((err) => {
  //      throw err;
  //    });
  //};

  // 임직원 등록 거절
  //const handleDelete = async (employeeId: number) => {
  //  await request("delete", `/users/${employeeId}`, {}, {})
  //    .then(() => {
  //      navigate("/curr_status/employee", { replace: true });
  //    })
  //    .catch((err) => {
  //      console.log(err);
  //    });
  //};

  //const handleSort = () => {
  //  setIsSorting((prev) => !prev);
  //  setTableData(tableData.reverse());
  //};

  const pageArr = usePageSlice(TestData);

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {},
      (err) => {}
    )();
  };

  return (
    <Layout page={PAGE_LAYOUT.employee}>
      <EmployeeStyled>
        <div className="status-wrap">
          <div className="status-header">
            <div className="search-sec">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleFormSubmit();
                }}
              >
                <div className="filter">
                  <Controller
                    control={control}
                    name="filter"
                    render={({ field }) => (
                      <Filter
                        {...field}
                        option={EmployeeFilterOptions}
                        handleFormSubmit={handleFormSubmit}
                      />
                    )}
                  />
                </div>
                <div className="search">
                  <div className="search-select">
                    <Controller
                      control={control}
                      name="select"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="전체"
                          w="130"
                          h="52"
                          fontS="15"
                          variant="tertiary"
                          color={ThemeMode === "light" ? "#000" : "#fff"}
                          bgColor={ThemeMode === "light" ? "#fff" : "#211f1e"}
                          options={EmployeeSelectOptions}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name="search"
                      render={({ field }) => (
                        <Search
                          {...field}
                          color={ThemeMode === "light" ? "#000" : "#c9c9c9"}
                          bgcolor={ThemeMode === "light" ? "#fff" : "#211f1e"}
                          placeholder="검색어를 입력하세요."
                          handleFormSubmit={handleFormSubmit}
                        />
                      )}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="status-btn">
              <div className="xlsx-download">엑셀로 내보내기</div>
              <div
                className="status-regist-btn"
                onClick={() => navigate("/curr_status/employee/regist")}
              >
                등록
              </div>
            </div>
          </div>
          <div>
            {TestData.length > 0 ? (
              <div className="status-table">
                <div css={fixed}>
                  <div className="fixed-table">
                    <StatusName>
                      성명{" "}
                      <img
                        src={IcDropBtnSVG}
                        alt="드랍 버튼 이미지"
                        style={{
                          transform: isSorting
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </StatusName>
                    <StatusBOD>
                      생년월일{" "}
                      <img
                        src={IcDropBtnSVG}
                        alt="드랍 버튼 이미지"
                        style={{
                          transform: isSorting
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </StatusBOD>
                    <StatusGender>
                      성별{" "}
                      <img
                        src={IcDropBtnSVG}
                        alt="드랍 버튼 이미지"
                        style={{
                          transform: isSorting
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </StatusGender>
                    <StatusNumber>
                      사원번호{" "}
                      <img
                        src={IcDropBtnSVG}
                        alt="드랍 버튼 이미지"
                        style={{
                          transform: isSorting
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </StatusNumber>
                  </div>
                  <div className="fixed-data">
                    {pageArr[page - 1]?.map((item: any, i: number) => {
                      const idToSting = item?.user_employee_info?.id.toString();
                      return (
                        <div
                          key={i}
                          onClick={() =>
                            navigate(
                              `/curr_status/employee/detail/${item.id}`,
                              {
                                state: { employee: item },
                              }
                            )
                          }
                        >
                          <div className="fixed-data-profile">
                            <ProfileImage
                              width="28px"
                              height="28px"
                              fileId={
                                item?.user_employee_info?.profile_image_info
                                  ? item?.user_employee_info?.profile_image_info
                                      ?.id
                                  : null
                              }
                              name={item?.user_employee_info?.user_name}
                              isPopup={true}
                            />
                            <span>{item?.user_employee_info?.user_name}</span>
                          </div>
                          <div>{item?.user_employee_info?.birth}</div>
                          <div>
                            {item?.user_employee_info?.gender === "Male"
                              ? "남성"
                              : "여성"}
                          </div>
                          <div>
                            {idToSting.length === 1
                              ? "E00000" + item?.user_employee_info?.id
                              : "E0000" + item?.user_employee_info?.id}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="fixed-line"></div>
                <div css={scroll}>
                  <div className="scroll-table">
                    <div>
                      {values.filter[0]?.map((item: any, i: number) => {
                        const clientWidth =
                          scrollContentRef?.current?.children[0]?.children[
                            i - 1
                          ]?.clientWidth;

                        const result = EmployeeFilterOptions.filter(
                          (e: any) => e.value === item
                        );

                        return (
                          item !== "All" && (
                            <div
                              key={i}
                              css={{
                                textAlign: "center",
                                width: `${clientWidth}px`,
                                minWidth: "120px",
                              }}
                            >
                              {result[0]?.label}{" "}
                              <img
                                src={IcDropBtnSVG}
                                alt="드랍 버튼 이미지"
                                style={{
                                  transform: isSorting
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                }}
                              />
                            </div>
                          )
                        );
                      })}
                      <div
                        css={{
                          minWidth: "120px",
                        }}
                      >
                        급여명세서{" "}
                        <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                      </div>
                      <div
                        css={{
                          minWidth: "120px",
                        }}
                      >
                        등록현황{" "}
                        <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                      </div>
                    </div>
                  </div>
                  <div className="scroll-data" ref={scrollContentRef}>
                    {pageArr[page - 1]?.map((item: any, i: number) => {
                      const data = item?.user_employee_info;
                      return (
                        <div
                          key={i}
                          onClick={() =>
                            navigate(
                              `/curr_status/employee/detail/${item.id}`,
                              {
                                state: { employee: item },
                              }
                            )
                          }
                        >
                          {values.filter[0].includes("workplace") && (
                            <div>
                              {data?.working_place ? data?.working_place : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("department") && (
                            <div>{data?.department}</div>
                          )}
                          {values.filter[0].includes("position") && (
                            <div>{data?.position}</div>
                          )}
                          {values.filter[0].includes("rank") && (
                            <div>{data?.rank}</div>
                          )}
                          {values.filter[0].includes("typeOfWork") && (
                            <div>
                              {data?.user_role === "FULLTIME_WORKER" &&
                                "정규직"}
                              {data?.user_role === "CONTRACT_WORKER" &&
                                "계약직"}
                              {data?.user_role === "ADMIN" && "관리자"}
                            </div>
                          )}
                          {values.filter[0].includes("term") && (
                            <div>{data?.term}년</div>
                          )}
                          {values.filter[0].includes("joining") && (
                            <div>
                              {dayjs(data?.join_date).format("YYYY.MM.DD")}
                            </div>
                          )}
                          {values.filter[0].includes("leave") && (
                            <div>
                              {data?.leave_date
                                ? dayjs(data?.leave_date).format("YYYY.MM.DD")
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("workingDay") && (
                            <div>{data?.leave_date ? "개월" : "-"}</div>
                          )}
                          {values.filter[0].includes("contract") && (
                            <div>{data?.contract_salary}만원</div>
                          )}
                          {values.filter[0].includes("current") && (
                            <div>{data?.current_salary}만원</div>
                          )}
                          {values.filter[0].includes("lastYear") && (
                            <div>
                              {data?.last_years_salary
                                ? data?.last_years_salary + "만원"
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("basicAnnual") && (
                            <div>{data?.offer_holiday}일</div>
                          )}
                          {values.filter[0].includes("useAnnual") && (
                            <div>
                              {data?.used_holiday
                                ? data?.used_holiday + "일"
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("remainAnnual") && (
                            <div>
                              {data?.left_holiday
                                ? data?.left_holiday + "일"
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("paidVacation") && (
                            <div>
                              {data?.used_paid_holiday
                                ? data?.used_paid_holiday + "일"
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("unpaidVacation") && (
                            <div>
                              {data?.used_unpaid_holiday
                                ? data?.used_unpaid_holiday + "일"
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("bank") && (
                            <div>{data?.bank}</div>
                          )}
                          {values.filter[0].includes("bankAccount") && (
                            <div className="account-sec">
                              {data?.bank_account}
                            </div>
                          )}
                          {values.filter[0].includes("address") && (
                            <div className="address-sec">
                              ({data?.zip_code}) {data?.address}
                            </div>
                          )}
                          {values.filter[0].includes("email") && (
                            <div className="email-sec">{data?.signname}</div>
                          )}
                          {values.filter[0].includes("phoneNum") && (
                            <div>
                              {data?.user_phone.substring(0, 3) +
                                "-" +
                                data?.user_phone.substring(3, 7) +
                                "-" +
                                data?.user_phone.substring(7, 11)}
                            </div>
                          )}
                          {values.filter[0].includes("note") && (
                            <div className="note-ellipsis">{data?.note}</div>
                          )}
                          <div className="payslip-img">
                            <a
                              href={""}
                              download
                              onClick={(e) => e.stopPropagation()}
                            >
                              <img
                                src={DownloadSVG}
                                alt="급여명세서 다운로드 이미지"
                              />
                            </a>
                          </div>
                          {data?.registration_status === "ING" && (
                            <div className="registration-status">
                              <button
                                className="scroll-data-btn approve"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const isAccept = noti(
                                    "수락하시겠습니까?",
                                    "예",
                                    "아니오"
                                  );
                                  const getData = () => {
                                    isAccept.then((res: boolean) => {
                                      return;
                                    });
                                  };
                                  getData();
                                }}
                              >
                                수락
                              </button>
                              <button
                                className="scroll-data-btn reject"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const isReject = noti(
                                    "거부하시겠습니까?",
                                    "예",
                                    "아니오"
                                  );
                                  const getData = () => {
                                    isReject.then((res: boolean) => {
                                      return;
                                    });
                                  };
                                  getData();
                                }}
                              >
                                거부
                              </button>
                            </div>
                          )}
                          {data?.registration_status === "ACCEPT" && (
                            <div className="registration-accept">등록 완료</div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="list-no-data-wrap">
                <div>
                  <div>
                    <div className="no-data-img">
                      <img src={NoDataSVG} alt="검색 결과 없음 이미지" />
                    </div>
                    <div>검색 결과가 없습니다.</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="pagenation">
            <Pagination
              page={page}
              count={totalPages}
              list={TestData.length}
              onChangePage={setPage}
            />
          </div>
        </div>
      </EmployeeStyled>
    </Layout>
  );
};

export default Employee;

const FixedWrap = () => {
  return css`
    width: 35%;

    @media screen and (max-width: 1600px) {
      width: 60%;
    }

    .fixed-table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      font-size: 18px;
      font-weight: bold;

      & div {
        cursor: pointer;
        text-align: center;
        width: 25%;
      }
    }

    .fixed-data {
      & > div {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          text-align: center;
          width: 25%;
        }
      }

      .fixed-data-profile {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin: 0 0 0 8px;
        }
      }
    }
  `;
};

const ScrollWrap = (sWidth: number) => {
  return css`
    width: 1000px;
    position: relative;
    left: -15px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #1532ee;
      border-radius: 10px;
      background-clip: padding-box;
    }

    @media screen and (max-width: 1600px) {
      width: 800px;
    }

    @media screen and (min-width: 1600px) {
      width: 1100px;
    }

    @media screen and (min-width: 2000px) {
      width: 1500px;
    }

    .scroll-table {
      width: ${`${sWidth}px`};

      & > div {
        width: ${`${sWidth}px`};
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 20px 20px;
        font-size: 18px;
        font-weight: bold;

        & div {
          flex: 0 0 auto;
          cursor: pointer;
          margin: 0 30px;
          text-align: center;
        }
      }
    }

    .scroll-data {
      width: ${`${sWidth}px`};

      & > div {
        width: ${`${sWidth}px`};
        padding: 20px 0 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          min-width: 120px;
          flex: 0 0 auto;
          cursor: pointer;
          margin: 0 30px;
          padding: 1px 0;
          text-align: center;
        }

        .payslip-img {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
        }

        .account-sec {
          min-width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .address-sec {
          width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .email-sec {
          min-width: 200px;
        }
        .note-ellipsis {
          width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .submit-done {
        font-weight: bold;
      }

      .registration-status {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
      }

      .registration-accept {
        font-weight: bold;
      }

      .scroll-data-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 30px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        margin: 0 3px;
      }

      .approve {
        border: solid 1px #1532ee;
        color: #1532ee;
      }

      .reject {
        border: solid 1px red;
        color: red;
      }
    }
  `;
};

const EmployeeStyled = styled.div`
  width: 100%;
  margin: 40px;

  .status-btn {
    padding-right: 15px;
  }

  .status-regist-btn {
    width: 78px;
  }
`;

const StatusName = styled.div``;
const StatusBOD = styled.div``;
const StatusGender = styled.div``;
const StatusNumber = styled.div``;
