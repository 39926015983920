import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/layout";

import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Select from "components/Select/Select";

import { PAGE_LAYOUT, APPROVAL_OPTIONS } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import { ApprovalForm } from "types/registType";

import ApprovalIcoSVG from "assets/images/approval_ico.svg";

import SeniorForm from "./components/SeniorForm";
import VacationAppForm from "./components/VacationAppForm";
import LetterAppForm from "./components/LetterAppForm";
import PlanAppForm from "./components/PlanAppForm";

const defaultValues: ApprovalForm = {
  approvalDocument: { value: "init", label: "선택" },
  approvalUser: "",
  approvalSenior01: "",
  approvalSenior02: "",
  approvalSenior03: "",
  approvalName: "",
  approvalPosition: "",
  approvalDepartment: "",
  vacationEmployeeNumber: "",
  vacationCount: "",
  vacationDate: "",
  vacationUsePeriod: {
    value: "init",
    label: "선택",
  },
  vacationReason: "",
  vacationSuccessor: "",
  vacationSituation: {
    value: "init",
    label: "선택",
  },
  vacationNote: "",
  approvalDate: "",
  latterTitle: "",
  latterItem: "",
  latterQuantity: "",
  latterPurpose: "",
  latterReason: "",
  latterExpense: "",
  latterEffect: "",
  latterAttachment: [],
  planTitle: "",
  planProposal: [],
  planField: "",
  planJoinMember: {
    join1: [],
    join2: [],
    join3: [],
    join4: [],
    join5: [],
    join6: [],
  },
  planAttachment: [],
};

const ApprovalRegist = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((s: any) => s.auth);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    setValue("approvalUser", userInfo?.user_name);
  }, [userInfo]);

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <Layout page={PAGE_LAYOUT.approval_detail}>
      <ApprovalRegistStyled>
        <div className="status-wrap">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                (res) => {
                  console.log(res);
                },
                (err) => {
                  console.log(err);
                }
              );
            }}
          >
            <div className="approval-document">
              <div className="form-text">문서종류</div>
              <Controller
                control={control}
                name="approvalDocument"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="선택"
                    w="300"
                    h="65"
                    options={APPROVAL_OPTIONS}
                  />
                )}
              />
              <div className="form-text form-left">작성자</div>
              <Controller
                control={control}
                name="approvalUser"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    w={"156"}
                    multiline={false}
                    fullWidth={false}
                    errors={errors}
                    disabled
                  />
                )}
              />
            </div>
            <div>
              {values.approvalDocument.value === "init" ? (
                <div className="select-approval-app">
                  <div className="select-approval-inner">
                    <div className="select-approval-icon">
                      <img src={ApprovalIcoSVG} alt="" />
                    </div>
                    <div>문서 종류 선택 시 양식이 노출됩니다.</div>
                  </div>
                  <Button
                    type="button"
                    variant="tertiary"
                    preset="1"
                    onClick={() => navigate(-1)}
                  >
                    목록
                  </Button>
                </div>
              ) : (
                <div className="select-approval-body">
                  {values?.approvalDocument?.value === "VACATION_APP" && (
                    <div>
                      <h3>연차 · 반차 신청</h3>
                      <SeniorForm
                        Controller={Controller}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                      />
                      <div className="select-approval-body-form">
                        <VacationAppForm
                          Controller={Controller}
                          setValue={setValue}
                          control={control}
                          errors={errors}
                          handleFormSubmit={handleFormSubmit}
                        />
                      </div>
                    </div>
                  )}
                  {values?.approvalDocument?.value === "LETTER_APP" && (
                    <div>
                      <h3>품의서</h3>
                      <SeniorForm
                        Controller={Controller}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                      />
                      <div>
                        <LetterAppForm
                          Controller={Controller}
                          setValue={setValue}
                          control={control}
                          errors={errors}
                          handleFormSubmit={handleFormSubmit}
                        />
                      </div>
                    </div>
                  )}
                  {values?.approvalDocument?.value === "PLAN_APP" && (
                    <div>
                      <h3>기획안</h3>
                      <SeniorForm
                        Controller={Controller}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                      />
                      <div>
                        <PlanAppForm
                          Controller={Controller}
                          setValue={setValue}
                          control={control}
                          errors={errors}
                          handleFormSubmit={handleFormSubmit}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </form>
        </div>
      </ApprovalRegistStyled>
    </Layout>
  );
};

export default ApprovalRegist;

const ApprovalRegistStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 40px;

  .approval-document {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-text {
    font-size: 18px;
    font-weight: bold;
    color: #222;
    margin-right: 24px;
  }

  .form-left {
    margin-left: 60px;
  }

  .select-approval-app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .select-approval-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 250px;
    margin-bottom: 300px;
    font-size: 18px;
  }

  .select-approval-icon {
    display: flex;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba(168, 154, 139, 0.6);
    margin-bottom: 25px;
  }

  .select-approval-body {
    display: flex;
    justify-content: center;

    h3 {
      font-family: Arita-buri-SemiBold;
      font-size: 45px;
      font-weight: 600;
      text-align: center;
      margin: 80px 0 60px 0;
    }
  }

  .select-approval-body-form {
    width: 680px;
    border-top: 1px solid #222;
    padding-top: 30px;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .search-member-sec {
    display: flex;
  }

  .search-member-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
    cursor: pointer;
  }

  .submit-btn {
    width: 408px;
    margin: 0 auto;
    padding-top: 10px;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
