/** @jsxImportSource @emotion/react */
import { forwardRef, useCallback, useRef, useEffect } from "react";
import { css } from "@emotion/react";

// utils
import { ERROR_COLOR } from "utils/constants";

// assets
import BtnXSVG from "assets/images/btn_x.svg";

interface FileInputProps {
  w?: string;
  value?: any;
  accept: string;
  errors: any;
  filename?: string;
  placeholder?: string;
  name?: string;
  fullWidth?: boolean;
  onChange: (e: any) => void;
}

const FileInput = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
  const { placeholder, accept, errors, fullWidth, ...rest } = props;

  const root = rootCss(props);
  const label = labelCss();

  const fileRef = useRef<HTMLInputElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (rest.value.length === 0) {
      if (fileInput.current) fileInput.current.value = "";
      if (fileRef.current) fileRef.current.value = "";
    }
    if (rest.value.length > 0) {
      if (fileRef.current) fileRef.current.value = rest.value[0]?.name;
    }
  }, [rest.value]);

  const handleChange = useCallback((e: any) => {
    if (fileRef.current) fileRef.current.value = e.target.files[0].name;
    rest.onChange(e.target.files);
  }, []);

  const handleRemoveFile = useCallback(() => {
    if (fileInput.current) fileInput.current.value = "";
    if (fileRef.current) fileRef.current.value = "";
  }, []);

  return (
    <div css={root}>
      <div className="file-inner">
        <input
          type="file"
          id="files"
          ref={fileInput}
          accept={accept}
          onChange={(e) => handleChange(e)}
        />
        <label css={label}>
          <input
            type="text"
            id="file-submit"
            ref={fileRef}
            placeholder={placeholder}
            disabled
          />
        </label>
        <button
          type="button"
          className="file-delete"
          onClick={() => handleRemoveFile()}
        >
          삭제
          <img src={BtnXSVG} alt="삭제 버튼 아이콘" />
        </button>
      </div>
      <button type="button" className="add-file">
        <label htmlFor="files">파일 첨부</label>
      </button>
    </div>
  );
});

const rootCss = (props: FileInputProps) => {
  return css`
    display: flex;
    width: ${props.fullWidth ? "100%" : "100%"};

    & .file-inner {
      width: 100%;
    }

    & #files {
      display: none;
    }

    & .file-delete {
      display: flex;
      align-items: center;
      position: absolute;
      margin-top: -42px;
      margin-left: 510px;
      background: none;
      border: 0;
      font-size: 14px;
      color: #707070;
      font-weight: normal;

      & img {
        margin-left: 4px;
      }
    }
    & .add-file {
      width: 120px;
      height: 65px;
      border: 1px solid #444;
      background-color: #444;
      color: #fff;
      font-size: 18px;
      margin-top: 0;
      margin-left: 8px;

      & label {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  `;
};

const labelCss = () => {
  return css`
    & #file-submit {
      display: flex;
      align-items: center;
      width: 100%;
      height: 65px;
      background: transparent;
      font-size: 16px;
      color: #000;
      padding-left: 20px;
      border: solid 1px #ddd;
      background: #f9f9f9;

      &::placeholder {
        color: #b2b2b2;
      }
    }
  `;
};

export default FileInput;
