import request from "api/request";
import { SettingTypes, UpdateSettingTypes } from "types/settingTypes";

// 환경설정 조회
const getSetting = async ({ type }: SettingTypes) => {
  try {
    const data = await request("get", "/settings", {}, type);

    return data;
  } catch (err) {
    throw err;
  }
};

// 환경설정 및 사용여부 서명양식 변경
const updateSetting = async ({ payload }: UpdateSettingTypes) => {
  try {
    const data = await request("put", "/settings/update", {}, payload);

    return data;
  } catch (err) {
    throw err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getSetting, updateSetting };
