import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Search from "components/Search/Search";

import IcDropBtnSVG from "assets/images/icon_drop.svg";

import { MASTER_CODE_OPTIONS } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import { TestMypageData } from "utils/testdata";

const defaultValues = {
  search: "",
};

const MypageHistoryForm = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <MypageHistoryFormStyled>
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleFormSubmit();
          }}
        >
          <Controller
            control={control}
            name="search"
            render={({ field }) => (
              <Search
                {...field}
                placeholder="검색어를 입력하세요."
                handleFormSubmit={handleFormSubmit}
              />
            )}
          />
        </form>
        <div className="code-body">
          <table>
            <thead>
              <tr>
                <th className="code-head-child1">
                  상태 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </th>
                <th className="code-head-child1">
                  행사명 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </th>
                <th className="code-head-child1">
                  장소 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </th>
                <th className="code-head-child1">
                  행사 날짜 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </th>
                <th className="code-head-child1">
                  행사 시간 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </th>
                <th className="code-head-child1">
                  지원일 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </th>
              </tr>
            </thead>
            <tbody>
              {TestMypageData.map((item: any, i: number) => {
                return (
                  <tr
                    key={i}
                    onClick={() => navigate(`/mypage/event/detail/${i}`)}
                  >
                    <td className="code-head-child1">
                      {item.status === "DONE" && (
                        <Done>
                          <span>신청 완료</span>
                        </Done>
                      )}
                      {item.status === "REJECT" && (
                        <Reject>
                          <span>거부</span>
                        </Reject>
                      )}
                      {item.status === "APP_CANCEL" && (
                        <AppCancel>
                          <span>지원 취소</span>
                        </AppCancel>
                      )}
                      {item.status === "EVENT_CANCEL" && (
                        <EventCancel>
                          <span>행사 취소</span>
                        </EventCancel>
                      )}
                      {item.status === "EVENT_DONE" && (
                        <EventDone>
                          <span>행사 완료</span>
                        </EventDone>
                      )}
                    </td>
                    <td className="code-head-child1">{item.title}</td>
                    <td className="code-head-child1">{item.place}</td>
                    <td className="code-head-child1">{item.date}</td>
                    <td className="code-head-child1">{item.time}</td>
                    <td className="code-head-child1">{item.application}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </MypageHistoryFormStyled>
  );
};

export default MypageHistoryForm;

const MypageHistoryFormStyled = styled.div`
  width: 100%;

  .code-wrap {
    width: 100%;
  }

  .code-body {
    margin-top: 30px;
    table {
      width: 100%;
    }
    thead {
      height: 68px;
      background-color: #f9f9f9;

      th {
        font-size: 18px;
        font-weight: bold;
        vertical-align: middle;
        color: #101010;
      }
    }
    tbody {
      tr {
        height: 66px;
        border-bottom: 1px solid #ebebeb;
        cursor: pointer;
      }

      td {
        font-size: 15px;
        font-weight: normal;
        vertical-align: middle;
        color: #444;
      }
    }

    .code-head-child1 {
      width: 15%;
      text-align: center;
    }

    .code-detail-btn {
      button {
        width: 72px;
        height: 30px;
        border-radius: 3px;
        border: solid 1px #c09260;
        color: #c09260;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
`;

const Done = styled.div`
  span {
    font-size: 15px;
    font-weight: bold;
    color: #c09260;
  }
`;
const Reject = styled.div`
  span {
    font-size: 15px;
    font-weight: bold;
    color: #ff1e1e;
  }
`;
const AppCancel = styled.div`
  span {
    font-size: 15px;
    font-weight: bold;
    color: #b2b2b2;
  }
`;
const EventCancel = styled.div`
  span {
    font-size: 15px;
    font-weight: bold;
    color: #444;
  }
`;
const EventDone = styled.div`
  span {
    font-size: 15px;
    font-weight: bold;
    color: #3c7ee1;
  }
`;
