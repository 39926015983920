import React, { forwardRef } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import IconSearchSVG from "assets/images/icon_search.svg";

interface SearchProps {
  w?: string;
  fullWidth?: boolean;
  placeholder: string;
  color?: string;
  bgcolor?: string;
  handleFormSubmit: () => void;
}

const rootCss = (props: SearchProps) => {
  const width = props.w ? props.w : "400px";

  return css`
    input {
      width: ${props.fullWidth ? "100%" : width};
      height: 52px;
      border: 1px solid #ddd;
      padding-left: 20px;
      font-size: 15px;
      background-color: ${props.bgcolor ? props.bgcolor : "#fff"};
    }
    input::placeholder {
      color: ${props.color ? props.color : "#c9c9c9"};
    }
    input::-ms-clear,
    input::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    input::-webkit-search-decoration,
    input::-webkit-search-cancel-button,
    input::-webkit-search-results-button,
    input::-webkit-search-results-decoration {
      display: none;
    }
    img {
      position: absolute;
      margin-top: 15px;
      margin-left: -40px;
    }
  `;
};

const Search = forwardRef<HTMLInputElement, SearchProps>((props, ref) => {
  const { placeholder, fullWidth, handleFormSubmit, ...rest } = props;
  const root = rootCss(props);

  const handleEnterEvent = (e: any) => {
    if (e.nativeEvent.isComposing) return;
    if (e.key === "Enter") handleFormSubmit();
  };

  return (
    <div css={root}>
      <input
        {...rest}
        type="search"
        name="search"
        id="search"
        placeholder={placeholder}
        onKeyDown={handleEnterEvent}
      />
      <img src={IconSearchSVG} alt="돋보기 아이콘" />
    </div>
  );
});

export default Search;
