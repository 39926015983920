import React from "react";
import styled from "@emotion/styled";
import Button from "components/Button/Button";
import Textbox from "components/TextBox/TextBox";
import BtnDelSVG from "assets/images/btn_del.svg";

interface CommissionPopupProps {
  setIsCommission: any;
}

const CommissionPopup = ({ setIsCommission }: CommissionPopupProps) => {
  return (
    <CommissionPopupStyled>
      <div className="commission-wrap">
        <div
          className="close-img"
          onClick={() => {
            setIsCommission(false);
          }}
        >
          <img src={BtnDelSVG} alt="닫기 이미지" />
        </div>
        <div>
          <h4>매출 수수료 변경</h4>
          <div>
            <div className="commission-form">
              <div className="commission-text">현재 수수료</div>
              <Textbox
                w="350px"
                name="current"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="2"
                disabled
                onChange={() => {}}
              >
                %
              </Textbox>
            </div>
            <div className="commission-form">
              <div className="commission-text">변경 수수료</div>
              <Textbox
                w="350px"
                name="current"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="2"
                onChange={() => {}}
              >
                %
              </Textbox>
            </div>
          </div>
        </div>
        <div className="commission-submit-btn">
          <Button
            w="151"
            h="57"
            type="button"
            variant="tertiary"
            preset="1"
            onClick={() => {
              setIsCommission(false);
            }}
          >
            취소
          </Button>
          <span className="void-sec"></span>
          <Button
            w="151"
            h="57"
            type="button"
            variant="primary"
            preset="2"
            onClick={() => {
              setIsCommission(false);
            }}
          >
            변경
          </Button>
        </div>
      </div>
    </CommissionPopupStyled>
  );
};

export default CommissionPopup;

const CommissionPopupStyled = styled.div`
  position: fixed;
  z-index: 999999999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  .commission-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 540px;
    border-radius: 20px;
    padding: 28px;
  }

  .close-img {
    text-align: right;
    cursor: pointer;
  }

  h4 {
    font-size: 26px;
    font-weight: bold;
    color: #c09260;
    margin: 0 0 24px 0;
  }

  .commission-form {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .commission-text {
    width: 130px;
    font-size: 14px;
    font-weight: normal;
  }

  .commission-submit-btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .void-sec {
      margin: 0 4px;
    }
  }
`;
