import { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { TapMenuProps } from "types/commonTypes";

const MailTapMenu = ({ textChange }: TapMenuProps) => {
  const btnRef = useRef([] as any);
  const navigate = useNavigate();

  useEffect(() => {
    if (textChange === "RECEIVED") {
      btnRef.current[0].className = "action";
      btnRef.current[1].className = "void";
      btnRef.current[2].className = "void";
      btnRef.current[3].className = "void";
    } else if (textChange === "SENT") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "action";
      btnRef.current[2].className = "void";
      btnRef.current[3].className = "void";
    } else if (textChange === "TRASH") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "void";
      btnRef.current[2].className = "action";
      btnRef.current[3].className = "void";
    } else if (textChange === "WRITE") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "void";
      btnRef.current[2].className = "void";
      btnRef.current[3].className = "action";
    }
  }, [textChange]);
  return (
    <MailTapMenuStyled>
      <div
        ref={(el) => (btnRef.current[0] = el)}
        onClick={() => {
          navigate("/mail", {
            state: { status: "RECEIVED" },
          });
        }}
      >
        받은 메일함
      </div>
      <span>&#183;</span>
      <div
        ref={(el) => (btnRef.current[1] = el)}
        onClick={() => {
          navigate("/mail", {
            state: { status: "SENT" },
          });
        }}
      >
        보낸 메일함
      </div>
      <span>&#183;</span>
      <div
        ref={(el) => (btnRef.current[2] = el)}
        onClick={() => {
          navigate("/mail", {
            state: { status: "TRASH" },
          });
        }}
      >
        휴지통
      </div>
      <span>&#183;</span>
      <div
        ref={(el) => (btnRef.current[3] = el)}
        onClick={() => {
          navigate("/mail", {
            state: { status: "WRITE" },
          });
        }}
      >
        메일 작성
      </div>
    </MailTapMenuStyled>
  );
};

export default MailTapMenu;

const MailTapMenuStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 35px;
    margin: 4px 12px 0 12px;
    color: #b2b2b2;
  }

  & > div {
    font-size: 28px;
    font-weight: bold;
    color: #707070;
    cursor: pointer;
  }

  .action {
    color: #c09260;
  }
`;
