import React, { useState, useEffect, useRef, useCallback } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/layout";

import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Radio from "components/Radio/Radio";
import Filter from "components/Filter/Filter";
import Select from "components/Select/Select";
import Search from "components/Search/Search";
import FileInput from "components/FileInput/FileInput";
import Textbox from "components/TextBox/TextBox";
import Postcode from "components/Postcode/Postcode";

import {
  PAGE_LAYOUT,
  PREFIX_NUMBER_OPTIONS,
  CARD_COMPANY_OPTIONS,
  DEPARTMENT_OPTIONS,
  PART1_OPTIONS,
  PART2_OPTIONS,
  PART3_OPTIONS,
  PART4_OPTIONS,
  PART5_OPTIONS,
  PartTimerLankSelectOptions,
} from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import { PartnerForm } from "types/registType";

const defaultValues: PartnerForm = {
  partnerCode: "",
  partnerCompanyName: "",
  partnerBusinessNum: "",
  partnerCooperation: "",
  zipCode: "",
  address: "",
  detailAddress: "",
  partnerFax1: "",
  partnerFax2: "",
  partnerFax3: "",
  partnerOnwerName: "",
  partnerOnwerNum1: "",
  partnerOnwerNum2: "",
  partnerOnwerNum3: "",
  partnerBank1: "",
  partnerBank2: "",
  partnerManagerName: "",
  partnerPosition: "",
  partnerManagerNum1: "",
  partnerManagerNum2: "",
  partnerManagerNum3: "",
  partnerEmail: "",
};

const PartnerEdit = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [enrollCompany, setEnrollCompany] = useState({
    address: "",
    zipcode: "",
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  return (
    <Layout page={PAGE_LAYOUT.partner}>
      <PartnerEditStyled>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              handleSubmit(
                () => {},
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div>
              <h3>&#183; 기업 정보 &#183;</h3>
              <Row label="협력사번호">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="partnerCode"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="기업명">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="partnerCompanyName"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="기업명을 입력해주세요."
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="사업자등록번호">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="partnerBusinessNum"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="협력분야">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="partnerCooperation"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="전체"
                        variant="primary"
                        fullWidth
                        options={PartTimerLankSelectOptions}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="주소">
                <div className="input-sec">
                  <div className="zipcode">
                    <Controller
                      control={control}
                      name="zipCode"
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          placeholder="우편번호"
                          value={enrollCompany.zipcode || ""}
                          fullWidth
                          errors={errors}
                        />
                      )}
                    />
                    <button
                      type="button"
                      className="address-search"
                      onClick={() => {
                        setPopup(true);
                      }}
                    >
                      주소찾기
                    </button>
                    {popup && (
                      <Postcode
                        setPopup={setPopup}
                        company={enrollCompany}
                        setcompany={setEnrollCompany}
                      />
                    )}
                  </div>
                  <div className="void-sec"></div>
                  <Controller
                    control={control}
                    name="address"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="주소"
                        fullWidth
                        value={enrollCompany.address || ""}
                        errors={errors}
                      />
                    )}
                  />
                  <div className="void-sec"></div>
                  <Controller
                    control={control}
                    name="detailAddress"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="상세주소를 입력하세요."
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="팩스번호">
                <div className="input-sec phonenumber">
                  <Controller
                    control={control}
                    name="partnerFax1"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="tertiary"
                        options={PREFIX_NUMBER_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                  <span>-</span>
                  <Controller
                    control={control}
                    name="partnerFax2"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                        errors={errors}
                      />
                    )}
                  />
                  <span>-</span>
                  <Controller
                    control={control}
                    name="partnerFax3"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
            </div>
            <div>
              <h3>&#183; 대표자 정보 &#183;</h3>
              <Row label="대표자 성명">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="partnerOnwerName"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="대표자 성명을 입력해주세요."
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="대표자 연락처">
                <div className="input-sec phonenumber">
                  <Controller
                    control={control}
                    name="partnerOnwerNum1"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="tertiary"
                        options={PREFIX_NUMBER_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                  <span>-</span>
                  <Controller
                    control={control}
                    name="partnerOnwerNum2"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                        errors={errors}
                      />
                    )}
                  />
                  <span>-</span>
                  <Controller
                    control={control}
                    name="partnerOnwerNum3"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="계좌번호">
                <div className="input-sec bank-select">
                  <Controller
                    control={control}
                    name="partnerBank1"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="은행 선택"
                        variant="secondary"
                        options={CARD_COMPANY_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                  <span className="void-sec"></span>
                  <Controller
                    control={control}
                    name="partnerBank2"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="'-' 없이 숫자만 입력하세요."
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
            </div>
            <div>
              <h3>&#183; 담당자 정보 &#183;</h3>
              <Row label="담당자 성명">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="partnerManagerName"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="담당자 성명을 입력해주세요."
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="직급">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="partnerPosition"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="직급을 입력해주세요."
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="담당자 연락처">
                <div className="input-sec phonenumber">
                  <Controller
                    control={control}
                    name="partnerManagerNum1"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="tertiary"
                        options={PREFIX_NUMBER_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                  <span>-</span>
                  <Controller
                    control={control}
                    name="partnerManagerNum2"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                        errors={errors}
                      />
                    )}
                  />
                  <span>-</span>
                  <Controller
                    control={control}
                    name="partnerManagerNum3"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="이메일">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="partnerEmail"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="이메일을 입력해주세요."
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
            </div>
            <div>
              <h3>&#183; 계정 상태 &#183;</h3>
              <div className="limit-box">
                <div className="limit-box-wrap">
                  <div>
                    <div className="fixed-void-text">계정상태</div>
                    <div>활성화</div>
                  </div>
                  <div className="box-btn inactive-btn">비 활성화</div>
                  {/*<div className="box-btn active-btn">활성화</div>*/}
                </div>
              </div>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                취소
              </Button>
              <span className="void-sec"></span>
              <Button type="submit" variant="primary" preset="2">
                수정
              </Button>
            </div>
          </form>
        </div>
      </PartnerEditStyled>
    </Layout>
  );
};

export default PartnerEdit;

const PartnerEditStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 35px;
  }

  form {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #222;
  }

  .input-sec {
    margin: 16px 0 30px;
  }
  .void-sec {
    height: 10px;
    margin: 0 6px;
  }

  .zipcode {
    display: flex;
  }

  .address-search {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
  }

  .phonenumber {
    display: flex;

    span {
      margin: 27px 10px 0 10px;
    }
  }

  .bank-select {
    display: flex;
  }
  .limit-box {
    .limit-box-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 30px 0;
      margin-top: -10px;
      margin-bottom: 30px;
      border-bottom: 1px solid #ddd;

      & > div {
        display: flex;
      }
    }

    .box-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 84px;
      height: 35px;
      cursor: pointer;
      font-size: 15px;
      font-weight: normal;
      border-radius: 3px;
    }

    .inactive-btn {
      color: #ff4b4b;
      border: solid 1px #ff4b4b;
    }

    .active-btn {
      color: #c09260;
      border: solid 1px #c09260;
    }
  }

  .fixed-void-text {
    width: 150px;
    font-size: 18px;
    font-weight: bold;
    color: #b2b2b2;
  }

  .submit-btn {
    width: 408px;
    margin: 0 auto;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
  }
`;
