import request from "api/request";
import { LoginTypes } from "types/authTypes";

// 로그인
const login = async ({
  userEmail,
  userPassword,
  rememberId,
  isAutoLogin,
}: LoginTypes) => {
  try {
    const data = await request(
      "post",
      "/auth/login",
      {},
      {
        username: userEmail,
        password: userPassword,
      }
    );

    if (data?.status === 200) {
      const targetStorage = isAutoLogin ? localStorage : sessionStorage;

      targetStorage.setItem("accessToken", data.data.access_token);
      targetStorage.setItem("refreshToken", data.data.refresh_token);
    }

    if (rememberId) {
      localStorage.setItem("id", userEmail);
    } else {
      localStorage.removeItem("id");
    }

    return data;
  } catch (err) {
    throw err;
  }
};

// 로그아웃
const logout = async () => {
  const targetStorage = localStorage.getItem("accessToken")
    ? localStorage
    : sessionStorage;
  targetStorage.removeItem("accessToken");
  targetStorage.removeItem("refreshToken");
  targetStorage.removeItem("role");
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { login, logout };
