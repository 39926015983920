/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, forwardRef } from "react";

import ErrorMessage from "components/ErrorMessage/ErrorMessage";

interface TextBoxProps {
  w?: string;
  type: string;
  name: string;
  inputMode: string;
  pattern: string;
  maxLength?: string;
  children: string;
  disabled?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  leftpostion?: string;
  errors?: any;
  onChange?: (e: any) => void;
}

const Textbox = forwardRef<HTMLInputElement, TextBoxProps>((props, ref) => {
  const {
    type,
    inputMode,
    pattern,
    maxLength,
    children,
    fullWidth,
    placeholder,
    errors,
    ...rest
  } = props;

  const root = rootCss(props);
  const input = inputCss(props);

  const [currentLength, setCurrentLength] = useState(0);

  const handleChange = (e: any) => {
    if (e.target.maxLength > 0) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }

    props.onChange && props.onChange(e);
    setCurrentLength(e.target.value.length);
  };

  return (
    <div>
      <label css={root} className="component-birth">
        <input
          {...rest}
          css={input}
          ref={ref}
          onChange={handleChange}
          type={type}
          placeholder={placeholder}
        />
        <span>{children}</span>
      </label>
      {errors?.[props?.name]?.type === "required" && (
        <ErrorMessage errors={errors} name={props.name} />
      )}
    </div>
  );
});

const rootCss = (props: TextBoxProps) => {
  const width = props.w ? props.w : "115px";
  return css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: ${props.leftpostion ? "start" : "end"};
    background-color: #fff;
    width: ${props.fullWidth ? "100%" : width};
    height: 65px;

    & span {
      position: absolute;
      font-size: 16px;
      font-weight: normal;
      margin-right: 15px;

      margin-left: ${props.leftpostion ? "15px" : "0px"};
    }
  `;
};

const inputCss = (props: TextBoxProps) => {
  return css`
    width: 100%;
    height: 65px;
    border: solid 1px #ddd;
    font-size: 16px;
    padding-left: ${props.leftpostion ? "40px" : "20px"};

    &::placeholder {
      font-size: 16px;
      color: #b2b2b2;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  `;
};

export default Textbox;
