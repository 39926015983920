import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Textbox from "components/TextBox/TextBox";
import FileInput from "components/FileInput/FileInput";

import { ApprovalDocumentTypes } from "types/commonTypes";

const LetterAppForm = ({
  Controller,
  setValue,
  control,
  errors,
  handleFormSubmit,
}: ApprovalDocumentTypes) => {
  const navigate = useNavigate();

  return (
    <>
      <LetterAppFormStyled>
        <h5>&#183; 신청자 정보 &#183;</h5>
        <Row label="신청자">
          <div className="input-sec">
            <Controller
              control={control}
              name="approvalName"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                  disabled
                />
              )}
            />
          </div>
        </Row>
        <Row label="직급·직책">
          <div className="input-sec">
            <Controller
              control={control}
              name="approvalPosition"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                  disabled
                />
              )}
            />
          </div>
        </Row>
        <Row label="부서">
          <div className="input-sec">
            <Controller
              control={control}
              name="approvalDepartment"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                  disabled
                />
              )}
            />
          </div>
        </Row>
        <Row label="작성일">
          <div className="input-sec">
            <Controller
              control={control}
              name="approvalDate"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                  disabled
                />
              )}
            />
          </div>
        </Row>
        <h5>&#183; 신청 정보 &#183;</h5>
        <Row label="제목">
          <div className="input-sec">
            <Controller
              control={control}
              name="latterTitle"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                />
              )}
            />
          </div>
        </Row>
        <Row label="품목">
          <div className="input-sec">
            <Controller
              control={control}
              name="latterItem"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                />
              )}
            />
          </div>
        </Row>
        <Row label="수량">
          <div className="input-sec">
            <Controller
              control={control}
              name="latterQuantity"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                />
              )}
            />
          </div>
        </Row>
        <Row label="목적">
          <div className="input-sec">
            <Controller
              control={control}
              name="latterPurpose"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                />
              )}
            />
          </div>
        </Row>
        <Row label="사유">
          <div className="input-sec">
            <Controller
              control={control}
              name="latterReason"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                />
              )}
            />
          </div>
        </Row>
        <Row label="비용">
          <div className="input-sec">
            <Controller
              control={control}
              name="latterExpense"
              render={({ field }: any) => (
                <Textbox
                  {...field}
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  fullWidth
                  errors={errors}
                >
                  원
                </Textbox>
              )}
            />
          </div>
        </Row>
        <Row label="효과">
          <div className="input-sec">
            <Controller
              control={control}
              name="latterEffect"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                />
              )}
            />
          </div>
        </Row>
        <Row label="첨부파일">
          <div className="input-sec">
            <Controller
              control={control}
              name="latterAttachment"
              render={({ field }: any) => (
                <FileInput {...field} accept=".pdf" errors={errors} fullWidth />
              )}
            />
          </div>
        </Row>
        <div className="submit-btn">
          <Button
            type="button"
            variant="tertiary"
            preset="1"
            onClick={() => navigate(-1)}
          >
            목록
          </Button>
          <span className="void-sec"></span>
          <Button
            type="button"
            variant="primary"
            preset="2"
            onClick={() => handleFormSubmit()}
          >
            신청
          </Button>
        </div>
      </LetterAppFormStyled>
    </>
  );
};

export default LetterAppForm;

const LetterAppFormStyled = styled.div`
  width: 680px;
  h5 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #222;
    margin-bottom: 30px;
  }
`;
