import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Row from "components/LabelWithInput/LabelWithInput";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import { SCHEDULE_OPTIONS, PART_TIME_OPTIONS } from "utils/constants";

import IconPlusSVG from "assets/images/icon_plus.svg";
import ScheduleDateForm from "./ScheduleDateForm";
import ScheduleMemberForm from "./ScheduleMemberForm";

interface WorkFormProps {
  workFormFields: any;
  workFormRemove: any;
  handleAddWorkForm: any;
  setDescriptionId: (e: number) => void;
  Controller: any;
  setValue: any;
  control: any;
  errors: any;
}

const WorkForm = ({
  workFormFields,
  workFormRemove,
  handleAddWorkForm,
  setDescriptionId,
  Controller,
  setValue,
  control,
  errors,
}: WorkFormProps) => {
  const MasterCode = useSelector((s: any) => s.code);

  useEffect(() => {
    if (workFormFields.length > 1)
      setValue("scheduleGubun2", SCHEDULE_OPTIONS[0]);
  }, [workFormFields.length]);

  return (
    <>
      {workFormFields.map((work: any, index: number) => {
        return (
          <div key={index}>
            {workFormFields.length > 1 && index > 0 && (
              <div>
                <h3>&#183; 스케줄 생성 &#183;</h3>
                <ScheduleDateForm
                  label="날짜"
                  placeName={`scheduleWorkInput[${index}].scheduleDate`}
                  Controller={Controller}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
                <Row label="근무구분">
                  <div className="input-sec">
                    <Controller
                      control={control}
                      name="scheduleGubun2"
                      render={({ field }: any) => (
                        <Select
                          {...field}
                          placeholder="선택"
                          variant="primary"
                          fullWidth
                          options={SCHEDULE_OPTIONS}
                          errors={errors}
                        />
                      )}
                    />
                  </div>
                </Row>
              </div>
            )}
            <ScheduleMemberForm
              label="성명"
              placeName={`scheduleWorkInput[${index}].scheduleName`}
              placeId={`scheduleWorkInput[${index}].scheduleNameId`}
              Controller={Controller}
              setValue={setValue}
              control={control}
              errors={errors}
            />
            <Row label="지점">
              <div className="input-sec">
                <Controller
                  control={control}
                  name={`scheduleWorkInput[${index}].schedulePoint`}
                  render={({ field }: any) => (
                    <Select
                      {...field}
                      placeholder="선택"
                      variant="primary"
                      fullWidth
                      options={MasterCode.SPOT}
                      errors={errors}
                    />
                  )}
                />
              </div>
            </Row>
            <Row label="부서">
              <div className="input-sec">
                <Controller
                  control={control}
                  name={`scheduleWorkInput[${index}].scheduleDepartment`}
                  render={({ field }: any) => (
                    <Select
                      {...field}
                      placeholder="선택"
                      variant="primary"
                      fullWidth
                      options={MasterCode.DEPT}
                      errors={errors}
                    />
                  )}
                />
              </div>
            </Row>
            <Row label="근무 가능 시간">
              <div className="input-sec time-select">
                <Controller
                  control={control}
                  name={`scheduleWorkInput[${index}].scheduleTime1`}
                  render={({ field }: any) => (
                    <Select
                      {...field}
                      placeholder="선택"
                      variant="primary"
                      fullWidth
                      options={PART_TIME_OPTIONS}
                      errors={errors}
                    />
                  )}
                />
                <span className="void-sec">-</span>
                <Controller
                  control={control}
                  name={`scheduleWorkInput[${index}].scheduleTime2`}
                  render={({ field }: any) => (
                    <Select
                      {...field}
                      placeholder="선택"
                      variant="primary"
                      fullWidth
                      options={PART_TIME_OPTIONS}
                      errors={errors}
                    />
                  )}
                />
              </div>
            </Row>
            <Row label="실 근무 시간">
              <div className="input-sec">
                <Controller
                  control={control}
                  name={`scheduleWorkInput[${index}].scheduleWorkTime`}
                  render={({ field }: any) => (
                    <Input
                      {...field}
                      type="text"
                      multiline={false}
                      fullWidth={false}
                      errors={errors}
                      disabled
                    />
                  )}
                />
              </div>
            </Row>
            <Row label="비고">
              <div className="input-sec">
                <Controller
                  control={control}
                  name={`scheduleWorkInput[${index}].scheduleNote`}
                  render={({ field }: any) => (
                    <Input
                      {...field}
                      type="text"
                      placeholder="내용을 입력하세요."
                      multiline={true}
                      fullWidth={false}
                      useMaxLengthIndicator={true}
                      maxLength={"1000"}
                      errors={errors}
                    />
                  )}
                />
              </div>
            </Row>
            <div className="section-add">
              {workFormFields.length > 1 && (
                <button
                  type="button"
                  className="delete-sec"
                  onClick={() => workFormRemove(index)}
                >
                  삭제
                </button>
              )}
              <button
                className="add-sec"
                type="button"
                onClick={() => handleAddWorkForm()}
              >
                일정추가 <img src={IconPlusSVG} alt="자격증 추가 버튼" />
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default WorkForm;
