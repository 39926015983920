import React, { useState, useEffect, useRef } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import request from "api/request";

import Layout from "layout/layout";

import usePageSlice from "hooks/usePageSlice";

import Filter from "components/Filter/Filter";
import Select from "components/Select/Select";
import Search from "components/Search/Search";
import Pagination from "components/Pagination/Pagination";

import {
  PAGE_LAYOUT,
  EmployeeSelectOptions,
  partnerFilterOptions,
} from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";
import { useTheme } from "utils/context/themeProvider";

import NoDataSVG from "assets/images/no_data_ico.svg";
import IcDropBtnSVG from "assets/images/icon_drop.svg";
import { TestData } from "utils/testdata";
const defaultValues = {
  filter: [
    partnerFilterOptions.map((e: any) => {
      if (e.checked) return e.value;
    }),
  ],
  select: {
    value: "ALL",
    label: "전체",
  },
  search: "",
};

const Partner = () => {
  const navigate = useNavigate();
  const [ThemeMode] = useTheme();
  const scrollContentRef = useRef<HTMLDivElement | null>(null);
  const [sWidth, setSWidth] = useState<number>(0);
  const [tableData, setTableData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const fixed = FixedWrap();
  const scroll = ScrollWrap(sWidth);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (scrollContentRef.current)
      setSWidth(scrollContentRef?.current?.scrollWidth);
  }, [values.filter, scrollContentRef, tableData]);

  //useEffect(() => {
  //  let keywords: string | undefined = "";

  //  let payload: any = {
  //    type: "PARTNER",
  //    search_filter: values?.select.value,
  //  };

  //  if (values?.select.value === "ID" && values?.search?.split("")[0] === "B") {
  //    const regex = /(BPZ|0)/i;
  //    keywords = values?.search?.split(regex).pop();
  //  }

  //  if (keywords !== undefined && keywords !== "") {
  //    payload = {
  //      ...payload,
  //      keyword: keywords,
  //    };
  //  } else {
  //    payload = {
  //      ...payload,
  //      keyword: values?.search ? values?.search : null,
  //    };
  //  }

  //  request("get", "/users", {}, payload)
  //    .then((res: any) => {
  //      if (res.status === 200) {
  //        setTableData(res?.data.result.data);
  //        setTotalCount(res.data.result.total_elements);
  //        setTotalPages(Math.ceil(res.data.result.total_elements / 10));
  //      }
  //    })
  //    .catch((err) => {
  //      console.log(err);
  //    });
  //}, [values?.search, values?.select.value]);

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  const pageArr = usePageSlice(TestData);

  return (
    <Layout page={PAGE_LAYOUT.partner}>
      <PartnerStyled>
        <div className="status-wrap">
          <div className="status-header">
            <div className="search-sec">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleFormSubmit();
                }}
              >
                <div className="filter">
                  <Controller
                    control={control}
                    name="filter"
                    render={({ field }) => (
                      <Filter
                        {...field}
                        option={partnerFilterOptions}
                        handleFormSubmit={handleFormSubmit}
                      />
                    )}
                  />
                </div>
                <div className="search">
                  <div className="search-select">
                    <Controller
                      control={control}
                      name="select"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="전체"
                          w="130"
                          h="52"
                          fontS="15"
                          variant="tertiary"
                          color={ThemeMode === "light" ? "#000" : "#fff"}
                          bgColor={ThemeMode === "light" ? "#fff" : "#211f1e"}
                          options={EmployeeSelectOptions}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name="search"
                      render={({ field }) => (
                        <Search
                          {...field}
                          color={ThemeMode === "light" ? "#000" : "#c9c9c9"}
                          bgcolor={ThemeMode === "light" ? "#fff" : "#211f1e"}
                          placeholder="검색어를 입력하세요."
                          handleFormSubmit={handleFormSubmit}
                        />
                      )}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="status-btn">
              <div className="xlsx-download">엑셀로 내보내기</div>
            </div>
          </div>
          <div>
            {TestData.length > 0 ? (
              <div className="status-table">
                <div css={fixed}>
                  <div className="fixed-table">
                    <div>
                      협력사 번호{" "}
                      <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                    <div>
                      기업명 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                    <div>
                      사업자 등록번호{" "}
                      <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                    <div>
                      협력분야 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </div>
                  </div>
                  <div className="fixed-data">
                    {pageArr[page - 1]?.map((item: any, i: number) => {
                      const idToString = item.company?.id.toString();
                      return (
                        <div
                          key={i}
                          onClick={() =>
                            navigate(`/curr_status/partner/detail/${item.id}`, {
                              state: { partner: item },
                            })
                          }
                        >
                          <div>
                            {idToString?.length === 1
                              ? "BPZ00" + item.company?.id
                              : "BPZ0" + item.company?.id}
                          </div>
                          <div>{item.company?.company_name}</div>
                          <div>{item.company?.business_num}</div>
                          <div>{item.department}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="fixed-line"></div>
                <div css={scroll}>
                  <div className="scroll-table">
                    <div>
                      {values.filter[0]?.map((item: any, i: number) => {
                        const clientWidth =
                          scrollContentRef?.current?.children[0]?.children[
                            i - 1
                          ]?.clientWidth;

                        const result = partnerFilterOptions.filter(
                          (e: any) => e.value === item
                        );
                        return (
                          item !== "All" && (
                            <div
                              key={i}
                              css={{
                                width: `${clientWidth}px`,
                                minWidth: "120px",
                              }}
                            >
                              {result[0]?.label}{" "}
                              <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                            </div>
                          )
                        );
                      })}
                      <div
                        css={{
                          minWidth: "120px",
                        }}
                      >
                        계정 상태{" "}
                        <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                      </div>
                    </div>
                  </div>
                  <div className="scroll-data" ref={scrollContentRef}>
                    {pageArr[page - 1]?.map((item: any, i: number) => {
                      return (
                        <div
                          key={i}
                          onClick={() =>
                            navigate(`/curr_status/partner/detail/${item.id}`, {
                              state: { partner: item },
                            })
                          }
                        >
                          {values.filter[0].includes("CEO") && (
                            <div>
                              {item.company.ceo ? item.company.ceo : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("manager") && (
                            <div>{item.user_name}</div>
                          )}
                          {values.filter[0].includes("position") && (
                            <div>{item.position}</div>
                          )}
                          {values.filter[0].includes("companyAddress") && (
                            <div className="company-address-wrap">
                              ({item.company.zip_code}){item.company.address}{" "}
                              {item.company.detail_address}
                            </div>
                          )}
                          {values.filter[0].includes("CEOPhone") && (
                            <div>
                              {item.company.company_phone
                                ? item.company.company_phone
                                : "-"}
                            </div>
                          )}
                          {values.filter[0].includes("managerPhone") && (
                            <div>
                              {item.user_phone.substring(0, 3) +
                                "-" +
                                item.user_phone.substring(3, 7) +
                                "-" +
                                item.user_phone.substring(7, 11)}
                            </div>
                          )}
                          {values.filter[0].includes("fax") && (
                            <div>{item.company.fax}</div>
                          )}
                          {values.filter[0].includes("email") && (
                            <div className="email-sec">{item.signname}</div>
                          )}
                          {values.filter[0].includes("bank") && (
                            <div>{item.company.bank}</div>
                          )}
                          {values.filter[0].includes("account") && (
                            <div className="account-wrap">
                              {item.company.account_number}
                            </div>
                          )}
                          {item.is_withdraw ? (
                            <div className="active-text">활성</div>
                          ) : (
                            <div className="inactive-text">비활성</div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="list-no-data-wrap">
                <div>
                  <div>
                    <div className="no-data-img">
                      <img src={NoDataSVG} alt="검색 결과 없음 이미지" />
                    </div>
                    <div>검색 결과가 없습니다.</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="pagenation">
            <Pagination
              page={page}
              count={totalPages}
              list={tableData.length}
              onChangePage={setPage}
            />
          </div>
        </div>
      </PartnerStyled>
    </Layout>
  );
};

export default Partner;

const FixedWrap = () => {
  return css`
    width: 55%;

    @media screen and (max-width: 1600px) {
      width: 75%;
    }

    .fixed-table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      font-size: 18px;
      font-weight: bold;

      & div {
        cursor: pointer;
        text-align: center;
        width: 25%;
      }
    }

    .fixed-data {
      & > div {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & div {
          cursor: pointer;
          text-align: center;
          width: 25%;
          padding: 1px 0;
        }
      }
    }
  `;
};

const ScrollWrap = (sWidth: number) => {
  return css`
    width: 1000px;
    position: relative;
    left: -15px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c09260;
      border-radius: 10px;
      background-clip: padding-box;
    }

    @media screen and (max-width: 1600px) {
      width: 700px;
    }

    @media screen and (min-width: 1600px) {
      width: 1100px;
    }

    .scroll-table {
      width: ${`${sWidth}px`};

      & > div {
        width: ${`${sWidth}px`};
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 20px 20px;
        font-size: 18px;
        font-weight: bold;

        & div {
          flex: 0 0 auto;
          cursor: pointer;
          margin: 0 30px;
          text-align: center;
        }
      }
    }

    .scroll-data {
      width: ${`${sWidth}px`};

      & > div {
        width: ${`${sWidth}px`};
        padding: 20px 0 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & div {
          min-width: 120px;
          flex: 0 0 auto;
          cursor: pointer;
          margin: 0 30px;
          padding: 1px 0;
          text-align: center;
        }

        .company-address-wrap {
          width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .email-sec {
          min-width: 230px;
        }

        .account-wrap {
          min-width: 150px;
        }
      }
    }
  `;
};

const PartnerStyled = styled.div`
  width: 100%;
  margin: 40px;

  .status-btn {
    padding-right: 15px;
  }

  .active-text {
    font-size: 15px;
    font-weight: bold;
    color: #c09260;
  }

  .inactive-text {
    font-size: 15px;
    font-weight: bold;
    color: #ff1e1e;
  }
`;
