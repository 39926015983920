import React, { useEffect, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";

import request from "api/request";
import Layout from "layout/layout";
import useSubmitAlert from "hooks/useSubmitAlert";
import Button from "components/Button/Button";
import {
  DEPARTMENT_OPTIONS,
  PAGE_LAYOUT,
  PARTTIMER_SUPPORT_PART_OPTIONS,
} from "utils/constants";

const PartTimerDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { noti } = useSubmitAlert();
  const { REACT_APP_API } = process.env;
  const [detailData, setDetailData] = useState<any>([]);
  const [parttimerData, setParttimerData] = useState<any>([]);
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    setParttimerData(location?.state?.parttimer);
  }, [isReload]);

  //useEffect(() => {
  //  request(
  //    "get",
  //    `/resume/admin/list/${location?.state?.parttimer.id}`,
  //    {},
  //    {}
  //  ).then((res: any) => {
  //    if (res.status === 200) setDetailData(res?.data?.result);
  //  });
  //}, []);

  //console.log("detailData: ", detailData);
  //console.log("parttimerData: ", parttimerData);

  return (
    <Layout page={PAGE_LAYOUT.part_timer}>
      <PartTimerDetailStyle>
        <div>
          <div className="detail-container">
            <div>
              <h3>&#183; 개인 정보 &#183;</h3>
              <ul>
                <li>
                  <div>ID</div>
                  <div>
                    {detailData?.id?.toString().length === 1
                      ? "A00000" + detailData?.id
                      : "A0000" + detailData?.id}
                  </div>
                </li>
                <li>
                  <div>성명</div>
                  <div>{detailData?.resume_name}</div>
                </li>
                <li>
                  <div>생년월일</div>
                  <div>
                    {detailData?.birth_day?.substring(0, 4)}.
                    {detailData?.birth_day?.substring(4, 6)}.
                    {detailData?.birth_day?.substring(6, 8)}
                  </div>
                </li>
                <li>
                  <div>성별</div>
                  <div>{detailData?.gender}</div>
                </li>
                <li>
                  <div>소속</div>
                  <div>
                    {parttimerData?.company
                      ? parttimerData?.company?.company_name
                      : "개인"}
                  </div>
                </li>
                <li>
                  <div>협력사 코드</div>
                  <div>
                    {parttimerData?.company
                      ? parttimerData?.company.id.toString().length === 1
                        ? "BPZ00" + parttimerData.company.id
                        : "BPZ0" + parttimerData.company.id
                      : "개인"}
                  </div>
                </li>
                <li>
                  <div>
                    회원가입 약관
                    <br />
                    동의여부
                  </div>
                  <div>동의</div>
                </li>
                <li>
                  <div>증명사진</div>
                  <div className="idphoto-sec">
                    {parttimerData?.image_info ? (
                      <img
                        src={`${REACT_APP_API}/files/${parttimerData.image_info?.id}`}
                        alt="증명사진 이미지"
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                </li>
                <li>
                  <div>이메일</div>
                  <div>{detailData?.email}</div>
                </li>
                <li>
                  <div>주소</div>
                  <div>
                    ({detailData?.zip_code}) {detailData?.address}{" "}
                    {detailData?.detail_address}
                  </div>
                </li>
                <li>
                  <div>휴대전화번호</div>
                  <div>
                    {detailData?.phone?.substring(0, 3)}-
                    {detailData?.phone?.substring(3, 7)}-
                    {detailData?.phone?.substring(7, 11)}
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <h3>&#183; 지원 분야 &#183;</h3>
              <ul>
                <li>
                  <div>1차 희망</div>
                  <div className="support-sec">
                    <div>
                      <span>부서</span>
                      {detailData?.job1_depth1
                        ? DEPARTMENT_OPTIONS.filter(
                            (e: any) => e.value === detailData?.job1_depth1
                          )[0]?.label
                        : "-"}
                    </div>
                    <div>
                      <span>파트</span>
                      {detailData?.job1_depth2
                        ? PARTTIMER_SUPPORT_PART_OPTIONS.filter(
                            (e: any) => e.value === detailData?.job1_depth2
                          )[0]?.label
                        : "-"}
                    </div>
                  </div>
                </li>
                <li>
                  <div>2차 희망</div>
                  <div className="support-sec">
                    <div>
                      <span>부서</span>
                      {detailData?.job2_depth1
                        ? DEPARTMENT_OPTIONS.filter(
                            (e: any) => e.value === detailData?.job2_depth1
                          )[0]?.label
                        : "-"}
                    </div>
                    <div>
                      <span>파트</span>
                      {detailData?.job2_depth2
                        ? PARTTIMER_SUPPORT_PART_OPTIONS.filter(
                            (e: any) => e.value === detailData?.job2_depth2
                          )[0]?.label
                        : "-"}
                    </div>
                  </div>
                </li>
                <li>
                  <div>계좌번호</div>
                  <div>
                    {parttimerData?.bank} {parttimerData?.account_number}
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <h3>&#183; 경력 사항 &#183;</h3>
              <ul>
                <li>
                  <div>경력여부</div>
                  <div>O</div>
                </li>
                <li>
                  <div>주 분야</div>
                  <div>
                    {parttimerData?.main_field
                      ? parttimerData?.main_field
                      : "-"}
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <h3>&#183; 정 보 &#183;</h3>
              <ul>
                <li>
                  <div>최근 기본 시급</div>
                  <div>
                    {parttimerData?.latest_hourly_wage
                      ? parttimerData?.latest_hourly_wage
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>
                    월 누적시간
                    <br />
                    (2022.09)
                  </div>
                  <div>
                    {parttimerData?.cumulative_hours_per_month
                      ? parttimerData?.cumulative_hours_per_month
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>근무 확인</div>
                  <div>
                    {parttimerData?.check_working
                      ? parttimerData?.check_working
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>등급</div>
                  <div>{parttimerData?.class}</div>
                </li>
                <li>
                  <div>인상률</div>
                  <div>{parttimerData?.increase_rate} %</div>
                </li>
                <li>
                  <div>경고 누적 수</div>
                  <div>
                    {parttimerData?.cumulative_number_of_warnings
                      ? parttimerData?.cumulative_number_of_warnings
                      : "0"}
                  </div>
                </li>
                <li>
                  <div>비고</div>
                  <div>{parttimerData?.note ? parttimerData?.note : "-"}</div>
                </li>
                <li>
                  <div>
                    누적시간
                    <br />
                    제한여부
                  </div>
                  <div className="limit-box-wrap">
                    <div>
                      {parttimerData?.accumulated_time_limit
                        ? "제한 설정"
                        : "제한 해제"}
                    </div>
                    <div
                      className={
                        parttimerData?.accumulated_time_limit
                          ? "limits unlimit-btn"
                          : "limits limit-btn"
                      }
                      onClick={() => {
                        //const isReject = noti(
                        //  `${
                        //    parttimerData?.accumulated_time_limit
                        //      ? "제한 해제"
                        //      : "제한 설정"
                        //  }하시겠습니까?`,
                        //  "예",
                        //  "아니오"
                        //);
                        //const getData = () => {
                        //  isReject.then((res: boolean) => {
                        //    if (res)
                        //location?.state?.handleEdit(
                        //  "limit",
                        //  parttimerData?.accumulated_time_limit,
                        //  parttimerData.id
                        //);
                        //  });
                        //};
                        //getData();
                      }}
                    >
                      {parttimerData?.accumulated_time_limit
                        ? "제한 해제"
                        : "제한 설정"}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                목록
              </Button>
              <span className="void-sec"></span>
              <Button type="button" variant="secondary" preset="1">
                삭제
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="primary"
                preset="2"
                onClick={() =>
                  navigate(
                    `/curr_status/part_timer/edit/${location.state?.listId}`,
                    {
                      state: { listId: location.state?.listId },
                    }
                  )
                }
              >
                수정
              </Button>
            </div>
          </div>
        </div>
      </PartTimerDetailStyle>
    </Layout>
  );
};

export default PartTimerDetail;

const PartTimerDetailStyle = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 35px;
  }

  .detail-container {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid #222;
  }

  li {
    display: flex;
    align-items: center;
    padding: 30px 0;
    border-bottom: 1px solid #ddd;

    & > div:nth-of-type(1) {
      width: 150px;
      font-size: 18px;
      font-weight: bold;
      color: #b2b2b2;
      line-height: 25px;
    }

    & > div:nth-of-type(2) {
      font-size: 18px;
      font-weight: normal;
      color: #000;
    }
  }

  .idphoto-sec {
    width: 100px;
    height: 134px;
    background-color: #f1f1f1;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .support-sec {
    display: flex;
    font-size: 18px;
    font-weight: normal;

    & > div {
      width: 150px;
    }

    span {
      color: #c09260;
      margin-right: 6px;
    }
  }

  .limit-box-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .limits {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 35px;
    cursor: pointer;
    font-size: 15px;
    font-weight: normal;
    border-radius: 3px;
  }
  .limit-btn {
    color: #a89a8b;
    border: solid 1px #a89a8b;
  }

  .unlimit-btn {
    color: #ff4b4b;
    border: solid 1px #ff4b4b;
  }

  .submit-btn {
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
