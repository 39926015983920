import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "react-dayjs-picker";
import dayjs from "dayjs";

import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import SearchMember from "components/SearchPopup/SearchMember";

import { ApprovalDocumentTypes } from "types/commonTypes";

import IconCalSVG from "assets/images/icon_cal.svg";

import { GENDER_OPTIONS } from "utils/constants";

const VacationAppForm = ({
  Controller,
  setValue,
  control,
  errors,
  handleFormSubmit,
}: ApprovalDocumentTypes) => {
  const navigate = useNavigate();
  const [openDate, setOpenDate] = useState(false);
  const [result, setResult] = useState<any>([]);
  const [openSearchMember, setOpenSearchMember] = useState<boolean>(false);

  useEffect(() => {
    if (result.length > 0) {
      setValue(
        "vacationSuccessor",
        "[" + result[0].department + "] " + result[0].name
      );
    }
  }, [result]);

  return (
    <>
      <Row label="신청자">
        <div className="input-sec">
          <Controller
            control={control}
            name="approvalName"
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
                disabled
              />
            )}
          />
        </div>
      </Row>
      <Row label="직급·직책">
        <div className="input-sec">
          <Controller
            control={control}
            name="approvalPosition"
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
                disabled
              />
            )}
          />
        </div>
      </Row>
      <Row label="부서">
        <div className="input-sec">
          <Controller
            control={control}
            name="approvalDepartment"
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
                disabled
              />
            )}
          />
        </div>
      </Row>
      <Row label="사원번호">
        <div className="input-sec">
          <Controller
            control={control}
            name="vacationEmployeeNumber"
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
                disabled
              />
            )}
          />
        </div>
      </Row>
      <Row label="보유 연차 개수">
        <div className="input-sec">
          <Controller
            control={control}
            name="vacationCount"
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
                disabled
              />
            )}
          />
        </div>
      </Row>
      <Row label="신청일">
        <div
          className="date-image"
          onClick={() => {
            setOpenDate(true);
          }}
        >
          <img src={IconCalSVG} alt="캘린더" />
        </div>
        <div className="input-sec date-input">
          <Controller
            control={control}
            name="vacationDate"
            render={({ field }: any) => (
              <DatePicker
                //register={register("employeeBOD")}
                isOpen={openDate}
                setIsOpen={setOpenDate}
                popoverPositions={["bottom"]}
                onSelect={(date) => {
                  field.onChange(dayjs(date).format("YYYY-MM-DD"));
                }}
                value={field.value}
                closeOnSelect={true}
                onChange={() => {}}
                placeholder="생년월일을 선택하세요."
                //errors={errors}
              />
            )}
          />
        </div>
      </Row>
      <Row label="사용기간">
        <div className="input-sec">
          <Controller
            control={control}
            name="vacationUsePeriod"
            render={({ field }: any) => (
              <Select
                {...field}
                placeholder="선택"
                variant="primary"
                fullWidth
                options={GENDER_OPTIONS}
                errors={errors}
              />
            )}
          />
        </div>
      </Row>
      <Row label="사유">
        <div className="input-sec">
          <Controller
            control={control}
            name="vacationReason"
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
              />
            )}
          />
        </div>
      </Row>
      <Row label="인수인계자">
        <div className="input-sec search-member-sec">
          <Controller
            control={control}
            name="vacationSuccessor"
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
              />
            )}
          />
          <button
            type="button"
            className="search-member-btn"
            onClick={() => setOpenSearchMember(true)}
          >
            인원 검색
          </button>
          {openSearchMember && (
            <SearchMember
              setOpenSearchMember={setOpenSearchMember}
              setResult={setResult}
            />
          )}
        </div>
      </Row>
      <Row label="인수인계 현황">
        <div className="input-sec">
          <Controller
            control={control}
            name="vacationSituation"
            render={({ field }: any) => (
              <Select
                {...field}
                placeholder="선택"
                variant="primary"
                fullWidth
                options={GENDER_OPTIONS}
                errors={errors}
              />
            )}
          />
        </div>
      </Row>
      <Row label="비고">
        <div className="input-sec">
          <Controller
            control={control}
            name="vacationNote"
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
              />
            )}
          />
        </div>
      </Row>
      <div className="submit-btn">
        <Button
          type="button"
          variant="tertiary"
          preset="1"
          onClick={() => navigate(-1)}
        >
          목록
        </Button>
        <span className="void-sec"></span>
        <Button
          type="button"
          variant="primary"
          preset="2"
          onClick={() => handleFormSubmit()}
        >
          신청
        </Button>
      </div>
    </>
  );
};

export default VacationAppForm;
