import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

import Layout from "layout/layout";

import { PAGE_LAYOUT } from "utils/constants";

import LinenTapMenu from "./LinenTapMenu";
import InventoryForm from "./Inventory/InventoryForm";
import PaymentAppForm from "./PaymentApp/PaymentAppForm";
import ItemManageForm from "./ItemManage/ItemManageForm";
import LinenInfoForm from "./LinenInfo/LinenInfoForm";
import LaundryForm from "./Laundry/LaundryForm";

const Linen = () => {
  const location = useLocation();

  return (
    <Layout page={PAGE_LAYOUT.linen}>
      <LinenStyled>
        <LinenTapMenu textChange={location?.state?.textChange} />
        <div className="status-wrap">
          {location?.state?.textChange === "INVENTORY" && (
            <InventoryForm textChange={location?.state?.textChange} />
          )}
          {location?.state?.textChange === "PAYMENT_APP" && (
            <PaymentAppForm textChange={location?.state?.textChange} />
          )}
          {location?.state?.textChange === "ITEM_MANAGEMENT" && (
            <ItemManageForm />
          )}
          {location?.state?.textChange === "LINEN_INFO" && <LinenInfoForm />}
          {location?.state?.textChange === "LAUNDRY" && (
            <LaundryForm textChange={location?.state?.textChange} />
          )}
        </div>
      </LinenStyled>
    </Layout>
  );
};

export default Linen;

const LinenStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 40px;

  & > div {
    display: flex;
    padding: 35px;
  }
`;
