import { useState } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";

import Select from "components/Select/Select";
import Search from "components/Search/Search";
import Pagination from "components/Pagination/Pagination";

import IconCalSVG from "assets/images/icon_cal.svg";

import { MODE_OPTIONS, EmployeeSelectOptions } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";
import { TapMenuProps } from "types/commonTypes";

import "react-dayjs-picker/dist/index.css";

import ListMode from "./components/ListMode";
import DataMode from "./components/DataMode";

const defaultValues = {
  selectMode: {
    value: "LIST_MODE",
    label: "목록 모드",
  },
  selectPlace: {
    value: "init",
    label: "선택",
  },
  selectFloor: {
    value: "init",
    label: "선택",
  },
  selectDate1: "",
  selectDate2: "",
  itemSearch: "",
};

const PaymentAppForm = ({ textChange }: TapMenuProps) => {
  const navigate = useNavigate();
  const [openDate1, setOpenDate1] = useState(false);
  const [openDate2, setOpenDate2] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <PaymentAppFormStyled>
      <div className="payment-form-wrap">
        <div className="payment-form-search">
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
              handleSubmit(
                () => {},
                (err) => {}
              );
            }}
          >
            <div className="linen-form">
              <div className="search-select">
                <div className="header-text">모드</div>
                <Controller
                  control={control}
                  name="selectMode"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="전체"
                      w="150"
                      h="52"
                      fontS="15"
                      variant="tertiary"
                      options={MODE_OPTIONS}
                    />
                  )}
                />
              </div>
              <div className="search-select">
                <div className="header-text">지점</div>
                <Controller
                  control={control}
                  name="selectPlace"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="전체"
                      w="186"
                      h="52"
                      fontS="15"
                      variant="tertiary"
                      options={EmployeeSelectOptions}
                    />
                  )}
                />
              </div>
              <div className="search-select">
                <div className="header-text">층</div>
                <Controller
                  control={control}
                  name="selectFloor"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="전체"
                      w="130"
                      h="52"
                      fontS="15"
                      variant="tertiary"
                      options={EmployeeSelectOptions}
                    />
                  )}
                />
              </div>
            </div>
            <div className="linen-form">
              <div className="search-select date-input">
                <div className="header-text">기간</div>
                <div
                  className="search-date-image"
                  onClick={() => {
                    setOpenDate1(true);
                  }}
                >
                  <img src={IconCalSVG} alt="캘린더" />
                </div>
                <Controller
                  control={control}
                  name="selectDate1"
                  render={({ field }) => (
                    <DatePicker
                      isOpen={openDate1}
                      setIsOpen={setOpenDate1}
                      popoverPositions={["bottom"]}
                      onSelect={(date) => {
                        field.onChange(dayjs(date).format("YYYY-MM-DD"));
                      }}
                      value={field.value}
                      closeOnSelect={true}
                      onChange={() => {}}
                    />
                  )}
                />
                <span className="date-span">~</span>
                <div
                  className="search-date-image2"
                  onClick={() => {
                    setOpenDate2(true);
                  }}
                >
                  <img src={IconCalSVG} alt="캘린더" />
                </div>
                <Controller
                  control={control}
                  name="selectDate2"
                  render={({ field }) => (
                    <DatePicker
                      isOpen={openDate2}
                      setIsOpen={setOpenDate2}
                      popoverPositions={["bottom"]}
                      onSelect={(date) => {
                        field.onChange(dayjs(date).format("YYYY-MM-DD"));
                      }}
                      value={field.value}
                      closeOnSelect={true}
                      onChange={() => {}}
                    />
                  )}
                />
              </div>
              <div className="search-select">
                <div className="header-text">검색</div>
                <Controller
                  control={control}
                  name="itemSearch"
                  render={({ field }) => (
                    <Search
                      {...field}
                      w="337px"
                      placeholder="검색어를 입력하세요."
                      handleFormSubmit={handleFormSubmit}
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="payment-form-btn">
          <div className="xlsx-download">엑셀로 내보내기</div>
          <div
            className="add-payment"
            onClick={() =>
              navigate("/work/house/linen/payment", {
                state: { textChange: textChange },
              })
            }
          >
            작성
          </div>
        </div>
      </div>
      <div className="payment-form-body">
        {values.selectMode.value === "LIST_MODE" && (
          <ListMode textChange={textChange} />
        )}
        {values.selectMode.value === "DATA_MODE" && <DataMode />}
      </div>
    </PaymentAppFormStyled>
  );
};

export default PaymentAppForm;

const PaymentAppFormStyled = styled.div`
  width: 100%;
  .payment-form-wrap {
    display: flex;
    justify-content: space-between;
  }

  .payment-form-search {
    .search-select {
      display: flex;
      align-items: center;
    }
    .header-text {
      font-size: 16px;
      font-weight: bold;
      color: #222;
      margin-right: 10px;
      margin-left: 5px;
    }

    .search-date-image2 {
      position: absolute;
      margin-top: 0px;
      margin-left: 320px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      background-color: #444;
      cursor: pointer;
    }

    .search-date-image {
      position: absolute;
      margin-top: 0px;
      margin-left: 144px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      background-color: #444;
      cursor: pointer;
    }

    .date-span {
      margin: 0 8px;
    }

    .date-input {
      input::placeholder {
        color: #b2b2b2;
      }
      input[name="rdp-date-picker"] {
        width: 150px;
        height: 52px;
        border: solid 1px #ddd;
        background-color: #fff;
        line-height: 1.29;
        font-size: 14px;
        color: #000;
        padding-left: 10px;
      }
      .react-tiny-popover-container {
        height: 100px;
        background-color: red;
      }
    }
  }

  .payment-form-body {
    width: 100%;
  }

  .linen-form {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .payment-form-btn {
    display: flex;
    justify-content: end;
    margin-top: 90px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      margin-left: 10px;
      border-radius: 6px;
      color: #fff;
      cursor: pointer;
    }
  }

  .add-payment {
    width: 80px;
    background-color: #c09260;
  }
`;
