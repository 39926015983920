import { useState, useEffect } from "react";

import Row from "components/LabelWithInput/LabelWithInput";
import Select from "components/Select/Select";
import Input from "components/Input/Input";
import SearchMember from "components/SearchPopup/SearchMember";

import { SCHEDULE_OPTIONS } from "utils/constants";

interface VacationFormProps {
  setDescriptionId: any;
  Controller: any;
  setValue: any;
  control: any;
  errors: any;
}

const VacationForm = ({
  setDescriptionId,
  Controller,
  setValue,
  control,
  errors,
}: VacationFormProps) => {
  const [result, setResult] = useState<any>([]);
  const [openSearchMember, setOpenSearchMember] = useState(false);

  useEffect(() => {
    if (result.length > 0) {
      setDescriptionId([result[0].id]);

      setValue(
        "scheduleVacationName",
        "[" + result[0].department + "] " + result[0].user_name
      );
    }
  }, [result]);

  return (
    <>
      <Row label="성명">
        <div className="input-sec search-member-sec">
          <Controller
            control={control}
            name="scheduleVacationName"
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
              />
            )}
          />
          <button
            type="button"
            className="search-member-btn"
            onClick={() => setOpenSearchMember(true)}
          >
            인원 검색
          </button>
          {openSearchMember && (
            <SearchMember
              setOpenSearchMember={setOpenSearchMember}
              setResult={setResult}
            />
          )}
        </div>
      </Row>
      <Row label="보유 연차 개수">
        <div className="input-sec">
          <Controller
            control={control}
            name="scheduleVacationCount"
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
                disabled
              />
            )}
          />
        </div>
      </Row>
      <Row label="연차 기간">
        <div className="input-sec">
          <Controller
            control={control}
            name="scheduleVacationPeriod"
            render={({ field }: any) => (
              <Select
                {...field}
                placeholder="선택"
                variant="primary"
                fullWidth
                options={SCHEDULE_OPTIONS}
                errors={errors}
              />
            )}
          />
        </div>
      </Row>
      <Row label="사용 후 연차 개수">
        <div className="input-sec">
          <Controller
            control={control}
            name="scheduleVacationUse"
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
                disabled
              />
            )}
          />
        </div>
      </Row>
      <Row label="사유">
        <div className="input-sec">
          <Controller
            control={control}
            name="scheduleVacationNote"
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                placeholder="내용을 입력하세요."
                multiline={true}
                fullWidth={false}
                useMaxLengthIndicator={true}
                maxLength={"1000"}
                errors={errors}
              />
            )}
          />
        </div>
      </Row>
    </>
  );
};

export default VacationForm;
