import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";

import Layout from "layout/layout";

import Button from "components/Button/Button";

import { PAGE_LAYOUT } from "utils/constants";

import SeniorForm from "./components/SeniorForm";
import VacationAppForm from "./components/VacationAppForm";
import LetterAppForm from "./components/LetterAppForm";
import PlanAppForm from "./components/PlanAppForm";

const ApprovalDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout page={PAGE_LAYOUT.approval_detail}>
      <ApprovalDetailStyled>
        <div className="status-wrap">
          <div className="select-approval-body">
            {location?.state?.docType === "VACATION_APP" && (
              <div>
                <h3>연차 · 반차 신청</h3>
                <SeniorForm />
                <div className="select-approval-body-form">
                  <VacationAppForm />
                </div>
              </div>
            )}
            {location?.state?.docType === "LETTER_APP" && (
              <div>
                <h3>품의서</h3>
                <SeniorForm />
                <div className="detail-approval-body-form">
                  <LetterAppForm />
                </div>
              </div>
            )}
            {location?.state?.docType === "PLAN_APP" && (
              <div>
                <h3>기획안</h3>
                <SeniorForm />
                <div className="detail-approval-body-form">
                  <PlanAppForm />
                </div>
              </div>
            )}
          </div>
        </div>
      </ApprovalDetailStyled>
    </Layout>
  );
};

export default ApprovalDetail;

const ApprovalDetailStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 40px;

  .select-approval-body {
    display: flex;
    justify-content: center;

    h3 {
      font-family: Arita-buri-SemiBold;
      font-size: 45px;
      font-weight: 600;
      text-align: center;
      margin: 80px 0 60px 0;
    }
  }

  h4 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid #222;
  }

  .select-approval-body-form {
    width: 680px;
    border-top: 1px solid #222;
  }

  .detail-approval-body-form {
    width: 680px;
  }

  .approval-detail-form {
    li {
      display: flex;
      align-items: center;
      padding: 30px 0;
      border-bottom: 1px solid #ddd;

      & > div:nth-of-type(1) {
        width: 150px;
        font-size: 18px;
        font-weight: bold;
        color: #b2b2b2;
        line-height: 25px;
      }

      & > div:nth-of-type(2) {
        font-size: 18px;
        font-weight: normal;
        color: #000;
      }
    }

    .approval-block-box {
      display: block;
      margin: 8px 0;

      .approval-block-box-flex {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex: 1 1 30%;
        margin-top: 16px;

        & > div {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 33%;
          height: 56px;
          border: solid 1px #c09260;
          margin-bottom: 5px;
          color: #c09260;
        }
      }

      .approval-block-box-body > div {
        display: flex;
        align-items: center;
        margin: 8px 0;
      }

      span {
        display: block;
        width: 200px;
      }

      input {
        width: 100%;
        height: 65px;
        border: 1px solid #ddd;
      }
    }
  }

  .attatch-file {
    color: #c09260;
    text-decoration: underline;
  }

  .submit-btn {
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
