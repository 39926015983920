import { useState } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";

import Layout from "layout/layout";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Select from "components/Select/Select";

import IconCalSVG from "assets/images/icon_cal.svg";

import { PAGE_LAYOUT, EmployeeSelectOptions } from "utils/constants";
import validation from "utils/validations/employeeValidationSchema";

import LinenTapMenu from "../LinenTapMenu";

const defaultValues = {
  selectPlace: {
    value: "init",
    label: "선택",
  },
  selectDate: "",
  selectFloor: {
    value: "init",
    label: "선택",
  },
  laundryCount: "",
};

const LaundryRegist = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openDate, setOpenDate] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validation),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  return (
    <Layout page={PAGE_LAYOUT.linen}>
      <LaundryRegistStyled>
        <LinenTapMenu textChange={location?.state?.textChange} />
        <div className="laundry-add-wrap">
          <form
            onSubmit={(e) => {
              e.preventDefault();

              handleSubmit(
                () => {},
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className="laundry-add-inner">
              <div className="laundry-add-container">
                <div className="search-select">
                  <div className="header-text">지점</div>
                  <Controller
                    control={control}
                    name="selectPlace"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="전체"
                        w="150"
                        h="52"
                        fontS="15"
                        variant="tertiary"
                        options={EmployeeSelectOptions}
                      />
                    )}
                  />
                </div>
                <div className="search-select date-input">
                  <div className="header-text">날짜</div>
                  <div
                    className="search-date-image"
                    onClick={() => {
                      setOpenDate(true);
                    }}
                  >
                    <img src={IconCalSVG} alt="캘린더" />
                  </div>
                  <Controller
                    control={control}
                    name="selectDate"
                    render={({ field }) => (
                      <DatePicker
                        isOpen={openDate}
                        setIsOpen={setOpenDate}
                        popoverPositions={["bottom"]}
                        onSelect={(date) => {
                          field.onChange(dayjs(date).format("YYYY-MM-DD"));
                        }}
                        value={field.value}
                        closeOnSelect={true}
                        onChange={() => {}}
                      />
                    )}
                  />
                </div>
                <div className="search-select">
                  <div className="header-text">호차</div>
                  <Controller
                    control={control}
                    name="selectFloor"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="전체"
                        w="130"
                        h="52"
                        fontS="15"
                        variant="tertiary"
                        options={EmployeeSelectOptions}
                      />
                    )}
                  />
                </div>
                <div className="laundry-add-regist-top-text">
                  <div>
                    롯데호텔 담담자 <span>김누리</span>
                  </div>
                  <div>
                    협력업체 <span>대웅크린월드</span>
                  </div>
                </div>
              </div>
              <div className="status-btn">
                <div className="xlsx-download">엑셀로 내보내기</div>
              </div>
            </div>
            <div className="laundry-add-body">
              <div className="laundry-add-info">
                <div className="laundry-add-info-title">품목정보</div>
                <div className="laundry-add-info-sub laundry-add-info-sub1">
                  <div>품목명</div>
                  <div>규격</div>
                  <div>품목단가</div>
                  <div>세탁단가</div>
                </div>
                <div className="laundry-add-info-data laundry-add-info-data1">
                  <div>품목 1</div>
                  <div>1040*1940</div>
                  <div>₩10,000</div>
                  <div>₩10,000</div>
                </div>
              </div>
              <div className="laundry-add-info2 laundry-add-line">
                <div className="laundry-add-info-title2">수량</div>
                <div className="laundry-add-info-data2">
                  <Controller
                    control={control}
                    name="laundryCount"
                    render={({ field }) => (
                      <Input
                        {...field}
                        w={"70"}
                        type="number"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="laundry-add-info2 laundry-add-line">
                <div className="laundry-add-info-title2">단가</div>
                <div className="laundry-add-info-data2">₩100,000</div>
              </div>
              <div className="laundry-add-info2">
                <div className="laundry-add-info-title2">세탁 단가</div>
                <div className="laundry-add-info-data2">₩100,000</div>
              </div>
            </div>
            <div className="laundry-add-total">
              <div>세탁 단가</div>
              <div>₩1,600,000</div>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                목록
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="secondary"
                preset="1"
                onClick={() => {}}
              >
                초기화
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="primary"
                preset="2"
                onClick={() => {}}
              >
                작성
              </Button>
            </div>
          </form>
        </div>
      </LaundryRegistStyled>
    </Layout>
  );
};

export default LaundryRegist;

const LaundryRegistStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 75px 40px 40px 40px;

  .laundry-add-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    padding: 35px;
    margin-top: 30px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  }

  .laundry-add-inner {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .laundry-add-container {
    width: 100%;
    display: flex;

    .search-select {
      display: flex;
      align-items: center;
    }
    .header-text {
      font-size: 16px;
      font-weight: bold;
      color: #222;
      margin-right: 10px;
      margin-left: 5px;
    }
    .search-date-image {
      position: absolute;
      margin-top: 0px;
      margin-left: 144px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      background-color: #444;
      cursor: pointer;
    }

    .date-span {
      margin: 0 8px;
    }

    .date-input {
      input::placeholder {
        color: #b2b2b2;
      }
      input[name="rdp-date-picker"] {
        width: 150px;
        height: 52px;
        border: solid 1px #ddd;
        background-color: #fff;
        line-height: 1.29;
        font-size: 14px;
        color: #000;
        padding-left: 10px;
      }
      .react-tiny-popover-container {
        height: 100px;
        background-color: red;
      }
    }
  }

  .laundry-add-regist-top-text {
    display: flex;
    align-items: center;
    color: #b2b2b2;
    font-size: 16px;
    font-weight: bold;
    margin-left: 15px;

    & > div:nth-of-type(1)::after {
      content: "";
      padding: 0 1px;
      margin: 0 16px;
      background-color: #707070;
    }
    span {
      color: #545454;
    }
  }

  .laundry-add-body {
    display: flex;
    margin-top: 30px;
  }
  .laundry-add-info {
    width: 55%;
    border-right: solid 1px #ebebeb;
  }
  .laundry-add-info2 {
    width: 15%;
  }
  .laundry-add-line {
    border-right: solid 1px #ebebeb;
  }
  .laundry-add-info-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 62px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: rgba(168, 154, 139, 0.3);
  }

  .laundry-add-info-title2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 131px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: rgba(168, 154, 139, 0.3);
  }

  .laundry-add-info-sub {
    display: flex;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: #f9f9f9;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 68px;
    }
  }

  .laundry-add-info-data {
    display: flex;
    font-size: 15px;
    color: #545454;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 68px;
      border-bottom: solid 1px #ebebeb;
    }
  }

  .laundry-add-info-data2 {
    font-size: 15px;
    color: #545454;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
    border-bottom: solid 1px #ebebeb;

    input {
      height: 40px;
      text-align: right;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .laundry-add-total {
    width: 100%;
    height: 68px;
    display: flex;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    border-bottom: 3px solid #444;

    & > div:nth-of-type(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55%;
      border-right: solid 1px #ebebeb;
    }
    & > div:nth-of-type(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45%;
    }
  }

  .submit-btn {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
