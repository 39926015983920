const usePageSlice = (tableData: any) => {
  const pageArr: any = [];
  for (let i = 0; i < tableData.length; i += 10) {
    pageArr.push(tableData.slice(i, i + 10));
  }

  return pageArr;
};

export default usePageSlice;
