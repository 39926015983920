import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/layout";
import Button from "components/Button/Button";
import Select from "components/Select/Select";
import { PAGE_LAYOUT } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

const defaultValues = {
  place: "",
};

const PaymentSetting = () => {
  const navigate = useNavigate();
  const MasterCode = useSelector((s: any) => s.code);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  return (
    <Layout page={PAGE_LAYOUT.setting}>
      <PaymentSettingStyled>
        <div className="payment-wrap status-wrap">
          <div>
            <div className="place-select">
              <div className="form-text form-left">지점</div>
              <Controller
                control={control}
                name="place"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="선택"
                    w="159"
                    h="52"
                    fontS="14"
                    variant="tertiary"
                    options={MasterCode.SPOT}
                  />
                )}
              />
            </div>
            <div className="detail-wrap">
              <div className="detail-container">
                <div>
                  <h3>&#183; 연차 · 반차 신청서 &#183;</h3>
                  <ul>
                    <li>
                      <div>상급자 1</div>
                      <div></div>
                    </li>
                    <li>
                      <div>상급자 2</div>
                      <div></div>
                    </li>
                    <li>
                      <div>상급자 3</div>
                      <div></div>
                    </li>
                  </ul>
                </div>
                <div>
                  <h3>&#183; 품의서 &#183;</h3>
                  <ul>
                    <li>
                      <div>상급자 1</div>
                      <div></div>
                    </li>
                    <li>
                      <div>상급자 2</div>
                      <div></div>
                    </li>
                    <li>
                      <div>상급자 3</div>
                      <div></div>
                    </li>
                  </ul>
                </div>
                <div>
                  <h3>&#183; 기획서 &#183;</h3>
                  <ul>
                    <li>
                      <div>상급자 1</div>
                      <div></div>
                    </li>
                    <li>
                      <div>상급자 2</div>
                      <div></div>
                    </li>
                    <li>
                      <div>상급자 3</div>
                      <div></div>
                    </li>
                  </ul>
                </div>
                <div className="edit-btn">
                  <Button
                    onClick={() => navigate("/setting/payment/edit")}
                    type="button"
                    variant="primary"
                    preset="2"
                  >
                    수정
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PaymentSettingStyled>
    </Layout>
  );
};

export default PaymentSetting;

const PaymentSettingStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    padding: 35px;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .place-select {
    display: flex;
    align-items: center;
  }

  .form-text {
    font-size: 16px;
    font-weight: bold;
    color: #222;
    margin-right: 24px;
  }

  .detail-wrap {
    display: flex;
    justify-content: center;
  }

  .detail-container {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid #222;
  }

  li {
    display: flex;
    align-items: center;
    padding: 30px 0;
    border-bottom: 1px solid #ddd;

    & > div:nth-of-type(1) {
      width: 150px;
      font-size: 18px;
      font-weight: bold;
      color: #b2b2b2;
    }

    & > div:nth-of-type(2) {
      font-size: 18px;
      font-weight: normal;
      color: #000;
    }
  }

  .edit-btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
`;
