import React from "react";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "layout/layout";
import Button from "components/Button/Button";
import { PAGE_LAYOUT } from "utils/constants";

import LinenTapMenu from "../LinenTapMenu";

const PaymentAppDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout page={PAGE_LAYOUT.linen}>
      <PaymentAppDetailStyled>
        <LinenTapMenu textChange={location?.state?.textChange} />
        <div className="payment-detail-wrap">
          <div className="payment-detail-header">
            <div className="payment-detail-header-text">
              <div>
                지점 <span>서울 M-Tower</span>
              </div>
              <div>
                날짜 <span>2022.09.16</span>
              </div>
              <div>
                층 <span>7F</span>
              </div>
              <div>
                롯데호텔 담당자 <span>김누리</span>
              </div>
              <div>
                협력업체 <span>대웅크린월드</span>
              </div>
            </div>
            <div className="status-btn">
              <div className="xlsx-download">엑셀로 내보내기</div>
            </div>
          </div>
          <div className="payment-add-body">
            <div className="payment-add-info-wrap">
              <div className="payment-add-info">
                <div className="payment-add-info-title">품목정보</div>
                <div className="payment-add-info-sub payment-add-info-sub1">
                  <div>품목명</div>
                  <div>규격</div>
                  <div>단가</div>
                </div>
                <div className="payment-add-info-data payment-add-info-data1">
                  <div>
                    <div>품목 1</div>
                    <div>1040*1940</div>
                    <div>₩400,000</div>
                  </div>
                </div>
              </div>
              <div className="payment-add-info">
                <div className="payment-add-info-title">7F</div>
                <div className="payment-add-info-sub payment-add-info-sub2">
                  <div>규격</div>
                  <div>단가</div>
                </div>
                <div className="payment-add-info-data payment-add-info-data2">
                  <div>
                    <div>20</div>
                    <div>₩400,000</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-add-info-total">
            <div>자산현황</div>
            <div>₩1,600,000</div>
          </div>
          <div className="grand-total">
            <div>GRAND TOTAL</div>
            <div>₩ 44,100,000</div>
          </div>
          <div className="submit-btn">
            <Button
              type="button"
              variant="tertiary"
              preset="1"
              onClick={() => navigate(-1)}
            >
              목록
            </Button>
          </div>
        </div>
      </PaymentAppDetailStyled>
    </Layout>
  );
};

export default PaymentAppDetail;

const PaymentAppDetailStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 75px 40px 40px 40px;

  .payment-detail-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    padding: 35px;
    margin-top: 30px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  }

  .payment-detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .payment-detail-header-text {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    color: #b2b2b2;

    span {
      color: #545454;
      margin-left: 5px;
    }

    & div:not(:last-of-type)::after {
      content: "";
      padding: 0 1px;
      margin: 0 16px;
      background-color: #707070;
    }
  }

  .payment-add-info-wrap {
    width: 100%;
    display: flex;
    margin-top: 30px;

    & > div:nth-of-type(1) {
      width: 60%;
      border-right: 1px solid rgba(168, 154, 139, 0.6);
    }
    & > div:nth-of-type(2) {
      width: 40%;
    }
  }

  .payment-add-info-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 62px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: rgba(168, 154, 139, 0.3);
  }

  .payment-add-info-sub {
    display: flex;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: #f9f9f9;
    text-align: center;
  }
  .payment-add-info-sub1 > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.333%;
    height: 68px;
    text-align: center;
    &:nth-of-type(2) {
      border-left: solid 1px #ebebeb;
      border-right: solid 1px #ebebeb;
    }
  }
  .payment-add-info-sub2 > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 68px;
    &:nth-of-type(2) {
      border-left: solid 1px #ebebeb;
    }
  }

  .payment-add-info-data {
    & > div {
      display: flex;
      border-bottom: solid 1px #ebebeb;
    }
  }
  .payment-add-info-data1 {
    & > div div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 68px;
      width: 33.333%;
      text-align: center;
    }
    & > div div:nth-of-type(2) {
      border-left: solid 1px #ebebeb;
      border-right: solid 1px #ebebeb;
    }
  }
  .payment-add-info-data2 {
    & > div div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 68px;
      width: 50%;
      text-align: center;
    }
    & > div div:nth-of-type(2) {
      border-left: solid 1px #ebebeb;
    }
  }

  .payment-add-info-total {
    display: flex;
    align-items: center;
    border-bottom: solid 1px #707070;

    & > div {
      display: flex;
      align-items: center;
      height: 70px;
      font-size: 18px;
      font-weight: bold;
      color: #101010;
    }

    & > div:nth-of-type(1) {
      width: 60%;
      padding-left: 130px;
      border-right: 1px solid rgba(168, 154, 139, 0.6);
    }
    & > div:nth-of-type(2) {
      justify-content: center;
      width: 40%;
    }
  }

  .grand-total {
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    border-bottom: 3px solid #444;

    & div {
      margin: 0 30px;
    }
  }

  .submit-btn {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
