import React from "react";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "layout/layout";
import Button from "components/Button/Button";
import { PAGE_LAYOUT } from "utils/constants";

import LinenTapMenu from "../LinenTapMenu";

const LaundryDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout page={PAGE_LAYOUT.linen}>
      <LaundryDetailStyled>
        <LinenTapMenu textChange={location?.state?.textChange} />
        <div className="laundry-detail-wrap">
          <div className="laundry-detail-header">
            <div className="laundry-detail-header-text">
              <div>
                지점 <span>서울 M-Tower</span>
              </div>
              <div>
                날짜 <span>2022.09.16</span>
              </div>
              <div>
                호차 <span>201</span>
              </div>
              <div>
                롯데호텔 담당자 <span>김누리</span>
              </div>
              <div>
                협력업체 <span>대웅크린월드</span>
              </div>
            </div>
            <div className="status-btn">
              <div className="xlsx-download">엑셀로 내보내기</div>
            </div>
          </div>
          <div className="laundry-detail-body">
            <div className="laundry-detail-info">
              <div className="laundry-detail-info-title">품목정보</div>
              <div className="laundry-detail-info-sub laundry-detail-info-sub1">
                <div>품목명</div>
                <div>규격</div>
                <div>품목단가</div>
                <div>세탁단가</div>
              </div>
              <div className="laundry-detail-info-data laundry-detail-info-data1">
                <div>품목 1</div>
                <div>1040*1940</div>
                <div>₩10,000</div>
                <div>₩10,000</div>
              </div>
            </div>
            <div className="laundry-detail-info2 laundry-detail-line">
              <div className="laundry-detail-info-title2">수량</div>
              <div className="laundry-detail-info-data2">40</div>
            </div>
            <div className="laundry-detail-info2 laundry-detail-line">
              <div className="laundry-detail-info-title2">단가</div>
              <div className="laundry-detail-info-data2">₩100,000</div>
            </div>
            <div className="laundry-detail-info2">
              <div className="laundry-detail-info-title2">세탁 단가</div>
              <div className="laundry-detail-info-data2">₩100,000</div>
            </div>
          </div>
          <div className="laundry-detail-total">
            <div>세탁 단가</div>
            <div>₩1,600,000</div>
          </div>
          <div className="submit-btn">
            <Button
              type="button"
              variant="tertiary"
              preset="1"
              onClick={() => navigate(-1)}
            >
              목록
            </Button>
            <span className="void-sec"></span>
            <Button
              type="button"
              variant="primary"
              preset="2"
              onClick={() => {}}
            >
              확정
            </Button>
          </div>
        </div>
      </LaundryDetailStyled>
    </Layout>
  );
};

export default LaundryDetail;
const LaundryDetailStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 75px 40px 40px 40px;

  .laundry-detail-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    padding: 35px;
    margin-top: 30px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  }

  .laundry-detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .laundry-detail-header-text {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    color: #b2b2b2;

    span {
      color: #545454;
      margin-left: 5px;
    }

    & div:not(:last-of-type)::after {
      content: "";
      padding: 0 1px;
      margin: 0 16px;
      background-color: #707070;
    }
  }

  .laundry-detail-body {
    display: flex;
    margin-top: 30px;
  }

  .laundry-detail-info {
    width: 55%;
    border-right: solid 1px #ebebeb;
  }

  .laundry-detail-info2 {
    width: 15%;
  }
  .laundry-detail-line {
    border-right: solid 1px #ebebeb;
  }
  .laundry-detail-info-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 62px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: rgba(168, 154, 139, 0.3);
  }

  .laundry-detail-info-title2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 131px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: rgba(168, 154, 139, 0.3);
  }

  .laundry-detail-info-sub {
    display: flex;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: #f9f9f9;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 68px;
    }
  }

  .laundry-detail-info-data {
    display: flex;
    font-size: 15px;
    color: #545454;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 68px;
      border-bottom: solid 1px #ebebeb;
    }
  }

  .laundry-detail-info-data2 {
    font-size: 15px;
    color: #545454;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
    border-bottom: solid 1px #ebebeb;
  }

  .laundry-detail-total {
    width: 100%;
    height: 68px;
    display: flex;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    border-bottom: 3px solid #444;

    & > div:nth-of-type(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55%;
      border-right: solid 1px #ebebeb;
    }
    & > div:nth-of-type(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45%;
    }
  }

  .submit-btn {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
