import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import RouteList from "pages/RouteList";
import Main from "pages/Main/Main";
import Login from "pages/Login/Login";
import Employee from "pages/CurrentStatus/Employee/Employee";
import EmployeeRegist from "pages/CurrentStatus/Employee/EmployeeRegist";
import EmployeeDetail from "pages/CurrentStatus/Employee/EmployeeDetail";
import EmployeeEdit from "pages/CurrentStatus/Employee/EmployeeEdit";
import Partner from "pages/CurrentStatus/Partner/Partner";
import PartnerEdit from "pages/CurrentStatus/Partner/PartnerEdit";
import PartnerDetail from "pages/CurrentStatus/Partner/PartnerDetail";
import PartTimer from "pages/CurrentStatus/PartTimer/PartTimer";
import PartTimerEdit from "pages/CurrentStatus/PartTimer/PartTimerEdit";
import PartTimerDetail from "pages/CurrentStatus/PartTimer/PartTimerDetail";
import PartTimerSupport from "pages/CurrentStatus/PartTimerSupport/PartTimerSupport";
import PartTimerSupportDetail from "pages/CurrentStatus/PartTimerSupport/PartTimerSupportDetail";
import CredentialDetail from "pages/CurrentStatus/PartTimerSupport/CredentialDetail";
import WorkStatus from "pages/WorkStatus/WorkStatus";
import CreateSchedule from "pages/WorkStatus/CreateSchedule/CreateSchedule";
import Approval from "pages/Approval/Approval";
import ApprovalRegist from "pages/Approval/ApprovalRegist/ApprovalRegist";
import ApprovalDetail from "pages/Approval/ApprovalDetail/ApprovalDetail";
import FrontOffice from "pages/Work/FrontOffice/FrontOffice";
import Housekeeping from "pages/Work/Housekeeping/Housekeeping";
import Roundley from "pages/Work/Housekeeping/Roundley/Roundley";
import Linen from "pages/Work/Housekeeping/Linen/Linen";
import InventoryProcess from "pages/Work/Housekeeping/Linen/Inventory/InventoryProcess";
import PaymentAppRegist from "pages/Work/Housekeeping/Linen/PaymentApp/PaymentAppRegist";
import PaymentAppDetail from "pages/Work/Housekeeping/Linen/PaymentApp/PaymentAppDetail";
import LaundryRegist from "pages/Work/Housekeeping/Linen/Laundry/LaundryRegist";
import LaundryDetail from "pages/Work/Housekeeping/Linen/Laundry/LaundryDetail";
import FoodBeverage from "pages/Work/FoodBeverage/FoodBeverage";
import Security from "pages/Work/Security/Security";
import Facilities from "pages/Work/Facilities/Facilities";
import CustomerService from "pages/Work/CustomerService/CustomerService";
import Plan from "pages/Work/Plan/Plan";
import BusinessSupport from "pages/Work/BusinessSupport/BusinessSupport";
import TechnicalSupport from "pages/Work/TechnicalSupport/TechnicalSupport";
import HEON from "pages/Work/HEON/HEON";
import HEONItemManage from "pages/Work/HEON/HEONItemManage";
import SendEventNotices from "pages/Work/SendEventNotices/SendEventNotices";
import PartTimerRegistList from "pages/Work/PartTimerRegist/PartTimerRegistList";
import PartTimerRegist from "pages/Work/PartTimerRegist/PartTimerRegist";
import Mail from "pages/Mail/Mail";
import MailDetail from "pages/Mail/MailDetail";
import Mypage from "pages/Mypage/Mypage";
import MypageHistoryDetail from "pages/Mypage/MypageHistoryDetail";
import EventNotice from "pages/Notice/EventNotice/EventNotice";
import EventNoticeRegist from "pages/Notice/EventNotice/EventNoticeRegist";
import EventNoticeEdit from "pages/Notice/EventNotice/EventNoticeEdit";
import EventNoticeDetail from "pages/Notice/EventNotice/EventNoticeDetail";
import CompanyNotice from "pages/Notice/CompanyNotice/CompanyNotice";
import CompanyNoticeRegist from "pages/Notice/CompanyNotice/CompanyNoticeRegist";
import CompanyNoticeEdit from "pages/Notice/CompanyNotice/CompanyNoticeEdit";
import CompanyNoticeDetail from "pages/Notice/CompanyNotice/CompanyNoticeDetail";
import AuthSetting from "pages/AuthSetting/AuthSetting";
import DefaultSetting from "pages/Setting/DefaultSetting";
import RoomInfoSetting from "pages/Setting/RoomInfoSetting";
import PaymentSetting from "pages/Setting/PaymentSetting/PaymentSetting";
import PaymentSettingEdit from "pages/Setting/PaymentSetting/PaymentSettingEdit";
import CodeSetting from "pages/Setting/CodeSetting/CodeSetting";
import CodeSettingEdit from "pages/Setting/CodeSetting/CodeSettingEdit";
import Help from "pages/Help/Help";

import Error404 from "pages/Error404/Error404";
import ErrorMobile from "pages/Error404/ErrorMobile";
import AuthGuard from "components/Guard/AuthGuard";
import AdminGuard from "components/Guard/AdminGuard";
import RoleGuard from "components/Guard/RoleGuard";

const routesConfig = [
  {
    path: "/",
    element:
      process.env.NODE_ENV === "development"
        ? RouteList
        : () => <Navigate replace to="/login" />,
  },
  {
    path: "*",
    element: () => <Navigate replace to="/404" />,
  },
  {
    path: "/404",
    element: Error404,
  },
  {
    path: "/home",
    element: Main,
  },
  {
    path: "/login",
    element: Login,
  },
  {
    path: "/curr_status/employee",
    element: Employee,
  },
  {
    path: "/curr_status/employee/regist",
    element: EmployeeRegist,
  },
  {
    path: "/curr_status/employee/edit/:id",
    element: EmployeeEdit,
  },
  {
    path: "/curr_status/employee/detail/:id",
    element: EmployeeDetail,
  },
  {
    path: "/curr_status/partner",
    element: Partner,
  },
  {
    path: "/curr_status/partner/edit/:id",
    element: PartnerEdit,
  },
  {
    path: "/curr_status/partner/detail/:id",
    element: PartnerDetail,
  },
  {
    path: "/curr_status/part_timer",
    element: PartTimer,
  },
  {
    path: "/curr_status/part_timer/detail/:id",
    element: PartTimerDetail,
  },
  {
    path: "/curr_status/part_timer/edit/:id",
    element: PartTimerEdit,
  },
  {
    path: "/part_timer_support",
    element: PartTimerSupport,
  },
  {
    path: "/curr_status/part_timer_support/detail/:id",
    element: PartTimerSupportDetail,
  },
  {
    path: "/curr_status/part_timer_support/credential/:id",
    element: CredentialDetail,
  },
  {
    path: "/work_status",
    element: WorkStatus,
  },
  {
    path: "/work_status/create_schedule",
    element: CreateSchedule,
  },
  {
    path: "/approval",
    element: Approval,
  },
  {
    path: "/approval/regist",
    element: ApprovalRegist,
  },
  {
    path: "/approval/detail/:id",
    element: ApprovalDetail,
  },
  {
    path: "/work/room",
    element: FrontOffice,
  },
  {
    path: "/work/house",
    element: Housekeeping,
  },
  {
    path: "/work/house/roundley",
    element: Roundley,
  },
  {
    path: "/linen",
    element: Linen,
  },
  {
    path: "/process",
    element: InventoryProcess,
  },
  {
    path: "/work/house/linen/payment",
    element: PaymentAppRegist,
  },
  {
    path: "/work/house/linen/payment/detail/:id",
    element: PaymentAppDetail,
  },
  {
    path: "/work/house/linen/laundry",
    element: LaundryRegist,
  },
  {
    path: "/work/house/linen/laundry/detail/:id",
    element: LaundryDetail,
  },
  {
    path: "/work/fandb",
    element: FoodBeverage,
  },
  {
    path: "/work/security",
    element: Security,
  },
  {
    path: "/work/facilities",
    element: Facilities,
  },
  {
    path: "/work/customer",
    element: CustomerService,
  },
  {
    path: "/work/plan",
    element: Plan,
  },
  {
    path: "/work/business",
    element: BusinessSupport,
  },
  {
    path: "/work/technical",
    element: TechnicalSupport,
  },
  {
    path: "/work/heon",
    element: HEON,
  },
  {
    path: "/work/heon/manage",
    element: HEONItemManage,
  },
  {
    path: "/work/send_event",
    element: SendEventNotices,
  },
  {
    path: "/work/part_timer",
    element: PartTimerRegistList,
  },
  {
    path: "/work/part_timer/regist",
    element: PartTimerRegist,
  },
  {
    path: "/mail",
    element: Mail,
  },
  {
    path: "/mail/:id",
    element: MailDetail,
  },
  {
    path: "/mypage",
    element: Mypage,
  },
  {
    path: "/mypage/event/detail/:id",
    element: MypageHistoryDetail,
  },
  {
    path: "/notice/event",
    element: EventNotice,
  },
  {
    path: "/notice/event/regist",
    element: EventNoticeRegist,
  },
  {
    path: "/notice/event/edit/:id",
    element: EventNoticeEdit,
  },
  {
    path: "/notice/event/detail/:id",
    element: EventNoticeDetail,
  },
  {
    path: "/notice/company",
    element: CompanyNotice,
  },
  {
    path: "/notice/company/regist",
    element: CompanyNoticeRegist,
  },
  {
    path: "/notice/company/edit/:id",
    element: CompanyNoticeEdit,
  },
  {
    path: "/notice/company/detail/:id",
    element: CompanyNoticeDetail,
  },
  {
    path: "/auth_setting",
    element: AuthSetting,
  },
  {
    path: "/setting/default",
    element: DefaultSetting,
  },
  {
    path: "/setting/room",
    element: RoomInfoSetting,
  },
  {
    path: "/setting/payment",
    element: PaymentSetting,
  },
  {
    path: "/setting/payment/edit",
    element: PaymentSettingEdit,
  },
  {
    path: "/setting/code",
    element: CodeSetting,
  },
  {
    path: "/setting/code/edit/:id",
    element: CodeSettingEdit,
  },
  {
    path: "/help",
    element: Help,
  },
];

const renderRoutes = (routes) => (
  <Routes>
    {routes
      .filter((route) => !!route)
      .map((route, i) => {
        const Guard = route.guard || Fragment;
        const Role = route.role || Fragment;
        const Admin = route.admin || Fragment;
        const Element = route.element;
        const isMobile = window.innerWidth;

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Role>
                  <Admin>
                    {isMobile < 1300 ? <ErrorMobile /> : <Element />}
                  </Admin>
                </Role>
              </Guard>
            }
          />
        );
      })}
  </Routes>
);

const RoutesHandler = () => {
  return renderRoutes(routesConfig);
};

export default RoutesHandler;
