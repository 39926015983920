import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const Root = styled.div`
  padding: 10px;

  a {
    display: block;
    margin: 10px 0;
  }
`;

/**
 * @file (개발용) 페이지 분기 페이지
 * @author TW.Lee
 */
const Route = () => {
  return (
    <Root>
      <Link to="/home">홈</Link>
      <Link to="/login">로그인</Link>
      <Link to="/curr_status/employee">임직원 현황</Link>
      <Link to="/curr_status/part_timer_support">파트타이머 지원자 현황</Link>
      <Link to="/curr_status/part_timer">파트타이머 현황</Link>
      <Link to="/curr_status/partner">협력사 현황</Link>
      <Link to="/work_status">근무 현황</Link>
      <Link to="/approval">결재 현황</Link>
      <Link to="/work/front_office">객실</Link>
      <Link to="/work/house">객실 정비</Link>
      <Link to="/work/fandb">식음</Link>
      <Link to="/work/security">안전관리</Link>
      <Link to="/work/facilities">시설관리</Link>
      <Link to="/work/customer">고객응대</Link>
      <Link to="/work/plan">기획</Link>
      <Link to="/work/business">경영 지원</Link>
      <Link to="/work/technical">기술 지원</Link>
      <Link to="/work/heon">HE:ON</Link>
      <Link to="/work/send_event">행사 공지 발송</Link>
      <Link to="/mail/received">받은 메일함</Link>
      <Link to="/mail/write">메일 작성</Link>
      <Link to="/mail/sent">보낸 메일함</Link>
      <Link to="/mail/trash">휴지통</Link>
      <Link to="/message">메시지</Link>
      <Link to="/notice/event">행사공지 목록</Link>
      <Link to="/notice/company">사내 공지사항 · 경조사</Link>
      <Link to="/auth_setting">권한 설정</Link>
      <Link to="/setting/default">기본 환경설정</Link>
      <Link to="/setting/room">객실 정보 설정</Link>
      <Link to="/setting/payment">기본 결제선 설정</Link>
      <Link to="/setting/code">코드 설정</Link>
      <Link to="/help">도움말</Link>
    </Root>
  );
};

export default Route;
