import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import request from "api/request";
import { useNavigate, useLocation } from "react-router-dom";

import Layout from "layout/layout";

import useSubmitAlert from "hooks/useSubmitAlert";

import Button from "components/Button/Button";

import { PAGE_LAYOUT } from "utils/constants";
import { EventDetailProps } from "types/registType";

const EventNoticeDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { noti } = useSubmitAlert();
  const { REACT_APP_API } = process.env;
  const [events, setEvents] = useState<EventDetailProps>();

  //useEffect(() => {
  //  request("get", `/posts/${location.state?.eventId}`, {}, {})
  //    .then((res) => {
  //      setEvents(res?.data.result);
  //    })
  //    .catch((err) => {
  //      throw err;
  //    });
  //}, []);

  //const handleEventDelete = async () => {
  //  await request("delete", `/posts/${location.state?.eventId}`, {}, {})
  //    .then((res) => {
  //      navigate("/notice/event", { replace: true });
  //    })
  //    .catch((err) => {
  //      throw err;
  //    });
  //};

  return (
    <Layout page={PAGE_LAYOUT.event_notice}>
      <EventNoticeDetailStyled>
        <div className="status-wrap">
          <div className="event-detail-wrap">
            <div>
              <h3>&#183; 행사 공지 상세보기 &#183;</h3>
              <ul>
                <li className="detail-wrap">
                  <div>제목</div>
                  <div>{events?.title}</div>
                </li>
                <li className="detail-wrap">
                  <div>날짜</div>
                  <div>
                    {events?.start_date?.substring(0, 10)} ~{" "}
                    {events?.end_date?.substring(0, 10)}
                  </div>
                </li>
                <li className="detail-wrap">
                  <div>링크</div>
                  <div>
                    <a href={events?.web_site} target="_blank" rel="noreferrer">
                      행사공지 링크
                    </a>
                  </div>
                </li>
                <li className="image-sec">
                  <div className="image-inner">
                    <div>이미지</div>
                    <div>
                      <a
                        href={`${REACT_APP_API}/files/${events?.posts_files.id}`}
                        download
                      >
                        {events?.posts_files.file_name}.
                        {events?.posts_files.extension}
                      </a>
                    </div>
                  </div>
                  <div className="image-con">
                    <img
                      src={
                        events?.posts_files
                          ? `${REACT_APP_API}/files/${events?.posts_files.id}`
                          : ""
                      }
                      alt="행사공지 등록 이미지"
                    />
                  </div>
                </li>
                <li className="detail-wrap notice-content-wrap">
                  <div>내용</div>
                  <div>{events?.content}</div>
                </li>
              </ul>
            </div>

            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                목록
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="secondary"
                preset="1"
                onClick={() => {
                  const isDelete = noti(
                    "해당 게시글을 삭제하시겠습니까?",
                    "예",
                    "아니오"
                  );
                  const getData = () => {
                    isDelete.then((res: boolean) => {
                      //if (res) handleEventDelete();
                    });
                  };
                  getData();
                }}
              >
                삭제
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="primary"
                preset="2"
                onClick={() =>
                  navigate(`/notice/event/edit/${location.state?.eventId}`, {
                    state: { eventId: location.state?.eventId },
                  })
                }
              >
                수정
              </Button>
            </div>
          </div>
        </div>
      </EventNoticeDetailStyled>
    </Layout>
  );
};

export default EventNoticeDetail;

const EventNoticeDetailStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    padding: 35px;
  }

  .event-detail-wrap {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid #222;
  }

  .detail-wrap {
    display: flex;
    align-items: center;
    padding: 30px 0;
    border-bottom: 1px solid #ddd;

    & > div:nth-of-type(1) {
      width: 150px;
      font-size: 18px;
      font-weight: bold;
      color: #b2b2b2;
    }

    & > div:nth-of-type(2) {
      font-size: 18px;
      font-weight: normal;
      color: #000;

      a {
        font-size: 18px;
        font-weight: normal;
        text-decoration: underline;
        color: #c09260;
      }
    }
  }

  .image-sec {
    display: block;
    padding: 0;
  }
  .image-inner {
    display: flex;
    padding: 30px 0;

    & > div:nth-of-type(1) {
      width: 150px;
      font-size: 18px;
      font-weight: bold;
      color: #b2b2b2;
    }

    & > div:nth-of-type(2) {
      a {
        font-size: 18px;
        font-weight: normal;
        text-decoration: underline;
        color: #c09260;
      }
    }
  }

  .image-con {
    width: 100%;
    img {
      width: 100%;
    }
  }

  .notice-content-wrap {
    flex-direction: column;
    & > div:nth-of-type(1) {
      width: 100%;
      padding-bottom: 30px;
    }
    & > div:nth-of-type(2) {
      width: 100%;
      line-height: 30px;
    }
  }

  .submit-btn {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
