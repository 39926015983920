import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import mailApi from "api/mail";
import usePageSlice from "hooks/usePageSlice";
import Search from "components/Search/Search";
import Pagination from "components/Pagination/Pagination";

import NoDataSVG from "assets/images/no_data_ico.svg";
import IcDropBtnSVG from "assets/images/icon_drop.svg";

import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import { TapMenuProps } from "types/commonTypes";

const defaultValues = {
  search: "",
};

const MailSentForm = ({ textChange }: TapMenuProps) => {
  const navigate = useNavigate();
  const [listOption, setListOption] = useState("created_at");
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [checkData, setCheckData] = useState<any | undefined>([]);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isKeyword, setIsKeyword] = useState<string>("");

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  //useEffect(() => {
  //  mailApi
  //    .mailsList({ types: "SENT", keywords: isKeyword })
  //    .then((res: any) => {
  //      if (res.status === 200) {
  //        setTableData(res.data.result.data);
  //        setTotalCount(res.data.result.total_elements);
  //        setTotalPages(Math.ceil(res.data.result.total_elements / 10));
  //      }
  //    })
  //    .catch((err) => {
  //      throw err;
  //    });
  //}, [isDelete, isKeyword]);

  const pageArr: any = usePageSlice(tableData);

  //useEffect(() => {
  //  const newArr: any = [];
  //  for (let i = 0; i < pageArr[page - 1]?.length; i++) {
  //    const newObj = Object.assign({}, pageArr[page - 1][i], {
  //      checked: false,
  //    });
  //    newArr.push(newObj);
  //  }
  //}, [pageArr, page]);

  // 리스트 체크박스
  const handleCheck = (e: any, item?: any) => {
    if (e.target.name === "All") {
      if (e.target.checked) {
        const allFilter = pageArr[page - 1].map((e: any) => {
          e.checked = true;
          return e.id;
        });
        setCheckData(allFilter);
      } else {
        pageArr[page - 1].map((e: any) => (e.checked = false));
        setCheckData([]);
      }
    } else {
      if (e.target.checked) {
        item.checked = true;
        setCheckData([...checkData, item.id]);
      } else {
        item.checked = false;
        setCheckData(checkData.filter((v: number) => v !== item.id));
      }
    }
  };

  // 보낸 메일 삭제 (휴지통)
  const handleDelList = async () => {
    Promise.all(
      checkData.map((id: number) => {
        return mailApi
          .editMails({ mailId: id, withdraw: true })
          .then((res: any) => {
            if (res.status === 200) {
              setIsDelete((prev) => !prev);
              setCheckData([]);
            }
          })
          .catch((err) => err);
      })
    );
  };

  // 상세페이지
  const handleDetailMail = async (mailId: number) => {
    await mailApi
      .editMails({ mailId: mailId, readed: true })
      .then((res: any) => {
        if (res.status === 200) {
          navigate(`/mail/${mailId}`, {
            state: {
              mailId: mailId,
              type: "SENT",
              textChange: textChange,
            },
          });
        }
      })
      .catch((err) => err);
  };

  const handleFormSubmit = () => {
    handleSubmit(
      (res: any) => {
        setIsKeyword(values.search);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <MailSentFormStyled>
      <div className="mail-wrap">
        <div className="mail-header">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
            }}
          >
            <Controller
              control={control}
              name="search"
              render={({ field }) => (
                <Search
                  {...field}
                  placeholder="검색어를 입력하세요."
                  handleFormSubmit={handleFormSubmit}
                />
              )}
            />
          </form>
          <div className="status-btn">
            <div className="mail-del-btn" onClick={() => handleDelList()}>
              선택 삭제
            </div>
          </div>
        </div>
        <div className="mail-body">
          <table>
            <thead>
              <tr>
                <th className="mail-child1">
                  <input
                    type="checkbox"
                    name="All"
                    onChange={(e: any) => handleCheck(e)}
                  />
                </th>
                <th className="mail-child2">
                  수신자 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </th>
                <th className="mail-child3">
                  제목 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </th>
                <th className="mail-child4">
                  발신 날짜 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </th>
                <th className="mail-child5">
                  발신 시간 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData?.length > 0 ? (
                pageArr[page - 1]?.map((item: any, i: number) => {
                  return (
                    <tr key={item.id} onClick={() => handleDetailMail(item.id)}>
                      <td className="mail-child1">
                        <input
                          type="checkbox"
                          name={item.id}
                          onChange={(e: any) => handleCheck(e, item)}
                          onClick={(e: any) => e.stopPropagation()}
                          checked={item.checked || false}
                        />
                      </td>
                      <td className="mail-child2">{item?.to_user.signname}</td>
                      <td className="mail-child3">{item?.title}</td>
                      <td className="mail-child4">
                        {dayjs(item?.created_at).format("YYYY.MM.DD")}
                      </td>
                      <td className="mail-child5">
                        {dayjs(item?.created_at).format("hh:mm A")}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="no-data-wrap">
                  <td colSpan={5}>
                    <div>
                      <div className="no-data-img">
                        <img src={NoDataSVG} alt="검색 결과 없음 이미지" />
                      </div>
                      <div>검색 결과가 없습니다.</div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pagenation">
          <Pagination
            page={page}
            count={totalPages}
            list={tableData.length}
            onChangePage={setPage}
          />
        </div>
      </div>
    </MailSentFormStyled>
  );
};

export default MailSentForm;

const MailSentFormStyled = styled.div`
  width: 100%;

  .no-data-wrap {
    text-align: center;

    td {
      padding: 200px 0;
      font-size: 24px;
      font-weight: normal;
      color: #444;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
