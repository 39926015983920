import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/layout";

import Select from "components/Select/Select";

import { PAGE_LAYOUT, EmployeeSelectOptions } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

const defaultValues = {
  select: "All",
};

const RoomInfoSetting = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <Layout page={PAGE_LAYOUT.setting}>
      <RoomInfoSettingStyled>
        <div className="status-wrap">
          <div className="room-info-wrap">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleFormSubmit();
                handleSubmit(
                  () => {},
                  (err) => {}
                );
              }}
            >
              <div className="search">
                <div className="search-select room-info-sec">
                  <div className="room-info-sec-text">지점</div>
                  <Controller
                    control={control}
                    name="select"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="전체"
                        w="130"
                        h="52"
                        options={EmployeeSelectOptions}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="search">
                <div className="search-select room-info-sec">
                  <div className="room-info-sec-text">층</div>
                  <Controller
                    control={control}
                    name="select"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="전체"
                        w="130"
                        h="52"
                        options={EmployeeSelectOptions}
                      />
                    )}
                  />
                </div>
              </div>
            </form>
            <div className="room-info-btn">
              <div className="room-info-del-btn">선택 삭제</div>
              <div className="room-info-list-btn">린넨 업무 현황</div>
            </div>
          </div>
        </div>
      </RoomInfoSettingStyled>
    </Layout>
  );
};

export default RoomInfoSetting;

const RoomInfoSettingStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    padding: 35px;
  }

  .room-info-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;

    form {
      display: flex;
    }
  }

  .room-info-sec {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .room-info-sec-text {
    font-size: 16px;
    font-weight: bold;
    color: #222;
    margin-right: 12px;
  }

  .room-info-btn {
    display: flex;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .room-info-del-btn {
    width: 100px;
    height: 44px;
    border-radius: 6px;
    background-color: #444;
    font-size: 15px;
    font-weight: normal;
    color: #fff;
  }

  .room-info-list-btn {
    width: 146px;
    height: 44px;
    margin-left: 10px;
    border-radius: 6px;
    background-color: #c09260;
    font-size: 15px;
    font-weight: normal;
    color: #fff;
  }
`;
