import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import authApi from "api/auth";

import { PageLayoutTypes } from "types/commonTypes";

import LogoIcSVG from "assets/images/Frame.png";
import HeaderBgPNG from "assets/images/bg@3x.png";
import LogoutSVG from "assets/images/logout_ic.svg";

const Header = ({ headingText, headingContent }: PageLayoutTypes) => {
  const navigate = useNavigate();
  const user = useSelector((s: any) => s.auth);
  const [role, setRole] = useState<string>("");

  console.log();

  useEffect(() => {
    switch (user.userRole) {
      case "EMPLOYEE":
        if (user.userInfo.user_employee_info.user_role === "ADMIN") {
          setRole("Admin");
        }
        if (user.userInfo.user_employee_info.user_role === "TIME_LEADER") {
          setRole("Manager");
        }
        if (user.userInfo.user_employee_info.user_role === "FULLTIME_WORKER") {
          setRole("");
        }
        if (user.userInfo.user_employee_info.user_role === "CONTRACT_WORKER") {
          setRole("");
        }
        break;
      case "PARTNER":
        setRole("Partners");
        break;
      case "PARTTIMER":
        setRole("Part-timer");
        break;
      default:
        break;
    }
  }, [user]);

  return (
    <HeaderStyled>
      <div className="header-logo" onClick={() => navigate("/home")}>
        <img src={LogoIcSVG} alt=" 로고" />
        <h1>디지털헬스케어</h1>
      </div>
      <div className="header-wrap">
        <div className="header-content">
          <span className="header-status">{role}</span>
          <div>
            {headingText}
            <span className="header-welcom">{headingContent}</span>
          </div>
        </div>
        <div className="header-item">
          <div
            onClick={() => {
              //authApi.logout();
              //navigate("/login", { replace: true });
            }}
          >
            Dark Mode
          </div>
          <span style={{ marginLeft: "15px" }}></span>
          <div
            onClick={() => {
              //authApi.logout();
              //navigate("/login", { replace: true });
            }}
          >
            <img src={LogoutSVG} alt="로그아웃 이미지" />
            View Site
          </div>
        </div>
      </div>
    </HeaderStyled>
  );
};

export default Header;

const HeaderStyled = styled.div`
  position: fixed;
  z-index: 9999999999;
  top: 0;
  width: 100vw;
  display: flex;

  .header-logo {
    width: 250px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    h1 {
      font-size: 28px;
      font-weight: bold;
      color: #fff;
    }

    img {
      width: 40px;
      margin-right: 6px;
      margin-left: -10px;
    }
  }

  .header-wrap {
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100vw - 250px);
    height: 140px;
    background-color: #fff;
    /*background: url(${HeaderBgPNG}) no-repeat center/cover;*/
  }

  .header-content {
    font-size: 32px;
    font-weight: 500;
    font-family: Arita-buri-SemiBold;
    color: #000;
    padding-left: 56px;

    .header-status {
      display: block;
      font-family: Arita-buri-_OTF;
      font-size: 18px;
      font-weight: 400;
      color: #a0adff;
      margin-bottom: 10px;
    }

    .header-welcom {
      font-size: 18px;
      margin-top: 10px;
      margin-left: 16px;
    }
  }

  .header-item {
    display: flex;
    align-items: flex-end;
    height: 140px;
    font-size: 16px;
    font-weight: normal;
    color: #000;
    margin: 0 40px 35px 0;
    cursor: pointer;

    div {
      display: flex;
      align-items: center;
    }

    img {
      margin-right: 4px;
      margin-top: -2px;
    }
  }

  @media screen and (max-width: 1600px) {
    .header-logo {
      width: 220px;
    }
    .header-wrap {
      width: calc(100vw - 220px);
    }
  }
`;
