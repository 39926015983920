import * as yup from "yup";

export default yup.object().shape({
  employeeName: yup.string().required("성명을 입력하세요."),
  employeeBOD: yup.string().required("생년월일을 선택하세요."),
  employeeGender: yup.object().nullable().required("성별을 선택하세요."),
  employeeEmail: yup.string().required("이메일을 입력하세요."),
  employeePlace: yup.object().nullable().required("근무지를 선택하세요."),
  employeeDepartment: yup.object().nullable().required("부서를 선택하세요."),
  employeePosition: yup.object().nullable().required("직책을 선택하세요."),
  employeeRank: yup.object().nullable().required("직급을 선택하세요."),
  employeePhone1: yup.object().nullable().required("휴대폰 번호를 입력하세요."),
  employeePhone2: yup.string().required(""),
  employeePhone3: yup.string().required(""),
  employeeBank1: yup.object().nullable().required("은행 선택"),
  employeeBank2: yup.string().required("계좌번호를 입력하세요."),
  zipCode: yup.string().required("우편번호를 입력하세요."),
  address: yup.string().required("주소를 입력하세요."),
  detailAddress: yup.string().required("상세 주소를 입력하세요."),
  employeeStatus: yup.object().nullable().required("상태를 선택하세요."),
  employment: yup.string().required("입사일을 선택하세요."),
  employeeWorkForm: yup.object().nullable().required("근로 형태를 선택하세요."),
  employeeContract: yup.string().required("계약 기간을 입력하세요."),
  employeeContractSalary: yup.string().required("계약 연봉을 입력하세요."),
  employeeCurrentSalary: yup.string().required("현재 연봉을 입력하세요."),
  employeeContent: yup.string().required("내용을 입력하세요."),
});
