import { useState, useEffect } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";

import userApis from "api/user";
import permiApis from "api/permissions";
import codeApis from "api/code";
import { setUserRole, setUserInfo, setPermissions } from "store/auth";
import {
  setHEBR,
  setHEPR,
  setSPOT,
  setDEPT,
  setRANK,
  setPOSI,
  setCOFI,
  setRECE,
  setCOLL,
  setCLAS,
  setDELI,
} from "store/code";
import { setMode } from "store/theme";

import AlertModal from "components/Modal/AlertModal";
import SubmitAlertModal from "components/Modal/SubmitAlertModal";

// dayjs
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { ThemeStyle } from "utils/theme/themeStyle";
import { ThemeProvider } from "utils/context/themeProvider";
import { MASTER_CODE_OPTIONS } from "utils/constants";

import CommonStyle from "assets/styles/common";
import "assets/styles/fonts.css";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";

import RoutesHandler from "./Routes";
import GlobalStyle from "./GlobalStyle";

import "assets/styles/fonts.css";
import "assets/styles/variables.css";
import "react-datepicker/dist/react-datepicker.css";

Modal.setAppElement("#root");

// 타임존 설정
dayjs.extend(weekday);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.tz.setDefault("Asia/Seoul");

let currentPath = "";

const App = () => {
  const location = useLocation();
  //const dispatch = useDispatch();
  //const [SETTINGSCODE, setSETTINGSCODE] = useState();

  //const targetStorage = localStorage.getItem("accessToken")
  //  ? localStorage
  //  : sessionStorage;
  //const accessToken = targetStorage.getItem("accessToken");
  //const theme = localStorage.getItem("theme");

  useEffect(() => {
    if (currentPath === location.pathname) window.location.reload();

    currentPath = location.pathname;
  }, [location]);

  //useEffect(() => {
  //  dispatch(setMode(theme));
  //}, [theme]);

  //useEffect(() => {
  //  if (accessToken) {
  //    // 권한 세팅
  //    permiApis.getPermissions().then((res) => {
  //      dispatch(setPermissions(res?.data.result));
  //      setSETTINGSCODE(res?.data.result.SETTINGS_CODE);
  //    });
  //  }
  //}, [accessToken]);

  //useEffect(() => {
  //  if (accessToken) {
  //    // 유저 정보 세팅
  //    userApis.authInfo().then((res) => {
  //      dispatch(setUserRole(res?.data.result.user_role));
  //      dispatch(setUserInfo(res?.data.result));
  //    });
  //  }
  //}, [accessToken]);

  //const handleCodeSetting = () => {
  //  // 코드 세팅
  //  Promise.all(
  //    MASTER_CODE_OPTIONS.map((data: any) => {
  //      const payload = {
  //        type: data.value,
  //      };
  //      return codeApis.getCodeTable({ payload: payload }).then((res: any) => {
  //        if (res.status === 200) {
  //          switch (data.value) {
  //            case "HEBR":
  //              dispatch(setHEBR(res?.data.result.reverse()));
  //              break;
  //            case "HEPR":
  //              dispatch(setHEPR(res?.data.result.reverse()));
  //              break;
  //            case "SPOT":
  //              dispatch(setSPOT(res?.data.result.reverse()));
  //              break;
  //            case "DEPT":
  //              dispatch(setDEPT(res?.data.result.reverse()));
  //              break;
  //            case "RANK":
  //              dispatch(setRANK(res?.data.result.reverse()));
  //              break;
  //            case "POSI":
  //              dispatch(setPOSI(res?.data.result.reverse()));
  //              break;
  //            case "COFI":
  //              dispatch(setCOFI(res?.data.result.reverse()));
  //              break;
  //            case "RECE":
  //              dispatch(setRECE(res?.data.result.reverse()));
  //              break;
  //            case "COLL":
  //              dispatch(setCOLL(res?.data.result.reverse()));
  //              break;
  //            case "CLAS":
  //              dispatch(setCLAS(res?.data.result.reverse()));
  //              break;
  //            case "DELI":
  //              dispatch(setDELI(res?.data.result.reverse()));
  //              break;
  //            default:
  //              break;
  //          }
  //        }
  //      });
  //    })
  //  );
  //};

  //useEffect(() => {
  //  if (SETTINGSCODE) {
  //    handleCodeSetting();
  //  }
  //}, [SETTINGSCODE]);

  return (
    <AppStyled>
      <ThemeProvider>
        <GlobalStyle />
        <CommonStyle />
        <ThemeStyle />
        <AlertModal />
        <SubmitAlertModal />
        <RoutesHandler />
      </ThemeProvider>
    </AppStyled>
  );
};

export default App;

const AppStyled = styled.div`
  font-family: "Pretendard";
`;
