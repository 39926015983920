import { useRef, useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";

import Select from "components/Select/Select";
import Search from "components/Search/Search";
import ProfileImage from "components/Profile/Profile";
import Pagination from "components/Pagination/Pagination";

import IcDropBtnSVG from "assets/images/icon_drop.svg";
import IconCalSVG from "assets/images/icon_cal.svg";

import { EmployeeSelectOptions } from "utils/constants";
import { TestTable5, TestScrollHeader5, TestScroll5 } from "utils/testdata";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import "react-dayjs-picker/dist/index.css";

const defaultValues = {
  selectDate: "",
  selectPlace: "All",
  searchSelect: "All",
  searchInput: "",
};

const DataMode = () => {
  const navigate = useNavigate();
  const scrollContentRef = useRef<HTMLDivElement | null>(null);
  const [page, setPage] = useState(1);
  const [sWidth, setSWidth] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [searchStatus, setSearchStatus] = useState<boolean>(false);
  const [openDate, setOpenDate] = useState(false);

  const fixed = FixedWrap();
  const scroll = ScrollWrap(sWidth);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    setValue("selectDate", dayjs().format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    if (scrollContentRef.current)
      setSWidth(scrollContentRef?.current?.scrollWidth);
  }, [scrollContentRef]);

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <div className="work-status-wrap">
      <div className="work-status-inner">
        <div className="work-status-top">
          <div className="work-status-day">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleFormSubmit();
                handleSubmit(
                  () => {},
                  (err) => {}
                );
              }}
            >
              <div className="search-select date-input">
                <div
                  className="search-date-image"
                  onClick={() => {
                    setOpenDate(true);
                  }}
                >
                  <img src={IconCalSVG} alt="캘린더" />
                </div>
                <div className="header-text">날짜</div>
                <Controller
                  control={control}
                  name="selectDate"
                  render={({ field }) => (
                    <DatePicker
                      isOpen={openDate}
                      setIsOpen={setOpenDate}
                      popoverPositions={["bottom"]}
                      onSelect={(date) => {
                        field.onChange(dayjs(date).format("YYYY-MM-DD"));
                      }}
                      value={field.value}
                      closeOnSelect={true}
                      onChange={() => {}}
                    />
                  )}
                />
              </div>
              <div className="search-select">
                <div className="header-text">근무지</div>
                <Controller
                  control={control}
                  name="selectPlace"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="전체"
                      w="130"
                      h="52"
                      options={EmployeeSelectOptions}
                    />
                  )}
                />
              </div>
              <div className="search">
                <div className="search-select">
                  <div className="header-text">검색</div>
                  <Controller
                    control={control}
                    name="searchSelect"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="전체"
                        w="130"
                        h="52"
                        options={EmployeeSelectOptions}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="searchInput"
                    render={({ field }) => (
                      <Search
                        {...field}
                        w="300px"
                        placeholder="검색어를 입력하세요."
                        handleFormSubmit={handleFormSubmit}
                      />
                    )}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="work-status-option">
            <div className="xlsx-download">엑셀로 내보내기</div>
            <div
              className="status-regist-btn"
              onClick={() => navigate("/work_status/create_schedule")}
            >
              스케줄 생성
            </div>
          </div>
        </div>
        <div>
          <div className="status-table">
            <div css={fixed}>
              <div className="fixed-table">
                <div>
                  현재 근무 상황{" "}
                  <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </div>
                <div>
                  성명 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </div>
                <div>
                  직책 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </div>
                <div>
                  직급 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </div>
                <div>
                  부서 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                </div>
              </div>
              <div className="fixed-data">
                {TestTable5.map((item, i) => {
                  return (
                    <div key={i}>
                      {item.status === "done" && (
                        <WorkDone>
                          <span>근무완료</span>
                        </WorkDone>
                      )}
                      {item.status === "miss" && (
                        <WorkMiss>
                          <span>퇴근누락</span>
                        </WorkMiss>
                      )}
                      {item.status === "late" && (
                        <WorkLate>
                          <span>지각</span>
                        </WorkLate>
                      )}
                      {item.status === "over" && (
                        <WorkOver>
                          <span>초과근무</span>
                        </WorkOver>
                      )}
                      {item.status === "none" && (
                        <WorkNone>
                          <span>일정없음</span>
                        </WorkNone>
                      )}
                      {item.status === "vacation" && (
                        <WorkVacation>
                          <span>휴가</span>
                        </WorkVacation>
                      )}
                      {item.status === "early" && (
                        <WorkEarly>
                          <span>조퇴</span>
                        </WorkEarly>
                      )}
                      <div className="fixed-data-profile">
                        <ProfileImage
                          width="28px"
                          height="28px"
                          fileId={i}
                          name={item.name}
                          isPopup={true}
                        />
                        <span>{item.name}</span>
                      </div>
                      <div>{item.level}</div>
                      <div>{item.position}</div>
                      <div>{item.department}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="fixed-line"></div>
            <div css={scroll}>
              <div className="scroll-table">
                <div>
                  {TestScrollHeader5.map((item, i) => {
                    return (
                      <div
                        key={i}
                        css={{
                          width: `${scrollContentRef?.current?.children[0].children[i].clientWidth}px`,
                          minWidth: "100px",
                        }}
                      >
                        {item} <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="scroll-data" ref={scrollContentRef}>
                {TestScroll5.map((item, i) => {
                  return (
                    <div key={i}>
                      <div>{item.empNum}</div>
                      <div>{item.place}</div>
                      {item.isWorkPlace ? (
                        <div>{item.workPlace}</div>
                      ) : (
                        <div>-</div>
                      )}
                      <div className="increase-width">{item.goto}</div>
                      <div className="increase-width">{item.leave}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="pagenation">
          <Pagination page={page} count={60} list={10} onChangePage={setPage} />
        </div>
      </div>
    </div>
  );
};

export default DataMode;

const FixedWrap = () => {
  return css`
    width: 55%;

    @media screen and (max-width: 1600px) {
      width: 100%;
    }

    .fixed-table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 20px 20px;
      font-size: 18px;
      font-weight: bold;
      background-color: #f9f9f9;

      & > div {
        cursor: pointer;
        text-align: center;
        width: 20%;
      }
    }

    .fixed-data {
      border-bottom: 3px solid #ebebeb;

      & > div {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ebebeb;

        & > div {
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          text-align: center;
          width: 20%;
        }
      }

      .fixed-data-profile {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin: 0 0 0 8px;
        }
      }
    }
  `;
};

const ScrollWrap = (sWidth: number) => {
  return css`
    width: 1000px;
    position: relative;
    left: -15px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c09260;
      border-radius: 10px;
      background-clip: padding-box;
    }

    @media screen and (max-width: 1600px) {
      width: 700px;
    }

    @media screen and (min-width: 1600px) {
      width: 1100px;
    }

    .scroll-table {
      /*width: ${`${sWidth}px`};*/
      width: 1000px;

      & > div {
        /*width: ${`${sWidth}px`};*/
        width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 20px 20px;
        font-size: 18px;
        font-weight: bold;
        background-color: #f9f9f9;

        & div {
          flex: 0 0 auto;
          cursor: pointer;
          margin: 0 30px;
          text-align: center;
        }
      }
    }

    .scroll-data {
      /*width: ${`${sWidth}px`};*/
      width: 1000px;
      border-bottom: 3px solid #ebebeb;

      & > div {
        /*width: ${`${sWidth}px`};*/
        width: 1000px;
        padding: 20px 0 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ebebeb;

        & div {
          min-width: 100px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 auto;
          cursor: pointer;
          margin: 0 30px;
          text-align: center;
        }

        .increase-width {
          width: 190px;
        }
      }
    }
  `;
};
const WorkDone = styled.div`
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 28px;
    font-size: 15px;
    border-radius: 14px;
    border: solid 1px #00b050;
    color: #00b050;
  }
`;
const WorkMiss = styled.div`
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 28px;
    font-size: 15px;
    border-radius: 14px;
    border: solid 1px #f00;
    color: #f00;
  }
`;
const WorkLate = styled.div`
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 28px;
    font-size: 15px;
    border-radius: 14px;
    border: solid 1px #ffc000;
    color: #ffc000;
  }
`;
const WorkOver = styled.div`
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 28px;
    font-size: 15px;
    border-radius: 14px;
    border: solid 1px #ffc000;
    color: #ffc000;
  }
`;
const WorkNone = styled.div`
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 28px;
    font-size: 15px;
    border-radius: 14px;
    border: solid 1px #3c7ee1;
    color: #3c7ee1;
  }
`;
const WorkVacation = styled.div`
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 28px;
    font-size: 15px;
    border-radius: 14px;
    border: solid 1px #7030a0;
    color: #7030a0;
  }
`;
const WorkEarly = styled.div`
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 28px;
    font-size: 15px;
    border-radius: 14px;
    border: solid 1px #7f7f7f;
    color: #7f7f7f;
  }
`;
