import * as yup from "yup";

export default yup.object().shape({
  parttimerName: yup.string().required("이름을 입력하세요."),
  parttimerEmail: yup.string().required("이메일 주소를 입력하세요."),
  parttimerGender: yup.string().required("성별을 선택하세요."),
  parttimerBirth01: yup.string().required("생년월일을 입력하세요."),
  parttimerBirth02: yup.string().required("생년월일을 입력하세요."),
  parttimerBirth03: yup.string().required("이메일 주소를 입력하세요."),
  parttimerPhone1: yup.object().shape({
    value: yup.string().test({
      message: "휴대전화번호를 선택하세요.",
      test: (value) => {
        if (value === "init") {
          return false;
        } else {
          return true;
        }
      },
    }),
  }),
  parttimerPhone2: yup.string().required("휴대전화번호를 입력하세요."),
  parttimerPhone3: yup.string().required("휴대전화번호를 입력하세요."),
  zipCode: yup.string().required("우편번호를 입력해 주세요."),
  address: yup.string().required("주소를 입력해 주세요."),
  detailAddress: yup.string().required("상세주소를 입력해 주세요."),
  parttimerFirst01: yup.object().shape({
    value: yup.string().test({
      message: "1차 희망 부서를 선택해 주세요.",
      test: (value) => {
        if (value === "init") {
          return false;
        } else {
          return true;
        }
      },
    }),
  }),
  parttimerFirst02: yup.object().shape({
    value: yup.string().test({
      message: "1차 희망 파트를 선택해 주세요.",
      test: (value) => {
        if (value === "init") {
          return false;
        } else {
          return true;
        }
      },
    }),
  }),
  parttimerSecond01: yup.object().shape({
    value: yup.string().test({
      message: "2차 희망 부서를 선택해 주세요.",
      test: (value) => {
        if (value === "init") {
          return false;
        } else {
          return true;
        }
      },
    }),
  }),
  parttimerSecond02: yup.object().shape({
    value: yup.string().test({
      message: "2차 희망 파트를 선택해 주세요.",
      test: (value) => {
        if (value === "init") {
          return false;
        } else {
          return true;
        }
      },
    }),
  }),
  parttimerBank01: yup.object().shape({
    value: yup.string().test({
      message: "은행을 선택해 주세요.",
      test: (value) => {
        if (value === "init") {
          return false;
        } else {
          return true;
        }
      },
    }),
  }),
  parttimerBank02: yup.string().required("계좌번호를 입력해 주세요."),
  parttimerEdu: yup.object().shape({
    value: yup.string().test({
      message: "최종학력을 선택해 주세요.",
      test: (value) => {
        if (value === "init") {
          return false;
        } else {
          return true;
        }
      },
    }),
  }),
  parttimerSchoolName: yup.string().required("학교명을 입력해 주세요."),
  parttimerMajor: yup.string().required("전공을 입력해 주세요."),
  parttimerGraduateGubun: yup.object().shape({
    value: yup.string().test({
      message: "졸업구분을 선택해 주세요.",
      test: (value) => {
        if (value === "init") {
          return false;
        } else {
          return true;
        }
      },
    }),
  }),
  parttimerArmy: yup.object().shape({
    value: yup.string().test({
      message: "병역을 선택해 주세요.",
      test: (value) => {
        if (value === "init") {
          return false;
        } else {
          return true;
        }
      },
    }),
  }),
  parttimerVeterans: yup.object().shape({
    value: yup.string().test({
      message: "보훈대상을 선택해 주세요.",
      test: (value) => {
        if (value === "init") {
          return false;
        } else {
          return true;
        }
      },
    }),
  }),
  parttimerDisabled: yup.object().shape({
    value: yup.string().test({
      message: "장애등급을 선택해 주세요.",
      test: (value) => {
        if (value === "init") {
          return false;
        } else {
          return true;
        }
      },
    }),
  }),
  parttimerSelf: yup.string().required("자기소개를 입력해 주세요."),
  policyCheck: yup.bool().oneOf([true], "회원가입 동의여부를 체크해주세요."),
});
