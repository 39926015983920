import React, { useEffect, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "layout/layout";
import Button from "components/Button/Button";
import { PAGE_LAYOUT } from "utils/constants";
import useSubmitAlert from "hooks/useSubmitAlert";

const PartnerDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { noti } = useSubmitAlert();
  const [detailData, setDetailData] = useState<any>([]);
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    setDetailData(location?.state?.partner);
  }, [isReload]);

  return (
    <Layout page={PAGE_LAYOUT.partner}>
      <PartnerDetailStyle>
        <div>
          <div className="detail-container">
            <div>
              <h3>&#183; 기업 정보 &#183;</h3>
              <ul>
                <li>
                  <div>협력사번호</div>
                  <div>
                    {detailData?.company?.id?.toString().length === 1
                      ? "BPZ00" + detailData?.company?.id
                      : "BPZ0" + detailData?.company?.id}
                  </div>
                </li>
                <li>
                  <div>기업명</div>
                  <div>{detailData?.company?.company_name}</div>
                </li>
                <li>
                  <div>
                    사업자
                    <br />
                    등록번호
                  </div>
                  <div>{detailData?.company?.business_num}</div>
                </li>
                <li>
                  <div>협력분야</div>
                  <div>{detailData?.department}</div>
                </li>
                <li>
                  <div>주소</div>
                  <div>
                    ({detailData?.company?.zip_code}){" "}
                    {detailData?.company?.address}{" "}
                    {detailData?.company?.detail_address}
                  </div>
                </li>
                <li>
                  <div>팩스번호</div>
                  <div>{detailData?.company?.fax}</div>
                </li>
              </ul>
            </div>
            <div>
              <h3>&#183; 대표자 정보 &#183;</h3>
              <ul>
                <li>
                  <div>대표자 성명</div>
                  <div>{detailData?.company?.ceo}</div>
                </li>
                <li>
                  <div>대표자 연락처</div>
                  <div>
                    {detailData?.company?.company_phone?.substring(0, 3) +
                      "-" +
                      detailData?.company?.company_phone?.substring(3, 7) +
                      "-" +
                      detailData?.company?.company_phone?.substring(7, 11)}
                  </div>
                </li>
                <li>
                  <div>계좌번호</div>
                  <div>{detailData?.company?.account_number}</div>
                </li>
              </ul>
            </div>
            <div>
              <h3>&#183; 담당자 정보 &#183;</h3>
              <ul>
                <li>
                  <div>담당자 성명</div>
                  <div>{detailData?.user_name}</div>
                </li>
                <li>
                  <div>직급</div>
                  <div>{detailData?.position}</div>
                </li>
                <li>
                  <div>담당자 연락처</div>
                  <div>
                    {detailData?.user_phone?.substring(0, 3) +
                      "-" +
                      detailData?.user_phone?.substring(3, 7) +
                      "-" +
                      detailData?.user_phone?.substring(7, 11)}
                  </div>
                </li>
                <li>
                  <div>이메일</div>
                  <div>{detailData?.signname}</div>
                </li>
              </ul>
            </div>
            <div>
              <h3>&#183; 계정 상태 &#183;</h3>
              <ul>
                <li>
                  <div>계정상태</div>
                  <div className="limit-box-wrap">
                    <div>
                      {detailData?.is_withdraw ? "활성화" : "비 활성화"}
                    </div>
                    {detailData?.is_withdraw ? (
                      <div className="box-btn inactive-btn">비 활성화</div>
                    ) : (
                      <div className="box-btn active-btn">활성화</div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                목록
              </Button>
              <span className="void-sec"></span>
              <Button type="button" variant="secondary" preset="1">
                삭제
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="primary"
                preset="2"
                onClick={() =>
                  navigate(
                    `/curr_status/partner/edit/${location.state?.listId}`,
                    {
                      state: { listId: location.state?.listId },
                    }
                  )
                }
              >
                수정
              </Button>
            </div>
          </div>
        </div>
      </PartnerDetailStyle>
    </Layout>
  );
};

export default PartnerDetail;

const PartnerDetailStyle = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 35px;
  }

  .detail-container {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid #222;
  }

  li {
    display: flex;
    align-items: center;
    padding: 30px 0;
    border-bottom: 1px solid #ddd;

    & > div:nth-of-type(1) {
      width: 150px;
      font-size: 18px;
      font-weight: bold;
      color: #b2b2b2;
      line-height: 25px;
    }

    & > div:nth-of-type(2) {
      font-size: 18px;
      font-weight: normal;
      color: #000;
    }
  }

  .limit-box-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .box-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 84px;
      height: 35px;
      cursor: pointer;
      font-size: 15px;
      font-weight: normal;
      border-radius: 3px;
    }

    .inactive-btn {
      color: #ff4b4b;
      border: solid 1px #ff4b4b;
    }

    .active-btn {
      color: #c09260;
      border: solid 1px #c09260;
    }
  }

  .submit-btn {
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
