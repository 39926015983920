export const TestData = [
  {
    no: 1,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "2000.03.25",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    user_name: "김누리",
    department: "라운드리",
    position: "본부장",
    user_phone: "01029384855",
    company: {
      id: 10,
      ceo: "김대표",
      company_name: "테스트 업체",
      business_num: "34987632487364",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      detail_address: "1004호",
      company_phone: "01038456954",
      fax: "023449388",
      bank: "KB",
      bank_account: "345345435435345",
      is_withdraw: false,
    },
  },
  {
    no: 2,
    user_employee_info: {
      id: 1,
      user_name: "최누리",
      birth: "20000325",
      working_place: "서울 E-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-06-12",
  },
  {
    no: 3,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-05-18",
  },
  {
    no: 4,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-05-18",
  },
  {
    no: 5,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-05-03",
  },
  {
    no: 6,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-05-03",
  },
  {
    no: 7,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-05-03",
  },
  {
    no: 8,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-04-13",
  },
  {
    no: 9,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-06-13",
  },
  {
    no: 10,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-06-13",
  },
  {
    no: 11,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-06-13",
  },
  {
    no: 12,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-06-13",
  },
  {
    no: 13,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-06-13",
  },
  {
    no: 14,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-06-13",
  },
  {
    no: 15,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-06-13",
  },
  {
    no: 16,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-06-13",
  },
  {
    no: 17,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-06-13",
  },
  {
    no: 18,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-06-13",
  },
  {
    no: 19,
    user_employee_info: {
      id: 1,
      user_name: "김누리",
      birth: "20000325",
      working_place: "서울 M-Tower",
      department: "라운드리",
      position: "본부장",
      rank: "부사장",
      user_role: "FULLTIME_WORKER",
      term: "1",
      join_date: "20200128",
      leave_date: "20220128",
      contract_salary: "3000",
      current_salary: "4000",
      last_years_salary: "4500",
      offer_holiday: "15",
      used_holiday: "3",
      left_holiday: "0",
      used_paid_holiday: "2",
      used_unpaid_holiday: "4",
      bank: "KB",
      bank_account: "345345435435345",
      zip_code: "34467",
      address: "서울특별시 마포구 동교로 217(동교동)",
      signname: "nuri01@nuri-gs.co.kr",
      user_phone: "01029384855",
      note: "안녕하세요. 반갑습니다.",
      registration_status: "ING",
    },
    title: "모든 임직원 분들 께서는 이번주까지 근무현황을 업로드 해주세요.",
    regdtm: "2022-06-13",
  },
];

export const TestNotice = [
  {
    id: 1,
    name: "김누리",
    created_at: "1999.01.20",
    start_date: "1999.01.20",
    end_date: "1999.05.20",
    title: "회원 현황입니다.",
    number: "E000001",
  },
  {
    id: 2,
    name: "김누리",
    created_at: "2000.12.05",
    start_date: "1999.01.20",
    end_date: "1999.05.20",
    title: "회원 현황입니다.",
    number: "E000002",
  },
  {
    id: 3,
    name: "김누리",
    created_at: "1995.05.12",
    start_date: "1999.01.20",
    end_date: "1999.05.20",
    title: "회원 현황입니다.",
    number: "E000003",
  },
  {
    id: 4,
    name: "김누리",
    created_at: "1993.05.20",
    start_date: "1999.01.20",
    end_date: "1999.05.20",
    title: "회원 현황입니다.",
    number: "E000004",
  },
  {
    id: 5,
    name: "김누리",
    created_at: "1995.04.10",
    start_date: "1999.01.20",
    end_date: "1999.05.20",
    title: "회원 현황입니다.",
    number: "E000005",
  },
  {
    id: 6,
    name: "김누리",
    created_at: "1998.11.30",
    start_date: "1999.01.20",
    end_date: "1999.05.20",
    title: "회원 현황입니다.",
    number: "E000006",
  },
  {
    id: 7,
    name: "김누리",
    created_at: "1980.01.11",
    start_date: "1999.01.20",
    end_date: "1999.05.20",
    title: "회원 현황입니다.",
    number: "E000007",
  },
  {
    id: 8,
    name: "김누리",
    created_at: "1999.02.15",
    start_date: "1999.01.20",
    end_date: "1999.05.20",
    title: "회원 현황입니다.",
    number: "E000008",
  },
  {
    id: 9,
    name: "김누리",
    created_at: "1999.08.07",
    start_date: "1999.01.20",
    end_date: "1999.05.20",
    title: "회원 현황입니다.",
    number: "E000009",
  },
  {
    id: 10,
    name: "김누리",
    created_at: "2001.05.12",
    start_date: "1999.01.20",
    end_date: "1999.05.20",
    title: "회원 현황입니다.",
    number: "E000010",
  },
];

export const TestTable = [
  { name: "김누리", bod: "1999.01.20", gender: "남", number: "E000001" },
  { name: "김누리", bod: "2000.12.05", gender: "여", number: "E000002" },
  { name: "김누리", bod: "1995.05.12", gender: "여", number: "E000003" },
  { name: "김누리", bod: "1993.05.20", gender: "여", number: "E000004" },
  { name: "김누리", bod: "1995.04.10", gender: "남", number: "E000005" },
  { name: "김누리", bod: "1998.11.30", gender: "남", number: "E000006" },
  { name: "김누리", bod: "1980.01.11", gender: "여", number: "E000007" },
  { name: "김누리", bod: "1999.02.15", gender: "남", number: "E000008" },
  { name: "김누리", bod: "1999.08.07", gender: "여", number: "E000009" },
  { name: "김누리", bod: "2001.05.12", gender: "남", number: "E000010" },
];

export const TestTable2 = [
  {
    status: "지원서 작성중",
    approve: "수락",
    reject: "거절",
    resume: true,
    submit: true,
    name: "김누리",
    bod: "1999.01.20",
    gender: "남",
    number: "E000001",
  },
  {
    status: "지원서 작성중",
    approve: "수락",
    reject: "거절",
    submit: true,
    name: "김누리",
    bod: "2000.12.05",
    gender: "여",
    number: "E000002",
  },
  {
    status: "지원서 작성중",
    approve: "수락",
    reject: "거절",
    submit: false,
    name: "김누리",
    bod: "1995.05.12",
    gender: "여",
    number: "E000003",
  },
  {
    status: "지원서 작성중",
    approve: "수락",
    reject: "거절",
    submit: false,
    name: "김누리",
    bod: "1993.05.20",
    gender: "여",
    number: "E000004",
  },
  {
    status: "지원서 작성중",
    approve: "수락",
    reject: "거절",
    submit: true,
    name: "김누리",
    bod: "1995.04.10",
    gender: "남",
    number: "E000005",
  },
  {
    status: "지원서 작성중",
    approve: "수락",
    reject: "거절",
    submit: true,
    name: "김누리",
    bod: "1998.11.30",
    gender: "남",
    number: "E000006",
  },
  {
    status: "지원서 작성중",
    approve: "수락",
    reject: "거절",
    submit: true,
    name: "김누리",
    bod: "1980.01.11",
    gender: "여",
    number: "E000007",
  },
  {
    status: "지원서 작성중",
    approve: "수락",
    reject: "거절",
    submit: true,
    name: "김누리",
    bod: "1999.02.15",
    gender: "남",
    number: "E000008",
  },
  {
    status: "지원서 작성중",
    approve: "수락",
    reject: "거절",
    submit: false,
    name: "김누리",
    bod: "1999.08.07",
    gender: "여",
    number: "E000009",
  },
  {
    status: "지원서 작성중",
    approve: "수락",
    reject: "거절",
    submit: false,
    name: "김누리",
    bod: "2001.05.12",
    gender: "남",
    number: "E000010",
  },
];

export const TestTable3 = [
  {
    id: "A000001",
    confirm: true,
    workRequest: true,
    none: false,
    name: "김누리",
    code: "BZP001",
    company: "베스트초이스",
    workCheck: "확인 완료",
  },
  {
    id: "A000002",
    confirm: false,
    workRequest: false,
    none: false,
    name: "김누리",
    code: "BZP001",
    company: "베스트초이스",
    workCheck: "근무 확인 요청",
  },
  {
    id: "A000003",
    confirm: false,
    workRequest: false,
    none: false,
    name: "김누리",
    code: "BZP001",
    company: "ABC컴퍼니",
    workCheck: "근무 확인 요청",
  },
  {
    id: "A000004",
    confirm: false,
    workRequest: false,
    none: true,
    name: "김누리",
    code: "개인",
    company: "개인",
    workCheck: "불참",
  },
  {
    id: "A000005",
    confirm: false,
    workRequest: false,
    none: false,
    name: "김누리",
    code: "BZP001",
    company: "ABC컴퍼니",
    workCheck: "근무 확인 요청",
  },
  {
    id: "A000006",
    confirm: false,
    workRequest: false,
    none: false,
    name: "김누리",
    code: "BZP001",
    company: "베스트초이스",
    workCheck: "근무 확인 요청",
  },
  {
    id: "A000007",
    confirm: false,
    workRequest: false,
    none: false,
    name: "김누리",
    code: "BZP001",
    company: "베스트초이스",
    workCheck: "근무 확인 요청",
  },
  {
    id: "A000008",
    confirm: false,
    workRequest: false,
    none: false,
    name: "김누리",
    code: "BZP001",
    company: "베스트초이스",
    workCheck: "근무 확인 요청",
  },
  {
    id: "A000009",
    confirm: true,
    workRequest: false,
    none: false,
    name: "김누리",
    code: "BZP001",
    company: "베스트초이스",
    workCheck: "확인 완료",
  },
  {
    id: "A000010",
    confirm: true,
    workRequest: false,
    none: false,
    name: "김누리",
    code: "BZP001",
    company: "베스트초이스",
    workCheck: "확인 완료",
  },
];

export const TestTable4 = [
  {
    code: "BPZ00",
    companyName: "롯데호텔",
    busiNum: "000-00-00000",
    option: "기타",
  },
  {
    code: "BPZ00",
    companyName: "협력사1",
    busiNum: "000-00-00000",
    option: "인력 공급",
  },
  {
    code: "BPZ00",
    companyName: "협력사2",
    busiNum: "000-00-00000",
    option: "세탁물 공급",
  },
  {
    code: "BPZ00",
    companyName: "협력사3",
    busiNum: "000-00-00000",
    option: "식기류 공급",
  },
  {
    code: "BPZ00",
    companyName: "협력사1",
    busiNum: "000-00-00000",
    option: "식기류 공급",
  },
  {
    code: "BPZ00",
    companyName: "협력사1",
    busiNum: "000-00-00000",
    option: "식기류 공급",
  },
  {
    code: "BPZ00",
    companyName: "협력사1",
    busiNum: "000-00-00000",
    option: "식기류 공급",
  },
  {
    code: "BPZ00",
    companyName: "협력사1",
    busiNum: "000-00-00000",
    option: "식기류 공급",
  },
  {
    code: "BPZ00",
    companyName: "협력사1",
    busiNum: "000-00-00000",
    option: "식기류 공급",
  },
  {
    code: "BPZ00",
    companyName: "협력사1",
    busiNum: "000-00-00000",
    option: "식기류 공급",
  },
];

export const TestTable5 = [
  {
    status: "done",
    name: "김누리",
    level: "팀장",
    position: "과장",
    department: "경영지원",
  },
  {
    status: "miss",
    name: "김누리",
    level: "팀장",
    position: "과장",
    department: "경영지원",
  },
  {
    status: "late",
    name: "김누리",
    level: "팀장",
    position: "과장",
    department: "경영지원",
  },
  {
    status: "over",
    name: "김누리",
    level: "팀장",
    position: "과장",
    department: "경영지원",
  },
  {
    status: "none",
    name: "김누리",
    level: "팀장",
    position: "과장",
    department: "경영지원",
  },
  {
    status: "vacation",
    name: "김누리",
    level: "팀장",
    position: "과장",
    department: "경영지원",
  },
  {
    status: "early",
    name: "김누리",
    level: "팀장",
    position: "과장",
    department: "경영지원",
  },
  {
    status: "none",
    name: "김누리",
    level: "팀장",
    position: "과장",
    department: "경영지원",
  },
  {
    status: "none",
    name: "김누리",
    level: "팀장",
    position: "과장",
    department: "경영지원",
  },
  {
    status: "none",
    name: "김누리",
    level: "팀장",
    position: "과장",
    department: "경영지원",
  },
];

export const TestScrollHeader = [
  "주소",
  "이메일",
  "휴대폰 번호",
  "급여명세서",
  "등록현황",
  "부서",
  "직급",
  "주민등록번호",
  "입사일",
  "퇴사일",
];

export const TestScrollHeader2 = [
  "계좌번호",
  "자격증",
  "휴대폰 번호",
  "비상연락망",
  "관계",
  "이력서 보기",
  "직급",
  "주민등록번호",
  "입사일",
  "퇴사일",
];

export const TestScrollHeader3 = [
  "월 누적 시간",
  "등급",
  "인상률",
  "경고 누적 수",
  "비고",
  "누적시간",
  "직급",
  "주민등록번호",
  "입사일",
  "퇴사일",
];

export const TestScrollHeader4 = [
  "팩스번호",
  "이메일",
  "은행",
  "계좌번호",
  "계정상태",
];

export const TestScrollHeader5 = [
  "직원 번호",
  "근무지",
  "출근지",
  "[02월 08일] 출근시간",
  "[02월 08일] 퇴근시간",
];

export const TestScroll = [
  {
    address: "서울특별시 종로구 청계천로 67, 507호",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: true,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "서울특별시 종로구 청계천로 67, 507호",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: false,
    submit: true,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "서울특별시 종로구 청계천로 67, 507호",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: false,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "서울특별시 종로구 청계천로 67, 507호",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "서울특별시 종로구 청계천로 67, 507호",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: false,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "서울특별시 종로구 청계천로 67, 507호",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "서울특별시 종로구 청계천로 67, 507호",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "서울특별시 종로구 청계천로 67, 507호",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "서울특별시 종로구 청계천로 67, 507호",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "서울특별시 종로구 청계천로 67, 507호",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
];

export const TestScroll3 = [
  {
    address: "60",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: true,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "64",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: false,
    submit: true,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "30",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: false,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "50",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "30",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: false,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "30",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "30",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "30",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "30",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
  {
    address: "30",
    email: "nuri.kim@nuri.co.kr",
    phone: "010-9876-5432",
    pay: true,
    submit: false,
    approve: "수락",
    reject: "거절",
    department: "린넨",
    position: "부장",
    jumin: "901212-2******",
    joining: "2021.11.11",
    leave: "2023.01.30",
  },
];

export const TestScroll4 = [
  {
    fax: "02-1234-1234",
    email: "nuri.kim@nuri.com",
    bank: "우리",
    account: "123-456-789123",
    active: false,
  },
  {
    fax: "02-1234-1234",
    email: "nuri.kim@nuri.com",
    bank: "우리",
    account: "123-456-789123",
    active: true,
  },
  {
    fax: "02-1234-1234",
    email: "nuri.kim@nuri.com",
    bank: "하나",
    account: "123-456-789123",
    active: true,
  },
  {
    fax: "02-1234-1234",
    email: "nuri.kim@nuri.com",
    bank: "국민",
    account: "123-456-789123",
    active: false,
  },
  {
    fax: "02-1234-1234",
    email: "nuri.kim@nuri.com",
    bank: "기업",
    account: "123-456-789123",
    active: false,
  },
  {
    fax: "02-1234-1234",
    email: "nuri.kim@nuri.com",
    bank: "기업",
    account: "123-456-789123",
    active: false,
  },
  {
    fax: "02-1234-1234",
    email: "nuri.kim@nuri.com",
    bank: "하나",
    account: "123-456-789123",
    active: false,
  },
  {
    fax: "02-1234-1234",
    email: "nuri.kim@nuri.com",
    bank: "우리",
    account: "123-456-789123",
    active: true,
  },
  {
    fax: "02-1234-1234",
    email: "nuri.kim@nuri.com",
    bank: "우리",
    account: "123-456-789123",
    active: true,
  },
  {
    fax: "02-1234-1234",
    email: "nuri.kim@nuri.com",
    bank: "우리",
    account: "123-456-789123",
    active: true,
  },
];

export const TestScroll5 = [
  {
    empNum: "E000001",
    place: "서울 E-Tower",
    workPlace: "서울 E-Tower",
    goto: "[09:00] 09:00",
    leave: "[18:00] 18:00",
    isWorkPlace: true,
  },
  {
    empNum: "IP000001",
    place: "동양인력소개소",
    workPlace: "월드",
    goto: "[09:00] 09:00",
    leave: "[18:00] 18:00",
    isWorkPlace: true,
  },
  {
    empNum: "IP000002",
    place: "동양인력소개소",
    workPlace: "서울 M-Tower",
    goto: "[09:00] 09:00",
    leave: "[18:00] 18:00",
    isWorkPlace: true,
  },
  {
    empNum: "IP000003",
    place: "동양인력소개소",
    workPlace: "서울 M-Tower",
    goto: "[09:00] 09:00",
    leave: "[18:00] 18:00",
    isWorkPlace: true,
  },
  {
    empNum: "IP000003",
    place: "동양인력소개소",
    workPlace: "서울 M-Tower",
    goto: "[09:00] 09:00",
    leave: "[18:00] 18:00",
    isWorkPlace: true,
  },
  {
    empNum: "IP000004",
    place: "동양인력소개소",
    workPlace: "서울 E-Tower",
    goto: "[09:00] 09:00",
    leave: "[18:00] 18:00",
    isWorkPlace: false,
  },
  {
    empNum: "E000001",
    place: "시그니엘",
    workPlace: "서울 E-Tower",
    goto: "[09:00] 09:00",
    leave: "[18:00] 18:00",
    isWorkPlace: false,
  },
  {
    empNum: "E000002",
    place: "서울 E-Tower",
    workPlace: "시그니엘",
    goto: "[09:00] 09:00",
    leave: "[18:00] 18:00",
    isWorkPlace: true,
  },
  {
    empNum: "E000003",
    place: "서울 E-Tower",
    workPlace: "시그니엘",
    goto: "[09:00] 09:00",
    leave: "[18:00] 18:00",
    isWorkPlace: true,
  },
  {
    empNum: "E000004",
    place: "서울 E-Tower",
    workPlace: "시그니엘",
    goto: "[09:00] 09:00",
    leave: "[18:00] 18:00",
    isWorkPlace: true,
  },
];
export const TestPlaceOption = [
  { value: "MTower", label: "서울 M-Tower" },
  { value: "ETower", label: "서울 E-Tower" },
  { value: "World", label: "월드점" },
  { value: "Signiel", label: "시그니엘" },
];

const now = new Date();

// eslint-disable-next-line import/no-anonymous-default-export
export const testCalendarData = [
  {
    id: 1,
    title: "김누리 09:00 ~ 18:00",
    start: now,
    end: now,
  },
];

export const TestApprovalData = [
  {
    no: "10",
    status: "결재중",
    gubun: "LETTER_APP",
    title: "[2022.09.16] 김누리 연차 반차 신청",
    name: "김누리",
    place: "월드",
    rdgdtm: "2022.09.13",
  },
  {
    no: "9",
    status: "반려",
    gubun: "LETTER_APP",
    title: "[2022.09.16] 김누리 연차 반차 신청",
    name: "김누리",
    place: "월드",
    rdgdtm: "2022.09.13",
  },
  {
    no: "8",
    status: "결재중",
    gubun: "VACATION_APP",
    title: "[2022.09.16] 김누리 연차 반차 신청",
    name: "김누리",
    place: "월드",
    rdgdtm: "2022.09.13",
  },
  {
    no: "7",
    status: "결재완료",
    gubun: "PLAN_APP",
    title: "[2022.09.16] 김누리 연차 반차 신청",
    name: "김누리",
    place: "월드",
    rdgdtm: "2022.09.13",
  },
  {
    no: "6",
    status: "결재완료",
    gubun: "VACATION_APP",
    title: "[2022.09.16] 김누리 연차 반차 신청",
    name: "김누리",
    place: "월드",
    rdgdtm: "2022.09.13",
  },
  {
    no: "5",
    status: "결재완료",
    gubun: "VACATION_APP",
    title: "[2022.09.16] 김누리 연차 반차 신청",
    name: "김누리",
    place: "월드",
    rdgdtm: "2022.09.13",
  },
  {
    no: "4",
    status: "결재완료",
    gubun: "PLAN_APP",
    title: "[2022.09.16] 김누리 연차 반차 신청",
    name: "김누리",
    place: "월드",
    rdgdtm: "2022.09.13",
  },
  {
    no: "3",
    status: "결재완료",
    gubun: "LETTER_APP",
    title: "[2022.09.16] 김누리 연차 반차 신청",
    name: "김누리",
    place: "월드",
    rdgdtm: "2022.09.13",
  },
  {
    no: "2",
    status: "결재완료",
    gubun: "PLAN_APP",
    title: "[2022.09.16] 김누리 연차 반차 신청",
    name: "김누리",
    place: "월드",
    rdgdtm: "2022.09.13",
  },
  {
    no: "1",
    status: "결재완료",
    gubun: "LETTER_APP",
    title: "[2022.09.16] 김누리 연차 반차 신청",
    name: "김누리",
    place: "월드",
    rdgdtm: "2022.09.13",
  },
];

export const TestSeniorData = [
  { approver: "장누리[라운드리]", date: "2022.09.12", time: "13:00" },
  { approver: "장누리[라운드리]", date: "2022.09.12", time: "13:00" },
  { approver: "장누리[라운드리]", date: "2022.09.12", time: "13:00" },
  { approver: "장누리[라운드리]", date: "2022.09.12", time: "13:00" },
  { approver: "장누리[라운드리]", date: "2022.09.12", time: "13:00" },
  { approver: "장누리[라운드리]", date: "2022.09.12", time: "13:00" },
  { approver: "장누리[라운드리]", date: "2022.09.12", time: "13:00" },
  { approver: "장누리[라운드리]", date: "2022.09.12", time: "13:00" },
  { approver: "장누리[라운드리]", date: "2022.09.12", time: "13:00" },
  { approver: "장누리[라운드리]", date: "2022.09.12", time: "13:00" },
];

export const TestSearchMember = [
  {
    department: "경영지원",
    position: "부장",
    name: "김누리",
    place: "서울 M-Tower",
    checked: false,
  },
  {
    department: "린넨",
    position: "팀장",
    name: "최누리",
    place: "서울 M-Tower",
    checked: false,
  },
  {
    department: "라운드리",
    position: "부장",
    name: "이누리",
    place: "서울 M-Tower",
    checked: false,
  },
  {
    department: "식음",
    position: "부장",
    name: "박누리",
    place: "서울 M-Tower",
    checked: false,
  },
  {
    department: "고객응대",
    position: "부장",
    name: "한누리",
    place: "서울 M-Tower",
    checked: false,
  },
  {
    department: "고객응대",
    position: "부장",
    name: "한누리",
    place: "서울 M-Tower",
    checked: false,
  },
  {
    department: "고객응대",
    position: "부장",
    name: "한누리",
    place: "서울 M-Tower",
    checked: false,
  },
  {
    department: "고객응대",
    position: "부장",
    name: "한누리",
    place: "서울 M-Tower",
    checked: false,
  },
  {
    department: "고객응대",
    position: "부장",
    name: "한누리",
    place: "서울 M-Tower",
    checked: false,
  },
];

export const TestAuthData = [
  { name: "김누리", department: "기획", position: "부장", place: "본사" },
  { name: "이누리", department: "라운드리", position: "팀장", place: "서울" },
  { name: "박누리", department: "린넨", position: "매니저", place: "월드" },
  {
    name: "최누리",
    department: "룸메이드",
    position: "매니저",
    place: "시그니엘",
  },
  { name: "황누리", department: "고객응대", position: "부장", place: "월드" },
  { name: "한누리", department: "식음", position: "부장", place: "서울" },
  { name: "서누리", department: "린넨", position: "팀장", place: "서울" },
  {
    name: "오누리",
    department: "라운드리",
    position: "매니저",
    place: "시그니엘",
  },
  { name: "신누리", department: "기획", position: "사원", place: "본사" },
  { name: "지누리", department: "린넨", position: "부장", place: "본사" },
];

export const TestMypageData = [
  {
    status: "DONE",
    title: "롯데호텔 행사 일정",
    place: "서울 M-Tower",
    date: "2022.09.14",
    time: "15:00 ~ 21:00",
    application: "2022.09.13",
  },
  {
    status: "REJECT",
    title: "롯데호텔 행사 일정",
    place: "서울 M-Tower",
    date: "2022.09.14",
    time: "15:00 ~ 21:00",
    application: "2022.09.13",
  },
  {
    status: "APP_CANCEL",
    title: "롯데호텔 행사 일정",
    place: "서울 M-Tower",
    date: "2022.09.14",
    time: "15:00 ~ 21:00",
    application: "2022.09.13",
  },
  {
    status: "EVENT_CANCEL",
    title: "롯데호텔 행사 일정",
    place: "서울 M-Tower",
    date: "2022.09.14",
    time: "15:00 ~ 21:00",
    application: "2022.09.13",
  },
  {
    status: "EVENT_DONE",
    title: "롯데호텔 행사 일정",
    place: "서울 M-Tower",
    date: "2022.09.14",
    time: "15:00 ~ 21:00",
    application: "2022.09.13",
  },
  {
    status: "DONE",
    title: "롯데호텔 행사 일정",
    place: "서울 M-Tower",
    date: "2022.09.14",
    time: "15:00 ~ 21:00",
    application: "2022.09.13",
  },
  {
    status: "REJECT",
    title: "롯데호텔 행사 일정",
    place: "서울 M-Tower",
    date: "2022.09.14",
    time: "15:00 ~ 21:00",
    application: "2022.09.13",
  },
  {
    status: "APP_CANCEL",
    title: "롯데호텔 행사 일정",
    place: "서울 M-Tower",
    date: "2022.09.14",
    time: "15:00 ~ 21:00",
    application: "2022.09.13",
  },
  {
    status: "EVENT_CANCEL",
    title: "롯데호텔 행사 일정",
    place: "서울 M-Tower",
    date: "2022.09.14",
    time: "15:00 ~ 21:00",
    application: "2022.09.13",
  },
  {
    status: "EVENT_DONE",
    title: "롯데호텔 행사 일정",
    place: "서울 M-Tower",
    date: "2022.09.14",
    time: "15:00 ~ 21:00",
    application: "2022.09.13",
  },
];

export const PaymentTestData = [
  {
    floor: "6F",
    countText: "수량",
    payment: "단가",
    count: 20,
    pay: "₩10,000",
  },
  {
    floor: "7F",
    countText: "수량",
    payment: "단가",
    count: 30,
    pay: "₩20,000",
  },
  {
    floor: "8F",
    countText: "수량",
    payment: "단가",
    count: 26,
    pay: "₩40,000",
  },
  {
    floor: "9F",
    countText: "수량",
    payment: "단가",
    count: 40,
    pay: "₩100,000",
  },
];

export const LaundryTestData = [
  {
    room: "201",
    count: 20,
    itempay: "₩10,000",
    laundrypay: "₩10,000",
  },
  {
    room: "202",
    count: 10,
    itempay: "₩10,000",
    laundrypay: "₩10,000",
  },
  {
    room: "203",
    count: 30,
    itempay: "₩30,000",
    laundrypay: "₩30,000",
  },
  {
    room: "204",
    count: 40,
    itempay: "₩40,000",
    laundrypay: "₩40,000",
  },
];
