import { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";

import Layout from "layout/layout";

import { PAGE_LAYOUT } from "utils/constants";

import BigDataForm from "./BigDataForm";
import ItemManageForm from "./ItemManageForm";

const Roundley = () => {
  const btnRef = useRef([] as any);
  const [textChange, setTextChange] = useState("BIGDATA");

  useEffect(() => {
    if (textChange === "BIGDATA") {
      btnRef.current[0].className = "action";
      btnRef.current[1].className = "void";
    } else if (textChange === "ITEM_MANAGEMENT") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "action";
    }
  }, [textChange]);

  return (
    <Layout page={PAGE_LAYOUT.roundley}>
      <RoundleyStyled>
        <div className="main-inner-title">
          <div
            ref={(el) => (btnRef.current[0] = el)}
            onClick={() => {
              setTextChange("BIGDATA");
            }}
          >
            빅데이터
          </div>
          <span>&#183;</span>
          <div
            ref={(el) => (btnRef.current[1] = el)}
            onClick={() => {
              setTextChange("ITEM_MANAGEMENT");
            }}
          >
            품목 관리
          </div>
        </div>
        <div className="status-wrap">
          {textChange === "BIGDATA" && <BigDataForm />}
          {textChange === "ITEM_MANAGEMENT" && <ItemManageForm />}
        </div>
      </RoundleyStyled>
    </Layout>
  );
};

export default Roundley;

const RoundleyStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 40px;

  & > div {
    display: flex;
    padding: 35px;
  }

  .main-inner-title {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 35px;
      margin: 4px 12px 0 12px;
      color: #b2b2b2;
    }

    & > div {
      font-size: 28px;
      font-weight: bold;
      color: #707070;
      cursor: pointer;
    }

    .action {
      color: #c09260;
    }
  }
`;
