import React from "react";
import styled from "@emotion/styled";
import ProfileImage from "components/Profile/Profile";
import ShortcutsSVG from "assets/images/Shortcuts_ic.svg";
import authApi from "api/auth";
import { useNavigate } from "react-router-dom";

const MainProfile = ({ user }: any) => {
  //console.log(user);
  const navigate = useNavigate();
  const companyIdToString = user.userInfo?.company?.id.toString();
  const employeeIdToSting = user.userInfo?.user_employee_info?.id.toString();
  return (
    <MainProfileStyled>
      {/*{user.userRole === "EMPLOYEE" && (*/}
      <div className="main-inner-con22222">
        <div className="main-inner-con2">
          <ProfileImage fileId={null} width="74px" height="74px" />
          <div className="main-user-name">
            <span>김헬스</span> 님
          </div>
          <span className="main-inner-con2-line"></span>
          <div>
            <div className="main-user-position">
              <div className="main-user-color">키(신장)</div>
              <div className="main-user-data">180 cm</div>
            </div>
            <div className="main-user-position">
              <div className="main-user-color">몸무게</div>
              <div className="main-user-data">75 kg</div>
            </div>
            <div className="main-user-position">
              <div className="main-user-color">체지방</div>
              <div className="main-user-data">8 %</div>
            </div>
          </div>
        </div>
        <div className="main-user-part-sec">
          <div className="main-user-color-sec">패스워드변경</div>
          <div
            className="main-user-data-sec"
            onClick={() => {
              authApi.logout();
              navigate("/login", { replace: true });
            }}
          >
            로그아웃
            <img src={ShortcutsSVG} alt="숏컷 이미지" />
          </div>
        </div>
      </div>
      {/*)}
      {user.userRole === "PARTNER" && (
        <div className="main-inner-con2">
          <ProfileImage fileId={null} width="74px" height="74px" />
          <div className="main-user-name">
            <span>{user.userInfo?.user_name}</span> 님
          </div>
          <span className="main-inner-con2-line"></span>
          <div className="main-user-part">
            <div className="main-user-color">업체명</div>
            <div className="main-user-data">
              {user.userInfo?.company?.company_name}
            </div>
            <img src={ShortcutsSVG} alt="숏컷 이미지" />
          </div>
          <div className="main-user-position">
            <div className="main-user-color">협력분야</div>
            <div className="main-user-data">{user.userInfo?.department}</div>
          </div>
          <div className="main-user-employeenum">
            <div className="main-user-color">협력사코드</div>
            <div className="main-user-data">
              {companyIdToString.length === 1
                ? "BPZ00" + user.userInfo?.company?.id
                : "BPZ0" + user.userInfo?.company?.id}
            </div>
          </div>
        </div>
      )}
      {user.userRole === "PARTTIMER" && (
        <div className="main-inner-con2">
          <ProfileImage fileId={null} width="74px" height="74px" />
          <div className="main-user-name">
            <span>{user.userInfo?.user_name}</span> 님
          </div>
          <span className="main-inner-con2-line"></span>
          <div className="main-user-part">
            <div className="main-user-color">부서</div>
            <div className="main-user-data">경영지원팀</div>
            <img src={ShortcutsSVG} alt="숏컷 이미지" />
          </div>
          <div className="main-user-position">
            <div className="main-user-color">최근 시급</div>
            <div className="main-user-data">￦ 10,000</div>
          </div>
          <div className="main-user-employeenum">
            <div className="main-user-color">사원번호</div>
            <div className="main-user-data">E000001</div>
          </div>
        </div>
      )}*/}
    </MainProfileStyled>
  );
};

export default MainProfile;
const MainProfileStyled = styled.div`
  .main-inner-con22222 {
    max-width: 294px;
    border-radius: 8px;
    margin: 80px 0 0 36px;
  }
  .main-inner-con2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 37px 37px;
  }

  .main-inner-con2-line {
    min-width: 220px;
    height: 1px;
    margin: 20px 0 30px;
  }
  .main-user-name {
    font-size: 18px;
    font-weight: normal;
    color: #5a5a5a;
    margin: 20px 0 12px 0;

    span {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .main-user-code {
    font-size: 16px;
    font-weight: normal;
    color: #a1a1a1;

    span {
      font-weight: 600;
      color: #1532ee;
    }
  }

  .main-user-position,
  .main-user-part,
  .main-user-employeenum {
    align-self: flex-start;
    font-size: 18px;
    font-weight: 500;
    display: flex;

    .main-user-color {
      width: 90px;
      font-weight: 600;
      color: #1532ee;
      margin-right: 10px;
    }
    img {
      cursor: pointer;
    }
  }

  .main-user-part-sec {
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 14px;
    padding: 0 10px 10px 0;
    /*margin-top: 20px;*/
  }

  .main-user-color-sec {
    cursor: pointer;
  }

  .main-user-data-sec {
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  .main-user-position {
    margin: 16px 0;
  }
`;
