import React from "react";
import styled from "@emotion/styled";

const SeniorForm = () => {
  return (
    <SeniorFormStyled>
      <div className="senior">
        <ul>
          <li>
            <div className="senior-title">상급자 1</div>
            <div className="senior-approval">
              <div>[라운드리] 장누리</div>
              <div className="senior-done">결재</div>
              <div className="senior-time">2022.09.17 13:00 PM</div>
            </div>
          </li>
          <li>
            <div className="senior-title">상급자 2</div>
            <div className="senior-approval">
              <div>[라운드리] 장누리</div>
              <div className="senior-done">결재</div>
              <div className="senior-time">2022.09.17 13:00 PM</div>
            </div>
          </li>
          <li>
            <div className="senior-title">상급자 3</div>
            <div className="senior-approval">
              <div>[라운드리] 장누리</div>
              <div className="senior-ing">결재 중</div>
              <div className="senior-time">-</div>
            </div>
          </li>
        </ul>
      </div>
    </SeniorFormStyled>
  );
};

export default SeniorForm;

const SeniorFormStyled = styled.div`
  .senior {
    & ul {
      width: 560px;
      border: solid 1px #b2b2b2;
      display: flex;
      justify-content: space-between;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #444;
      margin: 0 auto 60px auto;

      & > li {
        width: 33.3333%;
      }

      & > li:nth-of-type(2) {
        border-left: solid 1px #b2b2b2;
        border-right: solid 1px #b2b2b2;
      }
    }

    .senior-title {
      font-weight: normal;
      padding: 16px 0;
      margin-bottom: 27px;
      background-color: rgba(168, 154, 139, 0.3);
    }

    .senior-approval {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .senior-done {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      font-size: 18px;
      font-weight: bold;
      color: #78aef3;
      padding-top: 3px;
      border-radius: 50%;
      border: dotted 2px #78aef3;
      margin: 16px 0;
    }

    .senior-ing {
      font-size: 18px;
      font-weight: bold;
      color: #b2b2b2;
      margin: 36px 0;
    }

    .senior-time {
      font-size: 15px;
      font-weight: normal;
      color: #b2b2b2;
      margin-bottom: 20px;
    }
  }
`;
