import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "store/alert";

/**
 * @file 얼럿 커스텀 훅
 */
const useAlert = () => {
  const dispatch = useDispatch();
  const alert = useSelector((s: any) => s.alert);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const noti = (message: string, ok: string) => {
    const promise = new Promise((resolve, reject) => {
      const isCustomMessage = typeof message === "function";

      dispatch(
        setAlert({
          single: {
            message: isCustomMessage ? undefined : message,
            renderMessage: isCustomMessage ? message : undefined,
            isOpen: true,
            resolve,
            reject,
            ok,
          },
        })
      );
      setNeedsCleanup(true);
    });
    const reset = () => {
      dispatch(
        setAlert({
          single: {
            message: "",
            isOpen: false,
            resolve: null,
            reject: null,
            ok: "",
          },
        })
      );
      setNeedsCleanup(false);
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };

  useEffect(() => {
    // cleanup
    return () => {
      if (alert.single.reject && needsCleanup) {
        alert.single.reject();
      }
    };
  }, [alert, needsCleanup]);

  return {
    ...alert,
    noti,
  };
};

export default useAlert;
