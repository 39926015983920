import { useState, useCallback } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";

import Layout from "layout/layout";
import request from "api/request";
import schedulesApi from "api/schedules";

import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Select from "components/Select/Select";

import { PAGE_LAYOUT, SCHEDULE_OPTIONS } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";
import { SchedulesPayloadTypes } from "types/payloadTypes";
import IconCalSVG from "assets/images/icon_cal.svg";

import WorkForm from "./WorkForm";
import VacationForm from "./VacationForm";

interface CreateScheduleProps {
  scheduleDate: string;
  scheduleGubun: {
    value: string;
    label: string;
  };
  scheduleDate2: string;
  scheduleGubun2: {
    value: string;
    label: string;
  };
  scheduleWorkInput: [
    {
      scheduleDate: string;
      scheduleName: [];
      scheduleNameId: [];
      schedulePoint: {
        value: string;
        label: string;
      };
      scheduleDepartment: {
        value: string;
        label: string;
      };
      scheduleTime1: string;
      scheduleTime2: string;
      scheduleWorkTime: string;
      scheduleNote: string;
    }
  ];
  scheduleVacationName: string;
  scheduleVacationCount: string;
  scheduleVacationPeriod: string;
  scheduleVacationUse: string;
  scheduleVacationNote: string;
}

const defaultValues: CreateScheduleProps = {
  scheduleDate: "",
  scheduleGubun: {
    value: "init",
    label: "선택",
  },
  scheduleDate2: "",
  scheduleGubun2: {
    value: "",
    label: "",
  },
  scheduleWorkInput: [
    {
      scheduleDate: "",
      scheduleName: [],
      scheduleNameId: [],
      schedulePoint: {
        value: "init",
        label: "선택",
      },
      scheduleDepartment: {
        value: "init",
        label: "선택",
      },
      scheduleTime1: "",
      scheduleTime2: "",
      scheduleWorkTime: "",
      scheduleNote: "",
    },
  ],
  scheduleVacationName: "",
  scheduleVacationCount: "",
  scheduleVacationPeriod: "",
  scheduleVacationUse: "",
  scheduleVacationNote: "",
};

const CreateSchedule = () => {
  const navigate = useNavigate();

  const [openDate, setOpenDate] = useState(false);
  const [descriptionId, setDescriptionId] = useState<any>([]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const {
    fields: workFormFields,
    append: workFormAppend,
    remove: workFormRemove,
  } = useFieldArray({
    control,
    name: "scheduleWorkInput",
  });

  const handleAddWorkForm = useCallback(() => {
    workFormAppend({
      scheduleDate: "",
      scheduleName: [],
      scheduleNameId: [],
      schedulePoint: {
        value: "init",
        label: "선택",
      },
      scheduleDepartment: {
        value: "init",
        label: "선택",
      },
      scheduleTime1: "",
      scheduleTime2: "",
      scheduleWorkTime: "",
      scheduleNote: "",
    });
  }, [workFormAppend]);

  console.log(dayjs("2000-01-01 15:00").format("HH:mm:ss"));

  const handleAddSchedules = async () => {
    let payload: SchedulesPayloadTypes = {};

    if (values.scheduleGubun.value === "WORKING") {
      Promise.all(
        values.scheduleWorkInput.map((e: any, i: number) => {
          payload = {
            ...payload,
            schedules_date: i > 0 ? e.scheduleDate : values.scheduleDate,
            working_plan_type: values.scheduleGubun.value,
            names: {
              description: e.scheduleNameId,
            },
            hotel_type: e.schedulePoint.value,
            department: e.scheduleDepartment.value,
            working_start_date: dayjs(e.scheduleTime1.value).format("HH:mm:ss"),
            working_end_date: dayjs(e.scheduleTime2.value).format("HH:mm:ss"),
            note: e.scheduleNote,
          };
          console.log("WORKING", payload);
          //return schedulesApi.addSchedules({ payload: payload });
        })
      );
    }

    if (values.scheduleGubun.value === "HOLIDAY") {
      payload = {
        ...payload,
        schedules_date: values.scheduleDate,
        working_plan_type: values.scheduleGubun.value,
        names: {
          description: descriptionId,
        },
        have_holiday_count: Number(values.scheduleVacationCount),
        reason_for_holiday: values.scheduleVacationNote,
      };
      console.log("HOLIDAY", payload);
      //return schedulesApi.addSchedules({ payload: payload });
    }
  };

  return (
    <Layout page={PAGE_LAYOUT.work_status}>
      <CreateScheduleStyled>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              handleSubmit(
                (res) => {
                  console.log(res);
                  handleAddSchedules();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <h3>&#183; 스케줄 생성 &#183;</h3>
            <Row label="날짜">
              <div
                className="date-image"
                onClick={() => {
                  setOpenDate(true);
                }}
              >
                <img src={IconCalSVG} alt="캘린더" />
              </div>
              <div className="input-sec date-input">
                <Controller
                  control={control}
                  name="scheduleDate"
                  render={({ field }) => (
                    <DatePicker
                      isOpen={openDate}
                      setIsOpen={setOpenDate}
                      popoverPositions={["bottom"]}
                      onSelect={(date) => {
                        field.onChange(dayjs(date).format("YYYY-MM-DD"));
                      }}
                      value={field.value}
                      closeOnSelect={true}
                      onChange={() => {}}
                      placeholder="날짜를 선택하세요."
                    />
                  )}
                />
              </div>
            </Row>
            <Row label="근무구분">
              <div className="input-sec">
                <Controller
                  control={control}
                  name="scheduleGubun"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="선택"
                      variant="primary"
                      fullWidth
                      options={SCHEDULE_OPTIONS}
                      errors={errors}
                    />
                  )}
                />
              </div>
            </Row>
            {values.scheduleGubun.value === "WORKING" && (
              <WorkForm
                workFormFields={workFormFields}
                workFormRemove={workFormRemove}
                handleAddWorkForm={handleAddWorkForm}
                setDescriptionId={setDescriptionId}
                Controller={Controller}
                setValue={setValue}
                control={control}
                errors={errors}
              />
            )}
            {values.scheduleGubun.value === "HOLIDAY" && (
              <VacationForm
                setDescriptionId={setDescriptionId}
                Controller={Controller}
                setValue={setValue}
                control={control}
                errors={errors}
              />
            )}
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                취소
              </Button>
              <span className="void-sec"></span>
              <Button type="submit" variant="primary" preset="2">
                등록
              </Button>
            </div>
          </form>
        </div>
      </CreateScheduleStyled>
    </Layout>
  );
};

export default CreateSchedule;

const CreateScheduleStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 35px;
  }

  form {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid #222;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .date-image {
    position: absolute;
    margin-top: 8px;
    margin-left: 620px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 49px;
    height: 49px;
    background-color: #444;
    cursor: pointer;
  }

  .date-input {
    input::placeholder {
      color: #b2b2b2;
    }
    input[name="rdp-date-picker"] {
      width: 100%;
      height: 65px;
      border: solid 1px #ddd;
      background-color: #fff;
      line-height: 1.29;
      font-size: 16px;
      color: #000;
      padding-left: 20px;
    }
    .react-tiny-popover-container {
      height: 100px;
      background-color: red;
    }
  }

  .time-select {
    display: flex;
    align-items: center;

    .void-sec {
      margin: 0 5px;
    }
  }

  .time-select > div {
    flex-grow: 1;
  }

  .section-add {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;

    & .delete-sec {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 8px 12px;
      border: solid 1px #444;
      margin-right: 6px;
      color: #444;
    }

    & .add-sec {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      background-color: #444;
      color: #fff;

      & img {
        margin-left: 4px;
      }
    }
  }

  .search-member-sec {
    display: flex;
  }

  .search-member-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
    cursor: pointer;
  }

  .submit-btn {
    width: 408px;
    margin: 0 auto;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }

  .ellipsis {
    padding-right: 50px;
    text-overflow: ellipsis;
  }
`;
