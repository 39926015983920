import { createSlice } from "@reduxjs/toolkit";

export const alertSice = createSlice({
  name: "alert",
  initialState: {
    single: {
      message: "",
      isOpen: false,
      resolve: null,
      reject: null,
      ok: "",
    },
    double: {
      message: "",
      isOpen: false,
      resolve: null,
      reject: null,
      ok: "",
      cancel: "",
    },
  },
  reducers: {
    setAlert: (state, action) => {
      state.single.message = action.payload.single.message;
      state.single.isOpen = action.payload.single.isOpen;
      state.single.resolve = action.payload.single.resolve;
      state.single.reject = action.payload.single.reject;
      state.single.ok = action.payload.single.ok;
    },
    setSubmitAlert: (state, action) => {
      state.double.message = action.payload.double.message;
      state.double.isOpen = action.payload.double.isOpen;
      state.double.resolve = action.payload.double.resolve;
      state.double.reject = action.payload.double.reject;
      state.double.ok = action.payload.double.ok;
      state.double.cancel = action.payload.double.cancel;
    },
  },
});

export const { setAlert, setSubmitAlert } = alertSice.actions;

export default alertSice.reducer;
