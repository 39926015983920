import React from "react";
import styled from "@emotion/styled";

const Footer = () => {
  return (
    <FooterStyled className="footer-wrap">
      <div className="footer-inner">
        <div>㈜디지털헬스케어</div>
        <div>03189 서울특별시 종로구 청계천로 67, 501호</div>
        <div>대표이사 김헬스 [사업자등록번호 111-19-01123]</div>
      </div>
      <div>Copyright© 2023 DIGITAL Helth Care. All Rights Reserved.</div>
    </FooterStyled>
  );
};

export default Footer;

const FooterStyled = styled.div`
  position: relative;
  width: 100vw;
  height: 117px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #d4d4d4;
  font-size: 14px;

  .footer-inner {
    width: 750px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
  }
`;
