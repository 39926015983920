import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/layout";
import codeApi from "api/code";
import useAlert from "hooks/useAlert";
import usePageSlice from "hooks/usePageSlice";

import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Search from "components/Search/Search";
import Select from "components/Select/Select";
import BtnCheckOffSVG from "assets/images/btn_check_off.png";
import BtnCheckOnSVG from "assets/images/btn_check_on.png";

import { PAGE_LAYOUT, CODE_OPTIONS } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

const defaultValues = {
  search: "",
  subcodeName: "",
  subcode: {
    value: "init",
    label: "선택",
  },
};

const CodeSettingEdit = () => {
  const { noti } = useAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const codeNameRef = useRef<HTMLInputElement | null>(null);
  const listRef = useRef<HTMLTableSectionElement | null>(null);
  const [listData, setListData] = useState<any>([]);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>(0);
  const [editText, setEditText] = useState<string>("");
  const [editIsUse, setEditIsUse] = useState<any>();
  const [isAddItem, setIsAddItem] = useState(false);
  const [checkData, setCheckData] = useState<any | undefined>([]);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (isEdit && codeNameRef.current) {
      console.log((codeNameRef.current.value = editText));
    }
  }, [isEdit]);

  // 서브코드 리스트 호출
  useEffect(() => {
    const payload = {
      type: location.state?.masterCode,
      keyword: values.search,
    };

    codeApi
      .getCodeTable({ payload: payload })
      .then((res: any) => {
        if (res.status === 200) {
          setListData(res.data.result.reverse());
        }
      })
      .catch((err) => console.log(err));
  }, [isAdd]);

  // 서브코드 등록
  const handleSaveCode = async () => {
    const payload = {
      type: location.state?.masterCode,
      code_name: values.subcodeName,
      use_settings: values.subcode.value === "true" ? true : false,
      is_withdraw: false,
    };
    await codeApi
      .createSubCode({ payload: payload })
      .then((res: any) => {
        if (res.status === 200) {
          setIsAdd((prev: boolean) => !prev);
          setValue("subcodeName", "");
          setValue("subcode", { value: "init", label: "선택" });
        }
      })
      .catch((err) => console.log(err));
  };

  // 서브코드 삭제
  const handleDelList = () => {
    const payload = {
      type: location.state?.masterCode,
    };
    Promise.all(
      checkData.map((id: number) => {
        return codeApi
          .deleteSubCode({ settingCodeId: id, payload: payload })
          .then((res: any) => {
            console.log(res);
            if (res.status === 200) {
              setIsAdd((prev: boolean) => !prev);
            }
          })
          .catch((err) => console.log(err));
      })
    );
  };

  // 서브코드 수정
  const handleEdit = async (id: number) => {
    const payload = {
      type: location.state?.masterCode,
      code_name: editText,
      use_settings: editIsUse === "true" ? true : false,
      is_withdraw: false,
    };

    await codeApi
      .updateSubCode({ settingCodeId: id, payload: payload })
      .then((res: any) => {
        if (res.status === 200) {
          setIsEdit(false);
          setIsAdd((prev: boolean) => !prev);
        }
      })
      .catch((err) => console.log(err));
  };

  // 리스트 체크박스
  const handleCheck = (e: any, item?: any) => {
    if (e.target.name === "All") {
      if (e.target.checked) {
        const allFilter = listData.map((e: any) => {
          e.checked = true;
          return e.id;
        });
        setCheckData(allFilter);
      } else {
        listData.map((e: any) => (e.checked = false));
        setCheckData([]);
      }
    } else {
      if (e.target.checked) {
        item.checked = true;
        setCheckData([...checkData, item.id]);
      } else {
        item.checked = false;
        setCheckData(checkData.filter((v: number) => v !== item.id));
      }
    }
  };

  return (
    <Layout page={PAGE_LAYOUT.setting}>
      <CodeSettingEditStyled>
        <div className="status-wrap">
          <div className="code-wrap">
            <div className="code-setting-header">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(
                    (res) => {
                      console.log(res);
                    },
                    (err) => {
                      console.log(err);
                    }
                  )();
                }}
              >
                <Controller
                  control={control}
                  name="search"
                  render={({ field }) => (
                    <Search
                      {...field}
                      placeholder="검색어를 입력하세요."
                      handleFormSubmit={() => {}}
                    />
                  )}
                />
              </form>
              <div className="status-btn">
                <div
                  className="code-setting-del-btn"
                  onClick={() => {
                    if (checkData.length <= 0) {
                      noti("삭제할 코드를 체크해주세요.", "확인");
                    } else {
                      handleDelList();
                    }
                  }}
                >
                  선택 삭제
                </div>
                <div
                  className="code-setting-add-btn"
                  onClick={() => setIsAddItem(true)}
                >
                  항목 추가
                </div>
              </div>
            </div>
            <div className="code-body">
              <table>
                <thead>
                  <tr>
                    <th className="code-head-child1">
                      <input
                        type="checkbox"
                        name="All"
                        onChange={(e: any) => handleCheck(e)}
                      />
                    </th>
                    <th className="code-head-child2">서브 코드 ID</th>
                    <th className="code-head-child3">코드명</th>
                    <th className="code-head-child4">사용여부</th>
                    <th className="code-head-child5">관리</th>
                  </tr>
                </thead>
                <tbody ref={listRef}>
                  {isAddItem && (
                    <tr>
                      <td className="code-head-child1">
                        <input type="checkbox" name="" id="" />
                      </td>
                      <td className="code-head-child2">
                        {listData?.length >= 9
                          ? location?.state?.masterCode +
                            "0" +
                            (listData.length + 1)
                          : location?.state?.masterCode +
                            "00" +
                            (listData.length + 1)}
                      </td>
                      <td className="code-head-child3">
                        <Controller
                          control={control}
                          name="subcodeName"
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              multiline={false}
                              fullWidth
                              placeholder="코드명을 입력하세요."
                              errors={errors}
                            />
                          )}
                        />
                      </td>
                      <td className="code-head-child4">
                        <div className="code-head-child4-select">
                          <Controller
                            control={control}
                            name="subcode"
                            render={({ field }) => (
                              <Select
                                {...field}
                                placeholder="선택"
                                w="160"
                                h="40"
                                paddingT={10}
                                paddingB={10}
                                fontS="15"
                                variant="tertiary"
                                options={CODE_OPTIONS}
                              />
                            )}
                          />
                        </div>
                      </td>
                      <td className="code-head-child5 code-detail-btn">
                        <button onClick={() => handleSaveCode()}>저장</button>
                      </td>
                    </tr>
                  )}
                  {listData.length > 0 &&
                    listData.map((item: any, i: number) => {
                      return (
                        <tr key={i}>
                          <td className="code-head-child1">
                            <input
                              type="checkbox"
                              name={item.id}
                              onChange={(e: any) => handleCheck(e, item)}
                              onClick={(e: any) => e.stopPropagation()}
                              checked={item.checked || false}
                            />
                          </td>
                          <td className="code-head-child2">
                            {i >= 9
                              ? location?.state?.masterCode + "0" + (i + 1)
                              : location?.state?.masterCode + "00" + (i + 1)}
                          </td>
                          {isEdit && item.id === editId ? (
                            <td className="code-head-child3">
                              <input
                                ref={codeNameRef}
                                type="text"
                                style={{ paddingLeft: "10px" }}
                                onChange={(e: any) =>
                                  setEditText(e.target.value)
                                }
                              />
                            </td>
                          ) : (
                            <td className="code-head-child3">
                              {item.code_name}
                            </td>
                          )}
                          {isEdit && item.id === editId ? (
                            <td className="code-head-child4">
                              <div className="code-head-child4-select">
                                <Select
                                  placeholder="선택"
                                  w="160"
                                  h="40"
                                  paddingT={10}
                                  paddingB={10}
                                  fontS="15"
                                  variant="tertiary"
                                  defaultValue={
                                    item.use_settings ? "true" : "false"
                                  }
                                  options={CODE_OPTIONS}
                                  onChange={(e: any) => setEditIsUse(e.value)}
                                />
                              </div>
                            </td>
                          ) : (
                            <td className="code-head-child4">
                              {item.use_settings ? "사용" : "사용안함"}
                            </td>
                          )}
                          <td className="code-head-child5 code-detail-btn">
                            {isEdit && item.id === editId ? (
                              <button onClick={() => handleEdit(item.id)}>
                                저장
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  setIsEdit(true);
                                  setEditId(item.id);
                                  setEditText(item.code_name);
                                  setEditIsUse(String(item.use_settings));
                                }}
                              >
                                수정
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="back-btn">
              <Button
                onClick={() => navigate(-1)}
                type="button"
                variant="tertiary"
                preset="1"
              >
                목록
              </Button>
            </div>
          </div>
        </div>
      </CodeSettingEditStyled>
    </Layout>
  );
};

export default CodeSettingEdit;

const CodeSettingEditStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    padding: 35px;
  }

  .code-wrap {
    width: 100%;
  }

  .code-setting-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .code-setting-del-btn {
    width: 100px;
    height: 44px;
    border-radius: 6px;
    background-color: #444;
    color: #fff;
    cursor: pointer;
  }

  .code-setting-add-btn {
    width: 100px;
    height: 44px;
    margin-left: 10px;
    border-radius: 6px;
    background-color: #c09260;
    color: #fff;
    cursor: pointer;
  }

  .code-body {
    margin-top: 30px;
    table {
      width: 100%;
    }

    thead {
      height: 68px;
      background-color: #f9f9f9;

      th {
        font-size: 18px;
        font-weight: bold;
        vertical-align: middle;
        color: #101010;
      }
    }
    tbody {
      tr {
        height: 66px;
        border-bottom: 1px solid #ebebeb;
      }

      td {
        font-size: 15px;
        font-weight: normal;
        vertical-align: middle;
        color: #444;

        &:nth-of-type(3) {
          /*padding-left: 50px;*/
        }
      }
    }

    .code-head-child1 {
      width: 5%;
      text-align: center;

      input {
        appearance: none;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        background: url(${BtnCheckOffSVG}) no-repeat center/100%;

        &:checked {
          background: url(${BtnCheckOnSVG}) no-repeat center/100%;
        }
      }
    }

    .code-head-child2 {
      width: 15%;
      text-align: center;
    }

    .code-head-child3 {
      width: 40%;

      input {
        width: 70%;
        height: 40px;
        border: 1px solid #d9d9d9;
      }
    }

    .code-head-child4 {
      width: 20%;
      text-align: center;

      .code-head-child4-select {
        display: flex;
        justify-content: center;
      }
    }

    .code-head-child5 {
      width: 20%;
      text-align: center;
    }

    .code-detail-btn {
      button {
        width: 72px;
        height: 30px;
        border-radius: 3px;
        border: solid 1px #c09260;
        color: #c09260;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }

  .back-btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
`;
