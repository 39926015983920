import React from "react";
import styled from "@emotion/styled";

import Layout from "layout/layout";

import { PAGE_LAYOUT } from "utils/constants";

import Preparing from "pages/Error404/Preparing";

const TechnicalSupport = () => {
  return (
    <Layout page={PAGE_LAYOUT.main}>
      <Preparing />
    </Layout>
  );
};

export default TechnicalSupport;
