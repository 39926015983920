import React, { useState, useEffect, useRef, useCallback } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/layout";

import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Radio from "components/Radio/Radio";
import Filter from "components/Filter/Filter";
import Select from "components/Select/Select";
import Search from "components/Search/Search";
import FileInput from "components/FileInput/FileInput";
import Textbox from "components/TextBox/TextBox";
import Postcode from "components/Postcode/Postcode";

import {
  PAGE_LAYOUT,
  PREFIX_NUMBER_OPTIONS,
  CARD_COMPANY_OPTIONS,
  DEPARTMENT_OPTIONS,
  PART1_OPTIONS,
  PART2_OPTIONS,
  PART3_OPTIONS,
  PART4_OPTIONS,
  PART5_OPTIONS,
  PartTimerLankSelectOptions,
} from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import { PartTimerForm } from "types/registType";
import { PartOptionTypes } from "types/commonTypes";

const defaultValues: PartTimerForm = {
  parttimerID: "",
  parttimerName: "",
  parttimerBirth01: "",
  parttimerBirth02: "",
  parttimerBirth03: "",
  parttimerGender: "",
  parttimerBelong: "",
  parttimerCode: "",
  parttimerTerm: "",
  parttimerIDPhoto: [],
  parttimerEmail: "",
  zipCode: "",
  address: "",
  detailAddress: "",
  parttimerPhone1: "",
  parttimerPhone2: "",
  parttimerPhone3: "",
  parttimerFirst01: {
    value: "init",
    label: "부서 선택",
    code: "",
  },
  parttimerFirst02: {
    value: "init",
    label: "파트 선택",
    code: "",
  },
  parttimerSecond01: {
    value: "init",
    label: "부서 선택",
    code: "",
  },
  parttimerSecond02: {
    value: "init",
    label: "파트 선택",
    code: "",
  },
  parttimerBank1: "",
  parttimerBank2: "",
  parttimerSalay: "",
  parttimerTime: "",
  parttimerLankSelect: "",
  parttimerLankPercent: "",
  parttimerWarning: "",
  parttimerNote: "",
};

const PartTimerEdit = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [firstPartOption, setFirstPartOption] = useState<PartOptionTypes[]>([]);
  const [secondPartOption, setSecondPartOption] = useState<PartOptionTypes[]>(
    []
  );
  const [enrollCompany, setEnrollCompany] = useState({
    address: "",
    zipcode: "",
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  // 1차 희망 선택 시 파트 옵션
  const choiceFirstPartOption = useCallback(() => {
    switch (values.parttimerFirst01.label) {
      case "객실팀":
        setFirstPartOption(PART1_OPTIONS);
        break;
      case "객실정비팀":
        setFirstPartOption(PART2_OPTIONS);
        break;
      case "식음팀":
        setFirstPartOption(PART3_OPTIONS);
        break;
      case "안전관리팀":
        setFirstPartOption(PART4_OPTIONS);
        break;
      case "시설관리팀":
        setFirstPartOption(PART5_OPTIONS);
        break;
      default:
        setFirstPartOption([]);
        break;
    }
  }, [values.parttimerFirst01]);

  // 2차 희망 선택 시 파트 옵션
  const choiceSecondPartOption = useCallback(() => {
    switch (values.parttimerSecond01.label) {
      case "객실팀":
        setSecondPartOption(PART1_OPTIONS);
        break;
      case "객실정비팀":
        setSecondPartOption(PART2_OPTIONS);
        break;
      case "식음팀":
        setSecondPartOption(PART3_OPTIONS);
        break;
      case "안전관리팀":
        setSecondPartOption(PART4_OPTIONS);
        break;
      case "시설관리팀":
        setSecondPartOption(PART5_OPTIONS);
        break;
      default:
        setSecondPartOption([]);
        break;
    }
  }, [values.parttimerSecond01]);

  // 최대 업데이트 깊이 초과 에러 대응
  useEffect(() => {
    choiceFirstPartOption();
    choiceSecondPartOption();
  }, [choiceFirstPartOption, choiceSecondPartOption]);

  return (
    <Layout page={PAGE_LAYOUT.part_timer}>
      <ParttimerEditStyle>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              handleSubmit(
                () => {},
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className="sec-gubun">
              <h3>&#183; 개인정보 &#183;</h3>
              <Row label="ID">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerID"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="성명">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerName"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        placeholder="성명을 입력하세요."
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="생년월일">
                <div className="input-sec birth-box">
                  <Controller
                    control={control}
                    name="parttimerBirth01"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        placeholder="YYYY"
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                      >
                        년
                      </Textbox>
                    )}
                  />
                  <span className="void-sec"></span>
                  <Controller
                    control={control}
                    name="parttimerBirth02"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        placeholder="MM"
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="2"
                      >
                        월
                      </Textbox>
                    )}
                  />
                  <span className="void-sec"></span>
                  <Controller
                    control={control}
                    name="parttimerBirth03"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        placeholder="DD"
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="2"
                      >
                        일
                      </Textbox>
                    )}
                  />
                </div>
              </Row>
              <Row label="성별">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerGender"
                    render={({ field }) => (
                      <div>
                        <Radio
                          {...field}
                          name="gender"
                          value="남성"
                          w="88"
                          h="65"
                          p="0"
                          isToggle={false}
                        >
                          남성
                        </Radio>
                        <span className="void-sec"></span>
                        <Radio
                          {...field}
                          name="gender"
                          value="여성"
                          w="88"
                          h="65"
                          p="0"
                          isToggle={false}
                        >
                          여성
                        </Radio>
                      </div>
                    )}
                  />
                </div>
              </Row>
              <Row label="소속">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerBelong"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="협력사 코드">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerCode"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="약관 동의여부">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerTerm"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="증명사진">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerIDPhoto"
                    render={({ field }) => (
                      <FileInput
                        {...field}
                        accept=".jpg, .png"
                        errors={errors}
                        fullWidth
                        placeholder={"(JPG(JPEG), PNG / 최대 5MB까지)"}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="이메일">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerEmail"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="주소">
                <div className="input-sec">
                  <div className="zipcode">
                    <Controller
                      control={control}
                      name="zipCode"
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          placeholder="우편번호"
                          value={enrollCompany.zipcode || ""}
                          fullWidth
                          errors={errors}
                        />
                      )}
                    />
                    <button
                      type="button"
                      className="address-search"
                      onClick={() => {
                        setPopup(true);
                      }}
                    >
                      주소찾기
                    </button>
                    {popup && (
                      <Postcode
                        setPopup={setPopup}
                        company={enrollCompany}
                        setcompany={setEnrollCompany}
                      />
                    )}
                  </div>
                  <div className="void-sec"></div>
                  <Controller
                    control={control}
                    name="address"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="주소"
                        fullWidth
                        value={enrollCompany.address || ""}
                        errors={errors}
                      />
                    )}
                  />
                  <div className="void-sec"></div>
                  <Controller
                    control={control}
                    name="detailAddress"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="상세주소를 입력하세요."
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="휴대전화번호">
                <div className="input-sec phonenumber">
                  <Controller
                    control={control}
                    name="parttimerPhone1"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="tertiary"
                        options={PREFIX_NUMBER_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                  <span>-</span>
                  <Controller
                    control={control}
                    name="parttimerPhone2"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                        errors={errors}
                      />
                    )}
                  />
                  <span>-</span>
                  <Controller
                    control={control}
                    name="parttimerPhone3"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
            </div>
            <div className="sec-gubun">
              <h3>&#183; 지원분야 &#183;</h3>
              <Row label="1차 희망">
                <div className="input-sec hope-select">
                  <Controller
                    control={control}
                    name="parttimerFirst01"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="부서 선택"
                        variant="primary"
                        options={DEPARTMENT_OPTIONS}
                      />
                    )}
                  />
                  <span className="void-sec"></span>
                  <Controller
                    control={control}
                    name="parttimerFirst02"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="파트 선택"
                        variant="primary"
                        options={firstPartOption}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="2차 희망">
                <div className="input-sec hope-select">
                  <Controller
                    control={control}
                    name="parttimerSecond01"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="부서 선택"
                        variant="primary"
                        options={DEPARTMENT_OPTIONS}
                      />
                    )}
                  />
                  <span className="void-sec"></span>
                  <Controller
                    control={control}
                    name="parttimerSecond02"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="파트 선택"
                        variant="primary"
                        options={secondPartOption}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="계좌번호">
                <div className="input-sec bank-select">
                  <Controller
                    control={control}
                    name="parttimerBank1"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="은행 선택"
                        variant="secondary"
                        options={CARD_COMPANY_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                  <span className="void-sec"></span>
                  <Controller
                    control={control}
                    name="parttimerBank2"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="'-' 없이 숫자만 입력하세요."
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
                <span className="bank-side-text">
                  * 본인의 시간제 (파트타임) 업무에 대한 급여가 입금될 계좌
                  정보를 입력해주십시오.
                </span>
              </Row>
            </div>
            <div>
              <h3>&#183; 경력사항 &#183;</h3>
              <div className="career-box">
                <div>
                  <div className="fixed-void-text">경력여부</div>
                  <div>O</div>
                </div>
                <div>
                  <div className="fixed-void-text">주 분야</div>
                  <div>컨시어지</div>
                </div>
              </div>
            </div>
            <div>
              <h3>&#183; 정보 &#183;</h3>
              <Row label="최근 기본 시급">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerSalay"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        fullWidth
                        disabled
                      >
                        원
                      </Textbox>
                    )}
                  />
                </div>
              </Row>
              <Row label="월 누적시간 (2022.09)">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerTime"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        fullWidth
                        disabled
                      >
                        시간
                      </Textbox>
                    )}
                  />
                </div>
              </Row>
              <div className="info-box">
                <div>
                  <div className="fixed-void-text">근무확인</div>
                  <div className="info-box-text">확인 필요</div>
                </div>
              </div>
              <Row label="등급">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerLankSelect"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="전체"
                        variant="primary"
                        fullWidth
                        options={PartTimerLankSelectOptions}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="인상률">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerLankPercent"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        fullWidth
                        disabled
                      >
                        %
                      </Textbox>
                    )}
                  />
                </div>
              </Row>
              <Row label="경고 누적수">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerWarning"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="전체"
                        variant="primary"
                        fullWidth
                        options={PartTimerLankSelectOptions}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="비고">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="parttimerNote"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <div className="limit-box">
                <div className="limit-box-wrap">
                  <div>
                    <div className="fixed-void-text">누적시간 제한여부</div>
                    <div>제한 해제</div>
                  </div>
                  <div className="limit-btn">제한 설정</div>
                </div>
              </div>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                취소
              </Button>
              <span className="void-sec"></span>
              <Button type="submit" variant="primary" preset="2">
                수정
              </Button>
            </div>
          </form>
        </div>
      </ParttimerEditStyle>
    </Layout>
  );
};

export default PartTimerEdit;

const ParttimerEditStyle = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 35px;
  }

  form {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #222;
  }

  .sec-gubun {
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }

  .input-sec {
    margin: 16px 0 30px;
  }
  .void-sec {
    height: 10px;
    margin: 0 6px;
  }

  .birth-box {
    display: flex;
  }

  .zipcode {
    display: flex;
  }

  .address-search {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
  }

  .bank-select {
    display: flex;
  }

  .phonenumber {
    display: flex;

    span {
      margin: 27px 10px 0 10px;
    }
  }

  .career-box {
    margin-top: -40px;
    & > div {
      display: flex;
      align-items: center;
      padding: 30px 0;
      border-bottom: 1px solid #ddd;
    }
  }

  .info-box {
    & > div {
      display: flex;
      align-items: center;
      padding: 30px 0;
      margin-bottom: 30px;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
  }

  .limit-box {
    .limit-box-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0;
      margin-bottom: 30px;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;

      & > div {
        display: flex;
      }
    }

    .limit-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 84px;
      height: 35px;
      cursor: pointer;
      font-size: 15px;
      font-weight: normal;
      color: #a89a8b;
      border-radius: 3px;
      border: solid 1px #a89a8b;
    }
  }

  .info-box-text {
    font-size: 18px;
    font-weight: normal;
    color: #c09260;
    border-radius: 3px;
    text-decoration: underline;
  }

  .fixed-void-text {
    width: 150px;
    font-size: 18px;
    font-weight: bold;
    color: #b2b2b2;
  }

  .hope-select {
    display: flex;
    align-items: center;
  }

  .hope-select > div {
    flex-grow: 1;
  }

  .bank-side-text {
    position: relative;
    font-size: 16px;
    font-weight: normal;
    color: #707070;
    top: -20px;
  }

  .submit-btn {
    width: 408px;
    margin: 0 auto;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
  }
`;
