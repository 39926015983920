import { useState, useEffect } from "react";

/**
 * @file 윈도우 스크롤 상태에 따른 스크롤 커스텀 훅
 */
export default ({ threshold = 50 } = { threshold: 50 }) => {
  const [state, setState] = useState({
    overflowY: false,
    x: 0,
    y: 0,
    bottomReached: false,
  });

  const onScroll = () => {
    setState({
      overflowY: document.body.offsetHeight > window.innerHeight,
      x: window.scrollX,
      y: window.scrollY,
      bottomReached:
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - threshold,
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  });

  return state;
};
