import React from "react";
import styled from "@emotion/styled";

import Footer from "layout/footer";
import { useNavigate } from "react-router-dom";
import Button from "components/Button/Button";

const Error404 = () => {
  const navigate = useNavigate();
  return (
    <ErrorStyled>
      <div className="error-wrap">
        <div className="error-inner">
          <h2>디지털헬스케어</h2>
          <div className="error-text1">
            해당 페이지는 디지털헬스케어의 업무 전용 페이지입니다.
            <br />
            회원가입은 관리자에게 문의해 주세요.
          </div>
          <div className="error-text2">
            회사소개나 인재풀 등록, 회원가입을 원하는 고객님은 공식 웹사이트로
            이동해 주시기 바랍니다.
          </div>
          <Button
            type="button"
            variant="primary"
            preset="2"
            w="306"
            onClick={() => navigate(-1)}
          >
            이전 페이지로 이동
          </Button>
        </div>
      </div>
      <div className="error-footer">
        <Footer />
      </div>
    </ErrorStyled>
  );
};

export default Error404;

const ErrorStyled = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #1d1d1d;
  color: #fff;

  h2 {
    font-family: "Pretendard";
    font-size: 62px;
    font-weight: bold;
    color: #e4caae;
    margin-bottom: 47px;
  }

  .error-wrap {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    a {
      color: #fff;
      display: block;
    }
  }

  .error-text1 {
    font-size: 23px;
    line-height: 1.52;
    font-weight: normal;
  }

  .error-text2 {
    font-size: 16px;
    font-weight: normal;
    opacity: 0.4;
    margin: 27px 0 78px 0;
  }

  .error-footer {
    position: fixed;
    bottom: 0;
  }
`;
