import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import Row from "components/LabelWithInput/LabelWithInput";
import Input from "components/Input/Input";
import SearchMember from "components/SearchPopup/SearchMember";

interface SearchMemberFormProps {
  label: string;
  placeName: string;
  placeId: string;
  Controller: any;
  setValue: any;
  control: any;
  errors: any;
}

const SearchMemberForm = ({
  label,
  placeName,
  placeId,
  Controller,
  setValue,
  control,
  errors,
}: SearchMemberFormProps) => {
  const [result, setResult] = useState<any>([]);
  const [openSearchMember, setOpenSearchMember] = useState<boolean>(false);

  useEffect(() => {
    console.log(result);
    if (result.length > 0) {
      setValue(
        placeId,
        result.map((e: any) => {
          return e.id;
        })
      );
      setValue(
        placeName,
        result.map((e: any) => {
          return "[" + e.department + "] " + e.user_name;
        })
      );
    }
  }, [result]);

  return (
    <SearchMemberFormStyled>
      <Row label={label}>
        <div className="input-sec search-member-sec">
          <Controller
            control={control}
            name={placeName}
            render={({ field }: any) => (
              <Input
                {...field}
                className="ellipsis"
                type="text"
                multiline
                useMaxLengthIndicator
                fullWidth={false}
                errors={errors}
              />
            )}
          />
          <button
            type="button"
            className="search-member-btn"
            onClick={() => setOpenSearchMember(true)}
          >
            인원 검색
          </button>
          {openSearchMember && (
            <SearchMember
              setOpenSearchMember={setOpenSearchMember}
              setResult={setResult}
            />
          )}
        </div>
      </Row>
    </SearchMemberFormStyled>
  );
};

export default SearchMemberForm;

const SearchMemberFormStyled = styled.div`
  .search-member-sec {
    display: flex;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .search-member-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
    cursor: pointer;
  }
`;
