import React from "react";
import styled from "@emotion/styled";
import DaumPostcode from "react-daum-postcode";

interface PostcodeProps {
  data?: any;
  company: any;
  setcompany: (e: any) => void;
  setPopup: (e: boolean) => void;
}

const Postcode = (props: PostcodeProps) => {
  const complete = (data: any) => {
    props.setPopup(false);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    props.setcompany({
      ...props.company,
      address: fullAddress,
      zipcode: data.zonecode,
    });
  };
  const postCodeStyle = {
    width: "500px",
    height: "450px",
  };

  return (
    <DaumPopup onClick={() => props.setPopup(false)}>
      <div>
        <DaumPostcode
          className="postmodal"
          style={postCodeStyle}
          autoClose
          onComplete={complete}
        />
      </div>
    </DaumPopup>
  );
};

export default Postcode;

const DaumPopup = styled.div`
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 99999999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  & div {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -35%);
  }
`;
