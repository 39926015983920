import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import request from "api/request";
import { useNavigate, useLocation } from "react-router-dom";

import Layout from "layout/layout";
import useSubmitAlert from "hooks/useSubmitAlert";

import {
  PAGE_LAYOUT,
  DEPARTMENT_OPTIONS,
  PARTTIMER_SUPPORT_PART_OPTIONS,
  EDUCATION_OPTIONS,
  GRADUATE_OPTIONS,
  MILITARY_OPTIONS,
  DISABLED_OPTIONS,
  EMPLOY_OPTIONS,
} from "utils/constants";

import Button from "components/Button/Button";
import Spiner from "utils/Spiner";

const PartTimerSupportDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { noti } = useSubmitAlert();
  const { REACT_APP_API } = process.env;
  const [detailData, setDetailData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDetailData(location?.state?.parttimer);
  }, []);

  //const handleSubmitStatus = async (isState: string) => {
  //  setIsLoading(true);
  //  let payload: any = {
  //    state: isState,
  //    phone: detailData?.phone,
  //  };

  //  if (detailData.company) {
  //    payload = {
  //      ...payload,
  //      company_id: detailData.company?.id,
  //    };
  //  }

  //  await request("put", `/resume/${detailData.id}`, {}, payload)
  //    .then((res: any) => {
  //      if (res.status === 200) {
  //        setIsLoading(false);
  //        navigate("/curr_status/part_timer_support", { replace: true });
  //      }
  //    })
  //    .catch((err) => {
  //      console.log(err);
  //    });
  //};

  return (
    <Layout page={PAGE_LAYOUT.part_timer_support}>
      {isLoading && <Spiner />}
      <EmployeeDetailStyle>
        <div>
          <div className="detail-container">
            <div>
              <h3>&#183; 개인 정보 &#183;</h3>
              <ul>
                <li>
                  <div>이름</div>
                  <div>{detailData?.resume_name}</div>
                </li>
                <li>
                  <div>생년월일</div>
                  <div>{detailData?.birth_day}</div>
                </li>
                <li>
                  <div>성별</div>
                  <div>{detailData?.gender}</div>
                </li>
                <li>
                  <div>증명사진</div>
                  <div className="idphoto-sec">
                    {detailData?.image_info ? (
                      <img
                        src={`${REACT_APP_API}/files/${detailData.image_info?.id}`}
                        alt="증명사진 이미지"
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                </li>
                <li>
                  <div>이메일 주소</div>
                  <div>{detailData?.email}</div>
                </li>
                <li>
                  <div>휴대전화 번호</div>
                  <div>{detailData?.phone}</div>
                </li>
                <li>
                  <div>우편 주소</div>
                  <div>{detailData?.zip_code}</div>
                </li>
                <li>
                  <div>주소</div>
                  <div>{detailData?.address}</div>
                </li>
                <li>
                  <div>상세 주소</div>
                  <div>{detailData?.detail_address}</div>
                </li>
              </ul>
            </div>
            <div>
              <h3>&#183; 지원 분야 &#183;</h3>
              <ul>
                <li>
                  <div>1차 희망</div>
                  <div className="support-sec">
                    <div>
                      <span>부서</span>
                      {detailData?.job1_depth1
                        ? DEPARTMENT_OPTIONS.filter(
                            (e: any) => e.value === detailData?.job1_depth1
                          )[0]?.label
                        : "-"}
                    </div>
                    <div>
                      <span>파트</span>
                      {detailData?.job1_depth2
                        ? PARTTIMER_SUPPORT_PART_OPTIONS.filter(
                            (e: any) => e.value === detailData?.job1_depth2
                          )[0]?.label
                        : "-"}
                    </div>
                  </div>
                </li>
                <li>
                  <div>2차 희망</div>
                  <div className="support-sec">
                    <div>
                      <span>부서</span>
                      {detailData?.job2_depth1
                        ? DEPARTMENT_OPTIONS.filter(
                            (e: any) => e.value === detailData?.job2_depth1
                          )[0]?.label
                        : "-"}
                    </div>
                    <div>
                      <span>파트</span>
                      {detailData?.job2_depth2
                        ? PARTTIMER_SUPPORT_PART_OPTIONS.filter(
                            (e: any) => e.value === detailData?.job2_depth2
                          )[0]?.label
                        : "-"}
                    </div>
                  </div>
                </li>
                <li>
                  <div>계좌번호</div>
                  <div>
                    {detailData?.bank} {detailData?.account_number}
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <h3>&#183; 학력·병역·장애 &#183;</h3>
              <ul>
                <li>
                  <div>최종학력</div>
                  <div>
                    {detailData?.education
                      ? EDUCATION_OPTIONS.filter(
                          (e: any) => e.value === detailData?.education
                        )[0]?.label
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>학교명</div>
                  <div>{detailData?.school_name}</div>
                </li>
                <li>
                  <div>전공</div>
                  <div>{detailData?.major}</div>
                </li>
                <li>
                  <div>졸업구분</div>
                  <div>
                    {detailData?.graduation_category
                      ? GRADUATE_OPTIONS.filter(
                          (e: any) =>
                            e.value === detailData?.graduation_category
                        )[0]?.label
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>졸업연도</div>
                  <div>
                    {detailData?.graduation ? detailData?.graduation : "-"}
                  </div>
                </li>
                <li>
                  <div>병역</div>
                  <div>
                    {detailData?.military_service
                      ? MILITARY_OPTIONS.filter(
                          (e: any) => e.value === detailData?.military_service
                        )[0]?.label
                      : "-"}
                  </div>
                </li>
                <li>
                  <div>장애</div>
                  <div>
                    {detailData?.disabled
                      ? DISABLED_OPTIONS.filter(
                          (e: any) => e.value === detailData?.disabled
                        )[0]?.label
                      : "-"}
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="career-heading">&#183; 경력사항 &#183;</h3>
              {detailData?.career_info &&
                JSON.parse(detailData.career_info).map(
                  (item: any, i: number) => {
                    return (
                      <ul key={i} className="career-wrap">
                        <li>
                          <div>기업명</div>
                          <div>{item?.career_name}</div>
                        </li>
                        <li>
                          <div>고용형태</div>
                          <div>
                            {item?.type
                              ? EMPLOY_OPTIONS.filter(
                                  (e: any) => e.value === item?.type
                                )[0]?.label
                              : "-"}
                          </div>
                        </li>
                        <li>
                          <div>근무기간</div>
                          <div>
                            {item?.start_date} ~ {item?.end_date}
                          </div>
                        </li>
                        <li>
                          <div>연봉</div>
                          <div>{`${Number(
                            item.salary
                          ).toLocaleString()} 원`}</div>
                        </li>
                        <li>
                          <div>직무</div>
                          <div>{item?.job}</div>
                        </li>
                        <li>
                          <div>직무요약</div>
                          <div>{item?.job_summary}</div>
                        </li>
                      </ul>
                    );
                  }
                )}
            </div>
            <div>
              <h3>&#183; 자격증 &#183;</h3>
              <ul className="credential-wrap">
                {detailData?.license_info &&
                  JSON.parse(detailData.license_info).map(
                    (item: any, i: number) => {
                      return (
                        <li key={i}>
                          <div className="credential-date">
                            {item?.pass_date}
                          </div>
                          <div>
                            <span>{item?.license_name}</span> {item?.publisher}
                          </div>
                        </li>
                      );
                    }
                  )}
              </ul>
            </div>
            <div>
              <h3>&#183; 자기소개 &#183;</h3>
              <div className="note-sec">{detailData?.self_introducation}</div>
            </div>
            {detailData?.state === "APPROVE_WAITING" ? (
              <div className="submit-btn">
                <Button
                  type="button"
                  variant="tertiary"
                  preset="1"
                  onClick={() => navigate(-1)}
                >
                  목록
                </Button>
                <span className="void-sec"></span>
                <Button
                  type="button"
                  variant="secondary"
                  preset="1"
                  onClick={() => {
                    const isReject = noti("거절하시겠습니까?", "예", "아니오");
                    const getData = () => {
                      isReject.then((res: boolean) => {
                        //if (res) handleSubmitStatus("APPROVE_REJECTED");
                      });
                    };
                    getData();
                  }}
                >
                  거절
                </Button>
                <span className="void-sec"></span>
                <Button
                  type="button"
                  variant="primary"
                  preset="2"
                  onClick={() => {
                    const isApproved = noti(
                      "수락하시겠습니까?",
                      "예",
                      "아니오"
                    );
                    const getData = () => {
                      isApproved.then((res: boolean) => {
                        //if (res) handleSubmitStatus("APPROVED");
                      });
                    };
                    getData();
                  }}
                >
                  수락
                </Button>
              </div>
            ) : (
              <div className="submit-btn">
                <Button
                  type="button"
                  variant="tertiary"
                  preset="1"
                  onClick={() => navigate(-1)}
                >
                  목록
                </Button>
              </div>
            )}
          </div>
        </div>
      </EmployeeDetailStyle>
    </Layout>
  );
};

export default PartTimerSupportDetail;

const EmployeeDetailStyle = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 35px;
  }

  .detail-container {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid #222;
  }

  li {
    display: flex;
    align-items: center;
    padding: 30px 0;
    border-bottom: 1px solid #ddd;

    & > div:nth-of-type(1) {
      width: 150px;
      font-size: 18px;
      font-weight: bold;
      color: #b2b2b2;
    }

    & > div:nth-of-type(2) {
      font-size: 18px;
      font-weight: normal;
      color: #000;
    }
  }

  .idphoto-sec {
    width: 100px;
    height: 134px;
    background-color: #f1f1f1;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .support-sec {
    display: flex;
    font-size: 18px;
    font-weight: normal;

    & > div {
      width: 150px;
    }

    span {
      color: #c09260;
      margin-right: 6px;
    }
  }

  .credential-wrap {
    .credential-date {
      width: 110px;
      font-size: 16px;
      font-weight: normal;
      color: #222;
    }
    span {
      font-size: 18px;
      font-weight: bold;
      color: #222;
      margin-right: 10px;
    }
  }

  .career-heading {
    border: 0;
  }

  .career-wrap {
    border-top: 1px solid #222;
  }

  .note-sec {
    margin-top: 30px;
    padding: 20px;
    border: solid 1px #ddd;
    background-color: #f9f9f9;
    min-height: 170px;
  }

  .submit-btn {
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
