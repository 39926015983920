import React from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "react-dayjs-picker";

import Layout from "layout/layout";

import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";

import { PAGE_LAYOUT } from "utils/constants";

import IconCalSVG from "assets/images/icon_cal.svg";

import "react-dayjs-picker/dist/index.css";

const MypageHistoryDetail = () => {
  const navigate = useNavigate();
  return (
    <Layout page={PAGE_LAYOUT.mypage}>
      <MypageHistoryDetailStyled>
        <div className="status-wrap">
          <div className="status-inner">
            <h3>&#183; 행사 공지 내용 &#183;</h3>
            <Row label="행사명">
              <div className="input-sec">
                <Input
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  onChange={() => {}}
                  onBlur={() => {}}
                  name="mypageHistory"
                  errors="errors"
                  disabled
                />
              </div>
            </Row>
            <Row label="행사 장소">
              <div className="input-sec">
                <Input
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  onChange={() => {}}
                  onBlur={() => {}}
                  name="mypageHistory"
                  errors="errors"
                  disabled
                />
              </div>
            </Row>
            <h3>&#183; 일정 및 인원 &#183;</h3>
            <Row label="행사 기간">
              <div className="date-image">
                <img src={IconCalSVG} alt="캘린더" />
              </div>
              <div className="input-sec date-input">
                <DatePicker
                  popoverPositions={["bottom"]}
                  closeOnSelect={true}
                  onChange={() => {}}
                />
              </div>
            </Row>
            <Row label="지원 마감일">
              <div className="input-sec">
                <Input
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  onChange={() => {}}
                  onBlur={() => {}}
                  name="mypageHistory"
                  errors="errors"
                  disabled
                />
              </div>
            </Row>
            <Row label="지원 마감시간">
              <div className="input-sec">
                <Input
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  onChange={() => {}}
                  onBlur={() => {}}
                  name="mypageHistory"
                  errors="errors"
                  disabled
                />
              </div>
            </Row>
            <Row label="필요 인원">
              <div className="input-sec">
                <Input
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  onChange={() => {}}
                  onBlur={() => {}}
                  name="mypageHistory"
                  errors="errors"
                  disabled
                />
              </div>
            </Row>
            <Row label="기본 시급">
              <div className="input-sec">
                <Input
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  onChange={() => {}}
                  onBlur={() => {}}
                  name="mypageHistory"
                  errors="errors"
                  disabled
                />
              </div>
            </Row>
            <Row label="비고">
              <div className="input-sec">
                <Input
                  type="text"
                  multiline={true}
                  fullWidth={false}
                  useMaxLengthIndicator
                  maxLength="1000"
                  onChange={() => {}}
                  onBlur={() => {}}
                  name="mypageHistory"
                  errors="errors"
                  disabled
                />
              </div>
            </Row>
            <div className="back-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                목록
              </Button>
            </div>
          </div>
        </div>
      </MypageHistoryDetailStyled>
    </Layout>
  );
};

export default MypageHistoryDetail;

const MypageHistoryDetailStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    padding: 35px;
  }

  .status-inner {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #222;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .date-image {
    position: absolute;
    margin-top: 8px;
    margin-left: 620px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 49px;
    height: 49px;
    background-color: #444;
    cursor: pointer;
  }

  .date-input {
    input::placeholder {
      color: #b2b2b2;
    }
    input[name="rdp-date-picker"] {
      width: 100%;
      height: 65px;
      border: solid 1px #ddd;
      background-color: #f9f9f9;
      line-height: 1.29;
      font-size: 16px;
      color: #000;
      padding-left: 20px;
    }
    .react-tiny-popover-container {
      height: 100px;
    }
  }

  .back-btn {
    width: 408px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
`;
