import React, { useEffect, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";
import request from "api/request";

import Layout from "layout/layout";
import useAlert from "hooks/useAlert";

import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import FileInput from "components/FileInput/FileInput";
import Textbox from "components/TextBox/TextBox";
import Postcode from "components/Postcode/Postcode";

import { EmployeeForm } from "types/registType";
import { EmployeePayloadTypes } from "types/payloadTypes";

import {
  PAGE_LAYOUT,
  EMPLOYEE_STATUS_OPTIONS,
  EMPLOYEE_TYPE_OPTIONS,
  GENDER_OPTIONS,
  PREFIX_NUMBER_OPTIONS,
  CARD_COMPANY_OPTIONS,
} from "utils/constants";

import IconCalSVG from "assets/images/icon_cal.svg";

import validation from "utils/validations/employeeValidationSchema";

import "react-dayjs-picker/dist/index.css";

const defaultValues: EmployeeForm = {
  employeeName: "",
  employeeBOD: "",
  employeeGender: {
    value: "init",
    label: "선택",
  },
  employeeNumber: "",
  employeeIDPhoto: [],
  employeeEmail: "",
  employeePlace: {
    value: "init",
    label: "선택",
  },
  employeeDepartment: {
    value: "init",
    label: "선택",
  },
  employeeRank: {
    value: "init",
    label: "선택",
  },
  employeePosition: {
    value: "init",
    label: "선택",
  },
  employeePhone1: {
    value: "init",
    label: "선택",
    code: "",
  },
  employeePhone2: "",
  employeePhone3: "",
  employeeBank1: {
    value: "init",
    label: "은행 선택",
    code: "",
  },
  employeeBank2: "",
  zipCode: "",
  address: "",
  detailAddress: "",
  employeeStatus: {
    value: "",
    label: "",
  },
  employment: "",
  departure: "",
  employeeWorkForm: {
    value: "init",
    label: "선택",
  },
  employeeContract: "",
  employeeContractSalary: "",
  employeeCurrentSalary: "",
  employeeLastYearSalary: "",
  employeePaySpecification: "",
  employeeAnnualPayment: "",
  employeeRegistStatus: "",
  employeeContent: "",
};

const EmployeeEdit = () => {
  const { noti } = useAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const MasterCode = useSelector((s: any) => s.code);
  const [openBOD, setOpenBOD] = useState(false);
  const [openEmployment, setOpenEmployment] = useState(false);
  const [popup, setPopup] = useState(false);
  const [enrollCompany, setEnrollCompany] = useState({
    address: "",
    zipcode: "",
  });

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validation),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // 성명 set
    setValue(
      "employeeName",
      location?.state?.employee?.user_employee_info?.user_name
        ? location?.state?.employee?.user_employee_info?.user_name
        : "-"
    );
    // 생년월일 set
    setValue(
      "employeeBOD",
      location?.state?.employee?.user_employee_info?.birth
    );
    // 성별 set
    for (let i = 0; i < GENDER_OPTIONS.length; i++) {
      if (
        GENDER_OPTIONS[i].value ===
        location?.state?.employee?.user_employee_info?.gender
      ) {
        setValue("employeeGender", GENDER_OPTIONS[i]);
      }
    }
    // 사원번호 set
    setValue(
      "employeeNumber",
      location?.state?.employee?.user_employee_info?.id
        ? "E00000" + location?.state?.employee?.user_employee_info?.id
        : "-"
    );
    // 증명사진 set
    let arrayFile: any = [];
    if (location?.state?.employee?.user_employee_info?.profile_image_info) {
      arrayFile = [
        {
          name: `${location?.state?.employee?.user_employee_info?.profile_image_info?.file_name}.${location?.state?.employee?.user_employee_info?.profile_image_info.extension}`,
          id: location?.state?.employee?.user_employee_info?.profile_image_info
            ?.id,
          size: location?.state?.employee?.user_employee_info
            ?.profile_image_info?.size,
        },
      ];
    }
    setValue(
      "employeeIDPhoto",
      location?.state?.employee?.user_employee_info?.profile_image_info
        ? arrayFile
        : ""
    );
    // 이메일 주소 set
    setValue(
      "employeeEmail",
      location?.state?.employee?.user_employee_info?.signname
        ? location?.state?.employee?.user_employee_info?.signname
        : "-"
    );
    // 근무지 set
    for (let i = 0; i < GENDER_OPTIONS.length; i++) {
      if (
        GENDER_OPTIONS[i].value ===
        location?.state?.employee?.user_employee_info?.working_place
      ) {
        setValue("employeePlace", GENDER_OPTIONS[i]);
      }
    }
    // 부서 set
    for (let i = 0; i < GENDER_OPTIONS.length; i++) {
      if (
        GENDER_OPTIONS[i].value ===
        location?.state?.employee?.user_employee_info?.department
      ) {
        setValue("employeeDepartment", GENDER_OPTIONS[i]);
      }
    }
    // 직급 set
    for (let i = 0; i < GENDER_OPTIONS.length; i++) {
      if (
        GENDER_OPTIONS[i].value ===
        location?.state?.employee?.user_employee_info?.rank
      ) {
        setValue("employeeRank", GENDER_OPTIONS[i]);
      }
    }
    // 직책 set
    for (let i = 0; i < GENDER_OPTIONS.length; i++) {
      if (
        GENDER_OPTIONS[i].value ===
        location?.state?.employee?.user_employee_info?.position
      ) {
        setValue("employeePosition", GENDER_OPTIONS[i]);
      }
    }
    // 휴대폰 번호 set
    const phone1 =
      location?.state?.employee?.user_employee_info?.user_phone?.substr(0, 3);
    for (let i = 0; i < PREFIX_NUMBER_OPTIONS.length; i++) {
      if (PREFIX_NUMBER_OPTIONS[i].value === phone1) {
        setValue("employeePhone1", PREFIX_NUMBER_OPTIONS[i]);
      }
    }
    setValue(
      "employeePhone2",
      location?.state?.employee?.user_employee_info?.user_phone
        ? location?.state?.employee?.user_employee_info?.user_phone?.substr(
            3,
            4
          )
        : ""
    );
    setValue(
      "employeePhone3",
      location?.state?.employee?.user_employee_info?.user_phone
        ? location?.state?.employee?.user_employee_info?.user_phone?.substr(
            7,
            4
          )
        : ""
    );
    // 계좌번호 set
    for (let i = 0; i < CARD_COMPANY_OPTIONS.length; i++) {
      if (
        CARD_COMPANY_OPTIONS[i].value ===
        location?.state?.employee?.user_employee_info?.bank
      ) {
        setValue("employeeBank1", CARD_COMPANY_OPTIONS[i]);
      }
    }
    setValue(
      "employeeBank2",
      location?.state?.employee?.user_employee_info?.bank_account
        ? location?.state?.employee?.user_employee_info?.bank_account
        : "-"
    );
    // 주소 set
    setEnrollCompany({
      address: location?.state?.employee?.user_employee_info?.address,
      zipcode: location?.state?.employee?.user_employee_info?.zip_code,
    });
    setValue("zipCode", enrollCompany.zipcode);
    setValue("address", enrollCompany.address);
    setValue(
      "detailAddress",
      location?.state?.employee?.user_employee_info?.detail_address
    );
    // 상태 set
    for (let i = 0; i < EMPLOYEE_STATUS_OPTIONS.length; i++) {
      if (
        EMPLOYEE_STATUS_OPTIONS[i].value ===
        location?.state?.employee?.user_employee_info?.status
      ) {
        setValue("employeeStatus", EMPLOYEE_STATUS_OPTIONS[i]);
      }
    }
    // 입사일 set
    setValue(
      "employment",
      dayjs(location?.state?.employee?.user_employee_info?.join_date).format(
        "YYYY-MM-DD"
      )
    );
    // 퇴사일 set
    setValue(
      "departure",
      location?.state?.employee?.user_employee_info?.leave_date
        ? dayjs(
            location?.state?.employee?.user_employee_info?.leave_date
          ).format("YYYY-MM-DD")
        : "-"
    );
    // 근로 형태 set
    for (let i = 0; i < EMPLOYEE_TYPE_OPTIONS.length; i++) {
      if (
        EMPLOYEE_TYPE_OPTIONS[i].value ===
        location?.state?.employee?.user_employee_info?.user_role
      ) {
        setValue("employeeWorkForm", EMPLOYEE_TYPE_OPTIONS[i]);
      }
    }
    // 계약 기간 set
    setValue(
      "employeeContract",
      location?.state?.employee?.user_employee_info?.term
    );
    // 계약 연봉 set
    setValue(
      "employeeContractSalary",
      location?.state?.employee?.user_employee_info?.contract_salary
        ? location?.state?.employee?.user_employee_info?.contract_salary
        : "-"
    );
    // 현재 연봉 set
    setValue(
      "employeeCurrentSalary",
      location?.state?.employee?.user_employee_info?.current_salary
        ? location?.state?.employee?.user_employee_info?.current_salary
        : "-"
    );
    // 작년 연봉 set
    setValue(
      "employeeLastYearSalary",
      location?.state?.employee?.user_employee_info?.last_years_salary
        ? location?.state?.employee?.user_employee_info?.last_years_salary
        : ""
    );
    // 급여 명세서 set
    setValue(
      "employeePaySpecification",
      location?.state?.employee?.user_employee_info?.payslip_info
        ? location?.state?.employee?.user_employee_info?.payslip_info
            ?.file_name +
            "." +
            location?.state?.employee?.user_employee_info?.payslip_info
              ?.extension
        : ""
    );
    // 등록 현황 set
    const reg_status =
      location?.state?.employee?.user_employee_info?.registration_status ===
      "ING"
        ? "등록중"
        : "등록 완료";
    setValue("employeeRegistStatus", reg_status);
    // 지급 연차 set
    setValue(
      "employeeAnnualPayment",
      location?.state?.employee?.user_employee_info?.offer_holiday
        ? location?.state?.employee?.user_employee_info?.offer_holiday
        : "-"
    );
    // 비고 set
    setValue(
      "employeeContent",
      location?.state?.employee?.user_employee_info?.note
        ? location?.state?.employee?.user_employee_info?.note
        : "-"
    );
  }, [enrollCompany.zipcode, enrollCompany.address]);

  const imageFormData = new FormData();
  imageFormData.append("file", values.employeeIDPhoto[0]);

  //const handleEditEmployee = async () => {
  //  const attachment: any = [];

  //  if (
  //    location?.state?.employee?.user_employee_info?.profile_image_info &&
  //    values.employeeIDPhoto[0].name ===
  //      `${location?.state?.employee?.user_employee_info?.profile_image_info?.file_name}.${location?.state?.employee?.user_employee_info?.profile_image_info.extension}`
  //  ) {
  //    attachment.push(
  //      location?.state?.employee?.user_employee_info?.profile_image_info?.id
  //    );
  //  } else {
  //    try {
  //      const res = await request("post", "/files", {}, imageFormData);

  //      if (res?.status === 200) {
  //        attachment.push(res.data.result.id);
  //      }
  //    } catch (err) {
  //      console.log(err);
  //    }
  //  }

  //  let payload: EmployeePayloadTypes = {
  //    user_role: values.employeeWorkForm.value,
  //    user_name: values.employeeName,
  //    signname: values.employeeEmail,
  //    birth: values.employeeBOD,
  //    gender: values.employeeGender.value,
  //    working_place: values.employeePlace.value,
  //    department: values.employeeDepartment.value,
  //    position: values.employeePosition.value,
  //    rank: values.employeeRank.value,
  //    user_phone:
  //      values.employeePhone1.value +
  //      values.employeePhone2 +
  //      values.employeePhone3,
  //    bank: values.employeeBank1.value,
  //    bank_account: values.employeeBank2,
  //    zip_code: enrollCompany.zipcode,
  //    address: enrollCompany.address,
  //    detail_address: values.detailAddress,
  //    status: values.employeeStatus.value,
  //    join_date: values.employment,
  //    leave_date: values.departure !== "-" ? values.departure : null,
  //    term: values.employeeContract,
  //    contract_salary: values.employeeContractSalary,
  //    current_salary: values.employeeCurrentSalary,
  //    last_years_salary:
  //      values.employeeLastYearSalary !== ""
  //        ? values.employeeLastYearSalary
  //        : null,
  //    payslip: location?.state?.employee?.user_employee_info?.payslip_info
  //      ? location?.state?.employee?.user_employee_info?.payslip_info.id
  //      : null,
  //    registration_status:
  //      location?.state?.employee?.user_employee_info?.registration_status,
  //    offer_holiday: values.employeeAnnualPayment,
  //    left_holiday: location?.state?.employee?.user_employee_info?.left_holiday,
  //    used_holiday: location?.state?.employee?.user_employee_info?.used_holiday,
  //    used_paid_holiday:
  //      location?.state?.employee?.user_employee_info?.used_paid_holiday,
  //    used_unpaid_holiday:
  //      location?.state?.employee?.user_employee_info?.used_unpaid_holiday,
  //    note: values.employeeContent,
  //  };

  //  if (attachment) {
  //    payload = {
  //      ...payload,
  //      profile_image_id: attachment,
  //    };
  //  }

  //  await request(
  //    "put",
  //    `/users/edit/employee/${location?.state?.employee.id}`,
  //    {},
  //    payload
  //  )
  //    .then((res: any) => {
  //      if (res.status === 200) {
  //        noti("수정되었습니다.", "확인");
  //        navigate("/curr_status/employee", { replace: true });
  //      }
  //    })
  //    .catch((err) => {
  //      throw err;
  //    });
  //};

  return (
    <Layout page={PAGE_LAYOUT.employee_edit}>
      <EmploteeRegistStyle>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              handleSubmit(
                () => {
                  if (values.employeeIDPhoto.length <= 0) {
                    noti("증명사진을 첨부해주세요.", "확인");
                  } else {
                    //handleEditEmployee();
                  }
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div>
              <h3>&#183; 개인 정보 &#183;</h3>
              <Row label="성명">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeName"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        placeholder="성명을 입력하세요."
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="생년월일">
                <div
                  className="date-image"
                  onClick={() => {
                    setOpenBOD(true);
                  }}
                >
                  <img src={IconCalSVG} alt="캘린더" />
                </div>
                <div className="input-sec date-input">
                  <Controller
                    control={control}
                    name="employeeBOD"
                    render={({ field }) => (
                      <DatePicker
                        //register={register("employeeBOD")}
                        isOpen={openBOD}
                        setIsOpen={setOpenBOD}
                        popoverPositions={["bottom"]}
                        onSelect={(date) => {
                          field.onChange(dayjs(date).format("YYYY-MM-DD"));
                        }}
                        value={field.value}
                        closeOnSelect={true}
                        onChange={() => {}}
                        placeholder="생년월일을 선택하세요."
                        //errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="성별">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeGender"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="primary"
                        fullWidth
                        options={GENDER_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="사원번호">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeNumber"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="증명사진">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeIDPhoto"
                    render={({ field }) => (
                      <FileInput
                        {...field}
                        accept=".jpg, .png"
                        errors={errors}
                        fullWidth
                        placeholder={"(JPG(JPEG), PNG / 최대 5MB까지)"}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="이메일 주소">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeEmail"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        placeholder="이메일을 입력하세요."
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="근무지">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeePlace"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="primary"
                        fullWidth
                        options={MasterCode.SPOT}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="부서">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeDepartment"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="부서명을 선택하세요."
                        variant="primary"
                        fullWidth
                        options={MasterCode.DEPT}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="직급">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeRank"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="직급을 선택하세요."
                        variant="primary"
                        fullWidth
                        options={MasterCode.RANK}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="직책">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeePosition"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="직책을 선택하세요."
                        variant="primary"
                        fullWidth
                        options={MasterCode.POSI}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="휴대폰 번호">
                <div className="input-sec phonenumber">
                  <Controller
                    control={control}
                    name="employeePhone1"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="tertiary"
                        options={PREFIX_NUMBER_OPTIONS}
                        errors={errors}
                        disabled
                        bgColor="#f9f9f9"
                      />
                    )}
                  />
                  <span>-</span>
                  <Controller
                    control={control}
                    name="employeePhone2"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                  <span>-</span>
                  <Controller
                    control={control}
                    name="employeePhone3"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength="4"
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="계좌번호">
                <div className="input-sec bank-select">
                  <Controller
                    control={control}
                    name="employeeBank1"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="은행 선택"
                        variant="secondary"
                        options={CARD_COMPANY_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                  <span className="void-sec"></span>
                  <Controller
                    control={control}
                    name="employeeBank2"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="'-' 없이 숫자만 입력하세요."
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="주소">
                <div className="input-sec">
                  <div className="zipcode">
                    <Controller
                      control={control}
                      name="zipCode"
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          placeholder="우편번호"
                          value={enrollCompany.zipcode || ""}
                          fullWidth
                          errors={errors}
                        />
                      )}
                    />
                    <button
                      type="button"
                      className="address-search"
                      onClick={() => {
                        setPopup(true);
                      }}
                    >
                      주소찾기
                    </button>
                    {popup && (
                      <Postcode
                        setPopup={setPopup}
                        company={enrollCompany}
                        setcompany={setEnrollCompany}
                      />
                    )}
                  </div>
                  <div className="void-sec"></div>
                  <Controller
                    control={control}
                    name="address"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="주소"
                        fullWidth
                        value={enrollCompany.address || ""}
                        errors={errors}
                      />
                    )}
                  />
                  <div className="void-sec"></div>
                  <Controller
                    control={control}
                    name="detailAddress"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder="상세주소를 입력하세요."
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
            </div>
            <div>
              <h3>&#183; 계약 정보 &#183;</h3>
              <Row label="상태">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeStatus"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="primary"
                        fullWidth
                        options={EMPLOYEE_STATUS_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="입사일">
                <div
                  className="date-image"
                  onClick={() => {
                    setOpenEmployment(true);
                  }}
                >
                  <img src={IconCalSVG} alt="캘린더" />
                </div>
                <div className="input-sec date-input">
                  <Controller
                    control={control}
                    name="employment"
                    render={({ field }) => (
                      <DatePicker
                        //register={register("employment")}
                        isOpen={openEmployment}
                        setIsOpen={setOpenEmployment}
                        popoverPositions={["bottom"]}
                        onSelect={(date) => {
                          field.onChange(dayjs(date).format("YYYY-MM-DD"));
                        }}
                        value={field.value}
                        closeOnSelect={true}
                        onChange={() => {}}
                        //errors={errors}
                        placeholder="입사일을 선택하세요."
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="퇴사일">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="departure"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                        placeholder={"-"}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="근로 형태">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeWorkForm"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        variant="primary"
                        fullWidth
                        options={EMPLOYEE_TYPE_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="계약 기간">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeContract"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        fullWidth
                        errors={errors}
                      >
                        년
                      </Textbox>
                    )}
                  />
                </div>
              </Row>
              <div className="fixed-void">
                <div className="fixed-void-text">총 근무 기간</div>
                <div>{"-"}</div>
              </div>
              <Row label="계약 연봉">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeContractSalary"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        fullWidth
                        errors={errors}
                      >
                        만원
                      </Textbox>
                    )}
                  />
                </div>
              </Row>
              <Row label="현재 연봉">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeCurrentSalary"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        fullWidth
                        errors={errors}
                      >
                        만원
                      </Textbox>
                    )}
                  />
                </div>
              </Row>
              <Row label="작년 연봉">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeLastYearSalary"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        fullWidth
                        disabled
                      >
                        만원
                      </Textbox>
                    )}
                  />
                </div>
              </Row>
              <Row label="급여 명세서">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeePaySpecification"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                        placeholder="급여명세서를 업로드 해주세요.(pdf)"
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="등록 현황">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="employeeRegistStatus"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        errors={errors}
                        disabled
                      />
                    )}
                  />
                </div>
              </Row>
            </div>
            <div>
              <h3>&#183; 연차 정보 &#183;</h3>
              <div className="annual-box">
                <div>
                  <div className="fixed-void-text">지급 연차</div>
                  <div className="fixed-void-content">
                    <Controller
                      control={control}
                      name="employeeAnnualPayment"
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="text"
                          multiline={false}
                          fullWidth={true}
                          placeholder="지급연차를 입력하세요."
                          errors={errors}
                        />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <div className="fixed-void-text">이용 연차</div>
                  <div>
                    {location?.state?.employee?.user_employee_info?.used_holiday
                      ? location?.state?.employee?.user_employee_info
                          ?.used_holiday
                      : "-"}
                  </div>
                </div>
                <div>
                  <div className="fixed-void-text">남은 연차</div>
                  <div>
                    {location?.state?.employee?.user_employee_info?.left_holiday
                      ? location?.state?.employee?.user_employee_info
                          ?.left_holiday
                      : "-"}
                  </div>
                </div>
                <div>
                  <div className="fixed-void-text">이용 유급휴가</div>
                  <div>
                    {location?.state?.employee?.user_employee_info
                      ?.used_paid_holiday
                      ? location?.state?.employee?.user_employee_info
                          ?.used_paid_holiday
                      : "-"}
                  </div>
                </div>
                <div>
                  <div className="fixed-void-text">이용 무급휴가</div>
                  <div>
                    {location?.state?.employee?.user_employee_info
                      ?.used_unpaid_holiday
                      ? location?.state?.employee?.user_employee_info
                          ?.used_unpaid_holiday
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h3>&#183; 비고 &#183;</h3>
              <div className="input-sec">
                <Controller
                  control={control}
                  name="employeeContent"
                  render={({ field }) => (
                    <Input
                      {...field}
                      multiline
                      useMaxLengthIndicator
                      maxLength="1000"
                      type="text"
                      placeholder="내용을 입력하세요."
                      fullWidth
                      errors={errors}
                    />
                  )}
                />
              </div>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                취소
              </Button>
              <span className="void-sec"></span>
              <Button type="submit" variant="primary" preset="2">
                수정
              </Button>
            </div>
          </form>
        </div>
      </EmploteeRegistStyle>
    </Layout>
  );
};

export default EmployeeEdit;

const EmploteeRegistStyle = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 35px;
  }

  form {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #222;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .phonenumber {
    display: flex;

    span {
      margin: 27px 10px 0 10px;
    }
  }

  .zipcode {
    display: flex;
  }

  .address-search {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
  }

  .void-sec {
    height: 10px;
    margin: 0 5px;
  }

  .bank-select {
    display: flex;
  }

  .submit-btn {
    width: 408px;
    margin: 0 auto;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }

  .fixed-void {
    display: flex;
    align-items: center;
    padding: 30px 0;
    margin-bottom: 40px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .fixed-void-text {
    width: 150px;
    font-size: 18px;
    font-weight: bold;
    color: #b2b2b2;
  }

  .fixed-void-content {
    width: 100%;
  }

  .annual-box {
    margin-top: -40px;
    & > div {
      display: flex;
      align-items: center;
      padding: 30px 0;
      border-bottom: 1px solid #ddd;
    }
  }
`;
