import { useState, forwardRef, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import FilterIcSVG from "assets/images/filter_ic.svg";
import FilterIcWSVG from "assets/images/filter_ic_W.svg";
import BtnCheckOnSVG from "assets/images/btn_check_on.png";
import BtnCheckOffSVG from "assets/images/btn_check_off.png";

type OptionTypes = {
  value: string;
  label: string;
  checked: boolean;
};

interface FilterProps {
  option: OptionTypes[];
  handleFormSubmit: () => void;
  fontWegiht?: string;
  value?: any;
}

const Filter = forwardRef<HTMLInputElement, FilterProps>((props, ref) => {
  const { option, handleFormSubmit, ...rest } = props;
  const [checked, setChecked] = useState<any>([]);
  const [isFilter, setIsFilter] = useState(false);
  const mode = localStorage.getItem("theme");

  const root = rootCss(props);

  useEffect(() => {
    const allFilter = option.map((e: any) => {
      if (e.checked) return e.value;
    });
    setChecked(allFilter);
  }, []);

  const handleCheck = (e: any, item: OptionTypes) => {
    if (item.value === "All") {
      if (e.target.checked) {
        const allFilter = option.map((e: OptionTypes) => {
          e.checked = true;
          if (e.checked) return e.value;
        });
        setChecked(allFilter);
      } else {
        option.map((e: OptionTypes) => (e.checked = false));
        setChecked([]);
      }
    } else {
      if (e.target.checked) {
        item.checked = true;
        setChecked([...checked, e.target.value]);
      } else {
        item.checked = false;
        setChecked(checked.filter((v: boolean) => v !== e.target.value));
      }
    }
  };

  return (
    <div css={root}>
      <div
        className="select-filter"
        onClick={() => setIsFilter((prev) => !prev)}
      >
        <div>
          <img
            src={mode === "dark" ? FilterIcWSVG : FilterIcSVG}
            alt="필터 아이콘"
          />
        </div>
        <div>필터 선택</div>
      </div>
      {isFilter && (
        <div className="filter-section">
          <div className="filter-content">
            {option.map((item: any, i: number) => {
              return (
                <div key={i} className="filter-item">
                  <label className="component-checkbox-filter">
                    <input
                      ref={ref}
                      type="checkbox"
                      value={item.value}
                      onChange={(e: any) => handleCheck(e, item)}
                      checked={item.checked}
                    />
                    {item.label}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="filter-regist">
            <div
              className="filter-reset"
              onClick={() => {
                const allFilter = option.map((e: OptionTypes) => {
                  e.checked = true;
                  if (e.checked) return e.value;
                });
                setChecked(allFilter);
              }}
            >
              초기화
            </div>
            <div
              className="filter-submit"
              onClick={() => {
                rest.value.pop();
                rest.value.push(checked);
                handleFormSubmit();
                setIsFilter(false);
              }}
            >
              적용
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

const rootCss = (props: FilterProps) => {
  return css`
    .select-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 126px;
      height: 52px;
      border: solid 1px #444;
      font-size: 15px;
      font-weight: normal;
      cursor: pointer;

      img {
        margin-right: 4px;
      }
    }

    .filter-section {
      position: absolute;
      z-index: 99999;
      width: 680px;
      border: solid 1px #707070;
      padding: 22px 30px 0 30px;
      margin-top: 5px;

      .filter-content {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 22px;
        border-bottom: 1px solid #d9d9d9;
      }

      .filter-regist {
        display: flex;
        justify-content: end;
        margin: 12px 0;
      }

      .filter-item {
        width: 25%;
        margin: 8px 0;
      }

      .filter-reset {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 74px;
        height: 34px;
        margin: 0 10px 0 0;
        border: solid 1px #b2b2b2;
        font-size: 15px;
        font-weight: normal;
        color: #b2b2b2;
        cursor: pointer;
      }

      .filter-submit {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: normal;
        cursor: pointer;
        width: 62px;
        height: 34px;
        color: #fff;
      }

      label {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 15px;
        cursor: pointer;
        font-weight: ${props.fontWegiht ? "bold" : "nomal"};
      }
      input {
        appearance: none;
        margin: 0 8px;
        width: 20px;
        height: 20px;
        background: url(${BtnCheckOffSVG}) no-repeat center/100%;
        &:checked {
          background: url(${BtnCheckOnSVG}) no-repeat center/100%;
        }
      }
    }
  `;
};

export default Filter;
