import { useState, forwardRef, useEffect } from "react";
import styled from "@emotion/styled";
import SearchMember from "components/SearchPopup/SearchMember";

interface FilterProps {
  value: any;
}

const PlanJoinMember = forwardRef<HTMLDivElement, FilterProps>((props, ref) => {
  const { ...rest } = props;
  const [result, setResult] = useState<any>([]);
  const [openSearchMember, setOpenSearchMember] = useState(false);

  useEffect(() => {
    if (result.length > 0) {
      rest.value.push(result[0]);
    }
  }, [result]);

  return (
    <PlanJoinMemberStyled>
      <div className="member-add-btn" onClick={() => setOpenSearchMember(true)}>
        {result.length === 0 ? (
          <span>추가</span>
        ) : (
          "[" + result[0]?.department + "] " + result[0]?.name
        )}
      </div>
      {openSearchMember && (
        <SearchMember
          setOpenSearchMember={setOpenSearchMember}
          setResult={setResult}
        />
      )}
    </PlanJoinMemberStyled>
  );
});

export default PlanJoinMember;

const PlanJoinMemberStyled = styled.div`
  .member-add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 221px;
    height: 56px;
    border: solid 1px #ddd;
    background-color: #fff;
    font-size: 18px;
    font-weight: normal;
    cursor: pointer;

    span {
      color: #78aef3;
      text-decoration: underline;
    }
  }
`;
