export const ERROR_COLOR = "#ff4b4b";

export const PAGE_LAYOUT = {
  main: {
    headingText: "환영합니다.",
    headingContent: "디지털헬스케어의 관리자 플랫폼을 확인해보세요.",
  },
  employee: {
    headingText: "CRM",
    headingContent: "디지털헬스케어의 CRM 현황을 확인해보세요.",
  },
  employee_submit: {
    headingText: "임직원 등록",
    headingContent: "디지털헬스케어의 임직원 현황을 확인해보세요.",
  },
  employee_edit: {
    headingText: "임직원 수정",
    headingContent: "디지털헬스케어의 임직원 현황을 확인해보세요.",
  },
  part_timer_support: {
    headingText: "통계",
    headingContent: "디지털헬스케어의 통계를 확인해보세요.",
  },
  part_timer: {
    headingText: "파트타이머 현황",
    headingContent: "디지털헬스케어의 파트타이머 현황을 확인해보세요.",
  },
  part_timer_regist_list: {
    headingText: "파트타이머 등록 현황",
    headingContent: "디지털헬스케어의 파트타이머 지원자 현황을 확인해보세요.",
  },
  part_timer_regist: {
    headingText: "파트타이머 등록",
    headingContent: "디지털헬스케어의 파트타이머 등록을 확인해보세요.",
  },
  partner: {
    headingText: "협력사 현황",
    headingContent: "디지털헬스케어의 협력사 현황을 확인해보세요.",
  },
  work_status: {
    headingText: "기준 관리",
    headingContent: "디지털헬스케어의 기준 관리를 확인해보세요.",
  },
  approval: {
    headingText: "상품 관리",
    headingContent: "디지털헬스케어의 상품 관리를 확인해보세요.",
  },
  approval_detail: {
    headingText: "결재 상세보기",
    headingContent: "디지털헬스케어의 결재 정보를 확인해보세요.",
  },
  notice: {
    headingText: "공지사항",
    headingContent: "디지털헬스케어의 공지사항을 확인해보세요.",
  },
  event_notice: {
    headingText: "코칭 관리",
    headingContent: "디지털헬스케어의 코칭 관리를 확인해보세요.",
  },
  roundley: {
    headingText: "주문 관리",
    headingContent: "디지털헬스케어의 주문 관리를 확인해보세요.",
  },
  linen: {
    headingText: "컨텐츠 관리",
    headingContent: "디지털헬스케어의 컨텐츠 관리를 확인해보세요.",
  },
  heon: {
    headingText: "HE:ON",
    headingContent: "디지털헬스케어의 HE:ON 판매현황을 확인해보세요.",
  },
  mypage: {
    headingText: "상담 관리",
    headingContent: "디지털헬스케어의 상담 관리를 확인해보세요.",
  },
  mail: {
    headingText: "건강검진 관리",
    headingContent: "디지털헬스케어의 건강검진 관리를 확인해보세요.",
  },
  auth_setting: {
    headingText: "프로모션 관리",
    headingContent: "디지털헬스케어의 프로모션 관리를 확인해보세요",
  },
  linen2: {
    headingText: "멤버십",
    headingContent: "",
  },
  setting: {
    headingText: "환경설정",
    headingContent: "",
  },
  help: {
    headingText: "BBS/CS",
    headingContent: "",
  },
};

export const EmployeeFilterOptions = [
  { value: "All", label: "전체", checked: true },
  { value: "workplace", label: "근무지", checked: true },
  { value: "department", label: "부서", checked: true },
  { value: "position", label: "직급", checked: true },
  { value: "rank", label: "직책", checked: true },
  { value: "typeOfWork", label: "근로형태", checked: true },
  { value: "term", label: "계약기간", checked: true },
  { value: "joining", label: "입사일", checked: true },
  { value: "leave", label: "퇴사일", checked: true },
  { value: "workingDay", label: "총 근무 기간", checked: true },
  { value: "contract", label: "계약연봉", checked: true },
  { value: "current", label: "현재연봉", checked: true },
  { value: "lastYear", label: "작년연봉", checked: true },
  { value: "basicAnnual", label: "기본연차", checked: true },
  { value: "useAnnual", label: "이용연차", checked: true },
  { value: "remainAnnual", label: "남은연차", checked: true },
  { value: "paidVacation", label: "이용 유급휴가", checked: true },
  { value: "unpaidVacation", label: "이용 무급휴가", checked: true },
  { value: "bank", label: "은행", checked: true },
  { value: "bankAccount", label: "계좌번호", checked: true },
  { value: "address", label: "주소", checked: true },
  { value: "email", label: "이메일", checked: true },
  { value: "phoneNum", label: "휴대폰 번호", checked: true },
  { value: "note", label: "비고", checked: true },
];

export const PartTimerSupportFilterOptions = [
  { value: "All", label: "전체", checked: true },
  { value: "email", label: "이메일", checked: true },
  { value: "address", label: "주소", checked: true },
  { value: "primary", label: "1차 희망분야", checked: true },
  { value: "secondary", label: "2차 희망분야", checked: true },
  { value: "highestLevel", label: "최종 학력", checked: true },
  { value: "schoolName", label: "학교명", checked: true },
  { value: "major", label: "전공", checked: true },
  { value: "graduationStatus", label: "졸업구분", checked: true },
  { value: "classOf", label: "졸업연도", checked: true },
  { value: "career", label: "총 경력", checked: true },
  { value: "military", label: "군 복무 상태", checked: true },
  { value: "disability", label: "장애여부", checked: true },
  { value: "bank", label: "은행", checked: true },
  { value: "account", label: "계좌번호", checked: true },
  { value: "credentials", label: "자격증", checked: true },
  { value: "phoneNumber", label: "휴대폰 번호", checked: true },
  { value: "resume", label: "이력서보기", checked: true },
];

export const PartTimerFilterOptions = [
  { value: "All", label: "전체", checked: true },
  { value: "terms", label: "약관 동의여부", checked: true },
  { value: "email", label: "이메일", checked: true },
  { value: "birth", label: "생년월일", checked: true },
  { value: "gender", label: "성별", checked: true },
  { value: "address", label: "주소", checked: true },
  { value: "phone", label: "전화번호", checked: true },
  { value: "bank", label: "은행", checked: true },
  { value: "account", label: "계좌번호", checked: true },
  { value: "isCareer", label: "경력여부", checked: true },
  { value: "primaryPart", label: "1차 희망부서", checked: true },
  { value: "secondaryPart", label: "2차 희망부서", checked: true },
  { value: "field", label: "주 분야", checked: true },
  { value: "hourlyWage", label: "최근 시급", checked: true },
  { value: "cumulativeTime", label: "월 누적 시간", checked: true },
  { value: "rating", label: "등급", checked: true },
  { value: "increaseRate", label: "인상률", checked: true },
  { value: "veteran", label: "보훈상태", checked: true },
  { value: "emergency", label: "비상 연락망", checked: true },
  { value: "relationship", label: "관계", checked: true },
  { value: "cumulativeNumber", label: "경고 누적 수", checked: true },
  { value: "note", label: "비고", checked: true },
  { value: "restriction", label: "누적시간 제한여부", checked: true },
];

export const partnerFilterOptions = [
  { value: "All", label: "전체", checked: true },
  { value: "CEO", label: "대표자 성명", checked: true },
  { value: "manager", label: "담당자 성명", checked: true },
  { value: "position", label: "직급", checked: true },
  { value: "companyAddress", label: "업체 주소", checked: true },
  { value: "CEOPhone", label: "대표자 연락처", checked: true },
  { value: "managerPhone", label: "담당자 연락처", checked: true },
  { value: "fax", label: "팩스 번호", checked: true },
  { value: "email", label: "이메일", checked: true },
  { value: "bank", label: "은행", checked: true },
  { value: "account", label: "계좌번호", checked: true },
];

export const approvalFilterOptions = [
  { value: "All", label: "전체", checked: true },
  { value: "applicant", label: "신청자", checked: true },
  { value: "worker", label: "근무자", checked: true },
  { value: "applicationDate", label: "신청일", checked: true },
  { value: "senior1", label: "상급자1", checked: true },
  { value: "senior2", label: "상급자2", checked: true },
  { value: "senior3", label: "상급자3", checked: true },
];

export const EMPLOYEE_STATUS_OPTIONS = [
  { value: "EXPECTED", label: "입사 예정" },
  { value: "IN_OFFICE", label: "재직중" },
  { value: "LEAVE", label: "퇴사" },
  { value: "DELETE", label: "삭제" },
];

export const EMPLOYEE_TYPE_OPTIONS = [
  { value: "CONTRACT_WORKER", label: "계약직" },
  { value: "FULLTIME_WORKER", label: "정규직" },
];

export const GENDER_OPTIONS = [
  { value: "Male", label: "남성" },
  { value: "Female", label: "여성" },
];

export const PartTimerLankSelectOptions = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
];

export const PartTimerWarningSelectOptions = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

export const EmployeeSelectOptions = [
  { value: "ALL", label: "전체" },
  { value: "ID", label: "사원번호" },
  { value: "NAME", label: "성명" },
  { value: "DEPARTMENT", label: "부서" },
  { value: "POSITION", label: "직급" },
  { value: "RANK", label: "직책" },
];

export const ParttimerSupportSelectOptions = [
  { value: "ALL", label: "전체" },
  { value: "ID", label: "사원번호" },
  { value: "NAME", label: "성명" },
];

export const ParttimerSelectOptions = [
  { value: "ALL", label: "전체" },
  { value: "ID", label: "사원번호" },
  { value: "DEPARTMENT", label: "소속" },
  { value: "NAME", label: "성명" },
];

export const PART_TIME_OPTIONS = [
  { value: "2000-01-01 00:00", label: "0시" },
  { value: "2000-01-01 01:00", label: "1시" },
  { value: "2000-01-01 02:00", label: "2시" },
  { value: "2000-01-01 03:00", label: "3시" },
  { value: "2000-01-01 04:00", label: "4시" },
  { value: "2000-01-01 05:00", label: "5시" },
  { value: "2000-01-01 06:00", label: "6시" },
  { value: "2000-01-01 07:00", label: "7시" },
  { value: "2000-01-01 08:00", label: "8시" },
  { value: "2000-01-01 09:00", label: "9시" },
  { value: "2000-01-01 10:00", label: "10시" },
  { value: "2000-01-01 11:00", label: "11시" },
  { value: "2000-01-01 12:00", label: "12시" },
  { value: "2000-01-01 13:00", label: "13시" },
  { value: "2000-01-01 14:00", label: "14시" },
  { value: "2000-01-01 15:00", label: "15시" },
  { value: "2000-01-01 16:00", label: "16시" },
  { value: "2000-01-01 17:00", label: "17시" },
  { value: "2000-01-01 18:00", label: "18시" },
  { value: "2000-01-01 19:00", label: "19시" },
  { value: "2000-01-01 20:00", label: "20시" },
  { value: "2000-01-01 21:00", label: "21시" },
  { value: "2000-01-01 22:00", label: "22시" },
  { value: "2000-01-01 23:00", label: "23시" },
];

export const PREFIX_NUMBER_OPTIONS = [
  { value: "010", label: "010", code: "01" },
  { value: "011", label: "011", code: "02" },
  { value: "016", label: "016", code: "03" },
  { value: "017", label: "017", code: "04" },
  { value: "019", label: "019", code: "05" },
  { value: "02", label: "02", code: "06" },
  { value: "031", label: "031", code: "07" },
  { value: "032", label: "032", code: "08" },
  { value: "033", label: "033", code: "08" },
  { value: "041", label: "041", code: "10" },
  { value: "042", label: "042", code: "11" },
  { value: "043", label: "043", code: "12" },
  { value: "051", label: "051", code: "13" },
  { value: "052", label: "052", code: "14" },
  { value: "053", label: "053", code: "15" },
  { value: "054", label: "054", code: "16" },
  { value: "055", label: "055", code: "17" },
  { value: "061", label: "061", code: "18" },
  { value: "062", label: "062", code: "19" },
  { value: "063", label: "063", code: "20" },
  { value: "064", label: "064", code: "21" },
];

export const EMPLOY_OPTIONS = [
  { value: "Full-time", label: "정규직", code: "01" },
  { value: "Temporary_Worker", label: "비정규직", code: "02" },
  { value: "Contract_Worker", label: "계약직", code: "03" },
  { value: "Freelancer", label: "프리랜서", code: "04" },
  { value: "Part_time_Job", label: "아르바이트", code: "05" },
];

export const EDUCATION_OPTIONS = [
  { value: "Graduate_School", label: "대학원", code: "01" },
  { value: "University", label: "대학", code: "02" },
  { value: "College", label: "전문대학", code: "03" },
  { value: "High_School", label: "고등학교", code: "04" },
  { value: "Middle_School", label: "중학교", code: "05" },
];

export const GRADUATE_OPTIONS = [
  { value: "Attending", label: "재학 중", code: "01" },
  { value: "Expected_to_Graduate", label: "졸업 예정", code: "02" },
  { value: "Graduated", label: "졸업", code: "03" },
];

export const MILITARY_OPTIONS = [
  { value: "None", label: "해당없음", code: "01" },
  { value: "Fulfilled", label: "만기전역", code: "02" },
  { value: "Impairment", label: "의병 · 의가사 제대", code: "03" },
  { value: "Unfulfilled", label: "미필", code: "04" },
  { value: "Exempted", label: "면제", code: "05" },
];

export const ELIGIBILTY_OPTIONS = [
  { value: "Eligible", label: "해당", code: "01" },
  { value: "Not_Eligible", label: "미해당", code: "02" },
];

export const DISABLED_OPTIONS = [
  { value: "None", label: "해당없음", code: "01" },
  { value: "Severe_Disease", label: "중증", code: "02" },
  { value: "Mild_Disease", label: "경증", code: "03" },
  { value: "Level1", label: "1급", code: "04" },
  { value: "Level2", label: "2급", code: "05" },
  { value: "Level3", label: "3급", code: "06" },
  { value: "Level4", label: "4급", code: "07" },
  { value: "Level5", label: "5급", code: "08" },
  { value: "Level6", label: "6급", code: "09" },
];

export const DEPARTMENT_OPTIONS = [
  { value: "Room_part_Team", label: "객실팀", code: "01" },
  { value: "Housekeeping", label: "객실정비팀", code: "02" },
  { value: "Food_and_Beverage", label: "식음팀", code: "03" },
  { value: "Security", label: "안전관리팀", code: "04" },
  { value: "Facilities_Management", label: "시설관리팀", code: "05" },
];

export const PART1_OPTIONS = [
  { value: "Concierge", label: "컨시어지", code: "01" },
  { value: "Front_Desk", label: "프론트", code: "02" },
  { value: "Reservation", label: "예약", code: "03" },
  { value: "Door_Attendant", label: "도어맨", code: "04" },
  { value: "Valet_Parking", label: "발렛파킹", code: "05" },
  { value: "Fitness", label: "휘트니스", code: "06" },
];

export const PART2_OPTIONS = [
  { value: "Housekeeper", label: "객실정리", code: "01" },
  { value: "Laundry", label: "라운드리", code: "02" },
  { value: "Linen", label: "린넨", code: "03" },
  { value: "Minibar", label: "미니바", code: "04" },
  { value: "Room_Maid", label: "룸메이드", code: "05" },
  { value: "Order_Taker", label: "오더테이커", code: "06" },
];

export const PART3_OPTIONS = [
  { value: "Restaurant", label: "영업장", code: "01" },
  { value: "Banquet", label: "연회", code: "02" },
];

export const PART4_OPTIONS = [
  { value: "Disaster_Prevention", label: "방재", code: "01" },
  { value: "Security", label: "경비", code: "02" },
];

export const PART5_OPTIONS = [
  { value: "Construction", label: "건축", code: "01" },
  { value: "Electricity", label: "전기", code: "02" },
  { value: "Equipment", label: "설비", code: "03" },
];

export const PARTTIMER_SUPPORT_PART_OPTIONS = [
  { value: "Concierge", label: "컨시어지", code: "01" },
  { value: "Front_Desk", label: "프론트", code: "02" },
  { value: "Reservation", label: "예약", code: "03" },
  { value: "Door_Attendant", label: "도어맨", code: "04" },
  { value: "Valet_Parking", label: "발렛파킹", code: "05" },
  { value: "Fitness", label: "휘트니스", code: "06" },
  { value: "Housekeeper", label: "객실정리", code: "07" },
  { value: "Laundry", label: "라운드리", code: "08" },
  { value: "Linen", label: "린넨", code: "09" },
  { value: "Minibar", label: "미니바", code: "10" },
  { value: "Room_Maid", label: "룸메이드", code: "11" },
  { value: "Order_Taker", label: "오더테이커", code: "12" },
  { value: "Restaurant", label: "영업장", code: "13" },
  { value: "Banquet", label: "연회", code: "14" },
  { value: "Disaster_Prevention", label: "방재", code: "15" },
  { value: "Security", label: "경비", code: "16" },
  { value: "Construction", label: "건축", code: "17" },
  { value: "Electricity", label: "전기", code: "18" },
  { value: "Equipment", label: "설비", code: "19" },
];

export const CARD_COMPANY_OPTIONS = [
  { value: "BC", label: "비씨", code: "01" },
  { value: "KB", label: "KB국민", code: "02" },
  { value: "KEB_HANA", label: "하나(외환)", code: "03" },
  { value: "SAMSUNG", label: "삼성", code: "04" },
  { value: "SHINHAN", label: "신한", code: "06" },
  { value: "HYUNDAI", label: "현대", code: "07" },
  { value: "LOTTE", label: "롯데", code: "08" },
  { value: "HANMI", label: "한미", code: "09" },
  {
    value: "SHINSAEGAE_HANMI",
    label: "신세계한미",
    code: "10",
  },
  { value: "CITY", label: "씨티", code: "11" },
  { value: "NH", label: "NH채움", code: "12" },
  { value: "SUHYUP", label: "수협", code: "13" },
  { value: "SHINHYUP", label: "신협", code: "14" },
  { value: "WOORI", label: "우리", code: "15" },
  { value: "HANA", label: "하나", code: "16" },
  { value: "GWANGJU", label: "광주", code: "21" },
  { value: "JUNBUK", label: "전북", code: "22" },
  { value: "JEJU", label: "제주", code: "23" },
  { value: "SANEUN_CAPITAL", label: "산은캐피탈", code: "24" },
  { value: "FOREIGN_BISA", label: "해외비자", code: "25" },
  { value: "FOREIGN_MASTER", label: "해외마스터", code: "26" },
  { value: "FOREIGN_DINUS", label: "해외다이너스", code: "27" },
  { value: "FOREIGN_AMX", label: "해외AMX", code: "28" },
  { value: "FOREIGN_JCB", label: "해외JCB", code: "29" },
  { value: "SK_OK_CASH_BAG", label: "SK-OK CASH-BAG", code: "31" },
  { value: "POST_OFFICE", label: "우체국", code: "32" },
  { value: "SAVINGS_BANK", label: "저축은행", code: "33" },
  { value: "UNION_PAY", label: "은련", code: "34" },
  { value: "SAEMAUL_GEUMGO", label: "새마을금고", code: "35" },
  { value: "KDB", label: "KDB산업", code: "36" },
  { value: "KAKAO", label: "카카오뱅크", code: "37" },
  { value: "KBANK", label: "케이뱅크", code: "38" },
  { value: "PAYCO", label: "PAYCO 포인트", code: "39" },
  { value: "KAKAO_MONEY", label: "카카오머니", code: "40" },
  { value: "SSG", label: "SSG머니", code: "41" },
  { value: "NAVER", label: "네이버포인트", code: "42" },
];

export const SCHEDULE_OPTIONS = [
  { value: "WORKING", label: "근무 스케줄" },
  { value: "HOLIDAY", label: "휴가" },
];

export const CALENDER_OPTIONS = [
  { value: "WEEK", label: "주별" },
  { value: "MONTH", label: "월별" },
];

export const THEME_OPTIONS = [
  { value: "light", label: "화이트 모드" },
  { value: "dark", label: "다크 모드" },
];

export const APPROVAL_OPTIONS = [
  { value: "VACATION_APP", label: "연차 · 반차 신청" },
  { value: "LETTER_APP", label: "품의서" },
  { value: "PLAN_APP", label: "기획안" },
];

export const SIGN_OPTIONS = [
  { value: "SIGN_IMAGE", label: "서명 이미지 등록" },
  { value: "SIGN_FORM", label: "서명 양식 사용" },
];

export const PLACE_OPTIONS = [
  { value: "M_TOWER", label: "서울 M-Tower" },
  { value: "E_TOWER", label: "서울 E-Tower" },
  { value: "WORLD", label: "월드" },
  { value: "SIGNIEL", label: "시그니엘" },
];

export const MASTER_CODE_OPTIONS = [
  { value: "HEBR", label: "HE:ON - 브랜드" },
  { value: "HEPR", label: "HE:ON - 품목 구분" },
  { value: "SPOT", label: "지점" },
  { value: "DEPT", label: "부서" },
  { value: "RANK", label: "직급" },
  { value: "POSI", label: "직책" },
  { value: "COFI", label: "협력분야" },
  { value: "RECE", label: "라운드리 - 접수 형태" },
  { value: "COLL", label: "라운드리 - 수거 장소" },
  { value: "CLAS", label: "라운드리 - 분류" },
  { value: "DELI", label: "라운드리 - 배달 장소" },
];

export const NOTICE_OPTIONS = [
  { value: "NOTICE", label: "사내 공지사항" },
  { value: "FAMILY_EVENT", label: "사내 경조사" },
];

export const MODE_OPTIONS = [
  { value: "LIST_MODE", label: "목록 모드" },
  { value: "DATA_MODE", label: "데이터 모드" },
];

export const CODE_OPTIONS = [
  { value: "true", label: "사용" },
  { value: "false", label: "사용안함" },
];
