import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const ThemeStyle = createGlobalStyle`
  ${reset}
  body {
    background-color: ${({ theme }: { theme: any }) => theme.contentBgColor};
  }
  
  .header-logo {
    background-color: ${({ theme }: { theme: any }) => theme.logoBgColor};
  }
  
  .sidebar-wrap {
    background-color: ${({ theme }: { theme: any }) => theme.contentBgColor};
  }


  .menu-wrap {
    color: ${({ theme }: { theme: any }) => theme.sidebarColor};
    background-color: ${({ theme }: { theme: any }) => theme.sidebarBgColor};
  }
  
  .content-wrap {
    background-color: ${({ theme }: { theme: any }) => theme.contentBgColor};
  }

  .footer-wrap {
    background-color: ${({ theme }: { theme: any }) => theme.footerBgColor};
  }

  .main-board {
    background-color: ${({ theme }: { theme: any }) => theme.sidebarBgColor};
  }

  .main-inner-con22222 {
    background-color: ${({ theme }: { theme: any }) => theme.sidebarBgColor};
  }

  .main-inner-con2-line {
    background-color: ${({ theme }: { theme: any }) =>
      theme.maincontentLineColor};
  }

  .board-table {
    color: ${({ theme }: { theme: any }) => theme.boardTableColor};
    background-color: ${({ theme }: { theme: any }) => theme.contentBgColor};
  }

  .board-data {
    & > div {
      color: ${({ theme }: { theme: any }) => theme.fontColor};
      border-bottom: ${({ theme }: { theme: any }) =>
        `1px solid ${theme.boardDataLineColor}`};
    }
  }

  .board-more-btn {
    .board-more-btn-inner {
      border: ${({ theme }: { theme: any }) =>
        `1px solid ${theme.moreBtnColor}`};
    }
  }
  
  .main-user-name {
    span {
      color: ${({ theme }: { theme: any }) => theme.mainUserNameColor};
    }
  }

  .main-user-position,
  .main-user-part,
  .main-user-employeenum {
    color: ${({ theme }: { theme: any }) => theme.mainUserNameColor};
  }

  .status-wrap {
    background-color: ${({ theme }: { theme: any }) => theme.sidebarBgColor};
  }
  
  .no-data-wrap {
    color: ${({ theme }: { theme: any }) => theme.subColor};
  }
  
  .select-filter {
    color: ${({ theme }: { theme: any }) => theme.filterSelectColor};
  }
  
  .filter-section {
    background-color: ${({ theme }: { theme: any }) => theme.sidebarBgColor};
  }
  
  .filter-submit {
    background-color: ${({ theme }: { theme: any }) => theme.filterBtnColor};
  }
  
  .component-checkbox-filter {
    color: ${({ theme }: { theme: any }) => theme.subColor};
  }
  
  .fixed-table {
    color: ${({ theme }: { theme: any }) => theme.subColor};
    background-color: ${({ theme }: { theme: any }) => theme.listBgColor};
  }
  
  .fixed-data {
    color: ${({ theme }: { theme: any }) => theme.subColor};
    border-bottom: ${({ theme }: { theme: any }) =>
      theme.fixedBorderBottomColor};
      & > div {
        border-bottom: ${({ theme }: { theme: any }) =>
          theme.fixedBorderBottomColor};
      }
  }
  
  .scroll-table {
    & > div {
      color: ${({ theme }: { theme: any }) => theme.subColor};
      background-color: ${({ theme }: { theme: any }) => theme.listBgColor};
    }
  }
  
  .scroll-data {
    color: ${({ theme }: { theme: any }) => theme.subColor};
    border-bottom: ${({ theme }: { theme: any }) =>
      theme.fixedBorderBottomColor};
      & > div {
        border-bottom: ${({ theme }: { theme: any }) =>
          theme.fixedBorderBottomColor};
      }
  }
  
  .fixed-line {
    background-image: ${({ theme }: { theme: any }) => theme.fixedLineColor};
  }
`;
