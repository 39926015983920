/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ErrorMessage } from "@hookform/error-message";
import { ERROR_COLOR } from "utils/constants";

interface ErrorMessageProps {
  errors: any;
  name: any;
}

function ErrorMessageComponent(props: ErrorMessageProps) {
  const { errors, name } = props;

  let message = "";
  if (errors[name]?.value) {
    message = errors[name]?.value.message;
  } else {
    message = errors[name]?.message;
  }

  const root = errorMessage();
  return (
    <>
      {errors?.[name] && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={() => <p css={root}>{`${message}`}</p>}
        />
      )}
    </>
  );
}

const errorMessage = () => {
  return css`
    color: ${ERROR_COLOR};
    /*color: var(--primary-color2);*/
    font-weight: 400;
    font-size: 15px;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
  `;
};

export default ErrorMessageComponent;
