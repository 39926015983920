import { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Filter from "components/Filter/Filter";
import Textbox from "components/TextBox/TextBox";
import Search from "components/Search/Search";
import Pagination from "components/Pagination/Pagination";

import IconCalSVG from "assets/images/icon_cal.svg";
import IcDropBtnSVG from "assets/images/icon_drop.svg";
import DELSVG from "assets/images/DEL.svg";
import BtnCheckOffSVG from "assets/images/btn_check_off.png";
import BtnCheckOnSVG from "assets/images/btn_check_on.png";

import { CODE_OPTIONS } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

const defaultValues = {
  search: "",
  manageItemName: "",
};

const ItemManageForm = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [listData, setListData] = useState([{ test: "1111" }]);
  const [isAddItem, setIsAddItem] = useState(false);
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const handleDelList = () => {};

  const handleEditList = () => {};

  const handleSaveCode = () => {};

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <ItemManageFormStyled>
      <div className="item-manage-form-wrap">
        <div className="item-manage-form-inner">
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
              handleSubmit(
                () => {},
                (err) => {}
              );
            }}
          >
            <div>
              <Controller
                control={control}
                name="search"
                render={({ field }) => (
                  <Search
                    {...field}
                    placeholder="검색어를 입력하세요."
                    handleFormSubmit={handleFormSubmit}
                  />
                )}
              />
            </div>
          </form>
        </div>
        <div className="item-manage-form-btn">
          <div className="select-del" onClick={() => handleDelList()}>
            선택 삭제
          </div>
          <div className="select-edit" onClick={() => handleEditList()}>
            선택 수정
          </div>
          <div className="item-add" onClick={() => setIsAddItem(true)}>
            품목 추가
          </div>
        </div>
      </div>
      <div className="item-manage-form-body">
        <table>
          <thead>
            <tr>
              <th className="manage-head-child1">
                <input type="checkbox" name="" id="" />
              </th>
              <th className="manage-head-child2">품목명</th>
              <th className="manage-head-child3">구분</th>
              <th className="manage-head-child4">
                Washing (W)
                <br />
                Price / Qty
              </th>
              <th className="manage-head-child4">
                Dry Cleaning (D)
                <br />
                Price
              </th>
              <th className="manage-head-child4">
                Pressing (P)
                <br />
                Price / Qty
              </th>
              <th className="manage-head-btn"></th>
            </tr>
          </thead>
          <tbody>
            {isAddItem && (
              <tr>
                <td className="manage-head-child1">
                  <input type="checkbox" name="" id="" />
                </td>
                <td className="manage-head-child2">
                  <Controller
                    control={control}
                    name="manageItemName"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </td>
                <td className="manage-head-child3">
                  <div className="manage-head-con">
                    <Controller
                      control={control}
                      name="manageItemName"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="선택"
                          w="160"
                          h="40"
                          paddingT={10}
                          paddingB={10}
                          fontS="15"
                          variant="tertiary"
                          options={CODE_OPTIONS}
                        />
                      )}
                    />
                  </div>
                </td>
                <td className="manage-head-child4">
                  <Controller
                    control={control}
                    name="manageItemName"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        fullWidth
                        leftpostion="true"
                        errors={errors}
                      >
                        ₩
                      </Textbox>
                    )}
                  />
                </td>
                <td className="manage-head-child4">
                  <Controller
                    control={control}
                    name="manageItemName"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        fullWidth
                        leftpostion="true"
                        errors={errors}
                      >
                        ₩
                      </Textbox>
                    )}
                  />
                </td>
                <td className="manage-head-child4">
                  <Controller
                    control={control}
                    name="manageItemName"
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        fullWidth
                        leftpostion="true"
                        errors={errors}
                      >
                        ₩
                      </Textbox>
                    )}
                  />
                </td>
                <td className="manage-head-btn">
                  <button onClick={() => handleSaveCode()}>저장</button>
                </td>
              </tr>
            )}
            {listData.length > 0 &&
              listData.map((e: any, i: number) => {
                return (
                  <tr key={i}>
                    <td className="manage-head-child1">
                      <input type="checkbox" name="" id="" />
                    </td>
                    <td className="manage-head-child2">SUIT</td>
                    <td className="manage-head-child3">일반</td>
                    <td className="manage-head-child4">₩ 10,000</td>
                    <td className="manage-head-child4">₩ 10,000</td>
                    <td className="manage-head-child4">₩ 10,000</td>
                    <td className="manage-head-btn">
                      <button className="void-btn"></button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="pagenation">
          <Pagination
            page={page}
            count={totalPages}
            list={tableData.length}
            onChangePage={setPage}
          />
        </div>
      </div>
    </ItemManageFormStyled>
  );
};

export default ItemManageForm;

const ItemManageFormStyled = styled.div`
  width: 100%;

  .item-manage-form-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .item-manage-form-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    & div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 44px;
      border-radius: 6px;
      font-size: 15px;
      font-weight: normal;
      color: #fff;
      cursor: pointer;
    }
  }
  .select-del {
    background-color: #444;
  }
  .select-edit {
    background-color: #444;
    margin: 0 10px;
  }
  .item-add {
    background-color: #c09260;
  }

  .item-manage-form-body {
    margin-top: 30px;
    table {
      width: 100%;
    }

    thead {
      height: 68px;
      background-color: #f9f9f9;

      th {
        font-size: 18px;
        font-weight: bold;
        vertical-align: middle;
        color: #101010;
      }
    }

    tbody {
      tr {
        height: 66px;
        border-bottom: 1px solid #ebebeb;
      }

      td {
        font-size: 15px;
        font-weight: normal;
        vertical-align: middle;
        color: #444;

        &:nth-of-type(2) {
          padding-left: 50px;
        }
      }
    }

    .manage-head-child1 {
      width: 5%;
      text-align: center;

      input {
        appearance: none;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        background: url(${BtnCheckOffSVG}) no-repeat center/100%;

        &:checked {
          background: url(${BtnCheckOnSVG}) no-repeat center/100%;
        }
      }
    }
    .manage-head-child2 {
      width: 25%;
      input {
        height: 40px;
        border: 1px solid #d9d9d9;
      }
    }
    .manage-head-child3 {
      width: 20%;
      text-align: center;
    }
    .manage-head-child4 {
      width: 16.6666%;
      text-align: center;

      & > div {
        margin-left: 25%;
      }

      input {
        width: 70%;
        height: 40px;
      }
    }

    .manage-head-con {
      display: flex;
      justify-content: center;
    }

    .manage-head-btn {
      width: 48px;

      .void-btn {
        margin-right: 20px;
        opacity: 0;
      }
      button {
        margin-right: 20px;
        width: 48px;
        height: 30px;
        border-radius: 3px;
        border: solid 1px #c09260;
        color: #c09260;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
`;
