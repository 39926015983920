import request from "api/request";

// 유저 정보 조회
const authInfo = async () => {
  try {
    const data = await request("get", "auth/login", {}, {});

    return data;
  } catch (err) {
    throw err;
  }
};

// 전체 인원 조회
const allMember = async () => {
  try {
    const data = await request("get", "/users", {}, { type: "ALL" });

    return data;
  } catch (err) {
    throw err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  authInfo,
  allMember,
};
