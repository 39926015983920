import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css, ClassNames } from "@emotion/react";
import Modal from "react-modal";

// components
import Button from "components/Button/Button";

// hooks
import useAlert from "hooks/useAlert";

const modalStyleCore = `
  .overlay-base {  
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition-property: opacity;
    transition-duration: 225ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms;
    outline: 0;
    z-index:99999;  
  }

  .overlay-after {
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
  }

  .overlay-before {
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
  }

  .content-base {  
    background: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 20px;
    padding: 0px;
    width: calc(100% - 32px);
    max-width: 360px;
    font-size: 17px;
    font-weight: normal;
    color: #222;
    border: solid 2.5px #c09260;
    box-sizing: border-box;
    border-collapse: collapse;
    max-height: calc(100% - 64px);
  }
`;

const inner = css`
  padding: 50px 40px 40px;
  background-color: #fff;
  text-align: center;
  line-height: 26px;
`;

const innerbutton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: solid 1px rgba(168, 154, 139, 0.6);
  height: 64px;

  button {
    display: block;
    width: 100%;
    height: 64px;
    font-size: 17px;
    font-weight: normal;
  }
`;

/**
 * @file AlertModal
 * @brief 얼럿 모달
 */
const AlertModal = () => {
  const { single } = useAlert();
  const [newLinePrompt, setNewLinePrompt] = useState([]);

  useEffect(() => {
    setNewLinePrompt(single?.message?.split("\n"));
  }, [single.isOpen]);

  return (
    <ClassNames>
      {({ css: coreCss }) => (
        <Modal
          isOpen={single.isOpen}
          onRequestClose={single.reject}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after",
            beforeClose: "content-before",
          }}
          portalClassName={coreCss`${modalStyleCore}`}
        >
          <div css={inner}>
            {single?.renderMessage
              ? single?.renderMessage()
              : newLinePrompt.map((x, i) => (
                  <p key={i} className={`alertText${i}`}>
                    {x}
                  </p>
                ))}
          </div>
          <div css={innerbutton}>
            <button onClick={single.resolve}>{single.ok}</button>
          </div>
        </Modal>
      )}
    </ClassNames>
  );
};

export default AlertModal;
