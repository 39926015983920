import React from "react";
import styled from "@emotion/styled";

import Layout from "layout/layout";

import { PAGE_LAYOUT } from "utils/constants";

const BusinessSupport = () => {
  return <Layout page={PAGE_LAYOUT.main}>BusinessSupport</Layout>;
};

export default BusinessSupport;
