import { useState } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";

import Select from "components/Select/Select";
import Filter from "components/Filter/Filter";
import Pagination from "components/Pagination/Pagination";

import IconCalSVG from "assets/images/icon_cal.svg";
import IcDropBtnSVG from "assets/images/icon_drop.svg";
import DELSVG from "assets/images/DEL.svg";

import { EmployeeSelectOptions, EmployeeFilterOptions } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import "react-dayjs-picker/dist/index.css";

const defaultValues = {
  filter: [],
  selectDate1: "",
  selectDate2: "",
  selectPlace: {
    value: "init",
    label: "선택",
  },
  searchSelect: {
    value: "init",
    label: "선택",
  },
};

const BigDataForm = () => {
  const navigate = useNavigate();
  const [openDate1, setOpenDate1] = useState(false);
  const [openDate2, setOpenDate2] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <BigDataFormStyled>
      <div className="big-data-form-wrap">
        <div className="big-data-form-inner">
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
              handleSubmit(
                () => {},
                (err) => {}
              );
            }}
          >
            <div className="filter">
              <Controller
                control={control}
                name="filter"
                render={({ field }) => (
                  <Filter
                    {...field}
                    option={EmployeeFilterOptions}
                    handleFormSubmit={handleFormSubmit}
                  />
                )}
              />
            </div>
            <div className="search-select">
              <div className="header-text">지점</div>
              <Controller
                control={control}
                name="selectPlace"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="전체"
                    w="130"
                    h="52"
                    fontS="15"
                    variant="tertiary"
                    options={EmployeeSelectOptions}
                  />
                )}
              />
            </div>
            <div className="search">
              <div className="search-select">
                <div className="header-text">구분</div>
                <Controller
                  control={control}
                  name="searchSelect"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="전체"
                      w="130"
                      h="52"
                      fontS="15"
                      variant="tertiary"
                      options={EmployeeSelectOptions}
                    />
                  )}
                />
              </div>
            </div>
            <div className="search-select date-input">
              <div className="header-text">기간</div>
              <div
                className="search-date-image"
                onClick={() => {
                  setOpenDate1(true);
                }}
              >
                <img src={IconCalSVG} alt="캘린더" />
              </div>
              <Controller
                control={control}
                name="selectDate1"
                render={({ field }) => (
                  <DatePicker
                    isOpen={openDate1}
                    setIsOpen={setOpenDate1}
                    popoverPositions={["bottom"]}
                    onSelect={(date) => {
                      field.onChange(dayjs(date).format("YYYY-MM-DD"));
                    }}
                    value={field.value}
                    closeOnSelect={true}
                    onChange={() => {}}
                  />
                )}
              />
              <span className="date-span">~</span>
              <div
                className="search-date-image2"
                onClick={() => {
                  setOpenDate2(true);
                }}
              >
                <img src={IconCalSVG} alt="캘린더" />
              </div>
              <Controller
                control={control}
                name="selectDate2"
                render={({ field }) => (
                  <DatePicker
                    isOpen={openDate2}
                    setIsOpen={setOpenDate2}
                    popoverPositions={["bottom"]}
                    onSelect={(date) => {
                      field.onChange(dayjs(date).format("YYYY-MM-DD"));
                    }}
                    value={field.value}
                    closeOnSelect={true}
                    onChange={() => {}}
                  />
                )}
              />
            </div>
          </form>
        </div>
        <div className="status-btn">
          <div className="xlsx-download">엑셀로 내보내기</div>
        </div>
      </div>
      <div className="big-data-form-body">
        <table>
          <thead>
            <tr className="big-data-table-header-top">
              <th colSpan={3}>기본정보</th>
              <th colSpan={3}>오더 프로세스</th>
              <th colSpan={4}>픽업 프로세스</th>
              <th rowSpan={2}>데이터 삭제</th>
            </tr>
            <tr className="big-data-table-header-bottom">
              <th>날짜</th>
              <th>
                작업번호 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
              </th>
              <th>
                RM# <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
              </th>
              <th className="line-sec1">
                접수자 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
              </th>
              <th>
                접수형태 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
              </th>
              <th className="line-sec2">
                접수시간 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
              </th>
              <th>
                수거자 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
              </th>
              <th>
                수거장소 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
              </th>
              <th>
                세탁유형 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
              </th>
              <th>
                EXPRESS <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2022.09.16</td>
              <td>L00000001</td>
              <td>3032</td>
              <td className="line-sec1">김누리</td>
              <td>INS</td>
              <td className="line-sec2">11:00 AM</td>
              <td>장누리</td>
              <td>G</td>
              <td>W</td>
              <td>1시간</td>
              <td className="line-sec1">
                <img src={DELSVG} alt="데이터 삭제 이미지 아이콘" />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="pagenation">
          <Pagination
            page={page}
            count={totalPages}
            list={tableData.length}
            onChangePage={setPage}
          />
        </div>
      </div>
    </BigDataFormStyled>
  );
};

export default BigDataForm;

const BigDataFormStyled = styled.div`
  width: 100%;

  .big-data-form-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .big-data-form-inner {
    display: flex;
    align-items: center;

    form {
      display: flex;
      align-items: center;

      .search-void {
        margin: 0 3px;
      }

      .search-select {
        display: flex;
        align-items: center;
      }

      .search-date-image2 {
        position: absolute;
        margin-top: 0px;
        margin-left: 333px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        background-color: #444;
        cursor: pointer;
      }

      .search-date-image {
        position: absolute;
        margin-top: 0px;
        margin-left: 157px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        background-color: #444;
        cursor: pointer;
      }

      .date-span {
        margin: 0 8px;
      }

      .date-input {
        input::placeholder {
          color: #b2b2b2;
        }
        input[name="rdp-date-picker"] {
          width: 150px;
          height: 52px;
          border: solid 1px #ddd;
          background-color: #fff;
          line-height: 1.29;
          font-size: 14px;
          color: #000;
          padding-left: 10px;
        }
        .react-tiny-popover-container {
          height: 100px;
          background-color: red;
        }
      }
    }

    .header-text {
      font-size: 16px;
      font-weight: bold;
      color: #222;
      margin-right: 12px;
      margin-left: 15px;
    }
  }

  .big-data-form-body {
    table {
      width: 100%;
      margin-top: 30px;

      thead {
        th {
          font-size: 18px;
          font-weight: bold;
          color: #101010;
          vertical-align: middle;
        }

        .big-data-table-header-top {
          height: 62px;
          background-color: rgba(168, 154, 139, 0.3);

          & th:nth-of-type(1) {
            width: 30%;
          }
          & th:nth-of-type(2) {
            width: 30%;
            border-left: 1px solid rgba(168, 154, 139, 0.3);
            border-right: 1px solid rgba(168, 154, 139, 0.3);
          }
          & th:nth-of-type(4) {
            width: 8%;
            border-left: 1px solid rgba(168, 154, 139, 0.3);
          }
        }

        .big-data-table-header-bottom {
          height: 68px;
          background-color: #f9f9f9;

          th {
            width: 9%;
          }
        }
      }

      tbody {
        tr {
          height: 68px;
          border-bottom: 1px solid #ebebeb;
        }
        td {
          font-size: 15px;
          font-weight: normal;
          color: #444;
          vertical-align: middle;
          text-align: center;
        }
      }

      .line-sec1 {
        border-left: solid 1px #ebebeb;
      }

      .line-sec2 {
        border-right: solid 1px #ebebeb;
      }
    }
  }
`;
