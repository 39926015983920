import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/layout";
import settingApis from "api/setting";
import useAlert from "hooks/useAlert";
import Button from "components/Button/Button";
import Select from "components/Select/Select";
import SearchEmployeeForm from "components/SearchPopup/SearchEmployeeForm";
import { PAGE_LAYOUT } from "utils/constants";
import paymentValidationSchema from "utils/validations/paymentValidationSchema";

const defaultValues = {
  place: {
    value: "init",
    label: "선택",
  },
  vacationSenior01: "",
  vacationSenior02: "",
  vacationSenior03: "",
  approvalSenior01: "",
  approvalSenior02: "",
  approvalSenior03: "",
  proposalSenior01: "",
  proposalSenior02: "",
  proposalSenior03: "",
};

const PaymentSettingEdit = () => {
  const navigate = useNavigate();
  const { noti } = useAlert();
  const MasterCode = useSelector((s: any) => s.code);
  const [placePoint, setPlacePoint] = useState<string>("");
  const [seniors, setSeniors] = useState({
    holiday_application1: 0,
    holiday_application2: 0,
    holiday_application3: 0,
    round_robin1: 0,
    round_robin2: 0,
    round_robin3: 0,
    proposal1: 0,
    proposal2: 0,
    proposal3: 0,
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(paymentValidationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    switch (values.place.value) {
      case "서울 M-Tower":
        setPlacePoint("MTOWER");
        break;
      case "서울 E-Tower":
        setPlacePoint("ETOWER");
        break;
      case "월드":
        setPlacePoint("WORLD");
        break;
      case "시그니엘":
        setPlacePoint("SIGNIEL");
        break;

      default:
        break;
    }
  }, [values]);

  const handleSave = async () => {
    const payload = {
      hotel_info: [
        {
          type: placePoint,
        },
      ],
      holiday_application: [
        seniors.holiday_application1,
        seniors.holiday_application2,
        seniors.holiday_application3,
      ],
      round_robin: [
        seniors.round_robin1,
        seniors.round_robin2,
        seniors.round_robin3,
      ],
      proposal: [seniors.proposal1, seniors.proposal2, seniors.proposal3],
    };

    console.log(payload);
    await settingApis
      .updateSetting({ payload: payload })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  return (
    <Layout page={PAGE_LAYOUT.setting}>
      <PaymentSettingEditStyled>
        <div className="place-select">
          <div className="form-text form-left">지점</div>
          <Controller
            control={control}
            name="place"
            render={({ field }) => (
              <Select
                {...field}
                placeholder="선택"
                w="159"
                h="52"
                fontS="14"
                variant="tertiary"
                options={MasterCode.SPOT}
              />
            )}
          />
        </div>
        <div className="payment-wrap">
          <form
            onSubmit={(e) => {
              e.preventDefault();

              handleSubmit(
                () => {
                  handleSave();
                },
                (err: any) => {
                  const keys = ["place"];

                  for (const key of keys) {
                    if (err[key]) {
                      noti(err[key].value.message, "확인");
                      break;
                    }
                  }
                }
              )();
            }}
          >
            <div>
              <h3>&#183; 연차 · 반차 신청서 &#183;</h3>
              <SearchEmployeeForm
                label="상급자 1"
                placeName="vacationSenior01"
                Controller={Controller}
                setValue={setValue}
                seniors={seniors}
                setSeniors={setSeniors}
                control={control}
                errors={errors}
              />
              <SearchEmployeeForm
                label="상급자 2"
                placeName="vacationSenior02"
                Controller={Controller}
                setValue={setValue}
                seniors={seniors}
                setSeniors={setSeniors}
                control={control}
                errors={errors}
              />
              <SearchEmployeeForm
                label="상급자 3"
                placeName="vacationSenior03"
                Controller={Controller}
                setValue={setValue}
                seniors={seniors}
                setSeniors={setSeniors}
                control={control}
                errors={errors}
              />
            </div>
            <div>
              <h3>&#183; 품의서 &#183;</h3>
              <SearchEmployeeForm
                label="상급자 1"
                placeName="approvalSenior01"
                Controller={Controller}
                setValue={setValue}
                seniors={seniors}
                setSeniors={setSeniors}
                control={control}
                errors={errors}
              />
              <SearchEmployeeForm
                label="상급자 2"
                placeName="approvalSenior02"
                Controller={Controller}
                setValue={setValue}
                seniors={seniors}
                setSeniors={setSeniors}
                control={control}
                errors={errors}
              />
              <SearchEmployeeForm
                label="상급자 3"
                placeName="approvalSenior03"
                Controller={Controller}
                setValue={setValue}
                seniors={seniors}
                setSeniors={setSeniors}
                control={control}
                errors={errors}
              />
            </div>
            <div>
              <h3>&#183; 기획서 &#183;</h3>
              <SearchEmployeeForm
                label="상급자 1"
                placeName="proposalSenior01"
                Controller={Controller}
                setValue={setValue}
                seniors={seniors}
                setSeniors={setSeniors}
                control={control}
                errors={errors}
              />
              <SearchEmployeeForm
                label="상급자 2"
                placeName="proposalSenior02"
                Controller={Controller}
                setValue={setValue}
                seniors={seniors}
                setSeniors={setSeniors}
                control={control}
                errors={errors}
              />
              <SearchEmployeeForm
                label="상급자 3"
                placeName="proposalSenior03"
                Controller={Controller}
                setValue={setValue}
                seniors={seniors}
                setSeniors={setSeniors}
                control={control}
                errors={errors}
              />
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                취소
              </Button>
              <span className="void-sec"></span>
              <Button type="submit" variant="primary" preset="2">
                저장
              </Button>
            </div>
          </form>
        </div>
      </PaymentSettingEditStyled>
    </Layout>
  );
};

export default PaymentSettingEdit;

const PaymentSettingEditStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 35px;
  }

  .payment-wrap {
    padding-top: 0;
  }

  .place-select {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .form-text {
    font-size: 16px;
    font-weight: bold;
    color: #222;
    margin-right: 24px;
  }

  form {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid #222;
  }

  .submit-btn {
    width: 408px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & .void-sec {
      margin: 0 6px;
    }
  }

  .search-member-sec {
    display: flex;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .search-member-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
    cursor: pointer;
  }
`;
