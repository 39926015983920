import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import Row from "components/LabelWithInput/LabelWithInput";
import Input from "components/Input/Input";
import SearchItem from "./SearchItem";

interface SearchItemFormProps {
  label: string;
  placeName: string;
  Controller: any;
  setValue: any;
  control: any;
  errors: any;
}

const SearchItemForm = ({
  label,
  placeName,
  Controller,
  setValue,
  control,
  errors,
}: SearchItemFormProps) => {
  const [result, setResult] = useState<any>([]);
  const [openSearchItem, setOpenSearchItem] = useState<boolean>(false);

  useEffect(() => {
    if (result.length > 0) {
      setValue(placeName, "[" + result[0].department + "] " + result[0].name);
    }
  }, [result]);

  return (
    <SearchItemFormStyled>
      <Row label={label}>
        <div className="input-sec search-popup-sec">
          <Controller
            control={control}
            name={placeName}
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
              />
            )}
          />
          <button
            type="button"
            className="search-popup-btn"
            onClick={() => setOpenSearchItem(true)}
          >
            품목 검색
          </button>
          {openSearchItem && (
            <SearchItem
              setOpenSearchPopup={setOpenSearchItem}
              setResult={setResult}
            />
          )}
        </div>
      </Row>
    </SearchItemFormStyled>
  );
};

export default SearchItemForm;

const SearchItemFormStyled = styled.div`
  .search-popup-sec {
    display: flex;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .search-popup-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
    cursor: pointer;
  }
`;
