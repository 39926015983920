import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "layout/layout";

import Button from "components/Button/Button";

import useSubmitAlert from "hooks/useSubmitAlert";

import mailApi from "api/mail";

import { PAGE_LAYOUT } from "utils/constants";

import MailTapMenu from "./MailTapMenu";

const MailDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { noti } = useSubmitAlert();
  const { REACT_APP_API } = process.env;
  const [tableData, setTableData] = useState<any>([]);

  console.log(location.state?.mailId);
  // 상세페이지 메일 가져오기
  useEffect(() => {
    mailApi
      .detailMails({ mailId: location.state?.mailId })
      .then((res: any) => {
        if (res.status === 200) setTableData(res.data.result);
      })
      .catch((err) => {
        throw err;
      });
  }, [location.state?.mailId]);

  // 상세페이지 메일 삭제
  const handleDelete = async () => {
    await mailApi
      .editMails({ mailId: location.state?.mailId, withdraw: true })
      .then((res: any) => {
        if (res.status === 200) {
          navigate("/mail", {
            state: { status: tableData.type },
          });
        }
      })
      .catch((err) => err);
  };

  return (
    <Layout page={PAGE_LAYOUT.mail}>
      <MailDetailStyled>
        <MailTapMenu textChange={location?.state?.textChange} />
        <div className="inventory-process-wrap">
          <div className="detail-mail-wrap">
            <div className="detail-mail-top">
              <div>
                <div className="detail-mail-left-title">제목</div>
                <div className="detail-mail-right">{tableData?.title}</div>
              </div>
              <div>
                <div className="detail-mail-left-title">
                  {location.state?.type === "GET" && "발신자"}
                  {location.state?.type === "SENT" && "수신자"}
                </div>
                <div className="detail-mail-right">
                  {location.state?.type === "GET" &&
                    tableData?.from_user?.signname}
                  {location.state?.type === "SENT" &&
                    tableData?.to_user?.signname}
                </div>
              </div>
              <div>
                <div className="detail-mail-left-title">참조</div>
                <div className="detail-mail-right">
                  {tableData?.reference?.map((e: any, i: number) => {
                    return <div key={i}>{e}, </div>;
                  })}
                </div>
              </div>
            </div>
            <div className="detail-mail-content-wrap">
              <div className="detail-mail-content-title">내용</div>
              <div className="detail-mail-content">{tableData?.content}</div>
            </div>
            <div className="detail-mail-attchment-wrap">
              <div className="detail-mail-left-title">첨부파일</div>
              <div className="detail-mail-attchment">
                {tableData.attachments?.map((e: any) => {
                  return (
                    <div key={e.id}>
                      <a href={`${REACT_APP_API}/files/${e.id}`} download>
                        {e.file_name}.{e.extension},
                      </a>
                      &nbsp;&nbsp;
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="detail-mail-sign-void">서명</div>
          </div>
          <div className="submit-btn">
            <Button
              type="button"
              variant="tertiary"
              preset="1"
              onClick={() => navigate(-1)}
            >
              목록
            </Button>
            <span className="void-sec"></span>
            <Button
              type="button"
              variant="secondary"
              preset="1"
              onClick={() => {
                const isDelete = noti(
                  "메일을 삭제하시겠습니까?",
                  "예",
                  "아니오"
                );
                const getData = () => {
                  isDelete.then((res: boolean) => {
                    if (res) handleDelete();
                  });
                };
                getData();
              }}
            >
              삭제
            </Button>
            <span className="void-sec"></span>
            {location?.state?.textChange === "TRASH" ? (
              <Button
                type="button"
                variant="primary"
                preset="2"
                onClick={() => {}}
              >
                복구
              </Button>
            ) : (
              <Button
                type="button"
                variant="primary"
                preset="2"
                onClick={() => {}}
              >
                답장
              </Button>
            )}
          </div>
        </div>
      </MailDetailStyled>
    </Layout>
  );
};

export default MailDetail;

const MailDetailStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 75px 40px 40px 40px;

  .detail-mail-wrap {
    border: solid 1px #ebebeb;
  }

  .detail-mail-top {
    & > div {
      display: flex;
      border-bottom: solid 1px #ebebeb;
    }
  }

  .detail-mail-left-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 70px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: #f9f9f9;
  }

  .detail-mail-right {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #444;
    padding-left: 30px;
  }

  .detail-mail-content-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: #f9f9f9;
    border-bottom: solid 1px #ebebeb;
  }

  .detail-mail-content {
    height: 400px;
    padding: 30px;
    font-size: 15px;
    line-height: 1.6;
    color: #444;
  }

  .detail-mail-attchment-wrap {
    display: flex;
    border-top: solid 1px #ebebeb;
    border-bottom: solid 1px #ebebeb;
  }

  .detail-mail-attchment {
    display: flex;
    align-items: center;
    padding-left: 30px;
    cursor: pointer;

    a {
      font-size: 18px;
      color: #c09260;
      text-decoration: underline;
    }
  }

  .detail-mail-sign-void {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: #f5f6fa;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
  }

  .submit-btn {
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
