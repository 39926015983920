import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import request from "api/request";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/layout";

import usePageSlice from "hooks/usePageSlice";

import Search from "components/Search/Search";
import Pagination from "components/Pagination/Pagination";

import NoDataSVG from "assets/images/no_data_ico.svg";
import IcDropBtnSVG from "assets/images/icon_drop.svg";
import BtnCheckOffSVG from "assets/images/btn_check_off.png";
import BtnCheckOnSVG from "assets/images/btn_check_on.png";

import { PAGE_LAYOUT } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

const defaultValues = {
  search: "",
};

const HEONItemManage = () => {
  return <Layout page={PAGE_LAYOUT.heon}>HEONItemManage</Layout>;
};

export default HEONItemManage;
