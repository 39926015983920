import { useState } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";

import Layout from "layout/layout";
import Button from "components/Button/Button";
import Select from "components/Select/Select";
import SearchItem from "components/SearchPopup/SearchItem";

import IconCalSVG from "assets/images/icon_cal.svg";

import { PAGE_LAYOUT, EmployeeSelectOptions } from "utils/constants";
import validation from "utils/validations/employeeValidationSchema";

import "react-dayjs-picker/dist/index.css";

import LinenTapMenu from "../LinenTapMenu";

const defaultValues = {
  selectPlace: {
    value: "init",
    label: "선택",
  },
  selectDate: "",
  selectFloor: {
    value: "init",
    label: "선택",
  },
};

const PaymentAppRegist = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openDate, setOpenDate] = useState(false);
  const [result, setResult] = useState<any>([]);
  const [openSearchItem, setOpenSearchItem] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validation),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  return (
    <Layout page={PAGE_LAYOUT.linen}>
      <PaymentAppRegistStyled>
        <LinenTapMenu textChange={location?.state?.textChange} />
        <div className="payment-add-wrap">
          <form
            onSubmit={(e) => {
              e.preventDefault();

              handleSubmit(
                () => {},
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className="payment-add-inner">
              <div className="payment-add-container">
                <div className="search-select">
                  <div className="header-text">지점</div>
                  <Controller
                    control={control}
                    name="selectPlace"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="전체"
                        w="186"
                        h="52"
                        fontS="15"
                        variant="tertiary"
                        options={EmployeeSelectOptions}
                      />
                    )}
                  />
                </div>
                <div className="search-select date-input">
                  <div className="header-text">기간</div>
                  <div
                    className="search-date-image"
                    onClick={() => {
                      setOpenDate(true);
                    }}
                  >
                    <img src={IconCalSVG} alt="캘린더" />
                  </div>
                  <Controller
                    control={control}
                    name="selectDate"
                    render={({ field }) => (
                      <DatePicker
                        isOpen={openDate}
                        setIsOpen={setOpenDate}
                        popoverPositions={["bottom"]}
                        onSelect={(date) => {
                          field.onChange(dayjs(date).format("YYYY-MM-DD"));
                        }}
                        value={field.value}
                        closeOnSelect={true}
                        onChange={() => {}}
                      />
                    )}
                  />
                </div>
                <div className="search-select">
                  <div className="header-text">층</div>
                  <Controller
                    control={control}
                    name="selectFloor"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="전체"
                        w="130"
                        h="52"
                        fontS="15"
                        variant="tertiary"
                        options={EmployeeSelectOptions}
                      />
                    )}
                  />
                </div>
                <div className="payment-add-regist-top-text">
                  <div>
                    롯데호텔 담담자 <span>김누리</span>
                  </div>
                  <div>
                    협력업체 <span>대웅크린월드</span>
                  </div>
                </div>
              </div>
              <div className="payment-form-btn">
                <div
                  className="add-payment"
                  onClick={() => setOpenSearchItem(true)}
                >
                  품목 등록
                </div>
                {openSearchItem && (
                  <SearchItem
                    setOpenSearchPopup={setOpenSearchItem}
                    setResult={setResult}
                  />
                )}
              </div>
            </div>
            <div className="payment-add-body">
              <div className="payment-add-info-wrap">
                <div className="payment-add-info">
                  <div className="payment-add-info-title">품목정보</div>
                  <div className="payment-add-info-sub payment-add-info-sub1">
                    <div>품목명</div>
                    <div>규격</div>
                    <div>단가</div>
                  </div>
                  <div className="payment-add-info-data payment-add-info-data1">
                    <div>
                      <div>품목 1</div>
                      <div>1040*1940</div>
                      <div>₩400,000</div>
                    </div>
                  </div>
                </div>
                <div className="payment-add-info">
                  <div className="payment-add-info-title">7F</div>
                  <div className="payment-add-info-sub payment-add-info-sub2">
                    <div>규격</div>
                    <div>단가</div>
                  </div>
                  <div className="payment-add-info-data payment-add-info-data2">
                    <div>
                      <div>
                        <input type="text" />
                      </div>
                      <div>₩400,000</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="payment-add-info-total">
              <div>자산현황</div>
              <div>₩1,600,000</div>
            </div>
            <div className="grand-total">
              <div>GRAND TOTAL</div>
              <div>₩ 44,100,000</div>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                목록
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                초기화
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="secondary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                임시저장
              </Button>
              <span className="void-sec"></span>
              <Button type="submit" variant="primary" preset="2">
                확정
              </Button>
            </div>
          </form>
        </div>
      </PaymentAppRegistStyled>
    </Layout>
  );
};

export default PaymentAppRegist;

const PaymentAppRegistStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 75px 40px 40px 40px;

  .payment-add-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    padding: 35px;
    margin-top: 30px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  }

  .payment-add-inner {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .payment-add-container {
    width: 100%;
    display: flex;

    .search-select {
      display: flex;
      align-items: center;
    }
    .header-text {
      font-size: 16px;
      font-weight: bold;
      color: #222;
      margin-right: 10px;
      margin-left: 5px;
    }
    .search-date-image {
      position: absolute;
      margin-top: 0px;
      margin-left: 144px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      background-color: #444;
      cursor: pointer;
    }

    .date-span {
      margin: 0 8px;
    }

    .date-input {
      input::placeholder {
        color: #b2b2b2;
      }
      input[name="rdp-date-picker"] {
        width: 150px;
        height: 52px;
        border: solid 1px #ddd;
        background-color: #fff;
        line-height: 1.29;
        font-size: 14px;
        color: #000;
        padding-left: 10px;
      }
      .react-tiny-popover-container {
        height: 100px;
        background-color: red;
      }
    }
  }
  .payment-add-regist-top-text {
    display: flex;
    align-items: center;
    color: #b2b2b2;
    font-size: 16px;
    font-weight: bold;
    margin-left: 15px;

    & > div:nth-of-type(1)::after {
      content: "";
      padding: 0 1px;
      margin: 0 16px;
      background-color: #707070;
    }
    span {
      color: #545454;
    }
  }
  .add-payment {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    margin-left: 10px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    width: 110px;
    background-color: #c09260;
  }

  .payment-add-info-wrap {
    width: 100%;
    display: flex;
    margin-top: 30px;

    & > div:nth-of-type(1) {
      width: 60%;
      border-right: 1px solid rgba(168, 154, 139, 0.6);
    }
    & > div:nth-of-type(2) {
      width: 40%;
    }
  }

  .payment-add-info-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 62px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: rgba(168, 154, 139, 0.3);
  }

  .payment-add-info-sub {
    display: flex;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: #f9f9f9;
    text-align: center;
  }
  .payment-add-info-sub1 > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.333%;
    height: 68px;
    text-align: center;
    &:nth-of-type(2) {
      border-left: solid 1px #ebebeb;
      border-right: solid 1px #ebebeb;
    }
  }
  .payment-add-info-sub2 > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 68px;
    &:nth-of-type(2) {
      border-left: solid 1px #ebebeb;
    }
  }

  .payment-add-info-data {
    & > div {
      display: flex;
      border-bottom: solid 1px #ebebeb;

      input {
        width: 70px;
        height: 40px;
        border: 1px solid #d9d9d9;
      }
    }
  }
  .payment-add-info-data1 {
    & > div div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 68px;
      width: 33.333%;
      text-align: center;
    }
    & > div div:nth-of-type(2) {
      border-left: solid 1px #ebebeb;
      border-right: solid 1px #ebebeb;
    }
  }
  .payment-add-info-data2 {
    & > div div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 68px;
      width: 50%;
      text-align: center;
    }
    & > div div:nth-of-type(2) {
      border-left: solid 1px #ebebeb;
    }
  }

  .payment-add-info-total {
    display: flex;
    align-items: center;
    border-bottom: solid 1px #707070;

    & > div {
      display: flex;
      align-items: center;
      height: 70px;
      font-size: 18px;
      font-weight: bold;
      color: #101010;
    }

    & > div:nth-of-type(1) {
      width: 60%;
      padding-left: 130px;
      border-right: 1px solid rgba(168, 154, 139, 0.6);
    }
    & > div:nth-of-type(2) {
      justify-content: center;
      width: 40%;
    }
  }

  .grand-total {
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    border-bottom: 3px solid #444;

    & div {
      margin: 0 30px;
    }
  }

  .submit-btn {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
