import React from "react";
import styled from "@emotion/styled";

import Layout from "layout/layout";

import { PAGE_LAYOUT } from "utils/constants";

const SendEventNotices = () => {
  return <Layout page={PAGE_LAYOUT.main}>SendEventNotices</Layout>;
};

export default SendEventNotices;
