import { forwardRef } from "react";
import ReactSelect, { components } from "react-select";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import DropdownSVG from "assets/images/icon_dropdewn.svg";

type OptionTypes = {
  value: any;
  label: string;
};

interface SelectProps {
  options: OptionTypes[];
  w?: string;
  h?: string;
  paddingT?: number;
  paddingB?: number;
  fontS?: string;
  placeholder?: string;
  defaultValue?: string;
  isSearchable?: boolean;
  variant?: string;
  name?: string;
  errors?: any;
  color?: string;
  bgColor?: string;
  position?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  onChange?: (e: any) => void;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const {
    options,
    placeholder,
    defaultValue,
    isSearchable = false,
    variant,
    disabled,
    position,
    errors,
    ...rest
  } = props;

  const defaultOption: number | null =
    defaultValue && options
      ? [...options].map((x: OptionTypes) => x.value).indexOf(defaultValue)
      : 0;

  return (
    <>
      <div>
        <ReactSelect
          {...rest}
          className="component-select"
          placeholder={placeholder}
          defaultValue={options[defaultOption]}
          components={{
            DropdownIndicator: Arrow,
          }}
          menuPosition={position ? "fixed" : "absolute"}
          options={options}
          isDisabled={disabled}
          styles={(() => {
            switch (variant) {
              case "primary":
                return primaryStyles;
              case "secondary":
                return secondaryStyles;
              case "tertiary":
                return tertiaryStyles;
              case "quaternary":
                return quaternaryStyles;
              default:
                return primaryStyles;
            }
          })()}
          isSearchable={isSearchable}
        />
        {errors && <ErrorMessage errors={errors} name={props.name} />}
      </div>
    </>
  );
});

const primaryStyles = {
  control: (provided: any, state: any) => {
    const initialState = state.selectProps.value.value === "init";
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "335px";
    const height = state.selectProps.h ? `${state.selectProps.h}px` : "65px";
    const borderColor = initialState
      ? "#dddddd"
      : state.hasValue
      ? "#707070"
      : "#dddddd";
    return {
      ...provided,
      width: state.selectProps.fullWidth ? "100%" : width,
      height,
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      border: `solid 1px ${
        state.selectProps.menuIsOpen ? "#707070" : borderColor
      } !important`,
      borderRadius: 0,
      boxShadow: "none",
    };
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      marginRight: 20,
      padding: 0,
      width: 20,
      height: 8,
      background: `url(${DropdownSVG}) no-repeat center right/15px`,
      transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    };
  },
  menu: (provided: any, state: any) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "335px";
    return {
      ...provided,
      width: `${state.selectProps.fullWidth ? "100%" : width}`,
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      marginTop: 4,
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid #707070",
    };
  },
  option: (provided: any, state: any) => {
    const color = state.selectProps.color ? state.selectProps.color : "#000";
    return {
      ...provided,
      paddingLeft: 20,
      paddingTop: 20,
      paddingBottom: 20,
      fontSize: state.selectProps.fontS
        ? `${state.selectProps.fontS}px`
        : "16px",
      textAlign: "left",
      color: state.isSelected ? color : "#b2b2b2",
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      cursor: "pointer",
    };
  },
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      margin: 0,
      fontSize: state.selectProps.fontS
        ? `${state.selectProps.fontS}px`
        : "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: 20,
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      marginLeft: 0,
      paddingTop: 20,
      paddingBottom: 20,
      fontSize: state.selectProps.fontS
        ? `${state.selectProps.fontS}px`
        : "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  indicatorSeparator: (provided: any) => ({ ...provided, display: "none" }),
};

const secondaryStyles = {
  control: (provided: any, state: any) => {
    const initialState = state.selectProps.value.value === "init";
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "155px";
    const borderColor = initialState
      ? "#dddddd"
      : state.hasValue
      ? "#707070"
      : "#dddddd";

    return {
      ...provided,
      width: state.selectProps.fullWidth ? "100%" : width,
      height: "65px",
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      border: `solid 1px ${
        state.selectProps.menuIsOpen ? "#707070" : borderColor
      } !important`,
      borderRadius: 0,
      boxShadow: "none",
    };
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      marginRight: 20,
      padding: 0,
      width: 20,
      height: 8,
      background: `url(${DropdownSVG}) no-repeat center right/15px`,
      transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    };
  },
  menu: (provided: any, state: any) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "155px";
    return {
      ...provided,
      width: `${state.selectProps.fullWidth ? "100%" : width}`,
      backgroundColor: "#fff",
      marginTop: 4,
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid #707070",
    };
  },
  option: (provided: any, state: any) => {
    const color = state.selectProps.color ? state.selectProps.color : "#000";
    return {
      ...provided,
      paddingLeft: 20,
      paddingTop: 20,
      paddingBottom: 20,
      fontSize: "16px",
      textAlign: "left",
      color: state.isSelected ? color : "#b2b2b2",
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      cursor: "pointer",
    };
  },
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      margin: 0,
      fontSize: "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  valueContainer: (provided: any) => {
    return {
      ...provided,
      paddingLeft: 20,
    };
  },
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      marginLeft: 0,
      fontSize: "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  indicatorSeparator: (provided: any) => ({ ...provided, display: "none" }),
};

const tertiaryStyles = {
  control: (provided: any, state: any) => {
    const initialState = state.selectProps.value.value === "init";
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "210px";
    const height = state.selectProps.h ? `${state.selectProps.h}px` : "65px";
    const borderColor = initialState
      ? "#dddddd"
      : state.hasValue
      ? "#707070"
      : "#dddddd";

    return {
      ...provided,
      width: state.selectProps.fullWidth ? "100%" : width,
      height: height,
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      border: `solid 1px ${
        state.selectProps.menuIsOpen ? "#707070" : borderColor
      } !important`,
      borderRadius: 0,
      boxShadow: "none",
    };
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      marginRight: 20,
      padding: 0,
      width: 20,
      height: 8,
      background: `url(${DropdownSVG}) no-repeat center right/15px`,
      transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    };
  },
  menu: (provided: any, state: any) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "210px";
    return {
      ...provided,
      width: `${state.selectProps.fullWidth ? "100%" : width}`,
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      marginTop: 4,
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid #707070",
    };
  },
  option: (provided: any, state: any) => {
    const paddingT = state.selectProps.paddingT
      ? state.selectProps.paddingT
      : 20;
    const paddingB = state.selectProps.paddingB
      ? state.selectProps.paddingB
      : 20;
    const color = state.selectProps.color ? state.selectProps.color : "#000";

    return {
      ...provided,
      paddingLeft: 20,
      paddingTop: paddingT,
      paddingBottom: paddingB,
      fontSize: state.selectProps.fontS
        ? `${state.selectProps.fontS}px`
        : "16px",
      textAlign: "left",
      color: state.isSelected ? color : "#b2b2b2",
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      cursor: "pointer",
    };
  },
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      margin: 0,
      fontSize: state.selectProps.fontS
        ? `${state.selectProps.fontS}px`
        : "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: 15,
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      marginLeft: 0,
      fontSize: state.selectProps.fontS
        ? `${state.selectProps.fontS}px`
        : "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  indicatorSeparator: (provided: any) => ({ ...provided, display: "none" }),
};

const quaternaryStyles = {
  control: (provided: any, state: any) => {
    const initialState = state.selectProps.value.value === "init";
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "70px";

    return {
      ...provided,
      width: state.selectProps.fullWidth ? "100%" : width,
      height: state.selectProps.h ? `${state.selectProps.h}px` : "40px",
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      border: `solid 1px ${
        state.selectProps.menuIsOpen ? "#707070" : "#ddd"
      } !important`,
      borderRadius: 0,
      boxShadow: "none",
    };
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      padding: 0,
      width: 25,
      height: 8,
      background: `url(${DropdownSVG}) no-repeat center/11px`,
      transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    };
  },
  menu: (provided: any, state: any) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "70px";
    return {
      ...provided,
      width: `${state.selectProps.fullWidth ? "100%" : width}`,
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      marginTop: 4,
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid #707070",
    };
  },
  option: (provided: any, state: any) => {
    const color = state.selectProps.color ? state.selectProps.color : "#000";
    return {
      ...provided,
      textAlign: "center",
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: "16px",
      color: state.isSelected ? color : "#b2b2b2",
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      cursor: "pointer",
    };
  },
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      margin: 0,
      fontSize: "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      marginLeft: 0,
      marginTop: "3px",
      fontSize: "16px",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  indicatorSeparator: (provided: any) => ({ ...provided, display: "none" }),
};

const Arrow = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <div />
    </components.DropdownIndicator>
  );
};

export default Select;
