import React, { useEffect, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";

import Layout from "layout/layout";

import { PAGE_LAYOUT } from "utils/constants";

const CredentialDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [detailData, setDetailData] = useState<any>([]);

  useEffect(() => {
    setDetailData(location?.state?.license);
  }, []);
  //resumeId;
  console.log(detailData);
  return (
    <Layout page={PAGE_LAYOUT.part_timer_support}>
      <CredentialDetailStyled>
        <div>
          <h2>자격증 확인</h2>
          <div className="credential-wrap">
            <h3>
              &#183; {location?.state?.resumeName} (
              {location?.state?.resumeId.toString().length === 1
                ? "A00000" + location?.state?.resumeId
                : "A0000" + location?.state?.resumeId}
              ) &#183;
            </h3>
            <ul>
              {detailData.map((item: any, i: number) => {
                return (
                  <li key={i}>
                    <div className="credential-date">{item?.pass_date}</div>
                    <div>
                      <span>{item?.publisher}</span> {item?.license_name}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </CredentialDetailStyled>
    </Layout>
  );
};

export default CredentialDetail;

const CredentialDetailStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    height: calc(100vh - 130px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 35px;
    text-align: center;
  }

  h2 {
    font-family: Arita-buri-SemiBold;
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 90px;
  }

  h3 {
    font-size: 26px;
    font-weight: bold;
    color: #c09260;
    padding-bottom: 30px;
    border-bottom: 1px solid #222;
  }

  .credential-wrap {
    width: 770px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 30px 50px;

    li {
      display: flex;
      padding: 30px 0;
      border-bottom: 1px solid #ddd;
    }

    .credential-date {
      width: 110px;
      font-size: 16px;
      font-weight: normal;
      color: #222;
    }

    span {
      font-size: 18px;
      font-weight: bold;
      color: #222;
      margin-right: 10px;
    }
  }
`;
