import { useEffect, useState } from "react";

import Row from "components/LabelWithInput/LabelWithInput";
import Input from "components/Input/Input";
import Textbox from "components/TextBox/TextBox";
import Postcode from "components/Postcode/Postcode";

interface PlaceSettingFormProps {
  label: string;
  placeName: string;
  radiusName: string;
  Controller: any;
  setValue: any;
  control: any;
  errors: any;
}

const PlaceSettingForm = ({
  label,
  placeName,
  radiusName,
  Controller,
  setValue,
  control,
  errors,
}: PlaceSettingFormProps) => {
  const [popup, setPopup] = useState(false);
  const [Place, setPlace] = useState({
    address: "",
    zipcode: "",
  });

  useEffect(() => {
    setValue(placeName, Place.address);
  }, [Place]);

  return (
    <Row label={label}>
      <div className="input-sec">
        <div className="place-sec">
          <div className="place-text">위치 설정</div>
          <Controller
            control={control}
            name={placeName}
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                placeholder="주소"
                value={Place.address}
                fullWidth
                errors={errors}
              />
            )}
          />
          <button
            type="button"
            className="address-search"
            onClick={() => {
              setPopup(true);
            }}
          >
            주소찾기
          </button>
          {popup && (
            <Postcode
              setPopup={setPopup}
              company={Place}
              setcompany={setPlace}
            />
          )}
        </div>
        <div className="place-sec">
          <div className="place-text">
            반경 설정<span>(최소 500M)</span>
          </div>
          <div className="place-radius">
            <Controller
              control={control}
              name={radiusName}
              render={({ field }: any) => (
                <Textbox
                  {...field}
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  fullWidth
                  min="500"
                  errors={errors}
                >
                  M
                </Textbox>
              )}
            />
          </div>
        </div>
      </div>
    </Row>
  );
};

export default PlaceSettingForm;
