import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import Layout from "layout/layout";
import { setMode } from "store/theme";
import request from "api/request";
import settingApis from "api/setting";
import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import SimpleRadio from "components/Radio/SimpleRadio";
import FileInput from "components/FileInput/FileInput";
import Postcode from "components/Postcode/Postcode";
import PlaceSettingForm from "components/PlaceSetting/PlaceSettingForm";
import { PAGE_LAYOUT, THEME_OPTIONS, SIGN_OPTIONS } from "utils/constants";
import { useTheme } from "utils/context/themeProvider";
import { DefaultSettingPayloadTypes } from "types/payloadTypes";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

const defaultValues = {
  Theme: { value: "", label: "" },
  Place01: "",
  radius01: "",
  Place02: "",
  radius02: "",
  Place03: "",
  radius03: "",
  Place04: "",
  radius04: "",
  SignStatus: "NOTUSED",
  SignSelect: {
    value: "SIGN_IMAGE",
    label: "서명 이미지 등록",
  },
  SignImage: [],
  SignName: "",
  SignPosition: "",
  SignPhone: "",
  SignEmail: "",
  SignWeb: "",
  SignAddress: "",
};

const Setting = () => {
  const dispatch = useDispatch();
  const user = useSelector((s: any) => s.auth);
  const [ThemeMode, toggleTheme] = useTheme();
  const [isSigned, setIsSigned] = useState(false);
  const [popup, setPopup] = useState(false);
  const [enrollCompany, setEnrollCompany] = useState({
    address: "",
    zipcode: "",
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    for (let i = 0; i < THEME_OPTIONS.length; i++) {
      if (THEME_OPTIONS[i].value === ThemeMode) {
        setValue("Theme", THEME_OPTIONS[i]);
      }
    }
  }, [ThemeMode, THEME_OPTIONS]);

  useEffect(() => {
    if (values.SignStatus === "USED") {
      setIsSigned(true);
    } else {
      setIsSigned(false);
    }
  }, [values.SignStatus]);

  // 서명 이미지 파일 폼데이터 처리
  const signFormData = new FormData();
  signFormData.append("file", values.SignImage[0]);

  //const handleSave = async () => {
  //  const attachment: any = [];

  //  // 이미지 파일이 있을 경우 files api로 file id get
  //  if (
  //    values.SignStatus === "USED" &&
  //    values.SignSelect.value === "SIGN_IMAGE" &&
  //    values.SignImage
  //  ) {
  //    try {
  //      const res = await request("post", "/files", {}, signFormData);

  //      if (res?.status === 200) {
  //        attachment.push(res.data.result.id);
  //      }
  //    } catch (err) {
  //      console.log(err);
  //    }
  //  }

  //  let payload: DefaultSettingPayloadTypes = {
  //    hotel_info: [
  //      {
  //        type: "NURI",
  //        address: values.Place01,
  //        redius_setting: Number(values.radius01),
  //      },
  //      {
  //        type: "MTOWER",
  //        address: values.Place02,
  //        redius_setting: Number(values.radius02),
  //      },
  //      {
  //        type: "ETOWER",
  //        address: values.Place02,
  //        redius_setting: Number(values.radius02),
  //      },
  //      {
  //        type: "WORLD",
  //        address: values.Place03,
  //        redius_setting: Number(values.radius03),
  //      },
  //      {
  //        type: "SIGNIEL",
  //        address: values.Place04,
  //        redius_setting: Number(values.radius04),
  //      },
  //    ],
  //    use_mail_sign_settings: isSigned,
  //  };

  //  if (attachment.length > 0) {
  //    payload = {
  //      ...payload,
  //      mail_sign_picture_id: attachment,
  //    };
  //  }

  //  if (values.SignSelect.value === "SIGN_FORM") {
  //    payload = {
  //      ...payload,
  //      mail_sign_info: {
  //        name: values.SignName,
  //        position: values.SignPosition,
  //        phone: values.SignPhone,
  //        email: values.SignEmail,
  //        web: values.SignWeb,
  //        address: values.SignAddress,
  //      },
  //    };
  //  }

  //  console.log(payload);
  //  //await settingApis
  //  //  .updateSetting({ payload: payload })
  //  //  .then((res) => console.log(res))
  //  //  .catch((err) => console.log(err));
  //};

  return (
    <Layout page={PAGE_LAYOUT.setting}>
      <SettingStyled>
        <div className="status-wrap">
          <form
            onSubmit={(e) => {
              e.preventDefault();

              handleSubmit(
                () => {
                  //handleSave();
                  if (values.Theme.value === ThemeMode) {
                    return;
                  } else {
                    toggleTheme();
                    dispatch(setMode(values.Theme.value));
                  }
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div>
              <h3>&#183; 테마 설정 &#183;</h3>
              <Row label="테마">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="Theme"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="테마 선택"
                        variant="primary"
                        fullWidth
                        color={ThemeMode === "light" ? "#000" : "#fff"}
                        bgColor={ThemeMode === "light" ? "#fff" : "#211f1e"}
                        options={THEME_OPTIONS}
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
            </div>
            {/*{user.permissions.CURRENT_SITUATION_EMPLOYEE && (*/}
            <div>
              <h3>&#183; 근무지 설정 &#183;</h3>
              <PlaceSettingForm
                label="본사"
                placeName="Place01"
                radiusName="radius01"
                Controller={Controller}
                setValue={setValue}
                control={control}
                errors={errors}
              />
              <PlaceSettingForm
                label="서울 (M-Tower, E-Tower)"
                placeName="Place02"
                radiusName="radius02"
                Controller={Controller}
                setValue={setValue}
                control={control}
                errors={errors}
              />

              <PlaceSettingForm
                label="월드"
                placeName="Place03"
                radiusName="radius03"
                Controller={Controller}
                setValue={setValue}
                control={control}
                errors={errors}
              />

              <PlaceSettingForm
                label="시그니엘"
                placeName="Place04"
                radiusName="radius04"
                Controller={Controller}
                setValue={setValue}
                control={control}
                errors={errors}
              />
            </div>
            {/*)}*/}
            {/*{user.userRole === "EMPLOYEE" && (*/}
            <div>
              <h3>&#183; 메일 서명 설정 &#183;</h3>
              <Row label="사용여부">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="SignStatus"
                    render={({ field }) => (
                      <div>
                        <SimpleRadio
                          {...field}
                          value="USED"
                          {...register("SignStatus")}
                        >
                          사용
                        </SimpleRadio>
                        <SimpleRadio
                          {...field}
                          value="NOTUSED"
                          {...register("SignStatus")}
                        >
                          사용안함
                        </SimpleRadio>
                      </div>
                    )}
                  />
                </div>
              </Row>
              {isSigned && (
                <div>
                  <Row label="서명 양식">
                    <div className="input-sec">
                      <Controller
                        control={control}
                        name="SignSelect"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="선택"
                            variant="primary"
                            fullWidth
                            options={SIGN_OPTIONS}
                            errors={errors}
                          />
                        )}
                      />
                      <span className="void-sec"></span>
                      {values.SignSelect.value === "SIGN_IMAGE" && (
                        <Controller
                          control={control}
                          name="SignImage"
                          render={({ field }) => (
                            <FileInput
                              {...field}
                              accept=".jpg, .png"
                              errors={errors}
                              fullWidth
                              placeholder={"(JPG(JPEG), PNG / 최대 5MB까지)"}
                            />
                          )}
                        />
                      )}
                      {values.SignSelect.value === "SIGN_FORM" && (
                        <div>
                          <div className="sign-sec">
                            <div className="sign-form-text">성명</div>
                            <Controller
                              control={control}
                              name="SignName"
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  multiline={false}
                                  fullWidth={false}
                                  placeholder="성명을 입력하세요."
                                  errors={errors}
                                />
                              )}
                            />
                          </div>
                          <div className="sign-sec">
                            <div className="sign-form-text">직책</div>
                            <Controller
                              control={control}
                              name="SignPosition"
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  multiline={false}
                                  fullWidth={false}
                                  placeholder="직책을 입력하세요."
                                  errors={errors}
                                />
                              )}
                            />
                          </div>
                          <div className="sign-sec">
                            <div className="sign-form-text">핸드폰 번호</div>
                            <Controller
                              control={control}
                              name="SignPhone"
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  multiline={false}
                                  fullWidth={false}
                                  placeholder="핸드폰 번호를 입력하세요."
                                  errors={errors}
                                />
                              )}
                            />
                          </div>
                          <div className="sign-sec">
                            <div className="sign-form-text">E-Mail</div>
                            <Controller
                              control={control}
                              name="SignEmail"
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  multiline={false}
                                  fullWidth={false}
                                  placeholder="이메일을 입력하세요."
                                  errors={errors}
                                />
                              )}
                            />
                          </div>
                          <div className="sign-sec">
                            <div className="sign-form-text">Web</div>
                            <Controller
                              control={control}
                              name="SignWeb"
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  multiline={false}
                                  fullWidth={false}
                                  placeholder="웹사이트 주소를 입력하세요."
                                  errors={errors}
                                />
                              )}
                            />
                          </div>
                          <div className="sign-sec">
                            <div className="sign-form-text">주소</div>
                            <Controller
                              control={control}
                              name="SignAddress"
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  w="452"
                                  type="text"
                                  placeholder="주소"
                                  value={enrollCompany.address}
                                  errors={errors}
                                />
                              )}
                            />
                            <button
                              type="button"
                              className="address-search"
                              onClick={() => {
                                setPopup(true);
                              }}
                            >
                              주소찾기
                            </button>
                            {popup && (
                              <Postcode
                                setPopup={setPopup}
                                company={enrollCompany}
                                setcompany={setEnrollCompany}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Row>
                </div>
              )}
            </div>
            {/*)}*/}
            <div className="submit-btn">
              <Button type="submit" variant="primary" preset="2">
                저장
              </Button>
            </div>
          </form>
        </div>
      </SettingStyled>
    </Layout>
  );
};

export default Setting;

const SettingStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    padding: 35px;
  }

  form {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #222;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .submit-btn {
    width: 408px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .place-sec {
    display: flex;
    margin-bottom: 10px;
  }

  .place-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    min-width: 100px;
    font-size: 18px;
    font-weight: bold;
    color: #b2b2b2;

    span {
      margin-top: 5px;
      font-size: 14px;
    }
  }

  .address-search {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
  }

  .place-radius {
    width: 100%;
  }

  .void-sec {
    display: block;
    height: 10px;
  }

  .sign-sec {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .sign-form-text {
    width: 150px;
    font-size: 18px;
    font-weight: normal;
  }
`;

//const ToggleWrapper = styled.button`
//  background-color: ${(props: any) => props.theme.bgColor};
//  border: ${(props: any) => props.theme.borderColor};
//  color: ${(props: any) => props.theme.fontColor};
//  font-size: 20px;
//  margin: 10px 0 0 10px;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  width: 96px;
//  height: 48px;
//  border: 1px solid #d9d9d9;
//`;
