import { ThemeTypes } from "types/commonTypes";

export const lightTheme: ThemeTypes = {
  fontColor: "#000",
  logoBgColor: "#000",
  sidebarBgColor: "#fff",
  sidebarColor: "#222",
  moveSiteBtnBgColor: "#f5f5f5",
  contentBgColor: "#f9f9f9",
  footerBgColor: "#000",
  maincontentLineColor: "#ddd",
  boardTableColor: "#101010",
  boardDataLineColor: "#ddd",
  moreBtnColor: "#e5e5e5",
  mainUserNameColor: "#222",
  subColor: "#444",
  filterBtnColor: "#b2b2b2",
  filterSelectColor: "#444",
  listBgColor: "#f9f9f9",
  fixedLineColor:
    "linear-gradient( to left, rgba(255, 255, 255, 0.1) 10%, rgba(100, 100, 100, 0.2) 300%, rgba(255, 255, 255, 0.5) 130%)",
  fixedBorderBottomColor: "3px solid #ebebeb",
};

export const darkTheme: ThemeTypes = {
  fontColor: "#fff",
  logoBgColor: "#33312f",
  sidebarBgColor: "#33312f",
  sidebarColor: "#efefef",
  moveSiteBtnBgColor: "#211f1e",
  contentBgColor: "#262523",
  footerBgColor: "#191919",
  maincontentLineColor: "#413f3f",
  boardTableColor: "#fff",
  boardDataLineColor: "#545454",
  moreBtnColor: "#3b3b3b",
  mainUserNameColor: "#fff",
  subColor: "#fff",
  filterBtnColor: "#222",
  filterSelectColor: "#c9c9c9",
  listBgColor: "#444",
  fixedLineColor:
    "linear-gradient( to left, rgba(0, 0, 0, 0.1) 10%, rgba(100, 100, 100, 0.2) 300%, rgba(255, 255, 255, 0.5) 130%)",
  fixedBorderBottomColor: "3px solid #545454",
};

export const theme = {
  lightTheme,
  darkTheme,
};
