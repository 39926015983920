import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";

// assets
import BtnNextPSVG from "assets/images/btn_next_P.svg";

interface PaginationProps {
  page: number;
  count: number;
  list: any;
  onChangePage: (e: number) => void;
}

const Pagination = ({ page, count, list, onChangePage }: PaginationProps) => {
  const buttonLimit = 5;
  const start = Math.floor((page - 1) / buttonLimit) * buttonLimit + 1;
  const rest =
    start + (buttonLimit - 1) < count
      ? buttonLimit - 1
      : (count - start) % buttonLimit;
  const btns = [];
  let totalBtnCount = start + rest;

  // 1페이지에 10개 이하의 리스트가 있을 경우
  if (page === 1 && list < 10) totalBtnCount = 1;

  for (let i: number = start; i <= totalBtnCount; i++) {
    btns.push(
      <PageSpan
        key={i}
        active={page === i}
        onClick={() => {
          if (page === i) {
            return;
          }
          onChangePage(i);
        }}
      >
        {i}
      </PageSpan>
    );
  }

  return (
    <Container>
      <PaginationWrap>
        <Icon
          src={BtnNextPSVG}
          onClick={() => (page === 1 ? null : onChangePage(page - 1))}
        />
        {btns}
        <Icon
          css={iconImg}
          src={BtnNextPSVG}
          onClick={() =>
            page === count || count <= 1 ? null : onChangePage(page + 1)
          }
        />
      </PaginationWrap>
    </Container>
  );
};

export default Pagination;

const iconImg = css`
  transform: rotate(180deg);
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const PaginationWrap = styled.div`
  width: 320px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  & img {
    height: 14px;
  }
`;

const PageSpan = styled.span<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 33px;
  margin: 0 12px;
  cursor: pointer;
  border-radius: 4px;

  ${({ active }) =>
    active
      ? css`
          background-color: #1532ee;
          color: #fff;
        `
      : css`
          background-color: transparent;
          color: #000;
        `}
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
