import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "react-dayjs-picker";

import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import FileInput from "components/FileInput/FileInput";

import { ApprovalDocumentTypes } from "types/commonTypes";

import PlanJoinMember from "./PlanJoinMember";

const PlanAppForm = ({
  Controller,
  setValue,
  control,
  errors,
  handleFormSubmit,
}: ApprovalDocumentTypes) => {
  const navigate = useNavigate();

  return (
    <>
      <PlanAppFormStyled>
        <h5>&#183; 신청자 정보 &#183;</h5>
        <Row label="신청자">
          <div className="input-sec">
            <Controller
              control={control}
              name="approvalName"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                  disabled
                />
              )}
            />
          </div>
        </Row>
        <Row label="직급·직책">
          <div className="input-sec">
            <Controller
              control={control}
              name="approvalPosition"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                  disabled
                />
              )}
            />
          </div>
        </Row>
        <Row label="부서">
          <div className="input-sec">
            <Controller
              control={control}
              name="approvalDepartment"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                  disabled
                />
              )}
            />
          </div>
        </Row>
        <Row label="작성일">
          <div className="input-sec">
            <Controller
              control={control}
              name="approvalDate"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                  disabled
                />
              )}
            />
          </div>
        </Row>
        <h5>&#183; 신청 정보 &#183;</h5>
        <Row label="제목">
          <div className="input-sec">
            <Controller
              control={control}
              name="planTitle"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                />
              )}
            />
          </div>
        </Row>
        <Row label="기획안">
          <div className="input-sec">
            <Controller
              control={control}
              name="planProposal"
              render={({ field }: any) => (
                <FileInput {...field} accept=".pdf" errors={errors} fullWidth />
              )}
            />
          </div>
        </Row>
        <Row label="기획분야">
          <div className="input-sec">
            <Controller
              control={control}
              name="planField"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={false}
                  fullWidth={false}
                  errors={errors}
                />
              )}
            />
          </div>
        </Row>
        <Row label="요약">
          <div className="input-sec">
            <Controller
              control={control}
              name="planField"
              render={({ field }: any) => (
                <Input
                  {...field}
                  type="text"
                  multiline={true}
                  fullWidth={false}
                  errors={errors}
                  placeholder="내용을 입력하세요."
                />
              )}
            />
          </div>
        </Row>
        <Row label="참여인원">
          <div className="input-sec plan-join-member">
            <Controller
              control={control}
              name={`planJoinMember.join1`}
              render={({ field }: any) => <PlanJoinMember {...field} />}
            />
            <Controller
              control={control}
              name={`planJoinMember.join2`}
              render={({ field }: any) => <PlanJoinMember {...field} />}
            />
            <Controller
              control={control}
              name={`planJoinMember.join3`}
              render={({ field }: any) => <PlanJoinMember {...field} />}
            />
            <Controller
              control={control}
              name={`planJoinMember.join4`}
              render={({ field }: any) => <PlanJoinMember {...field} />}
            />
            <Controller
              control={control}
              name={`planJoinMember.join5`}
              render={({ field }: any) => <PlanJoinMember {...field} />}
            />
            <Controller
              control={control}
              name={`planJoinMember.join6`}
              render={({ field }: any) => <PlanJoinMember {...field} />}
            />
          </div>
        </Row>
        <Row label="첨부파일">
          <div className="input-sec">
            <Controller
              control={control}
              name="planAttachment"
              render={({ field }: any) => (
                <FileInput {...field} accept=".pdf" errors={errors} fullWidth />
              )}
            />
          </div>
        </Row>
        <div className="submit-btn">
          <Button
            type="button"
            variant="tertiary"
            preset="1"
            onClick={() => navigate(-1)}
          >
            목록
          </Button>
          <span className="void-sec"></span>
          <Button
            type="button"
            variant="primary"
            preset="2"
            onClick={() => handleFormSubmit()}
          >
            신청
          </Button>
        </div>
      </PlanAppFormStyled>
    </>
  );
};

export default PlanAppForm;

const PlanAppFormStyled = styled.div`
  width: 680px;
  h5 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #222;
    margin-bottom: 30px;
  }

  .plan-join-member {
    display: flex;
    flex-wrap: wrap;
    & > div {
      flex: 1 1 30%;
      margin-bottom: 8px;
    }
  }
`;
