import { useState } from "react";
import styled from "@emotion/styled";

import Button from "components/Button/Button";

import BtnDelSVG from "assets/images/btn_del.svg";
import IconSearchSVG from "assets/images/icon_search.svg";
import BtnCheckOffSVG from "assets/images/btn_check_off.png";
import BtnCheckOnSVG from "assets/images/btn_check_on.png";

import { TestSearchMember } from "utils/testdata";

interface SearchItemProps {
  setResult: any;
  setOpenSearchPopup: any;
}

const SearchItem = ({ setResult, setOpenSearchPopup }: SearchItemProps) => {
  const [page, setPage] = useState<number>(1);
  const [checked, setChecked] = useState<any>([]);

  const handleCheck = (e: any, item?: any) => {
    if (e.target.name === "All") {
      if (e.target.checked) {
        const allFilter = TestSearchMember.map((e: any) => {
          e.checked = true;
          return e;
        });

        setChecked(allFilter);
      } else {
        TestSearchMember.map((e: any) => (e.checked = false));
        setChecked([]);
      }
    } else {
      if (e.target.checked) {
        item.checked = true;
        setChecked([...checked, item]);
      } else {
        item.checked = false;
        setChecked(checked.filter((v: boolean) => v !== item));
      }
    }
  };

  return (
    <SearchItemStyle>
      <div className="search-member-wrap">
        <div
          className="close-img"
          onClick={() => {
            setOpenSearchPopup(false);
            TestSearchMember.map((e: any) => (e.checked = false));
          }}
        >
          <img src={BtnDelSVG} alt="닫기 이미지" />
        </div>
        <div>
          <h4>린넨 품목 검색</h4>
          <div className="search-member-input">
            <input type="text" placeholder="검색어를 입력하세요." />
            <img src={IconSearchSVG} alt="돋보기 이미지" />
          </div>
          <div className="search-member-table">
            <table>
              <thead>
                <tr>
                  <th className="check-sec">
                    <input
                      type="checkbox"
                      name="All"
                      id="All"
                      onChange={(e: any) => handleCheck(e)}
                    />
                  </th>
                  <th>품목</th>
                  <th>규격</th>
                  <th>품목단가</th>
                </tr>
              </thead>
              <tbody>
                {TestSearchMember.map((item: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td className="check-sec">
                        <input
                          type="checkbox"
                          onChange={(e: any) => handleCheck(e, item)}
                          checked={item.checked}
                        />
                      </td>
                      <td>{item.department}</td>
                      <td>{item.position}</td>
                      <td>{item.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="search-member-submit-btn">
            <Button
              w="151"
              h="57"
              type="button"
              variant="tertiary"
              preset="1"
              onClick={() => {
                setOpenSearchPopup(false);
                TestSearchMember.map((e: any) => (e.checked = false));
                setChecked([]);
              }}
            >
              취소
            </Button>
            <span className="void-sec"></span>
            <Button
              w="151"
              h="57"
              type="button"
              variant="primary"
              preset="2"
              onClick={() => {
                setOpenSearchPopup(false);
                TestSearchMember.map((e: any) => (e.checked = false));
                setResult(checked);
              }}
            >
              선택
            </Button>
          </div>
        </div>
      </div>
    </SearchItemStyle>
  );
};

export default SearchItem;

const SearchItemStyle = styled.div`
  position: fixed;
  z-index: 999999999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  .search-member-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 541px;
    border-radius: 20px;
    padding: 28px;
  }

  .close-img {
    text-align: right;
    cursor: pointer;
  }

  h4 {
    font-size: 26px;
    font-weight: bold;
    color: #c09260;
    margin: 0 0 24px 0;
  }

  .search-member-input {
    margin-bottom: 10px;
    input {
      width: 100%;
      height: 52px;
      border: solid 1px #ddd;
      background-color: #fff;
      padding-left: 20px;

      &::placeholder {
        color: #c9c9c9;
      }
    }

    img {
      position: absolute;
      margin-top: 15px;
      right: 40px;
    }
  }

  .search-member-table {
    height: 480px;
    border-bottom: 3px solid #ebebeb;
    overflow: scroll;
    table {
      width: 100%;
    }
    thead {
      height: 68px;
      background-color: #f9f9f9;

      th {
        font-size: 18px;
        font-weight: bold;
        vertical-align: middle;
      }
    }

    tbody {
      tr {
        width: 100%;
        height: 68px;
        border-bottom: 1px solid #ebebeb;
      }

      td {
        text-align: center;
        font-size: 15px;
        color: #444;
        vertical-align: middle;
      }
    }

    .check-sec {
      width: 20%;

      input {
        appearance: none;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        background: url(${BtnCheckOffSVG}) no-repeat center/100%;

        &:checked {
          background: url(${BtnCheckOnSVG}) no-repeat center/100%;
        }
      }
    }
  }

  .search-member-submit-btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .void-sec {
      margin: 0 4px;
    }
  }
`;
