import { useState } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";

import Layout from "layout/layout";

import Button from "components/Button/Button";
import Search from "components/Search/Search";

import IconCalSVG from "assets/images/icon_cal.svg";

import { PAGE_LAYOUT } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import "react-dayjs-picker/dist/index.css";
import CommissionPopup from "./CommissionPopup";

const defaultValues = {
  selectDate1: "",
  selectDate2: "",
  itemSearch: "",
};

const HEON = () => {
  const navigate = useNavigate();
  const [openDate1, setOpenDate1] = useState(false);
  const [openDate2, setOpenDate2] = useState(false);
  const [isCommission, setIsCommission] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const handleFormSubmit = () => {
    setIsEdit(false);
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <Layout page={PAGE_LAYOUT.heon}>
      <HEONStyled>
        <div className="status-wrap">
          <div className="status-header">
            <div className="search-sec">
              <form
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleFormSubmit();
                }}
              >
                <div className="search-select date-input">
                  <div className="header-text">기간</div>
                  <div
                    className="search-date-image"
                    onClick={() => {
                      setOpenDate1(true);
                    }}
                  >
                    <img src={IconCalSVG} alt="캘린더" />
                  </div>
                  <Controller
                    control={control}
                    name="selectDate1"
                    render={({ field }) => (
                      <DatePicker
                        isOpen={openDate1}
                        setIsOpen={setOpenDate1}
                        popoverPositions={["bottom"]}
                        onSelect={(date) => {
                          field.onChange(dayjs(date).format("YYYY-MM-DD"));
                        }}
                        value={field.value}
                        closeOnSelect={true}
                        onChange={() => {}}
                      />
                    )}
                  />
                  <span className="date-span">~</span>
                  <div
                    className="search-date-image2"
                    onClick={() => {
                      setOpenDate2(true);
                    }}
                  >
                    <img src={IconCalSVG} alt="캘린더" />
                  </div>
                  <Controller
                    control={control}
                    name="selectDate2"
                    render={({ field }) => (
                      <DatePicker
                        isOpen={openDate2}
                        setIsOpen={setOpenDate2}
                        popoverPositions={["bottom"]}
                        onSelect={(date) => {
                          field.onChange(dayjs(date).format("YYYY-MM-DD"));
                        }}
                        value={field.value}
                        closeOnSelect={true}
                        onChange={() => {}}
                      />
                    )}
                  />
                </div>
                <div className="search-select">
                  <div className="header-text">검색</div>
                  <Controller
                    control={control}
                    name="itemSearch"
                    render={({ field }) => (
                      <Search
                        {...field}
                        placeholder="검색어를 입력하세요."
                        handleFormSubmit={handleFormSubmit}
                      />
                    )}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="heon-body">
            <table>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>자재 코드</th>
                  <th>브랜드</th>
                  <th>품목명</th>
                  <th>규격</th>
                  <th>단가</th>
                  <th>
                    판매가
                    <br />
                    (VAT 별도·NET)
                  </th>
                  <th>원가율</th>
                  <th>금일 판매량</th>
                  <th>할인률</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Duvet</td>
                  <td>HN0000001</td>
                  <td>브랜드 1</td>
                  <td>품목1</td>
                  <td>1040*1940</td>
                  <td>₩ 10,000</td>
                  <td>₩ 10,000</td>
                  <td>20%</td>
                  {isEdit ? (
                    <td>
                      <input type="number" id="today-sale" /> EA
                    </td>
                  ) : (
                    <td>2 EA</td>
                  )}
                  {isEdit ? (
                    <td>
                      <input type="number" id="discount" /> %
                    </td>
                  ) : (
                    <td>5%</td>
                  )}
                </tr>
              </tbody>
            </table>
            <div className="heon-total">
              <div className="heon-total-pay">
                <div className="heon-total-align">
                  <div className="heon-total-title heon-total-title1">
                    [2022.09.16]
                    <br />
                    매출순위
                  </div>
                  <div className="heon-total-value heon-total-lank">
                    <div>#1 [브랜드] 품명</div>
                    <div>#2 [브랜드] 품명</div>
                    <div>#3 [브랜드] 품명</div>
                    <div>#4 [브랜드] 품명</div>
                    <div>#5 [브랜드] 품명</div>
                  </div>
                </div>
                <div className="heon-total-net">
                  <div className="heon-total-align">
                    <div className="heon-total-title heon-total-title2">
                      [2022.09.16]
                      <br />
                      해온 NET
                    </div>
                    <div className="heon-total-value heon-total-value1">
                      ₩ 3,000,000
                    </div>
                  </div>
                  <div className="heon-total-align">
                    <div className="heon-total-title heon-total-title2">
                      판매 수수료
                    </div>
                    <div className="heon-total-value heon-total-value1">
                      ₩ 450,000
                    </div>
                  </div>
                </div>
              </div>
              <div className="heon-total-percent">
                <div className="heon-total-align">
                  <div className="heon-total-title heon-total-title3">
                    전일대비
                  </div>
                  <div className="heon-total-value heon-total-value2">7%</div>
                </div>
                <div className="heon-total-align">
                  <div className="heon-total-title heon-total-title3">
                    전월대비
                  </div>
                  <div className="heon-total-value heon-total-value2">3%</div>
                </div>
                <div className="heon-total-align">
                  <div className="heon-total-title heon-total-title3">
                    전년대비
                  </div>
                  <div className="heon-total-value heon-total-value2">-2%</div>
                </div>
              </div>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate("/work/heon/manage")}
              >
                품목관리
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => {}}
              >
                엑셀로 내보내기
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="secondary"
                preset="1"
                onClick={() => setIsCommission(true)}
              >
                매출 수수료 변경
              </Button>
              <span className="void-sec"></span>
              {isEdit ? (
                <Button
                  type="button"
                  variant="primary"
                  preset="2"
                  onClick={() => handleFormSubmit()}
                >
                  수정
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="primary"
                  preset="2"
                  onClick={() => setIsEdit(true)}
                >
                  수정 모드
                </Button>
              )}
            </div>
          </div>
          {isCommission && (
            <CommissionPopup setIsCommission={setIsCommission} />
          )}
        </div>
      </HEONStyled>
    </Layout>
  );
};

export default HEON;

const HEONStyled = styled.div`
  width: 100%;
  margin: 40px;

  form {
    .search-select {
      display: flex;
      align-items: center;
    }

    .header-text {
      font-size: 16px;
      font-weight: bold;
      color: #222;
      margin-right: 10px;
      margin-left: 5px;
    }

    .search-date-image2 {
      position: absolute;
      margin-top: 0px;
      margin-left: 319px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      background-color: #444;
      cursor: pointer;
    }

    .search-date-image {
      position: absolute;
      margin-top: 0px;
      margin-left: 144px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      background-color: #444;
      cursor: pointer;
    }

    .date-span {
      margin: 0 8px;
    }

    .date-input {
      input::placeholder {
        color: #b2b2b2;
      }
      input[name="rdp-date-picker"] {
        width: 150px;
        height: 52px;
        border: solid 1px #ddd;
        background-color: #fff;
        line-height: 1.29;
        font-size: 14px;
        color: #000;
        padding-left: 10px;
      }
      .react-tiny-popover-container {
        height: 100px;
      }
    }
  }

  .heon-body {
    margin-top: 30px;
    table {
      width: 100%;
      border-bottom: 3px solid #ebebeb;

      thead {
        th {
          width: 10%;
          font-size: 18px;
          line-height: 23px;
          font-weight: bold;
          color: #101010;
          vertical-align: middle;
          height: 68px;
          background-color: #f9f9f9;
        }
      }
      tbody {
        tr {
          height: 66px;
          border-bottom: 1px solid #ebebeb;
        }

        td {
          width: 10%;
          text-align: center;
          font-size: 15px;
          font-weight: normal;
          vertical-align: middle;
          color: #444;
        }

        input {
          width: 58px;
          height: 40px;
          border: solid 1px #ddd;
          padding-left: 10px;

          &:-internal-autofill-selected {
            transition: background-color 60000s;
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &[type="number"] {
            -moz-appearance: textfield;
          }
        }
      }
    }
  }

  .heon-total {
    display: flex;
    margin: 40px 0;

    .heon-total-pay {
      width: 65%;
      display: flex;
      margin-right: 5%;
      border: solid 1px #dcdcdc;
    }
    .heon-total-percent {
      width: 30%;
      border: solid 1px #dcdcdc;
    }
    .heon-total-net {
      width: 100%;
    }
    .heon-total-align {
      width: 100%;
      display: flex;
      text-align: center;
      line-height: 25px;
    }

    .heon-total-title {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      color: #444;
      background-color: #f5f6fa;
    }
    .heon-total-value {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 5%;
    }
    .heon-total-lank {
      width: 55%;
      height: 180px;
      flex-direction: column;
      align-items: flex-start;
    }

    .heon-total-value1 {
      width: 50%;
      justify-content: end;
    }
    .heon-total-value2 {
      width: 60%;
    }

    .heon-total-title1 {
      width: 40%;
      height: 180px;
    }
    .heon-total-title2 {
      width: 50%;
      height: 90px;
    }
    .heon-total-title3 {
      width: 40%;
    }
  }

  .submit-btn {
    margin: 0 auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
