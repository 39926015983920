import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import request from "api/request";

import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Radio from "components/Radio/Radio";
import Select from "components/Select/Select";
import Textbox from "components/TextBox/TextBox";
import FileInput from "components/FileInput/FileInput";
import Postcode from "components/Postcode/Postcode";

import { PREFIX_NUMBER_OPTIONS, CARD_COMPANY_OPTIONS } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import { MypageParttimerProps } from "types/registType";

const defaultValues: MypageParttimerProps = {
  mypageName: "",
  mypageEmployeeNumber: "",
  mypageEmail: "",
  mypagePassword: "",
  mypagePasswordCheck: "",
  mypageIDPhoto: [],
  mypageGender: "",
  mypageBirth01: "",
  mypageBirth02: "",
  mypageBirth03: "",
  mypagePhone1: {
    value: "init",
    label: "선택",
    code: "",
  },
  mypagePhone2: "",
  mypagePhone3: "",
  zipCode: "",
  address: "",
  detailAddress: "",
  mypageBank1: {
    value: "init",
    label: "은행 선택",
    code: "",
  },
  mypageBank2: "",
};

const MypageParttimerForm = () => {
  const [popup, setPopup] = useState(false);
  const [enrollCompany, setEnrollCompany] = useState({
    address: "",
    zipcode: "",
  });
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  return (
    <MypageParttimerFormStyled>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();

          handleSubmit(
            () => {},
            (err) => {
              console.log(err);
            }
          )();
        }}
      >
        <div>
          <h3>&#183; 개인 정보 &#183;</h3>
          <Row label="이름">
            <div className="input-sec">
              <Controller
                control={control}
                name="mypageName"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    multiline={false}
                    fullWidth={false}
                    placeholder="이름을 입력하세요."
                    errors={errors}
                  />
                )}
              />
            </div>
          </Row>
          <Row label="사원번호">
            <div className="input-sec">
              <Controller
                control={control}
                name="mypageEmployeeNumber"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    multiline={false}
                    fullWidth={false}
                    errors={errors}
                    disabled
                  />
                )}
              />
            </div>
          </Row>
          <Row label="이메일 주소">
            <div className="input-sec">
              <Controller
                control={control}
                name="mypageEmail"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    multiline={false}
                    fullWidth={false}
                    placeholder="이메일을 입력하세요."
                    errors={errors}
                  />
                )}
              />
            </div>
          </Row>
          <Row label="비밀번호 변경">
            <div className="input-sec">
              <Controller
                control={control}
                name="mypagePassword"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    multiline={false}
                    fullWidth={false}
                    placeholder="비밀번호를 입력해 주세요."
                    errors={errors}
                  />
                )}
              />
            </div>
          </Row>
          <Row label="비밀번호 확인">
            <div className="input-sec">
              <Controller
                control={control}
                name="mypagePasswordCheck"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    multiline={false}
                    fullWidth={false}
                    placeholder="비밀번호를 입력해 주세요."
                    errors={errors}
                  />
                )}
              />
            </div>
          </Row>
          <Row label="증명사진">
            <div className="input-sec">
              <Controller
                control={control}
                name="mypageIDPhoto"
                render={({ field }) => (
                  <FileInput
                    {...field}
                    accept=".jpg, .png"
                    errors={errors}
                    fullWidth
                    placeholder={"(JPG(JPEG), PNG / 최대 5MB까지)"}
                  />
                )}
              />
            </div>
          </Row>
          <Row label="성별">
            <div className="input-sec">
              <Controller
                control={control}
                name="mypageGender"
                render={({ field }) => (
                  <div>
                    <Radio
                      {...field}
                      name="gender"
                      value="남성"
                      w="88"
                      h="65"
                      p="0"
                      isToggle={false}
                    >
                      남성
                    </Radio>
                    <span className="void-sec"></span>
                    <Radio
                      {...field}
                      name="gender"
                      value="여성"
                      w="88"
                      h="65"
                      p="0"
                      isToggle={false}
                    >
                      여성
                    </Radio>
                  </div>
                )}
              />
            </div>
          </Row>
          <Row label="생년월일">
            <div className="input-sec birth-box">
              <Controller
                control={control}
                name="mypageBirth01"
                render={({ field }) => (
                  <Textbox
                    {...field}
                    placeholder="YYYY"
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength="4"
                  >
                    년
                  </Textbox>
                )}
              />
              <span className="void-sec"></span>
              <Controller
                control={control}
                name="mypageBirth02"
                render={({ field }) => (
                  <Textbox
                    {...field}
                    placeholder="MM"
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength="2"
                  >
                    월
                  </Textbox>
                )}
              />
              <span className="void-sec"></span>
              <Controller
                control={control}
                name="mypageBirth03"
                render={({ field }) => (
                  <Textbox
                    {...field}
                    placeholder="DD"
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength="2"
                  >
                    일
                  </Textbox>
                )}
              />
            </div>
          </Row>
          <Row label="휴대폰 번호">
            <div className="input-sec phonenumber">
              <Controller
                control={control}
                name="mypagePhone1"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="선택"
                    variant="tertiary"
                    options={PREFIX_NUMBER_OPTIONS}
                    errors={errors}
                  />
                )}
              />
              <span>-</span>
              <Controller
                control={control}
                name="mypagePhone2"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength="4"
                    errors={errors}
                  />
                )}
              />
              <span>-</span>
              <Controller
                control={control}
                name="mypagePhone3"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength="4"
                    errors={errors}
                  />
                )}
              />
            </div>
          </Row>
          <Row label="주소">
            <div className="input-sec">
              <div className="zipcode">
                <Controller
                  control={control}
                  name="zipCode"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      placeholder="우편번호"
                      value={enrollCompany.zipcode || ""}
                      fullWidth
                      errors={errors}
                    />
                  )}
                />
                <button
                  type="button"
                  className="address-search"
                  onClick={() => {
                    setPopup(true);
                  }}
                >
                  주소찾기
                </button>
                {popup && (
                  <Postcode
                    setPopup={setPopup}
                    company={enrollCompany}
                    setcompany={setEnrollCompany}
                  />
                )}
              </div>
              <div className="void-sec"></div>
              <Controller
                control={control}
                name="address"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder="주소"
                    fullWidth
                    value={enrollCompany.address || ""}
                    errors={errors}
                  />
                )}
              />
              <div className="void-sec"></div>
              <Controller
                control={control}
                name="detailAddress"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder="상세주소를 입력하세요."
                    fullWidth
                    errors={errors}
                  />
                )}
              />
            </div>
          </Row>
        </div>
        <div>
          <h3>&#183; 계좌정보 &#183;</h3>
          <Row label="계좌번호">
            <div className="input-sec bank-select">
              <Controller
                control={control}
                name="mypageBank1"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="은행 선택"
                    variant="secondary"
                    options={CARD_COMPANY_OPTIONS}
                    errors={errors}
                  />
                )}
              />
              <span className="void-sec"></span>
              <Controller
                control={control}
                name="mypageBank2"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    placeholder="'-' 없이 숫자만 입력하세요."
                    fullWidth
                    errors={errors}
                  />
                )}
              />
            </div>
          </Row>
        </div>
        <div>
          <h3>&#183; 경력사항 &#183;</h3>
          <div className="annual-box">
            <div>
              <div className="fixed-void-text">경력여부</div>
              <div>{"-"}</div>
            </div>
            <div>
              <div className="fixed-void-text">주 분야</div>
              <div>{"-"}</div>
            </div>
          </div>
        </div>
        <div>
          <h3>&#183; 정보 &#183;</h3>
          <div className="annual-box">
            <div>
              <div className="fixed-void-text">최근 기본 시급</div>
              <div>{"-"}</div>
            </div>
            <div>
              <div className="fixed-void-text">월 누적시간</div>
              <div>{"-"}</div>
            </div>
            <div>
              <div className="fixed-void-text">경고 누적 수</div>
              <div>{"-"}</div>
            </div>
            <div>
              <div className="fixed-void-text">비고</div>
              <div>{"-"}</div>
            </div>
          </div>
        </div>
        <div className="submit-btn">
          <Button type="submit" variant="primary" preset="2">
            수정
          </Button>
        </div>
      </form>
    </MypageParttimerFormStyled>
  );
};

export default MypageParttimerForm;

const MypageParttimerFormStyled = styled.div`
  form {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #222;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .phonenumber {
    display: flex;

    span {
      margin: 27px 10px 0 10px;
    }
  }

  .zipcode {
    display: flex;
  }

  .birth-box {
    display: flex;
  }

  .bank-select {
    display: flex;
  }

  .address-search {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
  }

  .void-sec {
    height: 10px;
    margin: 0 5px;
  }

  .fixed-void-text {
    width: 150px;
    font-size: 18px;
    font-weight: bold;
    color: #b2b2b2;
  }

  .annual-box {
    margin-top: -40px;
    & > div {
      display: flex;
      align-items: center;
      padding: 30px 0;
      border-bottom: 1px solid #ddd;
    }
  }

  .submit-btn {
    width: 408px;
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: center;
  }
`;
