import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import request from "api/request";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/layout";

import usePageSlice from "hooks/usePageSlice";

import Search from "components/Search/Search";
import Pagination from "components/Pagination/Pagination";

import NoDataSVG from "assets/images/no_data_ico.svg";
import IcDropBtnSVG from "assets/images/icon_drop.svg";
import BtnCheckOffSVG from "assets/images/btn_check_off.png";
import BtnCheckOnSVG from "assets/images/btn_check_on.png";

import { PAGE_LAYOUT } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

const defaultValues = {
  search: "",
};

const EventNotice = () => {
  const navigate = useNavigate();
  const [listOption, setListOption] = useState("created_at");
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [checkData, setCheckData] = useState<any | undefined>([]);
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  //useEffect(() => {
  //  request(
  //    "get",
  //    "/posts",
  //    {},
  //    {
  //      type: "EVENT",
  //      orderCol: listOption,
  //    }
  //  )
  //    .then((res: any) => {
  //      if (res.status === 200) {
  //        setTableData(res.data.result.data);
  //        setTotalCount(res.data.result.total_elements);
  //        setTotalPages(Math.ceil(res.data.result.total_elements / 10));
  //      }
  //    })
  //    .catch((err) => {
  //      throw err;
  //    });
  //}, [listOption, isDelete]);

  const pageArr: any = usePageSlice(tableData);

  //useEffect(() => {
  //  const newArr: any = [];
  //  for (let i = 0; i < pageArr[page - 1]?.length; i++) {
  //    const newObj = Object.assign({}, pageArr[page - 1][i], {
  //      checked: false,
  //    });
  //    newArr.push(newObj);
  //  }
  //}, [pageArr, page]);

  // 리스트 체크박스
  const handleCheck = (e: any, item?: any) => {
    if (e.target.name === "All") {
      if (e.target.checked) {
        const allFilter = pageArr[page - 1].map((e: any) => {
          e.checked = true;
          return e.id;
        });
        setCheckData(allFilter);
      } else {
        pageArr[page - 1].map((e: any) => (e.checked = false));
        setCheckData([]);
      }
    } else {
      if (e.target.checked) {
        item.checked = true;
        setCheckData([...checkData, item.id]);
      } else {
        item.checked = false;
        setCheckData(checkData.filter((v: number) => v !== item.id));
      }
    }
  };

  // 행사공지 삭제
  //const handleDelList = () => {
  //  Promise.all(
  //    checkData.map((id: number) => {
  //      return request("delete", `/posts/${id}`, {}, {})
  //        .then((res: any) => {
  //          if (res.status === 200) {
  //            setIsDelete((prev) => !prev);
  //            setCheckData([]);
  //          }
  //        })
  //        .catch((err) => err);
  //    })
  //  );
  //};

  const handleFormSubmit = () => {
    handleSubmit(
      (res: any) => {
        const searchResult = tableData.filter(
          (e: any) => e.title === values.search
        );
        setTableData(searchResult);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <Layout page={PAGE_LAYOUT.event_notice}>
      <EventNoticeStyled>
        <div className="status-wrap">
          <div className="event-wrap">
            <div className="event-notice-header">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleFormSubmit();
                }}
              >
                <Controller
                  control={control}
                  name="search"
                  render={({ field }) => (
                    <Search
                      {...field}
                      placeholder="검색어를 입력하세요."
                      handleFormSubmit={handleFormSubmit}
                    />
                  )}
                />
              </form>
              <div className="status-btn">
                <div
                  className="event-notice-del-btn"
                  //onClick={() => handleDelList()}
                >
                  선택 삭제
                </div>
                <div
                  className="event-notice-add-btn"
                  onClick={() => navigate("/notice/event/regist")}
                >
                  행사 공지 등록
                </div>
              </div>
            </div>
            <div className="event-notice-body">
              <table>
                <thead>
                  <tr>
                    <th className="event-notice-child1">
                      <input
                        type="checkbox"
                        name="All"
                        onChange={(e: any) => handleCheck(e)}
                      />
                    </th>
                    <th className="event-notice-child2">
                      No. <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </th>
                    <th className="event-notice-child3">
                      행사명 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </th>
                    <th className="event-notice-child4">
                      기간 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </th>
                    <th className="event-notice-child5">
                      등록일 <img src={IcDropBtnSVG} alt="드랍 버튼 이미지" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.length > 0 ? (
                    pageArr[page - 1]?.map((item: any, i: number) => {
                      const start = item.start_date.substr(0, 10);
                      const end = item.end_date.substr(0, 10);
                      const period = `${start} ~ ${end}`;

                      return (
                        <tr
                          key={i}
                          onClick={() => {
                            navigate(`/notice/event/detail/${item.id}`, {
                              state: { eventId: item.id },
                            });
                          }}
                        >
                          <td className="event-notice-child1">
                            <input
                              type="checkbox"
                              name={item.id}
                              onChange={(e: any) => handleCheck(e, item)}
                              onClick={(e: any) => e.stopPropagation()}
                              checked={item.checked || false}
                            />
                          </td>
                          <td className="event-notice-child2">
                            {(totalCount - (i + (page - 1) * 10))
                              .toString()
                              .padStart(2, "0")}
                          </td>
                          <td className="event-notice-child3">
                            <div className="ellipsis">{item.title}</div>
                          </td>
                          <td className="event-notice-child4">{period}</td>
                          <td className="event-notice-child5">
                            {item.created_at?.substr(0, 10)}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="no-data-wrap">
                      <td colSpan={5}>
                        <div>
                          <div className="no-data-img">
                            <img src={NoDataSVG} alt="검색 결과 없음 이미지" />
                          </div>
                          <div>검색 결과가 없습니다.</div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pagenation">
              <Pagination
                page={page}
                count={totalPages}
                list={tableData.length}
                onChangePage={setPage}
              />
            </div>
          </div>
        </div>
      </EventNoticeStyled>
    </Layout>
  );
};

export default EventNotice;

const EventNoticeStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 40px;

  & > div {
    display: flex;
    padding: 35px;
  }

  .event-wrap {
    width: 100%;
  }

  .event-notice-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .event-notice-del-btn {
    width: 100px;
    height: 44px;
    border-radius: 6px;
    background-color: #444;
    color: #fff;
    cursor: pointer;
  }

  .event-notice-add-btn {
    width: 146px;
    height: 44px;
    margin-left: 10px;
    border-radius: 6px;
    background-color: #c09260;
    color: #fff;
    cursor: pointer;
  }

  .event-notice-body {
    margin-top: 30px;
    table {
      width: 100%;
    }

    input {
      cursor: pointer;
      appearance: none;
      margin-right: 8px;
      width: 20px;
      height: 20px;
      background: url(${BtnCheckOffSVG}) no-repeat center/100%;

      &:checked {
        background: url(${BtnCheckOnSVG}) no-repeat center/100%;
      }
    }

    thead {
      height: 68px;
      background-color: #f9f9f9;

      th {
        font-size: 18px;
        font-weight: bold;
        vertical-align: middle;
        color: #101010;
      }
    }

    tbody {
      tr {
        height: 66px;
        border-bottom: 1px solid #ebebeb;
      }

      td {
        font-size: 15px;
        font-weight: normal;
        vertical-align: middle;
        color: #444;
        cursor: pointer;

        &:nth-of-type(3) {
          padding-left: 50px;
        }
      }
    }

    .event-notice-child1 {
      width: 5%;
      text-align: center;
    }
    .event-notice-child2 {
      width: 15%;
      text-align: center;
    }
    .event-notice-child3 {
      width: 40%;
    }
    .event-notice-child4 {
      width: 20%;
      text-align: center;
    }
    .event-notice-child5 {
      width: 20%;
      text-align: center;
    }
    .ellipsis {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .no-data-wrap {
      height: 690px;
      text-align: center;

      td {
        font-size: 24px;
        font-weight: normal;
        color: #444;

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;
