import { Global, css } from "@emotion/react";

/**
 * @file 전역 스타일 지정
 * @author TW.Lee
 */
const CommonStyle = () => {
  return (
    <Global
      styles={[
        css`
          /* 테이블 리스트 페이지 공통 스타일 */
          .status-wrap {
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
            padding: 35px;
          }

          .status-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .filter {
            margin-right: 10px;
          }

          .search-sec form {
            display: flex;
          }

          .search {
            display: flex;
          }

          .search-select {
            margin-right: 6px;
          }

          .status-btn {
            display: flex;
            div {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .xlsx-download {
            width: 140px;
            height: 44px;
            border-radius: 6px;
            background-color: #444;
            color: #fff;
            cursor: pointer;
          }

          .status-regist-btn {
            height: 44px;
            margin-left: 10px;
            border-radius: 6px;
            background-color: #1532ee;
            color: #fff;
            cursor: pointer;
          }

          .status-table {
            width: 100%;
            margin-top: 30px;
            display: flex;
          }

          .fixed-line {
            position: relative;
            z-index: 9999;
            width: 16px;
          }

          .date-input {
            input::placeholder {
              color: #b2b2b2;
            }

            input[name="rdp-date-picker"] {
              width: 100%;
              height: 65px;
              border: solid 1px #ddd;
              background-color: #fff;
              line-height: 1.29;
              font-size: 16px;
              color: #000;
              padding-left: 20px;
            }
            .react-tiny-popover-container {
              height: 100px;
              background-color: red;
            }
          }

          .date-image {
            position: absolute;
            margin-top: 8px;
            margin-left: 620px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 49px;
            height: 49px;
            background-color: #444;
            cursor: pointer;
          }

          .no-data-img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 87px;
            height: 87px;
            border-radius: 50%;
            background-color: rgb(168, 154, 139);
            margin-bottom: 30px;
          }

          .list-no-data-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 690px;
            text-align: center;

            & > div {
              font-size: 24px;
              font-weight: normal;
              color: #444;

              & > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
            }
          }

          .inventory-process-wrap {
            display: flex;
            justify-content: center;
            flex-direction: column;
            background-color: #fff;
            padding: 35px;
            margin-top: 30px;
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
          }

          .inventory-process-inner {
            display: flex;
            justify-content: center;
            background-color: #fff;
            padding: 35px;
          }

          .inventory-process-title {
            font-family: Arita-buri-SemiBold;
            font-size: 45px;
            font-weight: 600;
            text-align: center;
            padding: 80px 0 30px 0;
          }
        `,
      ]}
    />
  );
};

export default CommonStyle;
