import * as yup from "yup";

export default yup.object().shape({
  place: yup.object().shape({
    value: yup.string().test({
      message: "지점을 선택해주세요.",
      test: (value) => {
        if (value === "init") {
          return false;
        } else {
          return true;
        }
      },
    }),
  }),
});
