import { useNavigate } from "react-router-dom";
import Button from "components/Button/Button";

const LetterAppForm = () => {
  const navigate = useNavigate();

  return (
    <>
      <h4>&#183; 신청자 정보 &#183;</h4>
      <ul className="approval-detail-form">
        <li>
          <div>신청자</div>
          <div>김누리</div>
        </li>
        <li>
          <div>직급·직책</div>
          <div></div>
        </li>
        <li>
          <div>부서</div>
          <div></div>
        </li>
        <li>
          <div>작성일</div>
          <div></div>
        </li>
      </ul>
      <h4>&#183; 신청 정보 &#183;</h4>
      <ul className="approval-detail-form">
        <li>
          <div>제목</div>
          <div></div>
        </li>
        <li>
          <div>품목</div>
          <div></div>
        </li>
        <li>
          <div>수량</div>
          <div></div>
        </li>
        <li>
          <div>목적</div>
          <div></div>
        </li>
        <li>
          <div>사유</div>
          <div></div>
        </li>
        <li>
          <div>비용</div>
          <div></div>
        </li>
        <li>
          <div>효과</div>
          <div></div>
        </li>
        <li>
          <div>첨부파일</div>
          <div>
            <span className="attatch-file">첨부파일.pdf</span>
          </div>
        </li>
        <li className="approval-block-box">
          <div>의견</div>
          <div className="approval-block-box-body">
            <div>
              <span>[라운드리] 장누리</span>
              <input type="text" />
            </div>
            <div>
              <span>[경영] 최누리</span>
              <input type="text" />
            </div>
            <div>
              <span>[임원] 이누리</span>
              <input type="text" />
            </div>
          </div>
        </li>
      </ul>
      <div className="submit-btn">
        <Button
          type="button"
          variant="tertiary"
          preset="1"
          onClick={() => navigate(-1)}
        >
          목록
        </Button>
        <span className="void-sec"></span>
        <Button type="button" variant="secondary" preset="1">
          반려
        </Button>
        <span className="void-sec"></span>
        <Button type="button" variant="primary" preset="2">
          결재
        </Button>
      </div>
    </>
  );
};

export default LetterAppForm;
