/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Label from "components/Label/Label";

interface RowProps {
  label: string;
  children: React.ReactNode;
  isHightlight?: boolean;
}

const LabelWithInput = (props: RowProps) => {
  const { label, children, isHightlight } = props;

  return (
    <div>
      <div>
        <Label isHightlight={isHightlight}>{label}</Label>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default LabelWithInput;
