import { useRef, useState, useEffect } from "react";
import dayjs from "dayjs";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";

import Select from "components/Select/Select";
import Search from "components/Search/Search";

import IconCalSVG from "assets/images/icon_cal.svg";

import { CALENDER_OPTIONS, EmployeeSelectOptions } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import "dayjs/locale/ko";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { testCalendarData } from "utils/testdata";

const defaultValues = {
  searchDateSelect: {
    value: "WEEK",
    label: "주별",
  },
  selectDate: "",
  selectPlace: "All",
  searchSelect: "All",
  searchInput: "",
};

const CalenderMode = () => {
  dayjs.locale("ko");
  const navigate = useNavigate();
  const localizer = dayjsLocalizer(dayjs);
  const dateRef = useRef([] as any);
  const [openDate, setOpenDate] = useState(false);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  // 주별 데이터 (일 ~ 토)
  const makeWeekArr = (date: any) => {
    const day: any = dayjs(values?.selectDate).format("d");
    const week = [];
    for (let i = 0; i < 7; i++) {
      const newDate = new Date(date.valueOf() + 86400000 * (i - day));
      week.push([i, newDate]);
    }
    return week;
  };
  const date = new Date(dayjs(values.selectDate).format());
  const week = makeWeekArr(date);

  // 주별 캘린더 today 날짜에 배경색 표시
  useEffect(() => {
    if (values?.searchDateSelect?.value === "WEEK") {
      for (let i = 0; i < week.length; i++) {
        const day = dayjs(week[i][1]).format("DD");
        if (day === dayjs().format("DD")) {
          dateRef.current[i].className = "action";
        } else {
          dateRef.current[i].className = "void";
        }
      }
    }
  }, [values?.searchDateSelect?.value, week]);

  // 날짜 선택 value = today value
  useEffect(() => {
    setValue(
      "selectDate",
      values?.searchDateSelect?.value === "WEEK"
        ? dayjs().format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM")
    );
  }, [values?.searchDateSelect?.value]);

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <div className="work-status-wrap">
      <div className="work-status-inner">
        <div className="work-status-top">
          <div className="work-status-day">
            <form
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                handleFormSubmit();
                handleSubmit(
                  () => {},
                  (err) => {}
                );
              }}
            >
              <div className="search-select date-input">
                <div
                  className="search-date-image2"
                  onClick={() => {
                    setOpenDate(true);
                  }}
                >
                  <img src={IconCalSVG} alt="캘린더" />
                </div>
                <div className="header-text">날짜</div>
                <Controller
                  control={control}
                  name="searchDateSelect"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="선택"
                      w="110"
                      h="52"
                      fontS="15"
                      variant="tertiary"
                      options={CALENDER_OPTIONS}
                    />
                  )}
                />
                <span className="search-void"></span>
                <Controller
                  control={control}
                  name="selectDate"
                  render={({ field }) => (
                    <DatePicker
                      isOpen={openDate}
                      setIsOpen={setOpenDate}
                      popoverPositions={["bottom"]}
                      onSelect={(date) => {
                        values?.searchDateSelect?.value === "WEEK"
                          ? field.onChange(dayjs(date).format("YYYY-MM-DD"))
                          : field.onChange(dayjs(date).format("YYYY-MM"));
                      }}
                      value={field.value}
                      closeOnSelect={true}
                      onChange={() => {}}
                    />
                  )}
                />
              </div>
              <div className="search-select">
                <div className="header-text">근무지</div>
                <Controller
                  control={control}
                  name="selectPlace"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="전체"
                      w="120"
                      h="52"
                      fontS="15"
                      variant="tertiary"
                      options={EmployeeSelectOptions}
                    />
                  )}
                />
              </div>
              <div className="search">
                <div className="search-select">
                  <div className="header-text">검색</div>
                  <Controller
                    control={control}
                    name="searchSelect"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="전체"
                        w="120"
                        h="52"
                        fontS="15"
                        variant="tertiary"
                        options={EmployeeSelectOptions}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="searchInput"
                    render={({ field }) => (
                      <Search
                        {...field}
                        w="280px"
                        placeholder="검색어를 입력하세요."
                        handleFormSubmit={handleFormSubmit}
                      />
                    )}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="work-status-option">
            <div className="xlsx-download">엑셀로 내보내기</div>
            <div
              className="status-regist-btn"
              onClick={() => navigate("/work_status/create_schedule")}
            >
              스케줄 생성
            </div>
          </div>
        </div>
        <div className="calender-wrap">
          {values?.searchDateSelect?.value === "WEEK" ? (
            <table className="week-calender">
              <thead>
                <tr>
                  <th>성명</th>
                  {week.map((item: any, i: number) => {
                    const day = dayjs(item[1]).format("DD");
                    const dayOfWeek = dayjs(item[1]).locale("ko").format("ddd");
                    return (
                      <th key={i} ref={(el) => (dateRef.current[i] = el)}>
                        {day}일 ({dayOfWeek})
                      </th>
                    );
                  })}
                  <th>근무 총합</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <Calendar
              events={testCalendarData}
              localizer={localizer}
              style={{ height: 650 }}
            />
            //<table className="month-claender">
            //  <thead>
            //    <tr>
            //      <th>일</th>
            //      <th>월</th>
            //      <th>화</th>
            //      <th>수</th>
            //      <th>목</th>
            //      <th>금</th>
            //      <th>토</th>
            //    </tr>
            //  </thead>
            //  <tbody>
            //    <tr>
            //      <td></td>
            //      <td></td>
            //      <td></td>
            //      <td></td>
            //      <td></td>
            //      <td></td>
            //      <td></td>
            //    </tr>
            //  </tbody>
            //</table>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalenderMode;
