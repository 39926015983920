import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import authApi from "api/auth";
import useAlert from "hooks/useAlert";
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import Checkbox from "components/CheckBox/CheckBox";

import LoginValidationSchema from "utils/validations/LoginValidationSchema";
import BtnNextCircleSVG from "assets/images/btn_next_circle.svg";

import bgPNG from "assets/images/bg.png";

const defaultValues = {
  userId: "",
  userPw: "",
};

const Login = () => {
  const { noti } = useAlert();
  const navigate = useNavigate();
  const [autoLogin, setAutoLogin] = useState<boolean>(false);
  const [isSaveId, setIsSaveId] = useState<boolean>(false);
  const saveId = localStorage.getItem("id");

  const {
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(LoginValidationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (saveId !== null) {
      setValue("userId", saveId || "");
      setIsSaveId(true);
    }
  }, [saveId]);

  const handleLogin = async () => {
    navigate("/home", { replace: true });
    //await authApi
    //  .login({
    //    userEmail: values.userId,
    //    userPassword: values.userPw,
    //    rememberId: isSaveId,
    //    isAutoLogin: autoLogin,
    //  })
    //  .then((res: any) => {
    //    if (res.status === 200) {
    //      navigate("/home", { replace: true });
    //    }
    //  })
    //  .catch((err) => {
    //    if (err.response.data.message === "signname is required") {
    //      noti("가입된 회원정보가 없습니다.\n회원가입을 해주세요.", "확인");
    //    }
    //    if (err.response.data.message === "invalid password") {
    //      noti("비밀번호가 틀렸습니다.", "확인");
    //    }
    //    if (err.response.data.message === "invalid signname") {
    //      noti("가입된 회원정보가 없습니다.\n회원가입을 해주세요.", "확인");
    //    }

    //    if (err.response.status === 500) {
    //      noti(err?.response?.data?.message, "확인");
    //    }
    //  });
  };

  return (
    <LoginStyled>
      {/*<div className="login-content">
        <h3>NURIDA</h3>
        <div className="login-text1">
          주식회사 누리글로벌서비스의 업무 전용 페이지입니다.
          <br />
          임직원 회원가입은 관리자에게 문의해 주세요.
        </div>
        <div className="login-text2">
          회사소개나 인재풀 등록을 원하는 고객님은 공식 웹사이트로 이동해주시기
          바랍니다.
        </div>
        <div className="move_homepage">
          <a href="https://nuri-gs.com/home">
            <span>공식 웹사이트로 이동</span>
            <img
              src={BtnNextCircleSVG}
              alt="버튼 이미지"
              className="btn_next_circle"
            />
          </a>
        </div>
      </div>*/}
      <div className="login-wrap">
        <h2>로그인</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(
              (res) => {
                handleLogin();
              },
              (err) => {
                console.log(err);
              }
            )();
          }}
        >
          <div className="input-box">
            <Controller
              control={control}
              name="userId"
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="이메일 주소를 입력하세요."
                  fullWidth
                  errors={errors}
                />
              )}
            />
          </div>
          <div className="input-box">
            <Controller
              control={control}
              name="userPw"
              render={({ field }) => (
                <Input
                  {...field}
                  type="password"
                  placeholder="비밀번호를 입력하세요."
                  fullWidth
                  errors={errors}
                />
              )}
            />
          </div>
          <div className="login-option">
            <Checkbox
              checked={autoLogin}
              onChange={(e) => setAutoLogin(e.target.checked)}
            >
              자동 로그인
            </Checkbox>
            <Checkbox
              checked={isSaveId}
              onChange={(e) => setIsSaveId(e.target.checked)}
            >
              아이디 기억
            </Checkbox>
          </div>
          <Button type="submit" variant="primary" preset="2" fullWidth>
            로그인
          </Button>
        </form>
      </div>
    </LoginStyled>
  );
};

export default Login;

const LoginStyled = styled.div`
  /*background: url(${bgPNG}) no-repeat center/cover;*/
  height: 100vh;
  padding: 0 8vw;
  display: flex;
  justify-content: space-around;
  align-items: center;

  h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 43px;
  }

  .login-content {
    color: #fff;
    margin-right: 5rem * 0.05;

    h3 {
      font-family: "Pretendard";
      letter-spacing: 0.2rem;
      font-size: 65px;
      font-weight: bold;
      color: #a0adff;
      line-height: 1.45;
      margin-bottom: 63px;
    }
  }

  .login-text1 {
    font-size: 23px;
    line-height: 1.48;
    font-weight: normal;
  }

  .login-text2 {
    font-size: 16px;
    opacity: 0.4;
    font-weight: normal;
    margin-top: 10px;
  }

  .move_homepage {
    width: 180px;

    cursor: pointer;
    margin-top: 40px;

    span {
      margin-right: 12px;
    }

    a {
      display: flex;
      align-items: center;
      color: #fff;
    }
  }

  .login-wrap {
    width: 600px;
    margin-left: 5rem;
    padding: 80px 55px 79px;
    border-radius: 8px;
    border: 1px solid #a0adff;
    background-color: #fff;
    text-align: center;
  }

  .login-option {
    display: flex;
    margin-bottom: 40px;

    & label {
      margin-right: 30px;
    }
  }

  .input-box {
    margin-bottom: 20px;
  }
`;
