import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import request from "api/request";
import Layout from "layout/layout";
import usePageSlice from "hooks/usePageSlice";
import { PAGE_LAYOUT } from "utils/constants";
import NoDataSVG from "assets/images/no_data_ico.svg";
import IconPlusSVG from "assets/images/icon_plus.svg";
import { TestNotice } from "utils/testdata";
import MainProfile from "./MainProfile";

const Main = () => {
  const navigate = useNavigate();
  const user = useSelector((s: any) => s.auth);
  const btnRef = useRef([] as any);
  const [textChange, setTextChange] = useState("NOTICE");
  const [tableData, setTableData] = useState([]);

  //useEffect(() => {
  //  if (user.permissions.COMMON_POST_AND_FAMILY_EVENT) {
  //    setTextChange("NOTICE");
  //  } else {
  //    setTextChange("EVENT");
  //  }
  //}, [user]);

  useEffect(() => {
    if (textChange === "NOTICE") {
      btnRef.current[0].className = "action";
      btnRef.current[1].className = "void";
      btnRef.current[2].className = "void";
    } else if (textChange === "FAMILY_EVENT") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "action";
      btnRef.current[2].className = "void";
    } else if (textChange === "EVENT") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "void";
      btnRef.current[2].className = "action";
    }

    //if (user.userRole !== "EMPLOYEE") {
    //  btnRef.current[0].className = "action";
    //}
  }, [user, textChange]);

  //useEffect(() => {
  //  request(
  //    "get",
  //    "/posts",
  //    {},
  //    {
  //      type: textChange,
  //      orderCol: "created_at",
  //    }
  //  )
  //    .then((res: any) => {
  //      if (res.status === 200) {
  //        setTableData(res.data.result.data);
  //      }
  //    })
  //    .catch((err) => {
  //      throw err;
  //    });
  //}, [textChange]);

  const pageArr = usePageSlice(TestNotice);

  return (
    <Layout page={PAGE_LAYOUT.main}>
      <MainStyled>
        <div className="main-wrap">
          <div>
            <div className="main-inner-con1">
              {/*{user.userRole === "EMPLOYEE" ? (*/}
              <div className="main-inner-title">
                <div
                  ref={(el) => (btnRef.current[0] = el)}
                  onClick={() => {
                    setTextChange("NOTICE");
                  }}
                >
                  회원현황
                </div>

                <span>&#183;</span>
                <div
                  ref={(el) => (btnRef.current[1] = el)}
                  onClick={() => {
                    setTextChange("FAMILY_EVENT");
                  }}
                >
                  상담현황
                </div>
                <span>&#183;</span>
                <div
                  ref={(el) => (btnRef.current[2] = el)}
                  onClick={() => {
                    setTextChange("EVENT");
                  }}
                >
                  모니터링
                </div>
              </div>
              {/*) : (
                <div className="main-inner-title">
                  <div ref={(el) => (btnRef.current[0] = el)}>행사공지</div>
                </div>
              )}*/}
              <div className="main-board">
                <div className="board-table">
                  <BoardRegDtm>
                    {textChange === "EVENT" ? "기간" : "날짜"}
                  </BoardRegDtm>
                  <BoardTitle>제목</BoardTitle>
                </div>
                <div className="board-data">
                  {TestNotice?.length > 0 ? (
                    pageArr[0].map((data: any, i: number) => {
                      const start = data.start_date?.substr(0, 10);
                      const end = data.end_date?.substr(0, 10);
                      const period = `${start} ~ ${end}`;

                      return (
                        <div
                          key={i}
                          onClick={() => {
                            if (textChange === "EVENT") {
                              navigate(`/notice/event/detail/${data.id}`, {
                                state: { eventId: data.id },
                              });
                            } else {
                              navigate(`/notice/company/detail/${data.id}`, {
                                state: { eventId: data.id },
                              });
                            }
                          }}
                        >
                          <BoardRegDtm>
                            {textChange === "EVENT"
                              ? period
                              : dayjs(data.created_at).format("YYYY-MM-DD")}
                          </BoardRegDtm>
                          <BoardDataTitle className="hover-text">
                            {data.title}
                          </BoardDataTitle>
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-data-wrap">
                      <div className="no-data-inner">
                        <div>
                          <div className="no-data-img">
                            <img src={NoDataSVG} alt="검색 결과 없음 이미지" />
                          </div>
                          <div>검색 결과가 없습니다.</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="board-more-btn"
                  onClick={() => {
                    if (textChange === "EVENT") {
                      navigate("/notice/event");
                    } else {
                      navigate("/notice/company");
                    }
                  }}
                >
                  <div className="board-more-btn-inner">
                    <div>더보기</div>
                    <div>
                      <img src={IconPlusSVG} alt="더보기 버튼" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MainProfile user={user} />
          </div>
        </div>
      </MainStyled>
    </Layout>
  );
};

export default Main;

const MainStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 240px);
  display: flex;
  justify-content: center;

  .main-wrap {
    width: 100%;
    margin: 60px 40px 40px 40px;

    & > div {
      display: flex;
      justify-content: center;
    }
  }

  .main-inner-con1 {
    flex-grow: 1;
  }

  .main-inner-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    span {
      font-size: 35px;
      margin: 4px 12px 0 12px;
      color: #b2b2b2;
    }

    & > div {
      font-size: 28px;
      font-weight: bold;
      color: #707070;
      cursor: pointer;
    }

    .action {
      color: #1532ee;
    }
  }

  .main-board {
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    padding: 30px;
  }

  .board-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 61px;
    font-size: 18px;
    font-weight: bold;
  }

  .board-data {
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 81px;
      cursor: pointer;

      &:hover {
        .hover-text {
          color: #a0adff;
          text-decoration: underline;
        }
      }
    }
  }

  .board-more-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    .board-more-btn-inner {
      width: 180px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: normal;
      color: #707070;
      cursor: pointer;

      img {
        margin-left: 7px;
      }
    }
  }

  .no-data-wrap {
    min-height: 400px;
    text-align: center;
    font-size: 24px;
    font-weight: normal;
  }

  .no-data-inner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const BoardTitle = styled.div`
  width: 80%;
  text-align: center;
`;
const BoardDataTitle = styled.div`
  width: 80%;
  padding-left: 5%;
  display: flex;
`;
const BoardRegDtm = styled.div`
  text-align: center;
  width: 20%;
`;
