import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import request from "api/request";
import { useNavigate, useLocation } from "react-router-dom";

import Layout from "layout/layout";

import useSubmitAlert from "hooks/useSubmitAlert";

import Button from "components/Button/Button";

import { PAGE_LAYOUT } from "utils/constants";
import { CompanyNoticeDetailProps } from "types/registType";

const CompanyNoticeDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { noti } = useSubmitAlert();
  const [notice, setNotice] = useState<CompanyNoticeDetailProps>();

  //useEffect(() => {
  //  request("get", `/posts/${location.state?.eventId}`, {}, {})
  //    .then((res) => {
  //      setNotice(res?.data.result);
  //    })
  //    .catch((err) => {
  //      throw err;
  //    });
  //}, []);

  //const handleCompanyDelete = async () => {
  //  await request("delete", `/posts/${notice?.id}`, {}, {})
  //    .then((res) => {
  //      navigate("/notice/company", { replace: true });
  //    })
  //    .catch((err) => {
  //      throw err;
  //    });
  //};

  return (
    <Layout page={PAGE_LAYOUT.event_notice}>
      <CompanyNoticeDetailStyled>
        <div className="status-wrap">
          <div className="notice-detail-wrap">
            <div>
              <h3>&#183; 공지사항 상세보기 &#183;</h3>
              <ul>
                <li className="detail-wrap">
                  <div>구분</div>
                  <div>
                    {notice?.type === "NOTICE"
                      ? "사내 공지사항"
                      : "사내 경조사"}
                  </div>
                </li>
                <li className="detail-wrap">
                  <div>제목</div>
                  <div>{notice?.title}</div>
                </li>
                <li className="detail-wrap">
                  <div>날짜</div>
                  <div>{notice?.start_date?.substring(0, 10)}</div>
                </li>
                <li className="detail-wrap notice-content-wrap">
                  <div>내용</div>
                  <div>{notice?.content}</div>
                </li>
              </ul>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                목록
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="secondary"
                preset="1"
                onClick={() => {
                  const isDelete = noti(
                    "해당 게시글을 삭제하시겠습니까?",
                    "예",
                    "아니오"
                  );
                  const getData = () => {
                    isDelete.then((res: boolean) => {
                      //if (res) handleCompanyDelete();
                    });
                  };
                  getData();
                }}
              >
                삭제
              </Button>
              <span className="void-sec"></span>
              <Button
                type="button"
                variant="primary"
                preset="2"
                onClick={() =>
                  navigate(`/notice/company/edit/${notice?.id}`, {
                    state: { noticeId: notice?.id },
                  })
                }
              >
                수정
              </Button>
            </div>
          </div>
        </div>
      </CompanyNoticeDetailStyled>
    </Layout>
  );
};

export default CompanyNoticeDetail;

const CompanyNoticeDetailStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    padding: 35px;
  }

  .notice-detail-wrap {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid #222;
  }

  .detail-wrap {
    display: flex;
    align-items: center;
    padding: 30px 0;
    border-bottom: 1px solid #ddd;

    & > div:nth-of-type(1) {
      width: 150px;
      font-size: 18px;
      font-weight: bold;
      color: #b2b2b2;
    }

    & > div:nth-of-type(2) {
      font-size: 18px;
      font-weight: normal;
      color: #000;

      a {
        font-size: 18px;
        font-weight: normal;
        text-decoration: underline;
        color: #c09260;
      }
    }
  }

  .notice-content-wrap {
    flex-direction: column;
    & > div:nth-of-type(1) {
      width: 100%;
      padding-bottom: 30px;
    }
    & > div:nth-of-type(2) {
      width: 100%;
      line-height: 30px;
    }
  }

  .submit-btn {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
