import { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { TapMenuProps } from "types/commonTypes";

const LinenTapMenu = ({ textChange }: TapMenuProps) => {
  const btnRef = useRef([] as any);
  const navigate = useNavigate();

  useEffect(() => {
    if (textChange === "INVENTORY") {
      btnRef.current[0].className = "action";
      btnRef.current[1].className = "void";
      btnRef.current[2].className = "void";
      btnRef.current[3].className = "void";
      btnRef.current[4].className = "void";
    } else if (textChange === "PAYMENT_APP") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "action";
      btnRef.current[2].className = "void";
      btnRef.current[3].className = "void";
      btnRef.current[4].className = "void";
    } else if (textChange === "ITEM_MANAGEMENT") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "void";
      btnRef.current[2].className = "action";
      btnRef.current[3].className = "void";
      btnRef.current[4].className = "void";
    } else if (textChange === "LINEN_INFO") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "void";
      btnRef.current[2].className = "void";
      btnRef.current[3].className = "action";
      btnRef.current[4].className = "void";
    } else if (textChange === "LAUNDRY") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "void";
      btnRef.current[2].className = "void";
      btnRef.current[3].className = "void";
      btnRef.current[4].className = "action";
    }
  }, [textChange]);

  return (
    <LinenTapMenuStyled>
      <div
        ref={(el) => (btnRef.current[0] = el)}
        onClick={() => {
          navigate("/work/house/linen", {
            state: { textChange: "INVENTORY" },
          });
        }}
      >
        재고 현황
      </div>
      <span>&#183;</span>
      <div
        ref={(el) => (btnRef.current[1] = el)}
        onClick={() => {
          navigate("/work/house/linen", {
            state: { textChange: "PAYMENT_APP" },
          });
        }}
      >
        지급 신청서
      </div>
      <span>&#183;</span>
      <div
        ref={(el) => (btnRef.current[2] = el)}
        onClick={() => {
          navigate("/work/house/linen", {
            state: { textChange: "ITEM_MANAGEMENT" },
          });
        }}
      >
        품목 관리
      </div>
      <span>&#183;</span>
      <div
        ref={(el) => (btnRef.current[3] = el)}
        onClick={() => {
          navigate("/work/house/linen", {
            state: { textChange: "LINEN_INFO" },
          });
        }}
      >
        객실 린넨 정보
      </div>
      <span>&#183;</span>
      <div
        ref={(el) => (btnRef.current[4] = el)}
        onClick={() => {
          navigate("/work/house/linen", {
            state: { textChange: "LAUNDRY" },
          });
        }}
      >
        세탁 현황
      </div>
    </LinenTapMenuStyled>
  );
};

export default LinenTapMenu;

const LinenTapMenuStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 35px;
    margin: 4px 12px 0 12px;
    color: #b2b2b2;
  }

  & > div {
    font-size: 28px;
    font-weight: bold;
    color: #707070;
    cursor: pointer;
  }

  .action {
    color: #c09260;
  }
`;
