import { useState } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";

import Select from "components/Select/Select";
import Search from "components/Search/Search";

import IconCalSVG from "assets/images/icon_cal.svg";

import { EmployeeSelectOptions } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";
import { TapMenuProps } from "types/commonTypes";

import "react-dayjs-picker/dist/index.css";

const defaultValues = {
  selectDate1: "",
  selectDate2: "",
  selectPlace: {
    value: "init",
    label: "선택",
  },
  selectFloor: {
    value: "init",
    label: "선택",
  },
  itemSearch: "",
};

const InventoryForm = ({ textChange }: TapMenuProps) => {
  const navigate = useNavigate();
  const [openDate1, setOpenDate1] = useState(false);
  const [openDate2, setOpenDate2] = useState(false);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const handleFormSubmit = () => {
    handleSubmit(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    )();
  };

  return (
    <InventoryFormStyled>
      <div className="inventory-form-wrap">
        <div className="inventory-form-search">
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
              handleSubmit(
                () => {},
                (err) => {}
              );
            }}
          >
            <div className="linen-form">
              <div className="search-select">
                <div className="header-text">지점</div>
                <Controller
                  control={control}
                  name="selectPlace"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="전체"
                      w="130"
                      h="52"
                      fontS="15"
                      variant="tertiary"
                      options={EmployeeSelectOptions}
                    />
                  )}
                />
              </div>
              <div className="search-select date-input">
                <div className="header-text">기간</div>
                <div
                  className="search-date-image"
                  onClick={() => {
                    setOpenDate1(true);
                  }}
                >
                  <img src={IconCalSVG} alt="캘린더" />
                </div>
                <Controller
                  control={control}
                  name="selectDate1"
                  render={({ field }) => (
                    <DatePicker
                      isOpen={openDate1}
                      setIsOpen={setOpenDate1}
                      popoverPositions={["bottom"]}
                      onSelect={(date) => {
                        field.onChange(dayjs(date).format("YYYY-MM-DD"));
                      }}
                      value={field.value}
                      closeOnSelect={true}
                      onChange={() => {}}
                    />
                  )}
                />
                <span className="date-span">~</span>
                <div
                  className="search-date-image2"
                  onClick={() => {
                    setOpenDate2(true);
                  }}
                >
                  <img src={IconCalSVG} alt="캘린더" />
                </div>
                <Controller
                  control={control}
                  name="selectDate2"
                  render={({ field }) => (
                    <DatePicker
                      isOpen={openDate2}
                      setIsOpen={setOpenDate2}
                      popoverPositions={["bottom"]}
                      onSelect={(date) => {
                        field.onChange(dayjs(date).format("YYYY-MM-DD"));
                      }}
                      value={field.value}
                      closeOnSelect={true}
                      onChange={() => {}}
                    />
                  )}
                />
              </div>
            </div>
            <div className="linen-form">
              <div className="search-select">
                <div className="header-text">층</div>
                <Controller
                  control={control}
                  name="selectFloor"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="전체"
                      w="130"
                      h="52"
                      fontS="15"
                      variant="tertiary"
                      options={EmployeeSelectOptions}
                    />
                  )}
                />
              </div>
              <div className="search-select">
                <div className="header-text">품목</div>
                <Controller
                  control={control}
                  name="itemSearch"
                  render={({ field }) => (
                    <Search
                      {...field}
                      w="337px"
                      placeholder="검색어를 입력하세요."
                      handleFormSubmit={handleFormSubmit}
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="inventory-form-btn">
          <div
            className="new-purchase"
            onClick={() =>
              navigate("/work/house/linen/process", {
                state: { textChange: textChange, title: "신규구매" },
              })
            }
          >
            신규 구매
          </div>
          <div
            className="scrap-disposal"
            onClick={() =>
              navigate("/work/house/linen/process", {
                state: { textChange: textChange, title: "폐품처리" },
              })
            }
          >
            폐품 처리
          </div>
          <div className="xlsx-download">엑셀로 내보내기</div>
          <div className="edit-mode">수정모드</div>
        </div>
      </div>
      <div className="inventory-form-body">
        <table>
          <thead>
            <tr>
              <th rowSpan={2} className="inventory-head-child1">
                서울 전체
              </th>
              <th rowSpan={2} colSpan={3} className="inventory-head-child2">
                품목 정보
              </th>
              <th colSpan={7}></th>
            </tr>
            <tr>
              <th className="linenroom" colSpan={7}>
                린넨실
              </th>
            </tr>
            <tr className="third-th">
              <th>층</th>
              <th>품목명</th>
              <th>규격</th>
              <th>단가</th>
              <th>1회전 분량</th>
              <th>재고 수량</th>
              <th>품목 자산</th>
              <th>입고</th>
              <th>출고</th>
              <th>신규 구매</th>
              <th>구매 단가</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="inventory-total">
              <td colSpan={4}>자산 현황</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div className="grand-total">
          <div>GRAND TOTAL</div>
          <div>₩ 44,100,000</div>
        </div>
      </div>
    </InventoryFormStyled>
  );
};

export default InventoryForm;

const InventoryFormStyled = styled.div`
  width: 100%;

  .inventory-form-wrap {
    display: flex;
    justify-content: space-between;
  }
  .inventory-form-search {
    form {
      .linen-form {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
      }

      .search-select {
        display: flex;
        align-items: center;
      }

      .header-text {
        font-size: 16px;
        font-weight: bold;
        color: #222;
        margin-right: 10px;
        margin-left: 5px;
      }

      .search-date-image2 {
        position: absolute;
        margin-top: 0px;
        margin-left: 319px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        background-color: #444;
        cursor: pointer;
      }

      .search-date-image {
        position: absolute;
        margin-top: 0px;
        margin-left: 144px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        background-color: #444;
        cursor: pointer;
      }

      .date-span {
        margin: 0 8px;
      }

      .date-input {
        input::placeholder {
          color: #b2b2b2;
        }
        input[name="rdp-date-picker"] {
          width: 150px;
          height: 52px;
          border: solid 1px #ddd;
          background-color: #fff;
          line-height: 1.29;
          font-size: 14px;
          color: #000;
          padding-left: 10px;
        }
        .react-tiny-popover-container {
          height: 100px;
        }
      }
    }
  }

  .inventory-form-btn {
    display: flex;
    justify-content: end;
    margin-top: 90px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      margin-left: 10px;
      border-radius: 6px;
      color: #fff;
      cursor: pointer;
    }
  }

  .new-purchase {
    width: 100px;
    background-color: #444;
  }

  .scrap-disposal {
    width: 100px;
    background-color: #444;
  }

  .edit-mode {
    width: 100px;
    background-color: #c09260;
  }

  .inventory-form-body {
    table {
      width: 100%;

      thead {
        th {
          font-size: 18px;
          font-weight: bold;
          color: #101010;
          vertical-align: middle;
          height: 62px;
          background-color: #d9d2cb;
        }

        .third-th th {
          background-color: #f9f9f9;
        }
        .third-th th:not(:last-of-type) {
          border-right: solid 1px #ebebeb;
        }

        .linenroom {
          background-color: rgba(168, 154, 139, 0.3);
        }
      }

      tbody {
        tr {
          height: 66px;
          border-bottom: 1px solid #ebebeb;
        }

        td {
          font-size: 15px;
          font-weight: normal;
          vertical-align: middle;
          color: #444;
        }

        td:not(:last-of-type) {
          border-right: solid 1px #ebebeb;
        }

        .inventory-total {
          border-bottom: 3px solid #ebebeb;
          td {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #101010;
          }
        }
      }

      .inventory-head-child1 {
        width: 10%;
      }
      .inventory-head-child2 {
        width: 20%;
        border-left: 1px solid rgba(168, 154, 139, 0.3);
        border-right: 1px solid rgba(168, 154, 139, 0.3);
      }
    }

    .grand-total {
      width: 100%;
      height: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      color: #101010;
      border-bottom: 3px solid #444;

      & div {
        margin: 0 30px;
      }
    }
  }
`;
