import React from "react";
import styled from "@emotion/styled";

import TopBtnSVG from "assets/images/btn_next.svg";

/**
 * @file TopButton Layout
 */
const TopButton = () => {
  return (
    <TopButtonStyled
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <div>
        <img src={TopBtnSVG} />
      </div>
    </TopButtonStyled>
  );
};

export default TopButton;

const TopButtonStyled = styled.div`
  position: fixed;
  z-index: 950;
  right: 110px;
  bottom: 50px;

  & > div {
    width: 60px;
    height: 60px;
    padding: 26px 22px 26px 23px;
    opacity: 0.8;
    border-radius: 50%;
    background-color: #a0adff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
