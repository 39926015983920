import {
  Document,
  Page,
  Text,
  Font,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import {
  PARTTIMER_SUPPORT_PART_OPTIONS,
  EDUCATION_OPTIONS,
  GRADUATE_OPTIONS,
  MILITARY_OPTIONS,
  DISABLED_OPTIONS,
  EMPLOY_OPTIONS,
} from "utils/constants";

Font.register({
  family: "Nanum Gothic",
  src: "https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-ExtraBold.ttf",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    fontFamily: "Nanum Gothic",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const PDFDocument = ({ data }: any) => {
  //console.log(data);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>
            지원자 번호 :{" "}
            {data?.id?.toString().length === 1
              ? "A00000" + data.id
              : "A0000" + data.id}
          </Text>
          <Text>성명 : {data.resume_name}</Text>
          <Text>
            생년월일 :{" "}
            {data.birth_day.substring(0, 4) +
              "." +
              data.birth_day.substring(4, 6) +
              "." +
              data.birth_day.substring(6, 8)}
          </Text>
          <Text>성별 : {data.gender}</Text>
          <Text>
            휴대폰 번호 :{" "}
            {data.phone.substring(0, 3) +
              "-" +
              data.phone.substring(3, 7) +
              "-" +
              data.phone.substring(7, 11)}
          </Text>
          <Text>이메일 : {data.email}</Text>
          <Text>
            주소 : ({data.zip_code}){data.address} {data.detail_address}
            {"\n\n"}
          </Text>
          <Text>
            1차 희망분야 :{" "}
            {data.job1_depth2
              ? PARTTIMER_SUPPORT_PART_OPTIONS.filter(
                  (e: any) => e.value === data.job1_depth2
                )[0]?.label
              : "-"}
          </Text>
          <Text>
            2차 희망분야 :{" "}
            {data.job2_depth2
              ? PARTTIMER_SUPPORT_PART_OPTIONS.filter(
                  (e: any) => e.value === data.job2_depth2
                )[0]?.label
              : "-"}
            {"\n\n"}
          </Text>
          <Text>
            최종 학력 :{" "}
            {data.education
              ? EDUCATION_OPTIONS.filter(
                  (e: any) => e.value === data.education
                )[0]?.label
              : "-"}
          </Text>
          <Text>학교명 : {data.school_name}</Text>
          <Text>전공 : {data.major}</Text>
          <Text>
            졸업구분 :{" "}
            {data.graduation_category
              ? GRADUATE_OPTIONS.filter(
                  (e: any) => e.value === data.graduation_category
                )[0]?.label
              : "-"}
          </Text>
          <Text>졸업연도 : {data.graduation}</Text>
          <Text>
            군 복무 상태 :{" "}
            {data.military_service
              ? MILITARY_OPTIONS.filter(
                  (e: any) => e.value === data.military_service
                )[0]?.label
              : "-"}
            {"\n\n"}
          </Text>
          <Text>
            장애 여부 :{" "}
            {data.disabled
              ? DISABLED_OPTIONS.filter(
                  (e: any) => e.value === data.disabled
                )[0]?.label
              : "-"}
            {"\n\n"}
          </Text>
          <Text>
            계좌정보 : {data.bank} {data.account_number}
            {"\n\n"}
          </Text>
          <Text>
            경력사항 :{"\n\t\t"}
            {data.career_info
              ? JSON.parse(data.career_info).map((item: any, i: number) => {
                  return (
                    <Text key={i}>
                      <Text>
                        기업명 : {item?.career_name}
                        {"\n\t\t"}
                      </Text>
                      <Text>
                        고용형태 :{" "}
                        {item?.type
                          ? EMPLOY_OPTIONS.filter(
                              (e: any) => e.value === item?.type
                            )[0]?.label
                          : "-"}
                        {"\n\t\t"}
                      </Text>
                      <Text>
                        근무기간 : {item?.start_date} ~ {item?.end_date}
                        {"\n\t\t"}
                      </Text>
                      <Text>
                        연봉 : {`${Number(item.salary).toLocaleString()} 원`}
                        {"\n\t\t"}
                      </Text>
                      <Text>
                        직무 : {item?.job}
                        {"\n\t\t"}
                      </Text>
                      <Text>
                        직무요약 : {item?.job_summary}
                        {"\n\t\t"}
                      </Text>
                    </Text>
                  );
                })
              : "-"}
          </Text>
          <Text>
            자격증 : {"\n\t\t"}
            {data.license_info
              ? JSON.parse(data.license_info).map((item: any, i: number) => {
                  return (
                    <Text key={i}>
                      [{item?.pass_date}] {item?.publisher} -{" "}
                      {item?.license_name}
                      {"\n\t\t"}
                    </Text>
                  );
                })
              : "-"}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
