import { lightTheme, darkTheme } from "utils/theme/theme";
import { createContext, useContext, useState, useCallback } from "react";
import { ThemeProvider as StyledProvider } from "styled-components";
import { ChildrenTypes } from "types/commonTypes";

interface ContextType {
  ThemeMode: string;
  setThemeMode: (e: string) => void;
}

const ThemeContext = createContext<ContextType>({
  ThemeMode: "",
  setThemeMode: () => {},
});

const ThemeProvider = ({ children }: ChildrenTypes) => {
  const LocalTheme = localStorage.getItem("theme") || "light";
  const [ThemeMode, setThemeMode] = useState<string>(LocalTheme);
  const themeObject = ThemeMode === "light" ? lightTheme : darkTheme;

  return (
    <ThemeContext.Provider value={{ ThemeMode, setThemeMode }}>
      <StyledProvider theme={themeObject}>{children}</StyledProvider>
    </ThemeContext.Provider>
  );
};

function useTheme() {
  const { ThemeMode, setThemeMode } = useContext<ContextType>(ThemeContext);

  const toggleTheme: any = useCallback(() => {
    if (ThemeMode === "light") {
      setThemeMode("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setThemeMode("light");
      localStorage.setItem("theme", "light");
    }
  }, [ThemeMode]);

  return [ThemeMode, toggleTheme];
}

export { ThemeProvider, useTheme };
