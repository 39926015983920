import styled from "styled-components";

import LogoIcSVG from "assets/images/logo_ic.svg";

const Spiner = () => {
  return (
    <SpinStyled>
      <div className="spin-wrap">
        <div className="spin-inner">
          <img src={LogoIcSVG} alt="로딩 이미지" />
        </div>
        <div className="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </SpinStyled>
  );
};

export default Spiner;

const SpinStyled = styled.div`
  position: fixed;
  z-index: 99999999999999;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);

  .spin-wrap {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spin-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: #c09260;
  }
  .spinner {
    color: official;
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 100px;
  }
  .spinner div {
    transform-origin: 40px 50px;
    animation: ani-spinner 1.2s linear infinite;
  }
  .spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 4px;
    height: 13px;
    border-radius: 10px;
    background: #c09260;
  }
  .spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes ani-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
