import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import request from "api/request";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "react-dayjs-picker";

import Layout from "layout/layout";

import useAlert from "hooks/useAlert";

import Row from "components/LabelWithInput/LabelWithInput";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import FileInput from "components/FileInput/FileInput";

import { PAGE_LAYOUT } from "utils/constants";
import emptyVaildationSchema from "utils/validations/emptyValidationSchema";

import { EventNoticeForm, EventDetailProps } from "types/registType";

import IconCalSVG from "assets/images/icon_cal.svg";

import "react-dayjs-picker/dist/index.css";

const defaultValues: EventNoticeForm = {
  eventNoticeTitle: "",
  eventNoticeStartDate: "",
  eventNoticeEndDate: "",
  eventNoticeSite: "",
  eventNoticeImage: [],
  eventNoticeContent: "",
};

const EventNoticeEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { noti } = useAlert();
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [events, setEvents] = useState<EventDetailProps>();

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(emptyVaildationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  // 행사공지 데이터 get
  useEffect(() => {
    request("get", `/posts/${location.state?.eventId}`, {}, {})
      .then((res) => {
        setEvents(res?.data.result);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  // 행사공지 데이터 set
  useEffect(() => {
    setValue("eventNoticeTitle", events ? events.title : "");
    setValue(
      "eventNoticeStartDate",
      events ? dayjs(events.start_date).format("YYYY-MM-DD") : ""
    );
    setValue(
      "eventNoticeEndDate",
      events ? dayjs(events.end_date).format("YYYY-MM-DD") : ""
    );
    setValue("eventNoticeSite", events ? events.web_site : "");
    setValue("eventNoticeContent", events ? events.content : "");

    const arrayFile: any = [
      {
        name: `${events?.posts_files.file_name}.${events?.posts_files.extension}`,
        id: events?.posts_files.id,
        size: events?.posts_files.size,
      },
    ];
    setValue("eventNoticeImage", events ? arrayFile : "");
  }, [events]);

  // 이미지 파일 형식 및 용량 체크
  useEffect(() => {
    if (values.eventNoticeImage[0]?.size > 1024 * 1024 * 5) {
      noti("업로드 할 수 있는 파일 형식과\n최대 용량을 확인해주세요.", "확인");
      setValue("eventNoticeImage", []);
    }

    const reg = /jpg|jpeg|png/i;
    if (values.eventNoticeImage[0] !== undefined) {
      if (
        reg.test(
          values.eventNoticeImage[0]?.name.substring(
            values.eventNoticeImage[0]?.name.lastIndexOf(".") + 1
          )
        ) === false
      ) {
        noti(
          "업로드 할 수 있는 파일 형식과\n최대 용량을 확인해주세요.",
          "확인"
        );
        setValue("eventNoticeImage", []);
      }
    }
  }, [values.eventNoticeImage]);

  const imageFormData = new FormData();
  imageFormData.append("file", values.eventNoticeImage[0]);

  //const handleEventEdit = async () => {
  //  const attachment: any = [];

  //  if (
  //    values.eventNoticeImage[0]?.name ===
  //    `${events?.posts_files?.file_name}.${events?.posts_files?.extension}`
  //  ) {
  //    attachment.push(events?.posts_files?.id);
  //  } else {
  //    try {
  //      const res = await request("post", "/files", {}, imageFormData);

  //      if (res?.status === 200) {
  //        attachment.push(res.data.result.id);
  //      }
  //    } catch (err) {
  //      console.log(err);
  //    }
  //  }

  //  let payload: any = {
  //    title: values.eventNoticeTitle,
  //    content: values.eventNoticeContent,
  //    start_date: values.eventNoticeStartDate,
  //    end_date: values.eventNoticeEndDate,
  //    web_site: values.eventNoticeSite,
  //  };

  //  if (attachment) {
  //    payload = {
  //      ...payload,
  //      image: attachment,
  //    };
  //  }

  //  await request("put", `/posts/${events?.id}`, {}, payload)
  //    .then((res: any) => {
  //      if (res.status === 200) {
  //        noti("수정되었습니다.", "확인");
  //      }
  //      navigate("/notice/event", { replace: true });
  //    })
  //    .catch((err) => {
  //      throw err;
  //    });
  //};

  return (
    <Layout page={PAGE_LAYOUT.event_notice}>
      <EventNoticeEditStyled>
        <div className="status-wrap">
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();

              handleSubmit(
                () => {
                  //handleEventEdit();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div>
              <h3>&#183; 행사 공지 등록 &#183;</h3>
              <Row label="제목">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="eventNoticeTitle"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        placeholder="제목을 입력하세요."
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="날짜">
                <div className="input-sec date-input">
                  <div className="date-inner">
                    <div
                      className="date-image date-image1"
                      onClick={() => {
                        setStartDate(true);
                      }}
                    >
                      <img src={IconCalSVG} alt="캘린더" />
                    </div>
                    <Controller
                      control={control}
                      name="eventNoticeStartDate"
                      render={({ field }) => (
                        <DatePicker
                          //register={register("employeeBOD")}
                          isOpen={startDate}
                          setIsOpen={setStartDate}
                          popoverPositions={["bottom"]}
                          onSelect={(date) => {
                            field.onChange(dayjs(date).format("YYYY-MM-DD"));
                          }}
                          value={field.value}
                          closeOnSelect={true}
                          onChange={() => {}}
                          placeholder="시작일"
                        />
                      )}
                    />
                  </div>
                  <span className="date-void-sec">~</span>
                  <div className="date-inner">
                    <div
                      className="date-image date-image2"
                      onClick={() => {
                        setEndDate(true);
                      }}
                    >
                      <img src={IconCalSVG} alt="캘린더" />
                    </div>
                    <Controller
                      control={control}
                      name="eventNoticeEndDate"
                      render={({ field }) => (
                        <DatePicker
                          //register={register("employeeBOD")}
                          isOpen={endDate}
                          setIsOpen={setEndDate}
                          popoverPositions={["bottom"]}
                          onSelect={(date) => {
                            field.onChange(dayjs(date).format("YYYY-MM-DD"));
                          }}
                          value={field.value}
                          closeOnSelect={true}
                          onChange={() => {}}
                          placeholder="종료일"
                        />
                      )}
                    />
                  </div>
                </div>
              </Row>
              <Row label="링크">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="eventNoticeSite"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        multiline={false}
                        fullWidth={false}
                        placeholder="https://"
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="이미지">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="eventNoticeImage"
                    render={({ field }) => (
                      <FileInput
                        {...field}
                        accept=".jpg, .png"
                        errors={errors}
                        fullWidth
                        placeholder={"(JPG(JPEG), PNG / 최대 5MB까지)"}
                      />
                    )}
                  />
                </div>
              </Row>
              <Row label="내용">
                <div className="input-sec">
                  <Controller
                    control={control}
                    name="eventNoticeContent"
                    render={({ field }) => (
                      <Input
                        {...field}
                        multiline
                        useMaxLengthIndicator
                        maxLength="1000"
                        type="text"
                        placeholder="내용을 입력하세요."
                        fullWidth
                        errors={errors}
                      />
                    )}
                  />
                </div>
              </Row>
            </div>
            <div className="submit-btn">
              <Button
                type="button"
                variant="tertiary"
                preset="1"
                onClick={() => navigate(-1)}
              >
                취소
              </Button>
              <span className="void-sec"></span>
              <Button type="submit" variant="primary" preset="2">
                수정
              </Button>
            </div>
          </form>
        </div>
      </EventNoticeEditStyled>
    </Layout>
  );
};

export default EventNoticeEdit;

const EventNoticeEditStyled = styled.div`
  width: 100%;
  margin: 40px;

  & > div {
    display: flex;
    justify-content: center;
    padding: 35px;
  }

  form {
    width: 680px;
  }

  h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #222;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .date-input {
    width: 100%;
    display: flex;
    align-items: center;

    .date-inner {
      width: 100%;
    }

    input::placeholder {
      color: #b2b2b2;
    }
    input[name="rdp-date-picker"] {
      width: 100%;
      height: 65px;
      border: solid 1px #ddd;
      background-color: #fff;
      line-height: 1.29;
      font-size: 16px;
      color: #000;
      padding-left: 20px;
    }
    .react-tiny-popover-container {
      height: 100px;
      background-color: red;
    }

    .date-void-sec {
      margin: 0 15px;
    }
  }

  .date-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 49px;
    height: 49px;
    margin-top: 8px;
    background-color: #444;
    cursor: pointer;
  }
  .date-image1 {
    right: 50%;
    transform: translateX(97px);
  }
  .date-image2 {
    right: 50%;
    transform: translateX(457px);
  }

  .submit-btn {
    width: 408px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
