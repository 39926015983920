import request from "api/request";
import {
  MailListTypes,
  SentMailTypes,
  MailIdTypes,
  EditMailTypes,
} from "types/mailTypes";

const mailsList = async ({ types, keywords }: MailListTypes) => {
  try {
    const data = await request(
      "get",
      "/mails",
      {},
      { type: types, keyword: keywords }
    );

    return data;
  } catch (err) {
    throw err;
  }
};

const sentMails = async (payload: SentMailTypes) => {
  try {
    const data = await request("post", "/mails", {}, payload);

    return data;
  } catch (err) {
    throw err;
  }
};

const detailMails = async ({ mailId }: MailIdTypes) => {
  try {
    const data = request("get", `/mails/${mailId}`, {}, {});

    return data;
  } catch (err) {
    throw err;
  }
};

const editMails = async ({ mailId, withdraw, readed }: EditMailTypes) => {
  try {
    const data = request(
      "put",
      `/mails/${mailId}`,
      {},
      { is_withdraw: withdraw, is_readed: readed }
    );

    return data;
  } catch (err) {
    throw err;
  }
};

const deleteMails = async ({ mailId }: MailIdTypes) => {
  try {
    const data = request("delete", `/mails/${mailId}`, {}, {});

    return data;
  } catch (err) {
    throw err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  mailsList,
  sentMails,
  detailMails,
  editMails,
  deleteMails,
};
