import { useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "react-dayjs-picker";
import Row from "components/LabelWithInput/LabelWithInput";
import IconCalSVG from "assets/images/icon_cal.svg";

interface ScheduleDateFormProps {
  label: string;
  placeName: string;
  Controller: any;
  setValue: any;
  control: any;
  errors: any;
}

const ScheduleDateForm = ({
  label,
  placeName,
  Controller,
  setValue,
  control,
  errors,
}: ScheduleDateFormProps) => {
  const [openDate, setOpenDate] = useState(false);

  return (
    <Row label={label}>
      <div
        className="date-image"
        onClick={() => {
          setOpenDate(true);
        }}
      >
        <img src={IconCalSVG} alt="캘린더" />
      </div>
      <div className="input-sec date-input">
        <Controller
          control={control}
          name={placeName}
          render={({ field }: any) => (
            <DatePicker
              isOpen={openDate}
              setIsOpen={setOpenDate}
              popoverPositions={["bottom"]}
              onSelect={(date) => {
                field.onChange(dayjs(date).format("YYYY-MM-DD"));
              }}
              value={field.value}
              closeOnSelect={true}
              onChange={() => {}}
              placeholder="날짜를 선택하세요."
            />
          )}
        />
      </div>
    </Row>
  );
};

export default ScheduleDateForm;
