import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import Row from "components/LabelWithInput/LabelWithInput";
import Input from "components/Input/Input";
import SearchEmployee from "./SearchEmployee";

interface SearchMemberFormProps {
  label: string;
  placeName: string;
  Controller: any;
  setValue: any;
  seniors: any;
  setSeniors: any;
  control: any;
  errors: any;
}

const SearchEmployeeForm = ({
  label,
  placeName,
  Controller,
  setValue,
  seniors,
  setSeniors,
  control,
  errors,
}: SearchMemberFormProps) => {
  const [result, setResult] = useState<any>([]);
  const [openSearchMember, setOpenSearchMember] = useState<boolean>(false);

  useEffect(() => {
    if (result.length > 0) {
      setValue(
        placeName,
        "[" + result[0].department + "] " + result[0].user_name
      );

      switch (placeName) {
        case "vacationSenior01":
          setSeniors({
            ...seniors,
            holiday_application1: result[0].user_employee_info.id,
          });
          break;
        case "vacationSenior02":
          setSeniors({
            ...seniors,
            holiday_application2: result[0].user_employee_info.id,
          });
          break;
        case "vacationSenior03":
          setSeniors({
            ...seniors,
            holiday_application3: result[0].user_employee_info.id,
          });
          break;
        case "approvalSenior01":
          setSeniors({
            ...seniors,
            round_robin1: result[0].user_employee_info.id,
          });
          break;
        case "approvalSenior02":
          setSeniors({
            ...seniors,
            round_robin2: result[0].user_employee_info.id,
          });
          break;
        case "approvalSenior03":
          setSeniors({
            ...seniors,
            round_robin3: result[0].user_employee_info.id,
          });
          break;
        case "proposalSenior01":
          setSeniors({
            ...seniors,
            proposal1: result[0].user_employee_info.id,
          });
          break;
        case "proposalSenior02":
          setSeniors({
            ...seniors,
            proposal2: result[0].user_employee_info.id,
          });
          break;
        case "proposalSenior03":
          setSeniors({
            ...seniors,
            proposal3: result[0].user_employee_info.id,
          });
          break;
        default:
          break;
      }
    }
  }, [result]);

  return (
    <SearchMemberFormStyled>
      <Row label={label}>
        <div className="input-sec search-member-sec">
          <Controller
            control={control}
            name={placeName}
            render={({ field }: any) => (
              <Input
                {...field}
                type="text"
                multiline={false}
                fullWidth={false}
                errors={errors}
              />
            )}
          />
          <button
            type="button"
            className="search-member-btn"
            onClick={() => setOpenSearchMember(true)}
          >
            인원 검색
          </button>
          {openSearchMember && (
            <SearchEmployee
              setOpenSearchMember={setOpenSearchMember}
              setResult={setResult}
            />
          )}
        </div>
      </Row>
    </SearchMemberFormStyled>
  );
};

export default SearchEmployeeForm;

const SearchMemberFormStyled = styled.div`
  .search-member-sec {
    display: flex;
  }

  .input-sec {
    margin: 16px 0 30px;
  }

  .search-member-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
    cursor: pointer;
  }
`;
