import { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import request from "api/request";

import Button from "components/Button/Button";
import SearchMemberForm from "components/SearchPopup/SearchMemberForm";

import useAlert from "hooks/useAlert";
import mailApi from "api/mail";
import mailValidationSchema from "utils/validations/mailValidationSchema";
import SmallBtnSVG from "assets/images/small_x_ic.svg";

const defaultValues = {
  title: "",
  sender: "",
  reference: "",
  content: "",
  file: [],
  withdraw: false,
};

const MailWriteForm = () => {
  const { noti } = useAlert();
  const navigate = useNavigate();
  const fileRef = useRef<HTMLInputElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [senderSearch, setSenderSearch] = useState<any>([]);
  const [referenceSearch, setReferenceSearch] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [isSender, setIsSender] = useState<any>([]);
  const [isReference, setIsReference] = useState<any>([]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(mailValidationSchema),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  // 수신자 데이터 가져오기 (임직원)
  //useEffect(() => {
  //  let payload: any = {
  //    type: "EMPLOYEE",
  //    search_filter: "ALL",
  //    keyword: null,
  //  };

  //  request("get", "/users", {}, payload)
  //    .then((res: any) => {
  //      if (res.status === 200) {
  //        setTableData(res.data.result.data);
  //      }
  //    })
  //    .catch((err) => {
  //      console.log(err);
  //    });
  //}, []);

  // 수신자 입력 시 동일 계정 뿌려주기
  useEffect(() => {
    const senders = tableData.filter((e: any) => {
      return values?.sender.slice(0, 2).includes(e.signname.slice(0, 2));
    });

    setSenderSearch(senders);
  }, [tableData, values?.sender]);

  // 참조 입력 시 동일 계정 뿌려주기
  useEffect(() => {
    const reference = tableData.filter((e: any) => {
      return values?.reference.slice(0, 2).includes(e.signname.slice(0, 2));
    });
    setReferenceSearch(reference);
  }, [tableData, values?.reference]);

  // 검색값이 있을 경우 표시
  useEffect(() => {
    if (senderSearch.length > 0 || referenceSearch.length > 0) setIsOpen(true);
  }, [senderSearch.length, referenceSearch.length]);

  // 메일 전송
  //const handleSendMail = async () => {
  //  const referenceArr = [];
  //  const attachment: any = [];

  //  const fileFormData = new FormData();
  //  for (let i = 0; i < values.file.length; i++) {
  //    fileFormData.append("files", values.file[i]);
  //  }

  //  if (values.file) {
  //    try {
  //      const res = await request("post", "/files/arrays", {}, fileFormData);

  //      if (res?.status === 200) {
  //        attachment.push(res.data.result.map((e: any) => e.id));
  //      }
  //    } catch (err) {
  //      console.log(err);
  //    }
  //  }

  //  if (values.reference) {
  //    referenceArr.push(values.reference.split(","));
  //  }

  //  let payload: any = {
  //    title: values.title,
  //    to_user: values.sender,
  //    content: values.content,
  //    is_withdraw: false,
  //  };

  //  if (attachment.length > 0) {
  //    payload = {
  //      ...payload,
  //      attachments: attachment[0],
  //    };
  //  }

  //  if (referenceArr.length > 0) {
  //    payload = {
  //      ...payload,
  //      reference: referenceArr[0],
  //    };
  //  }

  //  await request("post", "/mails", {}, payload)
  //    .then((res: any) => {
  //      if (res.status === 200) {
  //        const isDone = noti("메일이 발송되었습니다.", "확인");
  //        const getData = () => {
  //          isDone.then((res: boolean) => {
  //            if (res) {
  //              navigate("/mail", {
  //                state: { status: "RECEIVED" },
  //              });
  //            }
  //          });
  //        };
  //        getData();
  //      }
  //      console.log(res);
  //    })
  //    .catch((err) => console.log(err));
  //};

  // 수신자 선택 시
  const handleChoice = (signname: string) => {
    setIsOpen(false);
    if (isSender.length < 5) {
      setIsSender([...isSender, signname]);
    }
    setValue("sender", "");
  };

  // 수신자 x 버튼 클릭시 필터링
  const handleFilterSender = (signname: string) => {
    setIsSender(isSender.filter((e: any) => e !== signname));
  };

  // 참조 선택 시
  const handleReferenceChoice = (signname: string) => {
    setIsOpen(false);
    if (isReference.length < 5) {
      setIsReference([...isReference, signname]);
    }
    setValue("reference", "");
  };

  // 참조 x 버튼 클릭시 필터링
  const handleFilterReference = (signname: string) => {
    setIsReference(isReference.filter((e: any) => e !== signname));
  };

  // 파일 업로드 시
  const handleChange = (e: any) => {
    if (e.target.files.length >= 5) {
      noti("파일 갯수는 최대 5개까지 입니다.", "확인");
    } else {
      const arr: any = [];
      for (let i = 0; e.target.files.length > i; i++) {
        arr.push(e.target.files[i].name);
      }
      setFileList(arr);
      setValue("file", e.target.files);
    }
  };

  // 파일 여러개 업로드 시 텍스트 형태 변환
  useEffect(() => {
    if (fileRef.current) fileRef.current.value = fileList.join(", ");
  }, [fileRef.current, fileList]);

  return (
    <MailWriteFormStyled>
      <div>
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(
              (res: any) => {
                console.log(res);
                //handleSendMail();
              },
              (err) => {
                console.log(err);
              }
            )();
          }}
        >
          <div className="detail-mail-wrap">
            <div className="detail-mail-top">
              <div>
                <div className="detail-mail-left-title">제목</div>
                <div className="detail-mail-right">
                  <input id="title" type="text" {...register("title")} />
                </div>
              </div>
              <div>
                <div className="detail-mail-left-title">수신자</div>
                <div className="detail-mail-right">
                  <div className="detail-mail-sender-form">
                    {isSender.length > 0 &&
                      isSender.map((e: any, i: number) => {
                        return (
                          <div key={i} className="choice-sender">
                            <div>{e}</div>
                            <div
                              className="choice-btn"
                              onClick={() => handleFilterSender(e)}
                            >
                              <img src={SmallBtnSVG} alt="수신자 삭제 버튼" />
                            </div>
                          </div>
                        );
                      })}
                    <input {...register("sender")} id="sender" type="text" />
                  </div>
                  <div className="sender-select">
                    {isOpen &&
                      senderSearch.map((item: any) => {
                        return (
                          <div
                            key={item.id}
                            onClick={() => handleChoice(item.signname)}
                          >
                            <div>{item.signname}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div>
                <div className="detail-mail-left-title">참조</div>
                <div className="detail-mail-right">
                  <div className="detail-mail-sender-form">
                    {isReference.length > 0 &&
                      isReference.map((e: any, i: number) => {
                        return (
                          <div key={i} className="choice-sender">
                            <div>{e}</div>
                            <div
                              className="choice-btn"
                              onClick={() => handleFilterReference(e)}
                            >
                              <img src={SmallBtnSVG} alt="참조 삭제 버튼" />
                            </div>
                          </div>
                        );
                      })}
                    <input
                      id="reference"
                      type="text"
                      {...register("reference")}
                    />
                  </div>
                  <div className="sender-select">
                    {isOpen &&
                      referenceSearch.map((item: any) => {
                        return (
                          <div
                            key={item.id}
                            onClick={() => handleReferenceChoice(item.signname)}
                          >
                            <div>{item.signname}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="detail-mail-content-wrap">
              <div className="detail-mail-content-title">내용</div>
              <div className="detail-mail-content">
                <textarea id="content" {...register("content")}></textarea>
              </div>
            </div>
            <div className="detail-mail-attchment-wrap">
              <div className="detail-mail-attchment-inner">
                <div className="detail-mail-left-title">첨부파일</div>
                <div className="detail-mail-attchment">
                  <input
                    {...register("file")}
                    type="file"
                    id="files"
                    ref={fileInput}
                    accept=".pdf"
                    multiple
                    onChange={(e) => handleChange(e)}
                  />
                  <input type="text" id="file-submit" ref={fileRef} disabled />
                </div>
              </div>
              <div className="attchment-upload-btn">
                <button type="button">
                  <label htmlFor="files">업로드</label>
                </button>
              </div>
            </div>
            <div className="detail-mail-sign-void">서명</div>
          </div>
          <div className="submit-btn">
            <Button
              type="button"
              variant="tertiary"
              preset="1"
              onClick={() => navigate(-1)}
            >
              목록
            </Button>
            <span className="void-sec"></span>
            <Button
              type="submit"
              variant="primary"
              preset="2"
              onClick={() => {}}
            >
              메일 발송
            </Button>
          </div>
        </form>
      </div>
    </MailWriteFormStyled>
  );
};

export default MailWriteForm;

const MailWriteFormStyled = styled.div`
  width: 100%;

  .detail-mail-wrap {
    border: solid 1px #ebebeb;
  }

  .detail-mail-top {
    & > div {
      display: flex;
      border-bottom: solid 1px #ebebeb;
    }
  }

  .detail-mail-left-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 70px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: #f9f9f9;
  }

  .detail-mail-right {
    position: relative;
    width: calc(100% - 300px);
    font-size: 15px;
    color: #444;

    input {
      width: 100%;
      height: 70px;
      padding-left: 30px;
    }
  }

  .detail-mail-content-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    background-color: #f9f9f9;
    border-bottom: solid 1px #ebebeb;
  }

  .detail-mail-sender-form {
    display: flex;
    align-items: center;

    .choice-sender {
      display: flex;
      align-items: center;
      margin-left: 15px;
      border: 1px solid #c09260;
      border-radius: 10px;
      padding: 5px 5px 5px 10px;

      img {
        margin-top: 2px;
        margin-left: 5px;
        display: block;
      }
    }
    .choice-btn {
      cursor: pointer;
    }
  }
  .detail-mail-content {
    padding: 30px;
    font-size: 15px;
    line-height: 1.6;
    color: #444;

    textarea {
      width: 100%;
      height: 400px;
      resize: none;
    }

    textarea:focus {
      outline: none;
    }
  }

  .sender-select {
    position: absolute;
    z-index: 99999999;
    width: 500px;

    & > div {
      display: flex;
      align-items: center;
      height: 35px;
      background-color: #fff;
      border-top: 1px solid #d9d9d9;
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      cursor: pointer;

      &:hover {
        background-color: #d9d9d9;
      }

      & > div {
        display: flex;
        align-items: center;
        width: 100%;
        height: 35px;
        padding-left: 30px;
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }

  .detail-mail-attchment-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1px #ebebeb;
    border-bottom: solid 1px #ebebeb;
  }

  .detail-mail-attchment-inner {
    display: flex;
  }

  .detail-mail-attchment {
    display: flex;
    align-items: center;

    padding-left: 30px;
    cursor: pointer;
  }

  .detail-mail-sign-void {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: #f5f6fa;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
  }

  .attchment-upload-btn {
    display: flex;
    justify-content: center;
    width: 120px;
    height: 50px;
    background-color: #444;
    margin-right: 10px;
    cursor: pointer;
    button {
      font-size: 18px;
      color: #fff;
    }
    label {
      cursor: pointer;
    }
  }

  #files {
    display: none;
  }

  #file-submit {
    width: 40vw;
    background-color: #fff;
    font-size: 18px;
    color: #c09260;
    text-decoration: underline;
  }

  .submit-btn {
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;
