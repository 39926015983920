import { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

import Layout from "layout/layout";

import { PAGE_LAYOUT } from "utils/constants";

import BtnCheckOffSVG from "assets/images/btn_check_off.png";
import BtnCheckOnSVG from "assets/images/btn_check_on.png";

import MailTapMenu from "./MailTapMenu";
import MailReceivedForm from "./MailReceivedForm";
import MailWriteForm from "./MailWriteForm";
import MailSentForm from "./MailSentForm";
import MailTrashForm from "./MailTrashForm";

const Mail = () => {
  const location = useLocation();

  return (
    <Layout page={PAGE_LAYOUT.mail}>
      <MailStyled>
        <MailTapMenu textChange={location?.state?.status} />
        <div className="status-wrap">
          {location?.state?.status === "RECEIVED" && (
            <MailReceivedForm textChange={location?.state?.status} />
          )}
          {location?.state?.status === "SENT" && (
            <MailSentForm textChange={location?.state?.status} />
          )}
          {location?.state?.status === "TRASH" && (
            <MailTrashForm textChange={location?.state?.status} />
          )}
          {location?.state?.status === "WRITE" && <MailWriteForm />}
        </div>
      </MailStyled>
    </Layout>
  );
};

export default Mail;

const MailStyled = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  margin: 40px;

  & > div {
    display: flex;
    padding: 35px;
  }

  .mail-wrap {
    width: 100%;

    .mail-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .mail-del-btn {
      width: 100px;
      height: 44px;
      border-radius: 6px;
      background-color: #444;
      color: #fff;
      cursor: pointer;
    }

    .recovery-btn {
      width: 100px;
      height: 44px;
      border-radius: 6px;
      background-color: #444;
      color: #fff;
      cursor: pointer;
      margin-right: 10px;
    }

    .everlast-del-btn {
      width: 100px;
      height: 44px;
      border-radius: 6px;
      background-color: #c09260;
      color: #fff;
      cursor: pointer;
    }

    .mail-body {
      margin-top: 30px;
      table {
        width: 100%;
      }

      input {
        appearance: none;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        background: url(${BtnCheckOffSVG}) no-repeat center/100%;

        &:checked {
          background: url(${BtnCheckOnSVG}) no-repeat center/100%;
        }
      }

      thead {
        height: 68px;
        background-color: #f9f9f9;

        th {
          font-size: 18px;
          font-weight: bold;
          vertical-align: middle;
          color: #101010;
        }
      }

      tbody {
        tr {
          height: 66px;
          border-bottom: 1px solid #ebebeb;
        }

        td {
          font-size: 15px;
          font-weight: normal;
          vertical-align: middle;
          color: #444;
          cursor: pointer;

          &:nth-of-type(3) {
            padding-left: 50px;
          }
        }
      }

      .mail-child1 {
        width: 5%;
        text-align: center;
      }
      .mail-child2 {
        width: 15%;
        text-align: center;
      }
      .mail-child3 {
        width: 40%;
      }
      .mail-child4 {
        width: 20%;
        text-align: center;
      }
      .mail-child5 {
        width: 20%;
        text-align: center;
      }
      .trash-mail-child3 {
        width: 30%;
      }
      .trash-mail-child4 {
        width: 10%;
        text-align: center;
      }
      .ellipsis {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
