import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    name: "",
    userRole: "",
    userId: "",
    userInfo: {},
    permissions: {},
  },
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
});

export const { setName, setUserRole, setUserId, setUserInfo, setPermissions } =
  authSlice.actions;

export default authSlice.reducer;
