import React, { useState, useRef, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Chat from "components/Chat/Chat";
import NextBtnSVG from "assets/images/btn_next_circle_nonbg.svg";
import NextBtnBSVG from "assets/images/btn_next_B.svg";

enum TabTypeEnum {
  DEFAULT,
  MENU1,
  MENU2,
  MENU3,
  MENU4,
  MENU5,
  MENU6,
  MENU7,
  MENU8,
  MENU9,
  MENU10,
  MENU11,
  MENU12,
  MENU13,
}

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const menuRef = useRef([] as any);
  const downMenuRef = useRef([] as any);
  const user = useSelector((s: any) => s.auth);
  const [isChat, setIsChat] = useState(false);
  const [tabType, setTabType] = useState<TabTypeEnum>(TabTypeEnum.DEFAULT);
  const [splitLocation, setSplitLocation] = useState<string>("");

  useEffect(() => {
    setSplitLocation(location?.pathname + location?.search);
  }, []);

  useEffect(() => {
    switch (splitLocation.split("/")[1]) {
      case "home":
        setTabType(TabTypeEnum.DEFAULT);
        break;
      case "curr_status":
        setTabType(TabTypeEnum.MENU1);
        break;
      case "work_status":
        setTabType(TabTypeEnum.MENU2);
        break;
      case "approval":
        setTabType(TabTypeEnum.MENU3);
        break;
      case "work":
        setTabType(TabTypeEnum.MENU4);
        break;
      case "mail":
        setTabType(TabTypeEnum.MENU5);
        break;
      case "mypage":
        setTabType(TabTypeEnum.MENU6);
        break;
      case "notice":
        setTabType(TabTypeEnum.MENU7);
        break;
      case "auth_setting":
        setTabType(TabTypeEnum.MENU8);
        break;
      case "process":
        setTabType(TabTypeEnum.MENU9);
        break;
      case "help":
        setTabType(TabTypeEnum.MENU10);
        break;
      case "linen":
        setTabType(TabTypeEnum.MENU11);
        break;
      case "setting":
        setTabType(TabTypeEnum.MENU12);
        break;
      case "part_timer_support":
        setTabType(TabTypeEnum.MENU13);
        break;
      default:
        setTabType(TabTypeEnum.DEFAULT);
        break;
    }
  }, [splitLocation]);

  return (
    <SidebarStyled className="sidebar-wrap">
      <div className="menu-wrap">
        <nav>
          <ul>
            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU1}
              ref={(el) => (menuRef.current[0] = el)}
              onClick={() => navigate("/curr_status/employee")}
              onMouseOver={() =>
                (menuRef.current[0].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[0].children[0].style.display = "none")
              }
            >
              CRM
              <ul className="float-menu">
                <li
                  ref={(el) => (downMenuRef.current[0] = el)}
                  onMouseOver={() =>
                    (downMenuRef.current[0].children[1].style.display = "block")
                  }
                  onMouseLeave={() =>
                    (downMenuRef.current[0].children[1].style.display = "none")
                  }
                  onClick={() => navigate("/curr_status/employee")}
                >
                  회원관리
                  <img src={NextBtnBSVG} alt="하위메뉴 버튼 이미지" />
                  <ul className="float-down-menu">
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/curr_status/employee");
                      }}
                    >
                      - 기본정보
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/curr_status/employee");
                      }}
                    >
                      - 식단관리기록
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/curr_status/employee");
                      }}
                    >
                      - 걸음 수
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/curr_status/employee");
                      }}
                    >
                      - 체성분
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/curr_status/employee");
                      }}
                    >
                      - 혈압
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/curr_status/employee");
                      }}
                    >
                      - 혈당
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/curr_status/employee");
                      }}
                    >
                      - 건강리포트
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/curr_status/employee");
                      }}
                    >
                      - 검진기록
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/curr_status/employee");
                      }}
                    >
                      - 설문기록
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/curr_status/employee");
                      }}
                    >
                      - 상담조회
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/curr_status/employee");
                      }}
                    >
                      - 프리미엄 현황
                    </li>
                  </ul>
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/curr_status/part_timer_support");
                  }}
                >
                  앱푸시 관리
                </li>
              </ul>
            </SideMenuStyled>
            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU2}
              ref={(el) => (menuRef.current[1] = el)}
              onClick={() => navigate("/work_status")}
              onMouseOver={() =>
                (menuRef.current[1].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[1].children[0].style.display = "none")
              }
            >
              기준 관리
              <ul className="float-menu">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/work_status");
                  }}
                >
                  카테고리 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/work_status");
                  }}
                >
                  메뉴등록 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/work_status");
                  }}
                >
                  정기메뉴 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/work_status");
                  }}
                >
                  세트메뉴 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/work_status");
                  }}
                >
                  프로그램메뉴 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/work_status");
                  }}
                >
                  휴무일 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/work_status");
                  }}
                >
                  채널 관리
                </li>
              </ul>
            </SideMenuStyled>
            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU3}
              ref={(el) => (menuRef.current[2] = el)}
              onClick={() => navigate("/approval")}
              onMouseOver={() =>
                (menuRef.current[2].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[2].children[0].style.display = "none")
              }
            >
              상품 관리
              <ul className="float-menu">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/approval");
                  }}
                >
                  상품 리스트
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/approval");
                  }}
                >
                  진열순서 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/approval");
                  }}
                >
                  검색키워드 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/approval");
                  }}
                >
                  공통요소 관리
                </li>
              </ul>
            </SideMenuStyled>
            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU4}
              ref={(el) => (menuRef.current[3] = el)}
              onMouseOver={() =>
                (menuRef.current[3].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[3].children[0].style.display = "none")
              }
              onClick={() => navigate("/work/house/roundley")}
            >
              주문 관리
              <ul className="float-menu">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/work/house/roundley");
                  }}
                >
                  주문 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/work/house/roundley");
                  }}
                >
                  일괄 배송일/매뉴변경
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/work/house/roundley");
                  }}
                >
                  취소/환불 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/work/house/roundley");
                  }}
                >
                  알림톡 발송현황
                </li>
              </ul>
            </SideMenuStyled>
            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU5}
              ref={(el) => (menuRef.current[4] = el)}
              onClick={() =>
                navigate("/mail", {
                  state: { status: "RECEIVED" },
                })
              }
              onMouseOver={() =>
                (menuRef.current[4].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[4].children[0].style.display = "none")
              }
            >
              건강검진 관리
              <ul className="float-menu">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/mail", {
                      state: { status: "RECEIVED" },
                    });
                  }}
                >
                  건강검진관리
                </li>
              </ul>
            </SideMenuStyled>
          </ul>
          <ul>
            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU6}
              ref={(el) => (menuRef.current[5] = el)}
              onClick={() => navigate("/mypage")}
              onMouseOver={() =>
                (menuRef.current[5].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[5].children[0].style.display = "none")
              }
            >
              상담 관리
              <ul className="float-menu">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/mypage");
                  }}
                >
                  집중상담(챗봇)
                </li>
                <li
                  ref={(el) => (downMenuRef.current[1] = el)}
                  onMouseOver={() =>
                    (downMenuRef.current[1].children[1].style.display = "block")
                  }
                  onMouseLeave={() =>
                    (downMenuRef.current[1].children[1].style.display = "none")
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/mypage");
                  }}
                >
                  화상상담
                  <img src={NextBtnBSVG} alt="하위메뉴 버튼 이미지" />
                  <ul className="float-down-menu">
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/mypage");
                      }}
                    >
                      - 상담 정보
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/mypage");
                      }}
                    >
                      - 상담 진행
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/mypage");
                      }}
                    >
                      - 상담 내역
                    </li>
                  </ul>
                </li>
                <li
                  ref={(el) => (downMenuRef.current[2] = el)}
                  onMouseOver={() =>
                    (downMenuRef.current[2].children[1].style.display = "block")
                  }
                  onMouseLeave={() =>
                    (downMenuRef.current[2].children[1].style.display = "none")
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/mypage");
                  }}
                >
                  설문관리
                  <img src={NextBtnBSVG} alt="하위메뉴 버튼 이미지" />
                  <ul className="float-down-menu">
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/mypage");
                      }}
                    >
                      - 설문 템플릿 관리
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/mypage");
                      }}
                    >
                      - 설문 결과 조회
                    </li>
                  </ul>
                </li>
                {/*)}*/}
              </ul>
            </SideMenuStyled>
            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU7}
              ref={(el) => (menuRef.current[6] = el)}
              onClick={() => navigate("/notice/event")}
              onMouseOver={() =>
                (menuRef.current[6].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[6].children[0].style.display = "none")
              }
            >
              코칭 관리
              <ul className="float-menu">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/notice/event");
                  }}
                >
                  코치관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/notice/event");
                  }}
                >
                  스케줄관리
                </li>
                <li
                  ref={(el) => (downMenuRef.current[3] = el)}
                  onMouseOver={() =>
                    (downMenuRef.current[3].children[1].style.display = "block")
                  }
                  onMouseLeave={() =>
                    (downMenuRef.current[3].children[1].style.display = "none")
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/notice/event");
                  }}
                >
                  CS관리
                  <img src={NextBtnBSVG} alt="하위메뉴 버튼 이미지" />
                  <ul className="float-down-menu">
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/notice/event");
                      }}
                    >
                      - 공지사항
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/notice/event");
                      }}
                    >
                      - 문의관리
                    </li>
                  </ul>
                </li>
              </ul>
            </SideMenuStyled>
            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU8}
              ref={(el) => (menuRef.current[7] = el)}
              onClick={() => navigate("/auth_setting")}
              onMouseOver={() =>
                (menuRef.current[7].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[7].children[0].style.display = "none")
              }
            >
              프로모션 관리
              <ul className="float-menu">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/auth_setting");
                  }}
                >
                  상품별쿠폰 발급
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/auth_setting");
                  }}
                >
                  장바구니쿠폰 발급
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/auth_setting");
                  }}
                >
                  오프라인쿠폰 발급
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/auth_setting");
                  }}
                >
                  증정상품 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/auth_setting");
                  }}
                >
                  타임세일
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/auth_setting");
                  }}
                >
                  이벤트 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/auth_setting");
                  }}
                >
                  사음품 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/auth_setting");
                  }}
                >
                  사은품주소변경 관리
                </li>
              </ul>
            </SideMenuStyled>

            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU9}
              ref={(el) => (menuRef.current[8] = el)}
              onMouseOver={() =>
                (menuRef.current[8].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[8].children[0].style.display = "none")
              }
              onClick={() => navigate("/process")}
            >
              멤버십
              <ul className="float-menu">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/process");
                  }}
                >
                  포인트 관리
                </li>
              </ul>
            </SideMenuStyled>
            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU10}
              ref={(el) => (menuRef.current[9] = el)}
              onMouseOver={() =>
                (menuRef.current[9].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[9].children[0].style.display = "none")
              }
              onClick={() => navigate("/help")}
            >
              BBS/CS
              <ul className="float-menu">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/help");
                  }}
                >
                  공지사항 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/help");
                  }}
                >
                  FAQ 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/help");
                  }}
                >
                  1:1문의 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/help");
                  }}
                >
                  1:1문의 인사말 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/help");
                  }}
                >
                  뉴스/매거진 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/help");
                  }}
                >
                  건강라이프 관리
                </li>
              </ul>
            </SideMenuStyled>
            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU11}
              ref={(el) => (menuRef.current[10] = el)}
              onMouseOver={() =>
                (menuRef.current[10].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[10].children[0].style.display = "none")
              }
              onClick={() => navigate("/linen")}
            >
              컨텐츠 관리
              <ul className="float-menu">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/linen");
                  }}
                >
                  메인KV 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/linen");
                  }}
                >
                  메인 띠배너 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/linen");
                  }}
                >
                  팝업 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/linen");
                  }}
                >
                  서브KV 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/linen");
                  }}
                >
                  디자인 추천상품 관리
                </li>
              </ul>
            </SideMenuStyled>
            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU12}
              ref={(el) => (menuRef.current[11] = el)}
              onMouseOver={() =>
                (menuRef.current[11].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[11].children[0].style.display = "none")
              }
              onClick={() => navigate("/setting/default")}
            >
              환경설정
              <ul className="float-menu">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/setting/default");
                  }}
                >
                  관리자계정 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/setting/default");
                  }}
                >
                  배송비 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/setting/default");
                  }}
                >
                  이용약관 관리
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/setting/default");
                  }}
                >
                  개인정보처리방침 관리
                </li>
              </ul>
            </SideMenuStyled>
            <SideMenuStyled
              active={tabType === TabTypeEnum.MENU13}
              ref={(el) => (menuRef.current[12] = el)}
              onMouseOver={() =>
                (menuRef.current[12].children[0].style.display = "block")
              }
              onMouseLeave={() =>
                (menuRef.current[12].children[0].style.display = "none")
              }
              onClick={() => navigate("/part_timer_support")}
            >
              통계
              <ul className="float-menu">
                <li
                  ref={(el) => (downMenuRef.current[4] = el)}
                  onMouseOver={() =>
                    (downMenuRef.current[4].children[1].style.display = "block")
                  }
                  onMouseLeave={() =>
                    (downMenuRef.current[4].children[1].style.display = "none")
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/part_timer_support");
                  }}
                >
                  모니터링
                  <img src={NextBtnBSVG} alt="하위메뉴 버튼 이미지" />
                  <ul className="float-down-menu">
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/part_timer_support");
                      }}
                    >
                      - 건강위험 변화현황
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/part_timer_support");
                      }}
                    >
                      - 건강설문현황
                    </li>
                  </ul>
                </li>
              </ul>
            </SideMenuStyled>
          </ul>
        </nav>
        <div className="move-website">
          <a href="">
            {/*공식 웹사이트로 이동{" "}*/}
            {/*<img src={NextBtnSVG} alt="공식 웹사이트 이동 버튼 이미지" />*/}
          </a>
        </div>
      </div>
    </SidebarStyled>
  );
};

export default Sidebar;

const SidebarStyled = styled.div`
  width: 250px;

  .menu-wrap {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 500;

    .menu-line {
      display: block;
      width: 170px;
      height: 1px;
      margin: 36px 0 36px 40px;
      background-color: #e6e6e6;
    }
  }

  nav {
    margin: 52px 0 180px 0;
    & > ul > li {
      padding: 17px 40px;
      cursor: pointer;
    }
  }

  .float-menu {
    display: none;
    position: absolute;
    z-index: 99999999;
    margin-top: -37px;
    left: 250px;
    width: 224px;
    background-color: #000;
    color: #fff;

    & > li {
      display: flex;
      justify-content: space-between;
      padding: 16px 18px 16px 30px;

      &:hover {
        background-color: #a0adff;
      }
    }
  }

  .float-menu-style {
    width: 240px;
  }

  .float-down-menu {
    display: none;
    position: absolute;
    z-index: 999999;
    margin-top: -16px;
    left: 224px;
    width: 170px;
    background-color: #5d5d5d;
    padding: 14px 0 14px 26px;

    & > li {
      font-size: 14px;
      font-weight: normal;
      padding: 8px 0;
      color: #a7a7a7;

      &:hover {
        color: #fff;
      }
    }
  }

  .float-down-menu-style {
    width: 170px;
  }

  .move-website {
    a {
      width: 250px;
      height: 64px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 14px;
      font-weight: normal;
      color: #828282;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1600px) {
    width: 220px;

    .float-menu {
      left: 220px;
    }
    .move-website {
      a {
        width: 220px;
      }
    }
  }
`;

const SideMenuStyled = styled.li<{ active?: boolean }>`
  ${({ active }) =>
    active
      ? css`
          font-size: 18px;
          color: #a0adff;
          text-decoration-line: underline;
          text-underline-offset: 5px;
          text-decoration-thickness: 2px;
          color: #a0adff;
        `
      : css`
          &:hover {
            text-decoration-line: underline;
            text-underline-offset: 5px;
            text-decoration-thickness: 2px;
            color: #a0adff;
          }
        `}
`;
